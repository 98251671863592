import { StudentDeepDiveDetails } from '../../components/studentDeepDive/component';
import { StudentOverall } from "../../components/studentDeepDive/studentOverall/component";
import { AssignmentsTable } from "../../components/studentDeepDive/assignmentsTable/component";
import { StudentDeepDiveChapterSelectInput } from "../../components/studentDeepDive/chapterSelectInput/component";
import { JunctionAssessmentDetailModal } from "../../components/studentDeepDive/assessmentDetailModal/component";
import { JunctionAssessmentDetailAttemptTable } from '../../components/studentDeepDive/assessmentDetailAttemptTable/component';
import { JunctionAssessmentAttemptLearnosityIframe } from '../../components/studentDeepDive/assessmentAttemptLearnosityIframe/component';

import { AssessmentDeepDive } from '../../components/assessmentDeepDive/component';
import { AssessmentDeepDiveDetails } from '../../components/assessmentDeepDive/details/component';
import { StudentSubmissions } from '../../components/assessmentDeepDive/details/studentSubmissions/component';
import { StudentSubmissionsDetailsModal } from '../../components/assessmentDeepDive/details/studentSubmissions/detailsModal/component';
import { PerformanceByItem } from '../../components/assessmentDeepDive/details/performanceByItem/component';
import { PerformanceByLearningObjective } from '../../components/assessmentDeepDive/details/performanceByLearningObj/component';
import { LiveProgress } from '../../components/assessmentDeepDive/details/liveProgress/component';
import { ItemsDetailedReport } from '../../components/assessmentDeepDive/details/itemsDetailedReport/component';

import { DateTabularDisplay } from "../../components/dateTabularDisplay/component";
import { CourseHeader } from "../../components/templateComponents/courseHeader/component";
import { ReportRow } from "../../components/templateComponents/reportRow/component";
import { ReportChartRow } from "../../components/templateComponents/reportChartRow/component";
import { HighChart } from "../../components/templateComponents/highChart/component";
import { ListWithCheckbox } from "../../components/templateComponents/list/component";

export default (parted: boolean = false) => {
    StudentDeepDiveDetails;
    StudentOverall;
    AssignmentsTable;
    StudentDeepDiveChapterSelectInput;
    JunctionAssessmentDetailModal;
    JunctionAssessmentDetailAttemptTable;
    JunctionAssessmentAttemptLearnosityIframe;   
    AssessmentDeepDive;
    AssessmentDeepDiveDetails;
    StudentSubmissions;
    StudentSubmissionsDetailsModal;
    PerformanceByItem;
    PerformanceByLearningObjective;
    LiveProgress;
    ItemsDetailedReport;
    DateTabularDisplay;
    CourseHeader;
    ReportRow;
    ReportChartRow;
    HighChart;
    ListWithCheckbox;
}