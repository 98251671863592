import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-option 
    :selected="${(x, context) => x.active}"
    class="${(x, context) => x.active ? 'selected' : ''}"
    aria-selected="${(x, context) => x.active ? 'true' : 'false'}"
    @mouseenter="${(x, c) => x.onHoverConvo(c, c.event)}"
    @mouseleave="${(x, c) => x.onHoverConvo(c, c.event, false)}"
    @click="${(x, c) => x.handleItem()}"
    >    
    <div class="workarea${x => x.extended === 'true' ? ' extended' : ''}" >
        <p id="entry_${ (x,c) => x.item.id }" class="convo-link" >
            ${x => x.item.title ? x.item.title : ('Convo: ' + x.item.id.substring(-8))}
        </p>
        ${T.when(x => x.extended !== 'true', T.html`<div class="dots${(x, c) => x.dotsActive === x.item.id ? ' opened' : ''}">
            <div class="d-header" @click="${(x, c) => x.showDots()}"><jnct-icon size="21px">more_vert</jnct-icon>
            </div>
            <ul>
                <li>
                    <span @click="${(x, c) => x.delete(x.item)}">Delete</span>
                </li>
                <li>
                    <span @click="${(x, c) => x.rename(x.item)}">Rename</span>
                </li>
            </ul>
        </span>
    </div>`)}
    ${T.when(x => x.extended === 'true', T.html`<div class="convo-details">
        <span class="last-msg-date">${ x => x.getDateInfo()}</span>
        <span class="chapters">${ x => x.getChaptersInfo()}</span>
    </div>`)}
</fluent-option>`;

import './styles/layout.scss';
const styles = T.css`:host {
  position: relative;
  overflow: visible;
  margin: 4px 0;
  height: 32px;
}

fluent-option {
  --neutral-fill-stealth-rest: transparent;
  --neutral-fill-stealth-hover: var(--neutral-8);
  --neutral-fill-stealth-active: var(--chat-nav-hover-bg);
  --neutral-fill-stealth-focus: var(--chat-nav-hover-bg);
  --neutral-fill-secondary-rest: var(--primary-20);
  --neutral-fill-secondary-hover: var(--chat-nav-hover-bg);
  --accent-fill-rest: transparent;
  --neutral-fill-secondary-active: var(--chat-nav-hover-bg);
  --neutral-fill-secondary-focus: var(--chat-nav-hover-bg);
  color: #FFF;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: inherit;
  overflow: visible;
  position: relative;
  padding: 0 0 0 4px;
  width: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  font-family: inherit;
}
fluent-option:before {
  display: none;
}
fluent-option .workarea {
  width: 100%;
  height: 100%;
  position: absolute;
}
fluent-option .workarea > p {
  margin: 4px 35px 0 0;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
fluent-option .workarea .convo-details {
  position: absolute;
  width: 100%;
  height: 18px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: space-between;
}
fluent-option .workarea .convo-details span {
  overflow: hidden;
  color: var(--neutral-50);
  text-overflow: ellipsis;
  font-family: inherit;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
fluent-option .workarea .convo-details span:last-child {
  margin-right: 10px;
}
fluent-option .workarea.extended > p {
  margin: 4px 4px 0 0;
  width: calc(100% - 8px);
  color: var(--neutral-90);
}
fluent-option .selected {
  z-index: 1001;
}
fluent-option .selected .workarea {
  height: 120px;
}
fluent-option .dots {
  display: none;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 5px;
  top: 3px;
  border-radius: 4px;
}
fluent-option .dots .d-header {
  padding: 5px;
  width: 100%;
  color: #FFF;
  font-weight: normal;
  height: 100%;
  transform: translate(-3px, -3px);
}
fluent-option .dots ul {
  display: none;
  list-style: none;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: var(--chat-nav-light-hover-bg-solid, rgb(85, 186, 183));
  border-radius: 4px;
  padding: 0;
  right: 0;
  bottom: -4px;
  transform: translateY(100%);
}
fluent-option .dots ul li {
  padding: 5px;
  border-radius: 4px;
}
fluent-option .dots ul li span {
  border-radius: 4px;
  display: block;
  padding: 5px;
  cursor: pointer;
}
fluent-option .dots ul li:hover span {
  background-color: var(--primary_color);
}
fluent-option .dots.opened ul {
  display: block;
}
fluent-option .dots.opened ul li {
  display: list-item;
}
fluent-option:hover .dots, fluent-option.selected .dots {
  display: block;
}
fluent-option:hover .dots:hover, fluent-option:hover .dots.opened, fluent-option.selected .dots:hover, fluent-option.selected .dots.opened {
  background-color: var(--chat-nav-light-hover-bg-solid, rgb(85, 186, 183));
}`;

const shadowOptions = {"mode":"open"};
import { IConvo } from '@backend-types';

import { RWSViewComponent, RWSView, RWSInject, observable, attr } from '@rws-framework/client';
import ConvoService, { ConvoServiceInstance } from '../../../../../../services/ConvoService';
import moment from 'moment';

const _MIN_CHARS_FOR_TOOLTIP = 23;

@RWSView('chat-nav-item', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatNavItem extends RWSViewComponent  {

  @observable item: IConvo | null = null;
  @observable itemContext: any | null = null;
  @observable dotsActive: string | null = null;
  @observable convoId: string | null = null;
  @observable active: boolean = false;

  @attr extended: string = 'false';

  constructor(@RWSInject(ConvoService) private convoService: ConvoServiceInstance) {
    super();
  }

  itemChanged(oldVal: IConvo | undefined, newVal: IConvo | null){
    if(newVal){
      this.item = newVal;
    }
  }

  connectedCallback(): void {
    super.connectedCallback();

    if(this.extended === 'true'){
      (this.getShadowRoot().host as HTMLElement).style.height = '50px';
      (this.getShadowRoot().host as HTMLElement).style.margin = 'none';
    }  
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  getDateInfo(): string
  {
    return this.item.messages.length ? moment(this.item.messages[this.item.messages.length-1].created_at).format('MMMM DD, Y') : '';
  }

  getChaptersInfo(): string
  {
    if(!this.item.messages.length){
      return '';
    }

    let chapter: number | null = null;

    for(const msg of this.item.messages){     

      if(msg.var_storage.chapter_number !== null){

        if(chapter !== null && chapter !== msg.var_storage.chapter_number){
          return 'Multiple chapters';
        }

        chapter = msg.var_storage.chapter_number;
      }
    }

    return chapter !== null ? `Chapter ${chapter + 1}` : '';
  }

  handleItem(): void {    
    this.$emit('convo.propagation', this.item.id);    
  }

  showDots(): void {  
  
    console.log(this.dotsActive, this.item.id)

    if(this.dotsActive == this.item.id){
      this.dotsActive = null;
      this.$emit('chat-nav:set-active-dots', null);
      return;
    }

    this.dotsActive = this.item.id;
    console.log('sent', this.item.id)
    this.$emit('chat-nav:set-active-dots', this.item.id);
  }

  async delete(item: IConvo): Promise<void> {
    this.$emit('chat-nav:set-active-dots', null);
    await this.convoService.deleteConvo(item.id);
    this.$emit('chat-nav:reload-list', null);
  }

  async rename(item: IConvo): Promise<void> {
    this.$emit('chat-nav:set-active-dots', null);
    const newName = prompt('Rename conversation', item.title ? item.title : ('Convo: ' + item.id.substring(-8)));

    if (newName) {
      await this.convoService.renameConvo(item.id, newName);
    }

    this.$emit('chat-nav:reload-list', null);
  }


  makeTooltip(): HTMLElement {
    const el: HTMLElement = document.createElement('div');
    el.innerText = this.item.title;
    const hostElement: HTMLElement = this.getShadowRoot().host as HTMLElement;

    const offsetL = this.extended === 'true' ? 80 : -50;
    const offsetT = this.extended === 'true' ? 20 : -5;

    el.style.left = hostElement.getBoundingClientRect().left + offsetL + 'px';
    el.style.top = hostElement.getBoundingClientRect().top - hostElement.getBoundingClientRect().height + offsetT  + 'px';

    el.classList.add('convo-tooltip');

    return el;
  }

  onHoverConvo(item: IConvo, e: MouseEvent, mouseIn: boolean = true) {
    this.removeTooltips(mouseIn, e);

    if(!mouseIn){      
      return;
    }

    if(this.item.title.length <= _MIN_CHARS_FOR_TOOLTIP){
      return;
    }
    
    const tooltip = this.makeTooltip();

    document.querySelector('body').appendChild(tooltip); 
     
  }

  removeTooltips(mouseIn: boolean = true, e: MouseEvent){
    document.querySelectorAll('.convo-tooltip').forEach((item: HTMLElement) => {
      item.remove()      
    });
  }
}

ChatNavItem.defineComponent();

export { ChatNavItem, _MIN_CHARS_FOR_TOOLTIP }