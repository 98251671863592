import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="book-page-element">
    <slot></slot>
</div>   `;

import './styles/layout.scss';
const styles = T.css`.book-page-element {
  background-color: #FFF;
  color: #000;
  font-family: "Times New Roman", Times, serif;
  background-color: #fdfdfd;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  margin: 20px;
  padding: 20px;
  position: relative;
}
.book-page-element:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
}
.book-page-element:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.book-page-element .spacer {
  margin-right: 10px;
}
.book-page-element p {
  margin: 0;
  padding: 0;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent } from '@rws-framework/client';


@RWSView('book-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class BookPage extends RWSViewComponent  {
    
}

BookPage.defineComponent();

export { BookPage };