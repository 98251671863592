// External library imports
import { ControllerActionResponse, ControllerActionErrorResponse } from '@rws-framework/server/src/types/ControllerTypes';
import { BaseResponse as BaseWsResponse, ErrorResponse as ErrorWsResponse } from '@rws-framework/server/src/types/SocketTypes';
import { ILLMChunk } from '@rws-framework/server/src/models/interfaces/IPrompt';

// Local type imports
import IJWTUser from '../types/IJWTUser';

// Local interface imports
import { IBookBind, JunctionAuthUserType } from '../models/interfaces/IBookBind';
import { ILesson } from '../models/interfaces/ILesson';
import IBook, { IChapterDBEntry } from '../models/interfaces/IBook';
import { IExamplePrompt } from '../models/interfaces/IExamplePrompt';
import IMessage from '../models/interfaces/IMessage';
import IConvo from '../models/interfaces/IConvo';
import { ICourse } from '../models/interfaces/ICourse';
import { IChatProfile } from '../models/interfaces/IChatProfile';
import IPersona from '../models/interfaces/IPersona';

import { IConfigSplitterParams, DEFAULT_CONVO_SPLIT_PARAMS } from "@rws-framework/server/src/types/ISplitter";

//Prompts

import {ChapterQueryStartPrompt, ChapterQueryParts} from '../prompt_inputs/ChapterQueryPrompt';

// Hyperparams interfaces
import { 
    IHyperParameter,
    IAnthropicHyperParams,
    IMetaHyperParams,
    ICohereTextHyperParams,
    ICohereDocHyperParams,
    IAmazonHyperParams,
    IAmazonLLMHyperParams,
    IAI21HyperParams,
    _hyperParamsDefaults
} from '../interfaces/hyperparams';

// Book save interfaces
import { 
    IBookInfo,
    IChapterOutput, 
    IBookPageInput,    
} from '../interfaces/IBookInfo';

// Local service imports
import { BedrockBaseModelInput, BedrockBaseModelList, BedrockBaseModel } from '../types/Bedrock';
import { ISplitData } from '../types/ISplitData';
// Local controller imports
import { LessonListActionResponse, BookBindListActionResponse, BookBindFindActionResponse } from '../controllers/types/ICourseController';
import { ITalkApiResponse } from '../controllers/types/ITalkController';
import { ITrainApiResponse, ITrainApiPayload, IPartApiResponse } from '../controllers/types/ITrainController';
import { PersonaFindActionResponse, PersonaListActionResponse } from '../controllers/types/IChatPersonaController';

// Local socket imports
import { ITrainWsResponse, ProcessPayload } from '../types/sockets/ITrainSocket';
import { ChatSocketData, WSChatEndResponseType } from '../types/sockets/IChatSocket';
import { IBookSavePayload, IBookSaveWsResponse } from '../types/sockets/ISaveBookSocket';

// Local config imports
import Claude from '../config/claude_v2_model';

// Local routing imports
import backendRoutes from '../routing/routes';
import { IRWSPromptJSON } from '@rws-framework/server/src/models/interfaces/IPrompt';

import { DEFAULT_CLAUDE2_PARAMS } from '../config/claude_v2_params';

// Re-exports
export {     
    // Types
    WSChatEndResponseType,
    JunctionAuthUserType,
    ControllerActionResponse, 
    ControllerActionErrorResponse,

    // Interfaces
    IBook,
    IPersona,
    IExamplePrompt,
    IMessage,
    IConvo,
    IChatProfile,
    ICourse,
    IBookInfo,
    ISplitData,
    IPartApiResponse,
    IChapterOutput,
    IBookPageInput,
    ITalkApiResponse, 
    ITrainApiResponse,
    ITrainWsResponse,
    ITrainApiPayload,
    ProcessPayload,
    IHyperParameter,
    IAnthropicHyperParams,
    IMetaHyperParams,
    ICohereDocHyperParams,
    ICohereTextHyperParams,
    IAmazonHyperParams,
    IAmazonLLMHyperParams,
    IAI21HyperParams,    
    ILLMChunk,
    IBookSavePayload, 
    IBookSaveWsResponse,
    IJWTUser,
    IChapterDBEntry,
    ILesson,
    IBookBind,

    // Classes and Objects
    BedrockBaseModel, 
    BedrockBaseModelList, 
    ChatSocketData,
    BaseWsResponse, 
    ErrorWsResponse,

    // Constants and Config
    _hyperParamsDefaults,
    backendRoutes,    
    Claude as ClaudeModel,
    DEFAULT_CLAUDE2_PARAMS,

    // Response Types
    PersonaFindActionResponse,
    PersonaListActionResponse,
    LessonListActionResponse, 
    BookBindListActionResponse, 
    BookBindFindActionResponse,

    IRWSPromptJSON,

    ChapterQueryStartPrompt,
    ChapterQueryParts,

    IConfigSplitterParams, 
    DEFAULT_CONVO_SPLIT_PARAMS
};
