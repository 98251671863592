import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="header">
  <img
    src="${x => x.logo}"
    alt="${x => x.logo_alt}"
    class="header__logo">

  <div class="header__info">
    <span class="info">
      ${x => x.name}
    </span>
    <h1 class="header__bold" :innerHTML="${x => x.course_title}">
    </h1>
  </div>
</header>
`;

import './styles/layout.scss';
const styles = T.css`.header {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}
.header__logo {
  width: 65px;
  height: 64px;
  border-radius: 12px;
}
.header__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: var(--neutral-70);
  font-size: 1.14286rem;
  font-weight: 400;
  line-height: 160%;
}
.header__bold {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
  line-height: 140%;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";


@RWSView('course-header', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class CourseHeader extends RWSViewComponent  {
  @attr course_title: string;
  @attr name: string;
  @attr logo?: string;
  @attr logo_alt?: string;

  connectedCallback() {
    super.connectedCallback();
  };
}

CourseHeader.defineComponent();

export { CourseHeader };
