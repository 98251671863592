import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="prompt-form">
    <h4>"${x => x.partName}" prompt part</h4>
    <div class="part-form">
        <textarea @change="${ (x, c) => x.sendChange(c.event) }">${ x => x.value}</textarea>
    </div>
</div>
<fluent-button class="save" @click="${ x => x.resetPrompt()}">Reset</fluent-button>
              `;

import './styles/layout.scss';
const styles = T.css`textarea {
  border-radius: 8px;
  border: 1px solid var(--neutral-30);
  display: block;
  width: 100%;
  height: 160px;
  overflow-y: scroll;
}

fluent-button {
  --neutral-foreground-rest: #FFF;
  --neutral-fill-rest: var(--secondary_color);
  --neutral-fill-active: var(--secondary_color);
  --neutral-fill-hover: var(--secondary_color);
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr } from "@rws-framework/client";
import { IChatProfile, ChapterQueryParts } from '@backend-types';


@RWSView('chat-profile-query-prompt', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatProfileQueryPrompt extends RWSViewComponent  {
    @observable profile: IChatProfile = null;    
    @observable promptContent: string = null;
    @attr partName: string = null;
    @observable value: string = null;

    connectedCallback(): void {
        super.connectedCallback();        

        if(!this.value){
            this.value = ChapterQueryParts[this.partName];
        }
    }

    valueChanged(oldVal: string, newVal: string){
        if(newVal){
            this.value = this.value;
        }
    }

    sendChange(event: Event) {
        this.dispatchEvent(new CustomEvent<[string, string]>('change', {
            detail: [this.partName, (event.target as HTMLTextAreaElement).value]            
        }));
    }

    resetPrompt(){
        this.value = ChapterQueryParts[this.partName];
        (this.$('textarea') as HTMLTextAreaElement).value = this.value;
        this.dispatchEvent(new CustomEvent<[string, string]>('change', {
            detail: [this.partName, this.value]            
        }));
    }
}

ChatProfileQueryPrompt.defineComponent();

export { ChatProfileQueryPrompt }