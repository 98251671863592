import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="dropdown-content  ${x => x.showOptions ? '' : 'hidden'}">
 ${T.repeat(x => x.options, T.html`
    <span class="option" @click="${(x, c) => c.parent.action(x.value)}">
      ${x => x.name}
    </span>
 `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 157px;
  z-index: 100;
  border: 1px solid var(--neutral-20);
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}
.dropdown-content.open-upwards {
  top: auto;
  bottom: 100%;
}

.option {
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: white;
  color: var(--neutral-70);
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}
.option:first-child {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.option:last-child {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.option:hover {
  background-color: var(--neutral-20);
}
.option:focus, .option:active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

.hidden {
  display: none;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr } from "@rws-framework/client";
import { GradebookDropdownActions } from '../dropdownActions';
import { RosterRecord } from "src/types/users";
import { Course } from "src/types/course";
import { Activity } from "src/types/activities";

interface OptionType {
  name: string,
  value: string,
}

@RWSView('gradebook-dropdown', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradebookDropdown extends RWSViewComponent  {
  @attr options: OptionType[];
  @attr student: RosterRecord;
  @attr assessment: Activity;
  @attr course: Course;
  @attr session: any;
  @observable showOptions: boolean = true
  boundClickAwayHandler = this.clickAwayHandler.bind(this);

  connectedCallback() {
    super.connectedCallback();
    this.showOptions = true;
    setTimeout(() => {
      window.addEventListener("click", this.boundClickAwayHandler);
    }, 0);
    this.calculateDropdownPosition();
  }

  action(type: string) {
   switch(type) {
    case 'grade':
      if (this.assessment.type === 'project') {
        this.handleProjectRedirect(this.student, this.assessment, this.course);
        return;
      }
      if (this.assessment.actual === 'comments') {
        const link = this.assessment.link + '?view=comments';
        this.$emit('jnct:openlink:blank', link);
        return
      } 
      if (this.assessment.actual === 'board') {
        const link = '#/' + this.course._id + '/grading_center/board/' + this.assessment.id;
        this.$emit('jnct:openlink:blank', link);
        return;
      }
      const link = '#/' + this.course._id + '/grading_center/' + this.assessment.id + '/bystudent';
      this.$emit('jnct:openlink:blank', link);
      break;
    case 'adjust':
      if (this.assessment.type === 'project') {
        this.handleProjectRedirect(this.student, this.assessment, this.course);
        return;
      }
      if (this.assessment.actual === 'comments') {
        const link = this.assessment.link + '?view=comments';
        this.$emit('jnct:openlink:blank', link);
        return
      };
      if (this.assessment.actual === 'board') {
        const link = '#/' + this.course._id + '/grading_center/board/' + this.assessment.id;
        this.$emit('jnct:openlink:blank', link);
        return;
      };
      GradebookDropdownActions.openAdjustModal(this.student, this.assessment, this.course, this.session);
      break;
    case 'override':
      GradebookDropdownActions.openOverrideModal(this.student, this.assessment, this.course);
      break;
    case 'late-work':
      const url = '#/' + this.course._id + '/late_work_manager';
      this.$emit('jnct:openlink:blank', url);
      break;
    };
  };

  async handleProjectRedirect(student: RosterRecord, component, course: Course) {
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    fetch(APIprefix + "projects/status/" + course._id + '/' + component.id + '/' + student.id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken,
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error('Something went wrong. Please try again later.');
      }
      return response.json();
    }).then((response) => {
      //console.log(response)
      if (["submitted", "submitted late", "graded late", "graded"].includes(response.status)) {
        const url = component.link.replace("#/", "#/speed-grading/").replace("/" + component.id, "") + "/" + component.id + "/" + student.id
        this.$emit('jnct:openlink:blank', url);
      } else {
        this.$emit('jnct:notifications:info', 'No submissions found');
      }
    }).catch(error => {
      this.$emit('jnct:notifications:error', error.message);
    });
  };

  calculateDropdownPosition() {
    const element = this.shadowRoot.querySelector('.dropdown-content')
    const container = document.querySelector('gradebook-instructor').shadowRoot.querySelector('#gradebook');
    const containerRect = container.getBoundingClientRect();
    const triggerRect = element.getBoundingClientRect();
    const triggerRelativeBottom = containerRect.bottom - triggerRect.bottom;

    if (triggerRelativeBottom < 0) {
        element.classList.add('open-upwards');
        element.classList.remove('open-downwards');
    } else {
        element.classList.add('open-downwards');
        element.classList.remove('open-upwards');
    }
  };

  clickAwayHandler(event: MouseEvent) {
    // Check if the click was inside the component or dropdown
    const clickedInsideComponent = event.composedPath().some(
      (el) => el === this || (el instanceof HTMLElement && (el.classList.contains('dropdown-content') || el.classList.contains('option')))
    );

    // If the click was outside, hide the dropdown
    if (!clickedInsideComponent) {
      this.showOptions = false;
      this.remove()
    }
  };


  disconnectedCallback(): void {
    window.removeEventListener("click", this.boundClickAwayHandler);
  }
}

GradebookDropdown.defineComponent();

export { GradebookDropdown };
