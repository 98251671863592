import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.showSetup, T.html`<lti-sync-setup 
  courseId="${x => x.courseId}" 
  :course_config="${ x => x.course_config}"
  :syncAssignments="${x => x.assignments}"
  apiPrefix="${x => x.apiPrefix}"
  ></lti-sync-setup>
`)}
${T.when(x => !x.showSetup, T.html`<section class="lti">
  ${T.when(x => x.ltiStatus === 'loading-cfg', T.html`<main>
    <h3 style="text-align: center;">LTI Sync Manager loading...</h3>
    <jnct-loading-spinner></jnct-loading-spinner>

    <p>Loading LTI data...</p>
  </main>`)}
  ${T.when(x => x.ltiStatus === 'none', T.html`
  <div class="lti__header">
    ${T.when(x => x.active > 1, T.html`
    <button title="go to previous step" class="button__no-border" tabindex="0" @click="${x => x.goPrevious()}">
      <jnct-icon size="21px">arrow_back</jnct-icon>
      Previous step
    </button>
    `)}

    <div class="lti__header--main">
      <h1 class="lti__header--name">
        LTI Sync Manager
      </h1>

      ${T.when(x => x.ltiStatus !== 'loading' && x.ltiStatus !== 'loading-cfg' && x.active == 1, T.html`
      <button class="button__secondary" tabindex="0" @click="${x => x.setup()}">
        LTI Setup
      </button>
      `)}
    </div>

    ${T.when(x => !x.hideSteps, T.html`<ul class="lti__header--steps">
      ${T.repeat(x => x.steps, T.html`
      <li class="step ${(x,c) => c.parent.active >= x.id ? 'active' : ''}">
        ${x => x.title}
        <div class="step__stroke ${(x,c) => c.parent.active >= x.id ? 'active' : ''}"></div>
      </li>
      `)}
    </ul>`)}
  </div>

  ${T.when(x => x.ltiStatus === 'no-cfg', T.html`<main>
    <div class="lti-alert warning">No LTI configuration detected.
  </main>`)}

  ${T.when(x => x.ltiStatus === 'none', T.html`
  <main>
    ${T.when(x => x.active < 3, T.html`<main></main><div class="lti__search">
      <div class="lti__search--title">
        <jnct-icon size="21px">sync</jnct-icon>
        ${x => x.active === 1 ? 'Select Assignments to Sync' : 'Select Students to sync'}
      </div>
      <div class="lti__flex">
        ${T.when(x => x.showNext && x.active == 1, T.html`
        <button class="button__primary" @click="${x => x.goNext()}">Next</button>
        `)}

        ${T.when(x => x.showNext && x.active == 2, T.html`
        <button class="button__primary" @click="${x => x.goSync()}">Sync Now</button>
        `)}
        <jnct-searchbar :handleChange="${x => x.search}"></jnct-searchbar>
      </div>
    </div>`)}


    ${T.when(x => !x.assignments, T.html`
    <div class="lti__loader">
      <jnct-loading-spinner></jnct-loading-spinner>
    </div>
    `)}

    ${T.when(x => x.courseId && x.course_config && x.apiPrefix && !x.course_config[0].selection && !["all", "roll-up", "roll-up-percent", "chapter-percent", "chapter", "total", "total-percent"].includes(x.course_config[0].lti_sync_setup_type), T.html`
      The LTI connection has been established. The checked activities below have already been synced to your LMS. If you would like to change the sync settings, please click the LTI Set Up button above.
      <br><br>
    `)}

    ${T.when(x => x.courseId && x.course_config && x.apiPrefix && (x.course_config[0].lti_sync_setup_type === 'none' || x.course_config[0].lti_sync_setup_type === 'selection' && !Object.keys(x.course_config[0].line_items).length), T.html`
      You are not Syncing any Activities. You can change this choice in LTI Setup.<br>
    `)}

    ${T.when(x => x.courseId && x.course_config && x.apiPrefix, T.html`   
      ${T.when(x => x.active === 1, T.html`
      <lti-sync-assignments
          @change="${(x, c) => x.onSelectedAssignments(c.event) }"
          courseId="${x => x.courseId}"
          :assignments="${x => x.filteredAssignmentsList}"
          :selected="${ x => x.selectedAssignments }"
          apiPrefix="${x => x.apiPrefix}">
      </lti-sync-assignments>
      `)}

      ${T.when(x => x.active === 2, T.html`
        <lti-sync-students 
          courseId="${x => x.courseId}"
          @change="${(x, c) => x.onSelectedStudents(c.event)}"
          :selectedAssignments="${x => x.selectedAssignments}"
          :scores="${x => x.scores}"
          :user_scores="${x => x.user_scores}"
          :sessions="${x => x.sessions}"
          :selectedStudents="${x => x.selectedStudents}"
          :course_config="${ x => x.course_config}"
          :studentList="${x => x.filteredStudents}"
          apiPrefix="${x => x.apiPrefix}">
      </lti-sync-students>
      `)}

      ${T.when(x => x.active === 3, T.html`
        <lti-sync-process
          apiPrefix="${x => x.apiPrefix}"
          courseId="${x => x.courseId}"
          :selectedStudents="${x => x.selectedStudents}"
          :selectedAssignments="${x => x.selectedAssignments}"
          :course_config="${ x => x.course_config}">
          <p>Ready to proceed? ${x => x.selectedStudents.reduce((a,b) => a + b.counter, 0)} grade(s) found that will be synced but first ${x => x.selectedAssignments.length} assignment(s) will be created as needed.</p>
      </lti-sync-process>
      `)}
    `)}  

    ${T.when(x => x.active === 1, T.html`
    <button href="#" class="button__secondary lti-reload" @click="${x => x.reloadLTI()}">
      <jnct-icon size="18px">sync</jnct-icon>Reload Course Data
    </button>`)}        
  </main>`)}
  `)}

  ${T.when(x => x.ltiStatus === 'loading', T.html`
  <div class="lti-loading">
    <strong>Syncing LTI</strong>
    <jnct-loading-spinner></jnct-loading-spinner>
  </div>
  `)}

  ${T.when(x => x.ltiStatus === 'error', T.html`
  <lti-sync-confirm 
    icon="info"
    iconColor="#000"
    buttons="false"
    singleButton=""
    title="No LTI Connection Established">           
      ${T.when(x => x.errorObject, x => x.errorObject.stack)}
  </lti-sync-confirm>
  <div class="button__secondary"
    style="width: 160px; text-align: center;margin: 0 auto 30px auto;"
    @click="${x => x.goToCourseDashboard()}">
    Back to Dashboard
  </div>
  `)}
</section>`)}

${T.when(x => x.pickConfigModal && x.modalConfig, T.html`<jnct-modal
  templateName="jnct-lti-config-modal"
  :genericConfig="${ x => x.modalConfig}"
></jnct-modal>`)}`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.lti-alert {
  border: 1px solid var(--jnct-info-color, #247ed3);
  border-radius: 15px;
  padding: 15px;
  color: var(--jnct-info-color, #247ed3);
  text-align: center;
}
.lti-alert.warning {
  border-color: var(--jnct-warning-color, #c59326);
  color: var(--jnct-warning-color, #c59326);
}
.lti-alert.error {
  border-color: var(--jnct-error-color, #ca1515);
  color: var(--jnct-error-color, #ca1515);
}

.lti {
  box-sizing: border-box;
  padding: 14px 22px;
}
.lti__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.lti__header--previous {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 24px 8px 0px;
  color: var(--neutral-90);
  font-size: 1.14286em;
  font-weight: 600;
  line-height: 160%;
}
.lti__header--previous .material-symbols-outlined {
  font-size: 18px;
}
.lti__header--main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lti__header--name {
  padding: 5px 0px;
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.lti__header--steps {
  width: 100%;
  margin: 0px;
  padding: 0px 0px 12px 0px;
  display: flex;
  align-items: flex-start;
  list-style: none;
}
.lti__header--steps .step {
  flex: 1 0 0;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.lti__header--steps .step:first-child .step__stroke {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.lti__header--steps .step:last-child .step__stroke {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.lti__header--steps .step__stroke {
  height: 6px;
  width: 100%;
  color: black;
  background: var(--neutral-20);
}
.lti__header--steps .step__stroke.active {
  background: var(--primary_color);
}
.lti__header--steps .step.active {
  font-weight: 600;
}
.lti__flex {
  display: flex;
  gap: 20px;
  align-items: center;
}
.lti__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px;
}
.lti__search--title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.lti__search--title .material-symbols-outlined {
  font-size: 22px;
}
.lti__loader {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #0F75B8;
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
  cursor: pointer;
}

.lti-reload {
  display: inline-flex;
  margin-top: 15px;
  text-decoration: none;
  color: var(--neutral-90);
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
}
.lti-reload jnct-icon {
  width: 24px;
  height: 24px;
}`;

const shadowOptions = {"mode":"open"};

import { RWSViewComponent, RWSView, attr, RWSInject, observable } from "@rws-framework/client";
import { LTIConfigType } from "../../types/lti/config";

import LTISyncService, { LTISyncServiceInstance } from "../../services/LTISyncService";
import { IStudent } from "../../types/students"
import { startEventListeners } from "./eventListeners";

import { AssignmentsType, LtiSyncAssignments } from "./children/ltiSyncAssignments/component";
import { LTISyncConfirmValue } from "./children/ltiSyncConfirm/component";

LtiSyncAssignments;

type StepType = {
  title: string,
  id: number,
};

export type SelectedType = {[key :string]: boolean};

type LTIStatusType = 'loading' | 'ok' | 'none' | 'error' | 'no-cfg' | 'loading-cfg';

@RWSView('lti-sync-manager', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class LtiSyncManager extends RWSViewComponent  {
  
  @observable steps: StepType[] = [
    {
      id: 1,
      title: 'Step 1 - Select Assignments',
    },
    {
      id: 2,
      title: 'Step 2 - Select Students',
    },
    {
      id: 3,
      title: 'Step 3 - Sync',
    },
  ];
  
  @observable active: number = 1;

  @observable selectedAssignments: AssignmentsType[] = [];
  @observable selectedStudents: IStudent[] = [];  
  @attr course_configs: LTIConfigType;  
  @observable course_config: LTIConfigType;  
  @observable showNext: boolean = false;
  @attr courseId: string = null;  
  @attr course_max_score: number = null;  
  @observable ltiStatus: LTIStatusType = 'loading-cfg';
  @observable errorObject: Error | any = null;
  @observable user_scores: any = {};
  @observable searchTerm: string | null = null;
  search = this.handleSearch.bind(this);

  @attr assignments: AssignmentsType[] = [];
  @attr scores: any = {};
  @attr students: any = [];
  @attr sessions: any = {};
  @observable studentList: IStudent[] = [];

  @attr apiPrefix: string;

  @observable syncConfirmed: boolean = false;
  @observable hideSteps: boolean = false;
  @observable showSetup: boolean = false;

  @observable completedSyncAssignments: any = [];
  @observable completedSyncGrades: any = [];
  @observable completedSyncSelections: any = [];

  @observable modalConfig = { ltiConfigs: [], save: () => {
      this.$emit('jnct-lti-config-modal:save', true);
    }, switch: () => {
      this.$emit('jnct-lti-config-modal:switch', true);
    }, hide: () => {
      this.$emit('jnct-lti-config-modal:hide', true);
    }
  }

  private _init: boolean = false;
  @observable pickConfigModal: boolean = false;

  constructor(@RWSInject(LTISyncService) private ltiSyncService: LTISyncServiceInstance) {
    super();
  }

  async connectedCallback () {
    super.connectedCallback()        
    startEventListeners.bind(this)()

    this.on<boolean>('jnct:ltisetup:gotoltisyncmanager', (event) => {
      if (event.detail) {
        this.showSetup = false
      }
    })
    
    await this.initialize()
  }

  onSelectedAssignments(event: CustomEvent<AssignmentsType[]>) {
    if (event.detail) {
      this.selectedAssignments = event.detail
      this.checkShowNext()
    }    
  }

  onSelectedStudents(event: CustomEvent<IStudent[]>) {
    if (event.detail) {
      this.selectedStudents = event.detail
      this.checkShowNext()
    }  
  }

  makeAssignments(assignments: AssignmentsType[]): Array<Partial<AssignmentsType>> {
    if (!this.course_config[0].selection || [null, "selection", "all"].includes(this.course_config[0].lti_sync_setup_type)) {
      return assignments;
    }
    if (this.course_config[0].selection && ["chapter", "roll-up", "chapter-percent", "roll-up-percent"].includes(this.course_config[0].lti_sync_setup_type)) {
      return assignments.filter((x: any) => x.type == "lesson" && x.id)
        .map((x: any) => {
          x.xgraded = true;
          if (["chapter-percent", "roll-up-percent"].includes(this.course_config[0].lti_sync_setup_type)) {
            x.max_score = 100
          }
          return x
        });
    }
    // total column only(?)
    return Object.values(this.course_config[0].line_items).map((item: any, i) => ({
      id: item.resourceId, 
      report_title: item.label, 
      xgraded: true, 
      sync_index: -1 - i, 
      max_score: this.course_max_score
    }));
  }

  /*
    Please leave it be - will be exported to RWS package. 
    This works only on string values its a FAST bug. 

    The attrNameChanged grabs objects from outside JS and :attr assignments just fine.

    If possible I'll try to override FAST attributeChangedCallback so we can use it to 
    have a auto setter for changing attrs that will also pass objects. - Papa Black
  */
  attributeChangedCallback(name: string, oldValue: any, newValue: any): void {
    if (!newValue) {
      return
    }

    const componentKeys: (keyof LtiSyncManager)[] = ['courseId']

    if (componentKeys.includes(name as keyof LtiSyncManager)) {

      (this as any)[name] = newValue
    }            
  }

  assignmentsChanged(oldValue: any, newValue: any) {
    if (newValue) {      
      this.assignments = newValue;
    }
  }

  completedSyncAssignmentsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncAssignments = newValue
    }
  }

  completedSyncGradesChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncGrades = newValue
    }
  }

  completedSyncSelectionsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncSelections = newValue
    }
  };  

  ltiStatusChanged(oldValue: LTIStatusType, newValue: LTIStatusType) {    
    this.ltiStatus = newValue
  }

  async initialize() {
    if (this._init) {
      return
    }

    this.addEventListener('jnct:ltistudentlist', (e) => {
      this.studentList = e.detail;
    });


    try {
      if (this.courseId) {
        this.course_config = this.course_configs.filter((el: any) => !el.disabled) // difference between observable vs attr ??

        if (this.course_config && (this.course_config as any[]).length > 1) {
          this.modalConfig = {...this.modalConfig, ltiConfigs: this.course_config as any[]}
          this.pickConfigModal = true

          this.on('jnct-lti-config-modal:done', (e: CustomEvent<string>) => { 
            //console.log('done', e.detail)
            const selectedCourse = this.course_config.find(cfg => cfg._id === e.detail)
            const payload = {
              config: selectedCourse,
              course_id: this.courseId
            };        
    
            //console.log('SELECTED?');
            //@ts-ignore
            const csrfToken = Cookies.get('csrf_token')

            fetch(this.apiPrefix + `lti1.3/pick-cfg/${this.courseId}/${selectedCourse.guid}`, {
              method: 'PUT',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "X-CSRF-Token": csrfToken,
               },
              body: JSON.stringify(payload)
            })
            .then(response => response.json())
            .then(() => {
              //console.log('GOT CONFIG PICKED', selectedCourse)
              this.course_config = [selectedCourse]        
              this.pickConfigModal = false
 
            }).catch((e) => {
              throw e;
            });                              
          })
          
          this.on('jnct-lti-config-modal:hide', (e: CustomEvent<any>) => { 
            this.pickConfigModal = false
          })

          this.on('jnct-lti-config-modal:selected', (e: CustomEvent<any>) => {})
        }

        this.ltiStatus = 'none'
        this.selectedAssignments = []
        this.selectedStudents = []
        this.checkShowNext()
        this.startPick()

        this._init = true
      }
    } catch (e: Error | any) {
      this.ltiStatus = 'error'
    }
  }    

  checkShowNext(){
    if (this.active === 1) {
      this.showNext = this.selectedAssignments.length > 0
    }   
    if (this.active === 2) {
      this.showNext = this.selectedStudents.length > 0
    }
  }

  goPrevious() {
    this.setStep(this.active - 1)
    this.syncConfirmed = false
  }

  goNext() {
    this.setStep(this.active + 1)
    this.syncConfirmed = false
  }

  setup() {
    this.showSetup = true
  }

  setStep(step: number) {
    if (step < 1) {
      return 1
    }

    if (step > 3) {
      return 3
    }

    this.active = step
    this.checkShowNext()
  }

  async reloadLTI(force = true) {
    this.setStep(1)
    await this.initialize()
    this.$emit('jnct:ltisyncmanager:reloadLTI', force)
  }

  async getStudents(): Promise<IStudent> {
    return this.ltiSyncService.getStudents(this.apiPrefix, this.course_config, this.courseId)
  }

  async goSync() {
    const _self = this

    if (!this.syncConfirmed) {
      this.setStep(3)
      return
    }   
  }  

  // private async getLTIConfig() {    
  //   const { result, status } = await this.ltiSyncService.getLTIConfig(this.apiPrefix, this.courseId);
  //   return result;
  // };  

  goFinalConfirm(event: CustomEvent<LTISyncConfirmValue>) {    
    this.reloadLTI()
  }  

  startPick() {
    if (!this.course_config && !this.assignments.length) {
      return
    }

    const cfg = this.course_config[0]

    /* If you have a selection of lineitems use it. Otherwise display everything 
       Note that this view is NOT THE SAME for ltiSetup which is always going to
       show you everything and if you have cfg.selection then the lineites will
       need to be checked
    */
    if (cfg.selection) {
      const lineAssignments: AssignmentsType[] = [];

      for(const lineKey of Object.keys(cfg.line_items)) {
        if (lineKey) {
          const foundAssignment = this.assignments.find((el) => el.id === lineKey)

          if (foundAssignment && !lineAssignments.find(el => el.id === foundAssignment.id)) {
            lineAssignments.push(foundAssignment)
          }
        }
      }
    } else if (this.assignments.length) {
      let assignment: any
      for(assignment of this.assignments) {
        if (cfg.line_items[assignment.id]) {
          this.selectedAssignments.push(assignment)
        }
      }
    }
  }

  get filteredStudents(): IStudent[] {
    if (!this.searchTerm) {
      return this.studentList;
    }

    const searchTermLower = this.searchTerm.toLowerCase();

    return this.studentList.filter(student =>
      student.name.toLowerCase().includes(searchTermLower) ||
      student.surname.toLowerCase().includes(searchTermLower) ||
      student.email.toLowerCase().includes(searchTermLower)
    );
  }

  get filteredAssignmentsList(): Array<Partial<AssignmentsType>> {
    const assignmentsList = this.makeAssignments(this.assignments)
    if (!this.searchTerm) {
      return assignmentsList;
    }

    const searchTermLower = this.searchTerm.toLowerCase();

    return assignmentsList.filter(assignment =>
      assignment.report_title.toLowerCase().includes(searchTermLower) ||
      assignment.title.toLowerCase().includes(searchTermLower)
    );
  }

  handleSearch(value: string) {
    this.searchTerm = value || null;
  }

  goToCourseDashboard() {
    this.$emit('jnct:ltisyncmanager:goto-course-dashboard')
  }
}

LtiSyncManager.defineComponent()

export { LtiSyncManager }
