import RWSClient, { NotifyLogType, NotifyUiType, RWSContainer } from "@rws-framework/client";
import { RWSWebsocketsPlugin, WSOptions } from '@rws-framework/nest-interconnectors';
import { RWSClientInstance } from "@rws-framework/client/src/client";

import "./styles/main.rws.scss";

// For single file output (will inject itself to DI on import):
import initComponents from "./application/_initComponents";

import { backendRoutes } from '@backend-types';

async function initializeApp(automatic = true) {
  const theClient: RWSClientInstance = RWSContainer().get(RWSClient);
  const isParted: boolean = theClient.appConfig.get('parted');

  theClient.assignClientToBrowser();

  theClient.setBackendRoutes(backendRoutes);
  theClient.domService.setHTMLPolicy('junction-policy', (html: string) => {
    return html;
  });

  theClient.setNotifier((message: string, logType: NotifyLogType = 'info', uiType: NotifyUiType = 'alert', onConfirm) => {
    switch (uiType) {
      case 'notification':
        console.info(message);
        
        if(onConfirm){
          onConfirm();
        }

        return null;

      case 'alert':
        alert(message);

        if(onConfirm){
          onConfirm();
        }

        return null;
      case 'silent':
        if (logType == 'warning') {
          console.warn(message);
        } else if (logType == 'error') {
          console.error(message);
        } else {
          console.log(message);
        }
        return;
    }
  });

  
  theClient.addPlugin(RWSWebsocketsPlugin);

  theClient.onInit(async () => {
    initComponents(isParted);    
  });    
}

initializeApp();