export default [
    {
        prefix: '/api',
        routes: [         
            {
                name: 'persona:resource:mod',
                path: '/persona/add'
            },       
            {
                name: 'persona:resource:listadmin',
                path: '/persona/admin'
            },
            {
                name: 'persona:resource:list',
                path: '/persona/list'
            },      
            {
                name: 'persona:resource:delete',
                path: '/persona/delete/:id'
            },   
            {
                name: 'persona:resource:get',
                path: '/persona/:id'
            }, 
            {
                name: 'prompt:resource:delete',
                path: '/example-prompt/delete/:id'
            },
            {
                name: 'prompt:resource:create',
                path: '/example-prompt/add'
            },
            {
                name: 'prompt:resource:listadmin',
                path: '/example-prompt/admin/:courseId'
            },
            {
                name: 'prompt:resource:list',
                path: '/example-prompt/:courseId/:bookId'
            },
            {
                name: 'prompt:resource:get',
                path: '/example-prompt/:id'
            },     
            {
                name: 'talk:models:prompt',
                path: '/talk/prompt'
            },
            {
                name: 'talk:convo:list',
                path: '/talk/convo'
            },
            {
                name: 'talk:convo:instructor-list',
                path: '/talk/instructor-convo'
            },
            {
                name: 'talk:convo:delete',
                path: '/talk/convo/delete/:id'
            },    
            {
                name: 'talk:convo:rename',
                path: '/talk/convo/rename/:id'
            },                    
            {
                name: 'talk:convo:get',
                path: '/talk/convo/:id'
            },            
            {
                name: 'talk:score:response',
                path: '/talk/score/:messageId/:score'
            },
            {
                name: 'talk:models:bedrock-list',
                path: '/talk/bedrock-models'
            },
            {
                name: 'train:part:book',
                path: '/train/part/book'
            },
            {
                name: 'train:process:book',
                path: '/train/process/book'
            },
            {
                name: 'train:save:book',
                path: '/train/save/book'
            },
            {
                name: 'train:delete:book',
                path: '/train/delete/book/:id'
            },
            {
                name: 'train:get:books',
                path: '/train/books'
            },
            {
                name: 'train:get:books-full',
                path: '/train/books-full'
            },
            {
                name: 'train:get:book',
                path: '/train/book/:bookId'
            },
            {
                name: 'train:describe:book',
                path: '/train/book/describe/:bookId/:start'
            },
            {
                name: 'talk:models:about-book',
                path: '/talk/about'
            },
            {
                name: 'course:book-bindings:set',
                path: '/course/:courseId/book-bindings/set'
            },
            {
                name: 'course:lessons:read',
                path: '/course/:courseId/lessons'
            },
            {
                name: 'course:book-bindings:read-list',
                path: '/course/:courseId/book-bindings'
            },
            {
                name: 'course:book-bindings:delete',
                path: '/course/book-bindings/:id/delete'
            },
            {
                name: 'course:book-bindings:find',
                path: '/course/:courseId/:lessonId'
            },
            {
                name: 'chat-profile:resource:create',
                path: '/chat-profile/add'
            },            
            {
                name: 'chat-profile:resource:clear-visuals',
                path: '/chat-profile/clear/:type/:id'
            },
            {
                name: 'chat-profile:resource:get',
                path: '/chat-profile/:id'
            },

        ]
    }    
];