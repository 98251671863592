import { RWSClientInstance } from '@rws-framework/client/src/client';
import {declareRWSComponents} from '@rws-framework/client/src/components';

import _junction from './_init/_junction';
import _grading from './_init/_grading';
import _activity from './_init/_activity';
import _lwm from './_init/_lwm';
import _lti from './_init/_lti';
import _ai from './_init/_ai';
import _dd from './_init/_dd';

export default (parted: boolean = false) => {
    if (!parted) {
        _junction(parted);
        _grading(parted);
        _activity(parted);
        _lwm(parted);
        _lti(parted);
        _dd(parted);
        _ai(parted);
    }

    declareRWSComponents(parted);
   
    RWSClientInstance.defineAllComponents();
}
