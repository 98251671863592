import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="grading__header">
  <button
    class="button__no-border"
    @click="${x => x.goToGradingCenter()}">
    <span class="material-symbols-outlined">arrow_back</span>
    Back to Grading Center
  </button>

  <div class="grading__flex">
    ${T.when(x => x.actions, T.html`
      <fluent-select title="Actions" class="main__select" @change="${(x, c) => x.change(c.event)}">
        <fluent-option value="">
          Actions
        </fluent-option>
        <fluent-option value="givemax">
          Max Points
        </fluent-option>
      </fluent-select>
    `)}

    <button
      class="button__primary ${x => x.disabled? 'disabled' : ''}"
      @click="${x => x.disabled ? '' : x.save()}">
      ${x => x.name ? x.name : 'Save Changes'}
    </button>
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.grading__header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  top: -10px;
  padding: 22px 22px;
  margin-bottom: 22px;
  box-shadow: 0px 4px 14px -9px rgba(0, 0, 0, 0.05);
  background: white;
}

.grading__flex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.select {
  width: 195px;
  min-width: unset;
}

fluent-select {
  min-width: unset;
  height: 42px;
  font-family: inherit;
}

fluent-select::part(control) {
  width: 195px;
  padding: 8px 12px;
  border-radius: 12px;
  background: var(--neutral-20);
  color: var(--neutral-60);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
  font-family: inherit;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

import { ConfirmationModal } from '../../confirmationModal/component';


@RWSView('grading-header', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradingHeader extends RWSViewComponent  {
  @attr actions: boolean;
  @attr name?: string;
  @observable disabled: boolean;
  save: Function | undefined;

  beforeUnloadBind = this.beforeUnload.bind(this);
  clickingAwayBind = this.clickingAway.bind(this);

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('jnct:click:catcher', this.clickingAwayBind, true);
    window.addEventListener('beforeunload', this.beforeUnloadBind, true);
  };

  clickingAway(e) {
    e.preventDefault();
    if (this.disabled) {
      this.$emit('jnct:navigation:gofromwarningurl', e.detail)
    }
    if (!this.disabled) {
      const modal = document.createElement('confirmation-modal') as ConfirmationModal;
      modal.title = 'Leaving page';
      modal.text = 'Your changes will not be saved.';
      modal.button__text = 'Leave';
      modal.button__obj = {action: 'jnct:navigation:gofromwarningurl', detail: e.detail};
      document.body.appendChild(modal);
      //@ts-ignore
      document.documentElement.querySelector('#jnct-navigation').active = '';
      //@ts-ignore
      document.documentElement.querySelector('#jnct-navigation').active_dropdown = '';
      return;
    }
  }

  beforeUnload(e) {
    if (!this.disabled) {
      e.returnValue = "popup";
      return e.returnValue;
    }

    return undefined;
  }

  disconnectedCallback() {
    document.removeEventListener('jnct:click:catcher', this.clickingAwayBind, true);
    window.removeEventListener('beforeunload', this.beforeUnloadBind, true);
  }

  goToGradingCenter() {
    if (!this.disabled) {
      const modal = document.createElement('confirmation-modal') as ConfirmationModal;
      modal.title = 'Leaving Page';
      modal.text = 'Your changes will not be saved.';
      modal.button__text = 'Leave';
      modal.button__obj = {action: 'jnct:navigation:gofromwarningurl', detail: '/grading_center'};
      document.body.appendChild(modal);
      return;
    }

    this.$emit('jnct:navigation:gofromwarningurl', '/grading_center');
  };

  change(event: any) {
    const value = event.target.value;

    if (value === 'givemax') {
      const modal = document.createElement('confirmation-modal') as ConfirmationModal;
      modal.title = 'Max points';
      let text =  `You are about to grade all submissions with the maximum number of points on all questions (including both auto-graded and instructor-graded). This can take several minutes to process and appear in the Grade Book. Do you want to proceed?`;
      modal.text = text;
      modal.button__text = 'Proceed';
      modal.button__action = 'jnct:learnosity:givemax';
      document.body.appendChild(modal);
    }
  }
}

GradingHeader.defineComponent();

export { GradingHeader };
