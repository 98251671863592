import { JunctionActivitySettings } from "../../components/activitySettings/component";
import { JunctionActivitySettingsDefaults } from "../../components/activitySettings/defaults/component";
import { JunctionActivitySettingsForm } from "../../components/activitySettings/batchUpdate/activitySettingsForm/component";
import { JunctionActivitySettingsDateAndTimeForm } from "../../components/activitySettings/batchUpdate/activitySettingsDateAndTimeForm/component";
import { JunctionActivitySettingsPresetForm } from "../../components/activitySettings/batchUpdate/activitySettingsPresetForm/component";
import { JunctionActivitySettingsTooltip } from "../../components/activitySettings/batchUpdate/tooltip/component";
import { JunctionActivitySettingsChangeSummary } from "../../components/activitySettings/batchUpdate/activitySettingsChangeSummary/component";

export default (parted: boolean = false) => {
    if (!parted) {
        JunctionActivitySettings;
        JunctionActivitySettingsDefaults;
        JunctionActivitySettingsForm;
        JunctionActivitySettingsDateAndTimeForm;
        JunctionActivitySettingsPresetForm;
        JunctionActivitySettingsTooltip;
        JunctionActivitySettingsChangeSummary;
    }
}
