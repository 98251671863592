import IHyperParameter from './_hyperparam';

interface IMetaHyperParams extends IHyperParameter {
    max_gen_len: number;
    top_p: number;
}

export default IMetaHyperParams;

const _defaults = {
    temperature: 0.6,
    max_gen_len: 512,
    top_p: 0.9,
};

export {
    _defaults
};