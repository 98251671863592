import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="modal-content">
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Defaults"
  ></jnct-modal-header>
  <div class="content">
    <div class="options_row">
      <div class="header">Gradebook Settings</div>
      <div class="form_options">
        <div class="option">
          <span>Default Due Time</span>
          <jnct-datetime-input
              inType="time"
              default="${x=>x.globalTime}"
              :afterChange="${(x, c)=>x.upd}"
              fieldName="time"
              tabindex="4"
            ></jnct-datetime-input>
        </div>
        <div class="option">
          <span>Show Times in this Time Zone</span>
          <jnct-select-input
            style="width: 172px"
            fieldName="timezone"
            dropdownFixed="true"
            width="172px"
            :default="${x=>x.timezone == 'local' ? 'Local time': x.timezone}"
            :afterChange="${x=>x.valueChange}"
            :options="${x=>x.timeZones}">
          </jnct-select-input>
        </div>
        <div class="option">
          <span>Default Re-open Attempts</span>
          <jnct-number-input fieldName="resumeMaxAttempts" :default="${x=>x.resumeMaxAttempts}" :afterChange="${x=>x.valueChange}"></jnct-number-input>
        </div>
        <div class="option">
          <span>Filter by Gradeable</span>
          <jnct-checkbox-input
            fieldName="activity_settings_df"
            :default="${x=>x.activity_settings_df}"
            :afterChange="${x=>x.valueChange}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
  </div>
  <div class="options">
    <span
      ><button class="button__secondary" @click="${x=>x.genericConfig.switch()}">
        Cancel
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button class="button__primary" @click="${x=>x.save()}">
        Save Changes
      </button></span
    >
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.modal-content {
  display: flex;
  width: 640px;
  flex-direction: column;
  border: var(--account, 1px) solid var(--Color-palette-Neutral-20, #eeeff0);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.modal-content .content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
}
.modal-content .content .row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  display: flex;
  padding: 16px;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
}
.modal-content .content .options_row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
}
.modal-content .content .options_row .form_options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modal-content .content .options_row .form_options .option {
  display: flex;
  justify-content: space-between;
}
.modal-content .content .options_row .sub {
  box-shadow: none;
}
.modal-content .content .options_row .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  display: flex;
  gap: 8px;
}
.modal-content .content .preset_conf {
  display: flex;
  gap: 16px;
}
.modal-content .content .preset_conf .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}
.modal-content .content .preset_conf .name {
  width: 60%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .content .preset_conf .color {
  width: 40%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .options {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 48px);
  bottom: 0px;
}`;

const mode = "open";

import { attr, RWSViewComponent, RWSView, observable } from "@rws-framework/client";
import {convertTime12to24} from "../../../helpers/dateConversion";

@RWSView('jnct-activity-settings-defaults', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionActivitySettingsDefaults extends RWSViewComponent  {
  @attr genericConfig: any = {}
  @observable payload: object = {}
  timeZones = ["Local time", "Eastern", "Central", "Mountain", "Arizona (no DST)", "Pacific", "Alaska", "Hawai"]
  @observable timezone: string
  @observable globalTime: string
  @observable resumeMaxAttempts: number
  activity_settings_df = this.set_activity_settings_df()

  constructor() {
    super();
    this.upd = this.upd.bind(this);
  }

  valueChange = (fieldname, value: any) => {
    if (fieldname == "resumeMaxAttempts") {
      this.resumeMaxAttempts = value
    }
    if (fieldname == "timezone") {
      this.timezone = value
    }
    if (fieldname == "activity_settings_df") {
      this.activity_settings_df = value
    }
  }

  set_activity_settings_df(): boolean {
    if (window.localStorage.getItem('activity_settings_df')) {
      return window.localStorage.getItem('activity_settings_df') === "true"
    } else {
      return !!window.labelBundle.activity_settings_df
    }
  }

  getTZ(tz) {
    // @ts-ignore
    return (
      {
        // Obviously an incredibly restrictive list of IANAs for resolving local time
        "America/New_York": "Eastern",
        "America/Chicago": "Central",
        "America/Denver": "Mountain",
        "America/Phoenix": "Arizona (no DST)",
        "America/Los_Angeles": "Pacific",
        "America/Anchorage": "Alaska",
        "Pacific/Honolulu": "Hawaii",
      }[tz] || "local"
    );
  }

  mapTZ(tz) {
    return (
      {
        Eastern: "America/New_York",
        Central: "America/Chicago",
        Mountain: "America/Denver",
        "Arizona (no DST)": "America/Phoenix",
        Pacific: "America/Los_Angeles",
        Alaska: "America/Anchorage",
        Hawaii: "Pacific/Honolulu",
      }[tz] || "local"
    );
  }

  upd(fieldName: string, value: string){
    this.globalTime = convertTime12to24(value);
  }

  genericConfigChanged(olcVal: any, newVal: any) {
    if (typeof newVal.getDefaults === "function") {
      const defaults = newVal.getDefaults()
      this.resumeMaxAttempts = defaults.max_attempts
      this.globalTime = defaults["time"]
    }
  }

  async save(){
    this.genericConfig.switch()
    // convert time back to UTC in respect to local timezone
    // global_time is also converted when received from the backend, see `time_convert` in `settings_new.js`
    const date = new Date("1970-01-01T" + (this.globalTime || "23:59") + ":00").toISOString().substr(11, 5);
    var payload = {
      global_time: date,
      resume_max_attempts: this.resumeMaxAttempts,
      submission_attempt_override: false
    }
    localStorage.setItem('activity_settings_tz', this.mapTZ(this.timezone))
    localStorage.setItem('activity_settings_df', this.activity_settings_df.toString())
    this.genericConfig.save(payload)
  }
  connectedCallback () {
    super.connectedCallback();
    this.$emit("modal:shown")
    this.timezone = this.getTZ(localStorage.getItem('activity_settings_tz') || "Local time")
  }
}


JunctionActivitySettingsDefaults.defineComponent();

export{
  JunctionActivitySettingsDefaults
}
