import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog
  id="rollup_modal"
  trap-focus
  modal
  class="rollup_modal">

  <header class="modal__header">
    <div class="modal__header--flex">
      <span class="material-symbols-outlined">add_box</span>
      <h1 class="modal__header--title">${x => x.rollup?.title ? 'Edit Group' : 'New Group'}</h1>
    </div>

    <div class="modal__header--buttons">
      <button
        class="button__secondary"
        tabindex="0"
        title="Cancel"
        @click="${x => x.close()}">
        Cancel
      </button>
      <button
        class="button__primary ${x => x.disabledSave? 'disabled' : ''}"
        tabindex="0"
        title="Save Changes"
        @click="${x => x.disabledSave ? '' : x.save()}">
        ${x => x.rollup?.title ? 'Save Changes' : 'Create'}
      </button>
    </div>
  </header>

  ${T.when(x => !x.loaded, T.html`
    <section class="loader">
      <jnct-loading-spinner></jnct-loading-spinner>
    </section>
  `)}

    <section class="group">
      <div>
        Group Details
      </div>
      <div class="group__flex">
        <div class="group__item flex-1">
          Name
          <input
            :value="${x => x.rollup?.title || null}"
            type="text"
            name="title"
            id="title"
            class="custom__input width"
            placeholder="Group Name"
            @input="${(x, c) => x.changeVal('title', (c.event.target as HTMLInputElement).value)}"
          >
        </div>

        <div class="group__item">
          <div class="group__title">
            Column Order
            <span class="material-symbols-outlined" id="info">info</span>
            <fluent-tooltip
              anchor="info"
              position="left"
              class="left"
              delay="100">
              The "Column Order" input lets you set a group's position in the table. 
              <br>
              Enter "1" for the first position, "3" for the third, etc.
              <br>
              Leave it blank to order by creation date.
            </fluent-tooltip>
          </div>
          <input
            type="number"
            :value="${x => x.order ?? null}" 
            name="order"
            id="order"
            class="custom__input width"
            @input="${(x, c) => x.changeVal('order', (c.event.target as HTMLInputElement).value)}"
          >
        </div>

        <div class="group__item width-160">
          Color
          <div class="group__item--picker">
          <input
            type="text"
            :value="${x => x.rollup?.color ?? 'red'}"
            name="order"
            id="order"
            class="custom__input width-114"
            @input="${(x, c) => x.changeVal('color', (c.event.target as HTMLInputElement).value)}"
          >
          <div id="picker" class="picker" :style="background-color: ${x => x.rollup?.color || 'red'}"></div>
        </div>
        </div>
      </div>
    </section>


  ${T.when(x => x.loaded, T.html`
    <section class="table">
      <div class="table__header">
        <div>Add/Remove Activities</div>
        <div class="thin">${x => x.selectedItems.length} Activities added</div>
        <jnct-searchbar :handleChange="${x => x.search}"></jnct-searchbar>
      </div>

      <jnct-table
        class="width"
        :data="${x => x.data}"
        :columnsConfig="${x => x.columns}"
        allowChecking="true"
        :defaultSelectedItems="${x => x.selected}"
        :afterSelect="${x => x.afterSelect}"
        columnsLayout="40px 40% 15% 15% 30%">
      </jnct-table>
    </section>
  `)}
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  display: flex;
  width: 207px;
  min-width: 207px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: none;
}
fluent-select.big {
  width: 100%;
}

fluent-select::part(control), fluent-select::part(listbox) {
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

fluent-select::part(control) {
  height: 40px;
}
fluent-select::part(control):hover {
  border: 1px solid var(--neutral-30);
}
fluent-select::part(control):focus, fluent-select::part(control):active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

fluent-select::part(listbox) {
  padding: 2px 0px;
  background: white;
  border: none;
}

fluent-option {
  padding: 8px 12px;
  border-radius: unset;
  border: none;
}
fluent-option.selected {
  font-weight: 500;
  border-left: 2px solid #00BBB5;
  background: rgba(0, 193, 185, 0.08);
}
fluent-option.selected::before {
  display: none;
}
fluent-option:not(.selected):hover {
  font-weight: 400;
  background: var(--Color-Neutral-Transparent-neutral-a8, rgba(153, 160, 167, 0.08));
  background: var(--Color-Neutral-Transparent-neutral-a8, color(display-p3 0.6078 0.6275 0.6471/0.08));
}

fluent-tooltip::part(tooltip) {
  display: block;
  position: relative;
  padding: 8px 16px;
  left: 12px;
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--neutral-80);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
  font-family: inherit;
}

fluent-tooltip::part(tooltip)::after {
  left: -6px;
  background-color: var(--neutral-80);
  border: none;
}

fluent-tooltip.left::part(tooltip)::after {
  right: -6px;
  left: unset;
  background-color: var(--neutral-80);
  border: none;
}

.picker_wrapper.no_alpha .picker_alpha {
  display: none;
}

.picker_wrapper.no_editor .picker_editor {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.picker_wrapper.no_cancel .picker_cancel {
  display: none;
}

.layout_default.picker_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 10px;
  width: 25em;
  padding: 0.5em;
}

.layout_default.picker_wrapper input, .layout_default.picker_wrapper button {
  font-size: 1rem;
}

.layout_default.picker_wrapper > * {
  margin: 0.5em;
}

.layout_default.picker_wrapper::before {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  order: 1;
}

.layout_default .picker_slider, .layout_default .picker_selector {
  padding: 1em;
}

.layout_default .picker_hue {
  width: 100%;
}

.layout_default .picker_sl {
  flex: 1 1 auto;
}

.layout_default .picker_sl::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.layout_default .picker_editor {
  order: 1;
  width: 6.5rem;
}

.layout_default .picker_editor input {
  width: 100%;
  height: 100%;
}

.layout_default .picker_sample {
  order: 1;
  flex: 1 1 auto;
}

.layout_default .picker_done, .layout_default .picker_cancel {
  order: 1;
}

.picker_wrapper {
  box-sizing: border-box;
  background: #f2f2f2;
  box-shadow: 0 0 0 1px silver;
  cursor: default;
  font-family: sans-serif;
  color: #444;
  pointer-events: auto;
}

.picker_wrapper:focus {
  outline: none;
}

.picker_wrapper button, .picker_wrapper input {
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0 0 1px silver;
  outline: none;
}

.picker_wrapper button:focus, .picker_wrapper button:active, .picker_wrapper input:focus, .picker_wrapper input:active {
  box-shadow: 0 0 2px 1px #1e90ff;
}

.picker_wrapper button {
  padding: 0.4em 0.6em;
  cursor: pointer;
  background-color: #f5f5f5;
  background-image: linear-gradient(0deg, gainsboro, transparent);
}

.picker_wrapper button:active {
  background-image: linear-gradient(0deg, transparent, gainsboro);
}

.picker_wrapper button:hover {
  background-color: #fff;
}

.picker_selector {
  position: absolute;
  z-index: 1;
  display: block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0 0 3px 1px #67b9ff;
  background: currentColor;
  cursor: pointer;
}

.picker_slider .picker_selector {
  border-radius: 2px;
}

.picker_hue {
  position: relative;
  background-image: linear-gradient(90deg, red, yellow, lime, cyan, blue, magenta, red);
  box-shadow: 0 0 0 1px silver;
}

.picker_sl {
  position: relative;
  box-shadow: 0 0 0 1px silver;
  background-image: linear-gradient(180deg, white, rgba(255, 255, 255, 0) 50%), linear-gradient(0deg, black, rgba(0, 0, 0, 0) 50%), linear-gradient(90deg, #808080, rgba(128, 128, 128, 0));
}

.picker_alpha, .picker_sample {
  position: relative;
  background: linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%) 0 0/2em 2em, linear-gradient(45deg, lightgrey 25%, white 25%, white 75%, lightgrey 75%) 1em 1em/2em 2em;
  box-shadow: 0 0 0 1px silver;
}

.picker_alpha .picker_selector, .picker_sample .picker_selector {
  background: none;
}

.picker_editor input {
  font-family: monospace;
  padding: 0.2em 0.4em;
}

.picker_sample::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: currentColor;
}

.picker_arrow {
  position: absolute;
  z-index: -1;
}

.picker_wrapper.popup {
  position: absolute;
  z-index: 2;
  margin: 1.5em;
}

.picker_wrapper.popup, .picker_wrapper.popup .picker_arrow::before, .picker_wrapper.popup .picker_arrow::after {
  background: #f2f2f2;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
}

.picker_wrapper.popup .picker_arrow {
  width: 3em;
  height: 3em;
  margin: 0;
}

.picker_wrapper.popup .picker_arrow::before, .picker_wrapper.popup .picker_arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
}

.picker_wrapper.popup .picker_arrow::before {
  width: 100%;
  height: 100%;
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.picker_wrapper.popup .picker_arrow::after {
  width: 150%;
  height: 150%;
  box-shadow: none;
}

.popup.popup_top {
  bottom: 100%;
  left: 0;
}

.popup.popup_top .picker_arrow {
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.popup.popup_bottom {
  top: 100%;
  left: 0;
}

.popup.popup_bottom .picker_arrow {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg) scale(1, -1);
  transform: rotate(90deg) scale(1, -1);
}

.popup.popup_left {
  top: 0;
  right: 100%;
}

.popup.popup_left .picker_arrow {
  top: 0;
  right: 0;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.popup.popup_right {
  top: 0;
  left: 100%;
}

.popup.popup_right .picker_arrow {
  top: 0;
  left: 0;
}

* {
  box-sizing: border-box;
}

.rollup_modal::part(positioning-region) {
  height: fit-content;
  max-height: calc(100vh - 64px);
  width: 90%;
  margin: 32px auto 32px;
  overflow: auto;
  border-radius: 12px;
  z-index: 12000;
}
.rollup_modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
}
.rollup_modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}
.rollup_modal .modal__header--flex {
  display: flex;
  gap: 16px;
  align-items: center;
}
.rollup_modal .modal__header .material-symbols-outlined {
  font-size: 24px;
  width: 24px;
}
.rollup_modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
.rollup_modal .modal__header--buttons {
  display: flex;
  gap: 8px;
}

.group {
  margin-bottom: 24px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  color: var(--neutral-90);
  font-size: 18px;
  font-weight: 500;
  line-height: 160%;
}
.group__flex {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.group__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-basis: 160px;
  align-self: stretch;
  font-size: 16px;
}
.group__item:first-child {
  flex: 1;
}
.group__item--picker {
  display: flex;
  flex-direction: row;
}
.group__item--picker .custom__input {
  border-radius: 12px 0px 0px 12px;
}
.group__title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 18px;
  font-weight: 500;
  line-height: 160%;
}
.table__header .thin {
  color: var(--neutral-70);
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
}

.width {
  width: 100%;
}
.width-114 {
  width: 114px;
}

.custom__input {
  height: 46px;
}

.picker {
  width: 46px;
  height: 46px;
  border-radius: 0px 12px 12px 0px;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, RWSInject, attr } from "@rws-framework/client";
import { Activity, ActivityModel } from '../../../types/activities'
import { ColumnConfig, Data } from "../../../types/table";
import { Course } from "src/types/course";
import { ConfirmationModal } from '../../confirmationModal/component';
import Picker from 'vanilla-picker';

type Rollup = {
  title?: string,
  id: string,
  color?: string
  activities?: Activity[];
};

@RWSView('rollup-modal', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class RollupModal extends RWSViewComponent  {
  @attr course: Course;
  @observable activities: Activity[];
  @observable loaded: boolean = false;
  @observable disabledSave: boolean = true;
  @observable rollup: Rollup;
  @observable searchTerm: string | null;
  search = this.handleSearch.bind(this);
  displayType = ActivityModel.displayType;
  @observable columns: ColumnConfig[] = [
    {
      name: "title",
      title: "Title",
      allowSorting: true
    },
    {
      name: "type",
      title: "Type",
      allowSorting: true
    },
    {
      name: "grading",
      title: "Grading",
      allowSorting: false,
    },
    {
      name: "lesson",
      title: "Lesson",
      allowSorting: true
    },
  ];
  @observable table_data: Data[] | [];
  @observable selectedItems: Data[] | [] = [];
  @observable priorSelectedItems: Data[] | [] = [];
  @observable order: any;
  @observable rollups: Rollup[] = [];
  @observable all_activities: Activity[];
  @observable inx: number | null; 

  connectedCallback() {
    super.connectedCallback();
    this.createPicker();
    const empty_rollup_id = this.all_activities.filter(act => !act.rollup_id);
    if (this.rollup) {
      this.inx = this.rollups.findIndex(el => el.id === this.rollup.id);
      this.order = this.inx + 1;
      this.activities = [...this.all_activities.filter(act => act.rollup_id === this.rollup.id), ...empty_rollup_id];
    }
    if (!this.rollup) {
      this.rollup = {id: '' + new Date().getTime()};
      this.activities = [...empty_rollup_id];
    }
    this.table_data = this.activities.map(activity => {
      return {
        "id": {
          "value": activity.id,
        },
        "rollup_id": {
          "value": activity.rollup_id,
        },
        "title": {
          "value": activity.report_title,
        },
        "type": {
          "value": this.displayType(activity.type),
        },
        "grading": {
          "value": activity.scoring_type || '-',
        },
        "lesson": {
          "value": activity.lessonName,
        },
      }
    })
    this.loaded = true;
  };

  createPicker() {
    const parentCustom = this.shadowRoot.getElementById('picker');
    const picker = new Picker({
      parent: parentCustom,
      popup: 'left',
      color: this.rollup?.color || 'red',
      alpha: false,
      //editor: false,
      editorFormat: 'hex',
      onDone: (color) => {
        this.rollup = {
          ...this.rollup,
          color: color.hex,
        }
      },
    });
  }

  afterSelect = (items: any[]) => {
   this.selectedItems = [...items];
  
   if (this.selectedItems.length > 0) {
    this.disabledSave = false;
   }
  }

  get selected() {
    return this.table_data.filter(activity => activity['rollup_id']['value'] === this.rollup.id);
  }

  close() {
    this.remove();
  };

  handleSearch(value: string) {
    this.searchTerm = value || null; // Set searchTerm, or null if empty
  }

  save() {
    this.disabledSave = true;
    this.priorSelectedItems = this.table_data.filter(activity => activity['rollup_id']['value'] === this.rollup.id);
    if (this.selectedItems.length) {
      const ids = this.selectedItems.map(item => item.id.value);
      this.all_activities = this.all_activities.map(act => {
        if (act.rollup_id === this.rollup.id && !ids.includes(act.id)) {
          act.rollup_id = null;
        }
        if (ids.includes(act.id)) {
          act.rollup_id = this.rollup.id;
        }
        return act;
      });
    } else if (this.priorSelectedItems) {
      const ids = this.priorSelectedItems.map(item => item.id.value);
      this.all_activities = this.all_activities.map(act => {
        if (ids.includes(act.id)) {
          act.rollup_id = null
        }
        return act;
      });
    }

    if (this.order && this.order !== (this.inx + 1)) {
      if (this.inx > -1) this.rollups.splice(this.inx, 1); 
      this.rollups.splice((this.order - 1), 0, this.rollup);
    }

    if (!this.order) {
      this.rollups.push(this.rollup);
    }

    if (this.course.use_section_template && this.course.section_type == "section") {
      const modal = document.createElement('confirmation-modal') as ConfirmationModal;
      modal.title = 'Save Changes';
      modal.text = "⚠️ Warning! Saving this change will break the linkage to the section template.<br>" +
      "Future changes to the section template will not be reflected in your section.<br>" +
      "Save?";
      modal.button__text = 'Save';
      modal.button__function = this.saveChanges;
      document.body.appendChild(modal);
      return;
    }
    this.saveChanges();
  }

  async saveChanges() {
    const rollups = this.rollups.map(rollup => {
      if (rollup.id === this.rollup.id) {
        return this.rollup;
      }
      return rollup;
    }).filter(rollup => {
        // Silly sneaky way to delete a rollup group: un-select everything and remove the
        // value in the order field
        if (!this.selectedItems.length && !this.order && this.rollup.id === rollup.id) {
          return false
        }
        return true
    })
    const payload = {
      'rollup': [...rollups],
      'activities': this.all_activities,
    }
    
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "stats/rollup/" + this.course._id, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });
      if (!response.ok) {
        const error_message = 'Unexpected problem occurred. (' + JSON.stringify(response) + ')';
        this.$emit('jnct:notifications:error', error_message);
      }
      this.disabledSave = false;
      this.$emit('jnct:notifications:info', 'New grouping saved.');
      this.$emit('jnct:dashboardService:gradebookflush');
      this.$emit('rollup:flushdata');
      this.close();
    } catch (error) {
      this.disabledSave = false;
      const error_message = 'Failed to save the new grouping (' + JSON.stringify(error) + ')';
      this.$emit('jnct:notifications:error', error_message);
      console.error("Error:", error);
      this.$emit('rollup:flushdata');
      this.close();
    };
  }

  // getter that will be recalculated each time `table_data` or `searchTerm` changes
  get data(): Data[] {
    if (!this.searchTerm) {
      return this.table_data;
    }
    
    const searchTermLower = this.searchTerm.toLowerCase();

    return this.table_data
    .map(activity => {
      const matchesTitle = activity['title']['value'].toLowerCase().includes(searchTermLower);

      if (matchesTitle) {
        return {
          ...activity,
        };
      }

      return null;
    })
    .filter(activity => activity !== null) as Data[];
  }

  changeVal = (name: string, value: string) => {
    this.disabledSave = false;
    if (name === 'order') {
      this.order = value;
    } else {
      this.rollup = {
        ...this.rollup,
        [name]: value,
      }
    }
  }
}

RollupModal.defineComponent();

export { RollupModal };
