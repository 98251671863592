import { IBoxData } from '../../types/box-data';
import { BoundaryModType, DragOptions } from '../ResizeService';
import { BUMP_UNIT, makeBoundary, isOutOfBoundary, bump  } from './_boundary';
import { ResizeServiceInstance } from '../ResizeService'
import { getBoxData } from './_box';
import { getRelative, moveTarget } from './_move'
import { WebChat } from 'src/components/webchat/component';

export function onResize(this: ResizeServiceInstance, event: any, component: any, options: DragOptions){
    const startingPosition: { x: number, y: number } = { x: parseFloat(event.target.dataset.x), y: parseFloat(event.target.dataset.y) }

    const style = window.getComputedStyle(event.target);
    const matrix = new WebKitCSSMatrix(style.transform);
    let x = matrix.m41;
    let y = matrix.m42;
    const edges = event.edges;

    const oldH: number = parseFloat(event.target.style.height);
    const oldW: number = parseFloat(event.target.style.width);           
    const deltaW = oldW - event.rect.width;
    const deltaH = oldH - event.rect.height;

    let chatW: number = event.rect.width;
    let chatH: number = event.rect.height;


    // Update x and y based on the edges being handled

    if (edges.right && deltaW !== 0) {
        x -= deltaW;

        if (event.rect.width <= parseFloat(style['min-width'])) {
            x = startingPosition.x;
        }
    }

    if (edges.bottom && deltaH !== 0) {        
        y -= deltaH;

        if(deltaH < 0){                       
            const isMax = event.rect.height >= parseFloat(style['max-height']);

            if(isMax){
                y += deltaH;

            }            
        }else{            
            if (event.rect.height <= parseFloat(style['min-height'])) {
                y = startingPosition.y;
            }
        }
    }

   

    applySize(event.target, chatW, chatH);

    // Apply transform
    moveTarget(event.target, x, y);
    // Object.assign(event.target.dataset, { x, y });

    component.boxData = getBoxData(component);
    this.saveBoxData({
        left: x,
        top: y,
        w: chatW,
        h: chatH
    });

}

export function onResizeEnd(this: ResizeServiceInstance, event: any, component: any, options: DragOptions){
    this.interactTool.draggable(true);
    const boxData = this.getBoxData(component);
    component.boxData = boxData;
    document.body.style.userSelect = '';


    const boundary: IBoxData = makeBoundary(options?.boundary, options.boundaryMod);            

    if (boundary) {
        const isOutOfBounds: boolean = isOutOfBoundary(event.target, boundary);

        if(isOutOfBounds){     
        
            const targetBox = getBoxData(event.target);
            const bumpedBox: IBoxData & { edge: string } = bump(event.target, targetBox, boundary);


            const style = window.getComputedStyle(component);
            const matrix = new WebKitCSSMatrix(style.transform);
            let x = matrix.m41;
            let y = matrix.m42;
           
            let w = targetBox.w;
            let h = targetBox.h;           

            const deltaW = Math.abs(w - this.resizeData.initial.w);
            const deltaH = Math.abs(h - this.resizeData.initial.h);


            const relPos = getRelative({ x, y }, bumpedBox, targetBox);

            if(bumpedBox.edge === 'left'){
                relPos.x = relPos.x - deltaW;  
            }

            if(bumpedBox.edge === 'top'){
                relPos.y = relPos.y - deltaH;  
            }
            
            if(['left', 'right'].includes(bumpedBox.edge)){                                                     
                w = this.resizeData.initial.w;                         
            }

            if(['top', 'bottom'].includes(bumpedBox.edge)){                            
                h = this.resizeData.initial.h;     
            }

            applySize(event.target, w, h);
            moveTarget(event.target, relPos.x, relPos.y);
            
            component.boxData = {
                left: relPos.x,
                top: relPos.y,
                w,
                h
            };
          
            this.saveBoxData(component.boxData);
            return;           
        }
    }
}



export function applySize(target: HTMLElement, width: number, height: number){
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
}