import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="gradeby">
  ${T.when(x => x.loaded, T.html`
    <section class="column">
      ${T.when(x => x.assessment?.students.length > 0, T.html`
      <div class="column__top">
        <div class="height-16"></div>

        ${T.when(x => x.showGraded, T.html`
          <ul class="column__list">
            ${T.repeat(x => x.assessment?.students, T.html`
              <li
                class="column__item ${(x, c) => x._id === c.parent.selectedStudent._id ? 'active' : ''}"
                id="${x => x._id}"
                @click="${(x, c) => c.parent.selectStudent(x._id)}">
                ${(x, c) => c.parent.anonymous ? ('Student ' + (c.index + 1)) : x.name}
                ${T.when(x => x.graded, T.html`
                    <span class="material-symbols-outlined">check_circle</span>
                  `)}
              </li>
            `, { positioning: true })}
          </ul>
        `)}

        ${T.when(x => !x.showGraded, T.html`
          <ul class="column__list">
            ${T.repeat(x => x.assessment?.students.filter(el => !el.graded), T.html`
              <li
                class="column__item ${(x, c) => x._id === c.parent.selectedStudent._id ? 'active' : ''}"
                id="${x => x._id}"
                @click="${(x, c) => c.parent.selectStudent(x._id)}">
                  ${(x, c) => c.parent.anonymous ? ('Student ' + (c.index + 1)) : x.name}
                  ${T.when(x => x.graded, T.html`
                    <span class="material-symbols-outlined">check_circle</span>
                  `)}
              </li>
            `, { positioning: true })}
          </ul>
        `)}

        ${T.when(x => x.allGraded && !x.showGraded, T.html`
          <div class="column__empty">
            <jnct-success-state
              headerText="Grading Completed"
              description="">
            </jnct-success-state>
          </div>
        `)}
      </div>
      `)}

      ${T.when(x => x.assessment?.buttons, T.html`
        <div class="column__bottom">
          ${T.repeat(x => x.assessment.buttons, T.html`
            <div class="bottom">
              <fluent-switch
                id="${x => x.id}"
                ?checked="${(x, c) => (c.parent.showGraded && x.id === 'graded_switch') ? 'checked' : ''}"
                class="column__switch"
                @click="${x => x.action()}">
              </fluent-switch>
              ${x => x.title}
            </div>
          `)}
        </div>
      `)}
    </section>
  `)}
  <section class="gradeby__main">
    <grading-header
      class="width-100"
      actions="true"
      :disabled="${x => x.disabledSave}"
      :save="${x => x.save}">
    </grading-header>

    ${T.when(x => !x.loaded, T.html`
      <div class="main__loader">
        <jnct-loading-spinner></jnct-loading-spinner>
      </div>
    `)}

    ${T.when(x => x.loaded && x.allGraded && !x.showGraded, T.html`
      <div class="column__empty">
        <jnct-success-state
          headerText="Grading Completed"
          description="">
        </jnct-success-state>
        <button
          class="button__primary"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.goToGradingCenter() : ''}"
          @click="${x => x.goToGradingCenter()}">
        Back to Grading Center
      </button>
      </div>
    `)}

    ${T.when(x => x.loaded && !(x.allGraded && !x.showGraded), T.html`
      <div class="main">
        <div class="main__title">
          ${x => x.anonymous ? 'Student' : x.selectedStudent?.name} - 
          <span :innerHTML="${x => x.assessment?.title}"></span>
        </div>

        <div class="main__data data">
          <div class="data__header">
            <div class="data--100 data--padding">Attempt</div>
            <div class="data--200 data--padding">Date Submitted</div>
            <div class="data--200 data--padding">Date Started</div>
            <div class="data--220 data--padding">Submitted</div>
            <div class="data--120 data--padding">Duration</div>
            <div class="data--110 data--padding">Grade</div>
            <div class="data--110 data--padding">Scoring needed</div>
            ${T.when(x => x.data?.scores.length > 1, T.html`
              <div
                class="data__last data--110 data--padding"
                @click="${x => x.toggleAttempts()}">
                ${x => x.showAllAttempts ? 'Hide' : 'See all'}
              </div>
            `)}
            ${T.when(x => x.data?.scores.length === 1, T.html`
              <div class="data__last data--110 data--padding"></div>
            `)}
          </div>
          <ul class="data__main">
            ${T.when(x => !x.showAllAttempts, T.html`
              <li class="data__main--item">
                <div class="data--100 data--padding">
                  ${x => (x.data?.['scores'].findIndex(el => el.session_id === x.message?.session_id) + 1)}
                </div>
                <div class="data--200 data--padding">
                  ${x => x.formatDate(x.message?.session.dt_completed)}
                </div>
                <div class="data--200 data--padding">
                  ${x => x.formatDate(x.message?.session.dt_started)}
                </div>
                <div class="data--220 data--padding">
                  ${x => x.getStatus(x.message?.session)}
                </div>
                <div class="data--120 data--padding">
                  ${x => x.message?.session.duration}
                </div>
                <div class="data--110 data--padding">
                  ${x => x.message?.session.score} / ${x => x.message?.session.max_score}
                </div>
                <div class="data--110 data--padding">
                  ${(x, c) => !x.message?.session.dt_completed ? T.html`
                    <button
                      class="button__secondary"
                      @click="${(x, c) => x.submit(x.message?.session)}"
                    >
                      Submit
                    </button>
                    ` : undefined}
                  </td>
                </div>
                <div class="data--110 data--padding"></div>
              </li>
            `)}

            ${T.when(x => x.showAllAttempts, T.html`
              ${T.repeat(x => x.data['scores'], T.html`
                <li class="data__main--item">
                  <div class="data--100 data--padding">${(x, c) => c.index + 1}</div>
                  <div class="data--200 data--padding">${(x, c) => c.parent.formatDate(x.dt_completed)}</div>
                  <div class="data--200 data--padding">${(x, c) => c.parent.formatDate(x.dt_started)}</div>
                  <div class="data--220 data--padding">${(x, c) => c.parent.getStatus(x)}</div>
                  <div class="data--120 data--padding">${x => x.duration}</div>
                  <div class="data--110 data--padding">${x => x.score} / ${x => x.max_score}</div>
                  <div class="data--110 data--padding">
                    ${(x, c) => !x.dt_completed ? T.html`
                      <button
                        class="button__secondary"
                        @click="${(x, c) => c.parent.submit(x)}"
                      >
                        Submit
                      </button>
                      ` : undefined}
                    </td>
                  </div>

                  <div class="data--110">
                    ${T.when((x, c) => c.parent.message.session_id != x.session_id, T.html`
                    <button class="button__secondary" @click="${(x, c) => c.parent.reloadLearnosityItem(x)}">
                      View
                    </button>
                    `)}
                  </div>
                </li>
              `, { positioning: true })}
            `)}
          </ul>
        </div>
      </div>
    `)}
    
    <learnosity-item id="learn" class="${x => (x.loaded && !(x.allGraded && !x.showGraded)) ? 'exist' : 'none'}"></learnosity-item>
  </section>
</div>
${T.when(x => x.showSubmitModal && x.modalConfig, T.html`
  <jnct-modal
    templateName="submit-learnosity-modal"
    :genericConfig="${x=>x.modalConfig}"
  ></jnct-modal>
`)}`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.gradeby {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex: 1 0 0;
}
.gradeby .height {
  height: calc(100% - 16px);
}
.gradeby .height-16 {
  height: 16px;
}
.gradeby__main {
  max-height: calc(100vh - 26px);
  overflow: auto;
  flex: 1 0 0;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
}
.gradeby .main {
  flex: 1;
  align-self: stretch;
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  padding: 0px 22px 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.gradeby .main__loader {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gradeby .main__title {
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 600;
  line-height: 140%;
}
.gradeby .main .data {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.gradeby .main .data--padding {
  padding: 8px;
}
.gradeby .main .data__header {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.gradeby .main .data--100 {
  width: 100px;
}
.gradeby .main .data--200 {
  width: 200px;
}
.gradeby .main .data--120 {
  width: 120px;
}
.gradeby .main .data--110 {
  width: 110px;
}
.gradeby .main .data--220 {
  width: 220px;
}
.gradeby .main .data__last {
  color: var(--link);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
  text-align: end;
  cursor: pointer;
}
.gradeby .main .data__last:hover {
  text-decoration: underline;
}
.gradeby .main .data__main {
  list-style: none;
  margin: 0px;
  padding: 0px;
  border-top: 2px solid var(--neutral-20);
}
.gradeby .main .data__main--item {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.gradeby .main .data__main--item:nth-child(even) {
  background-color: white;
}
.gradeby .main .data__main--item:nth-child(odd) {
  background-color: var(--neutral-8);
}

.column {
  display: flex;
  width: 230px;
  height: calc(100vh - 16px);
  margin: -10px 0px -10px -10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
  border-right: 1px solid var(--neutral-20);
  background: var(--neutral-10);
  box-shadow: 4px 0px 16px 0px rgba(0, 0, 0, 0.05);
}
.column__top {
  width: 100%;
}
.column__title {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.column__list {
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  list-style: none;
}
.column__item {
  display: flex;
  padding: 12px 12px 12px 32px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}
.column__item.active {
  font-weight: 600;
  background: var(--primary-36);
}
.column__item .material-symbols-outlined {
  color: #23771C;
  font-size: 1.14286em;
}
.column__switch {
  width: 48px;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.column__bottom .bottom {
  display: flex;
  padding: 0px 8px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.column fluent-switch::part(switch) {
  width: 32px;
  background: #EEEFF0;
  border: none;
  height: 18px;
}
.column fluent-switch.checked::part(switch) {
  width: 32px;
  background: #55BAB7;
  height: 18px;
}
.column fluent-switch {
  --neutral-foreground-rest: white;
}

.column__empty {
  display: flex;
  padding: 24px 30px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.width-100 {
  width: 100%;
}

#learn.none {
  display: none;
}`;

const shadowOptions = {"mode":"open"};
import { 
  RWSView, RWSViewComponent, RWSInject, observable, attr
} from '@rws-framework/client';

import ActivitiesService, {ActivitiesServiceInstance} from '../../../services/ActivitiesService';
import {Activity, ActivityStudentScore} from "../../../types/activities";
import { LearnositySessionDuration, getLearnosityStatus } from "../../../helpers/learnositySessionDuration";

export interface CustomHTMLElement extends HTMLElement {
  data: any;
  sessions: string;
  course: string;
  items?: string[];
  disabledSave: boolean;
  isItemList?: boolean;
  students: any;
  activityId?: string;
};

type StudentType = {
  _id: string,
  name: string,
  graded: boolean,
  maxPoints: number,
  firstname: string,
  lastname: string
};

type ButtonType = {
  id: string,
  title: string,
  action: Function,
};

type AssessmentType = {
  id: string,
  activity_id: string,
  title: string,
  sessions: object,
  students: StudentType[],
  buttons: ButtonType[],
  link?: string,
  due_date?: string,
};

type GenericConfig = {
  switch: () => void,
  changeSubmitState: () => void,
  courseId: string,
  session: any
}

// @ts-ignore
const APIversion = window.edrnaConfig.learnosity.version;

@RWSView('grade-by-student', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradeByStudent extends RWSViewComponent  {
  @observable assessment: AssessmentType;
  @observable activity: Activity;
  @observable anonymous: boolean = false;
  @observable showGraded: boolean = false;
  @observable allGraded: boolean = false;
  @observable selectedStudent: StudentType;
  @observable showAllAttempts = false;
  @observable disabledSave: boolean = true;
  @observable message: any;
  @observable data: any;
  @observable loaded: boolean = false;
  @observable course: any;
  @observable students: any = {};
  @observable overrides: any;
  @observable showSubmitModal: boolean = false;
  @observable modalConfig: GenericConfig | null;

  constructor (
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance
  ) {
    super()
  };

  getRoster() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getRoster', (data: any) => {
        resolve(data)
      });
    })
  };

  getCourse() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getCourse', (data: any) => {
        resolve(data)
      });
    })
  };

  getAverage() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getAverage', (data: any) => {
        resolve(data)
      })
    })
  };

  submit(session) {
    this.modalConfig = {
      switch: () => { this.showSubmitModal = false},
      courseId: this.course._id,
      session: session,
      changeSubmitState: () => { 
        const today = new Date();
        this.data = {...this.data, 'scores': this.data['scores'].map(obj => {
          return obj.session_id === session.session_id 
            ? { ...obj, dt_completed: today.toISOString(),  } 
            : obj;
            }
          )
        }
      }
    }
    this.showSubmitModal = true;
  }
  
  async connectedCallback() {
    super.connectedCallback();

    this.course = await this.getCourse() as any;
    const roster = await this.getRoster() as any[];
    const average = await this.getAverage() as any;

    const activity_student_score = average.activity_student_score;

    this.activity = average.activity_manager.filter((activity: Activity) => activity.id === this.course.componentId)[0];

    //learnosity sessions for each student
    const sessions = {};

    roster.forEach(student => {
      // LTI, board, custom (with or without a lesson) uses the component_id (in the id field)
      let aid = this.activity.activity_id || this.activity.id;

      if (activity_student_score[aid][student._id]) {
        sessions[student._id] = activity_student_score[aid][student._id];
      }
      student.graded = this.activityService.applyGradedToStudent(this.activity ,activity_student_score,student._id)
    });

    roster.forEach(student => {
      if (sessions[student._id]) {
        this.students[student._id] = {
          name: student.lastname + ", " + student.firstname,
          session: sessions[student._id]
        }
      }
    })

    //showing students only with session id
    const students = roster.map(student => {
      if (sessions[student._id]?.session_id) {
        student.attempted = true;
      }
      return student;
    });


    this.assessment = {
      title: this.activity.report_title || this.activity.title || '-No-Title-',
      id: this.course.componentId,
      activity_id: this.activity.activity_id || this.activity.id,
      sessions: sessions,
      students: students.filter(student => student.attempted),
      link: this.activity.link,
      due_date: this.activity.due_date,
      buttons: [
        {
          id: 'anonymous_switch',
          title: 'Anonymous Grading',
          action: this.anonymousGradingHandler,
        },
        {
          id: 'graded_switch',
          title: 'Show graded',
          action: this.showGradedHandler,
        },
      ]
    };

    this.assessment.students = this.assessment.students.sort((a, b) => {
      return (a.lastname + a.firstname).localeCompare(b.lastname+b.firstname);
    });

    this.selectedStudent = this.assessment.students.find(student => !student.graded);
    if (!this.selectedStudent) {
      this.selectedStudent = this.assessment.students[0];
      this.showGraded = true;
      this.allGraded = true;
    }

    await this.getLearnosity();
    document.documentElement.addEventListener('jnct:learnosity:givemax', this.maxHandler, true)
  };

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.documentElement.removeEventListener('jnct:learnosity:givemax', this.maxHandler, true)
  }

  maxHandler = this.givemax.bind(this, true);

  givemax() {
    this.$emit('jnct:learnosity:savescores', {givemax: true});
  }
  async getLearnosity() {
    this.loaded = false;
    const request: object = {
      activity_id: [this.assessment.activity_id],
      user_id: [this.selectedStudent._id],
      include: {"sessions.session_metadata": ["course_id"]}, // very important for filtering below
      session_id: [],
      include_response_scores: true,
      status: ["Completed", "Incomplete"]
    };

    const signature = await this.getSignature(request);

    //getting scores from Learnosity API
    const scores = await this.getScores(signature);

    //getting override from Learnosity
    const override = await this.getOverride();
    this.overrides = override;
    if (override) {
      const filteredScores = scores.data.filter((session: any) => {
        const course_id = this.course.section_type == 'section' ? this.course.parent : this.course._id;
        return session.session_metadata && session.session_metadata.course_id == course_id;
      });

      this.getInfo(filteredScores, override);
    }
  };

  async getSignature(request: object) {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 

    try {
      const response = await fetch(APIprefix + "assessment/signature/data", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  async getScores(signature: object) {
    function getFormData(object: any) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    };

    //we need to change data into Form Data format for Learnosity
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 
    console.log('csrfToken2',csrfToken)
    const formdata = getFormData(signature);
    try {
      const response = await fetch('https://data.learnosity.com/' + APIversion + '/sessions/responses', {
        method: "POST",
        body: formdata,
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  async getOverride() {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');

    try {
      const response = await fetch(APIprefix + "assessment_override/" + this.course._id + '/' + this.assessment.id + "/" + this.selectedStudent._id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  getInfo(scores: any, overrides: any) {
    //@ts-ignore
    const temp_session = this.assessment.sessions[this.selectedStudent._id];
    let session_id = '';
    if (temp_session?.session_id) {
      session_id = temp_session.session_id;
    } else {
      session_id = scores.filter((s: any) => s.dt_completed).concat({'session_id':''})[0].session_id;
    };

    overrides["studentReportURL"] = session_id;
    overrides['link'] = this.assessment.link;
    overrides["scores"] = scores;

    Object.keys(overrides).forEach(function(option) {
      if ((option == 'due_date' || option === 'close_date' || option === 'open_date') && overrides[option]) {
        overrides[option] = new Date(overrides[option]);
        if (overrides[option].getFullYear() < 1971 || overrides[option].getFullYear() > 2198) {
          delete overrides[option]
        }
      } else {
        overrides[option] = overrides[option];
      }
    });
    if (overrides["time_limit"] == false) {
      overrides["time_limit"] = undefined;
    };
    overrides.title = this.assessment.title;
   
    //AUTODEDUCT injected here as well since scores are direct from learnosity
    scores.forEach((session: any) => this._autoDeduct(session, overrides));
    if (overrides.policy) {
      overrides.policy.humanly_per = overrides.policy.per == 3600? "hour" : overrides.policy.per == 86400? "day" : "week";
      overrides.config = {policy: overrides.policy};
    };

    this.data = overrides;
    this.data.scores = this.data.scores?.filter((el: { session_id?: any }) => el.session_id)
                                        .sort((x, y) => x.dt_started < y.dt_started? 1 : -1)
    this.data.scores?.forEach((score: any) => {
      score.isProfessor = this.selectedStudent.name? true : false;
      if (session_id === score.session_id) {
        this.message = {session_id: session_id, session: score, link: this.data.link, user_id: score.user_id, title: this.data.title};
      };
      score.manually_accepted = this.assessment.sessions[this.selectedStudent._id].manually_accepted;
      score.manually_rejected = this.assessment.sessions[this.selectedStudent._id].manually_rejected;
      score.contains_overdue = this.assessment.sessions[this.selectedStudent._id].contains_overdue;
      score.scoring_needed = score.responses.some((response: any) => {
      // TODO-AUTOMARKABLE
      const correctType = ['video', 'longtext', 'longtextV2', 'audio', 'plaintext', 'fileupload'].includes(response.question_type);
      return response.dt_score_updated < score.dt_completed && 
            response.max_score && correctType;
      });

      score.duration = LearnositySessionDuration(score);
    })

    if (this.data.time_limit) {
      this.data.time_limit = Math.round(this.data.time_limit / 60);
    };

    const learnositycomp = this.shadowRoot.getElementById('learn') as CustomHTMLElement;
    if (learnositycomp) {
      learnositycomp.data = JSON.stringify(this.data);
      learnositycomp.sessions = JSON.stringify(this.message);
      learnositycomp.course = JSON.stringify(this.course);
      learnositycomp.items = this.activity.items || [];
      learnositycomp.students = this.students;
      learnositycomp.activityId = this.activity.id;
      this.addEventListener('disabledSaveChanged', (event: CustomEvent) => {
        this.disabledSave = event.detail.disabledSave;
      });
    }

    this.loaded = true;
  };

  _autoDeduct(score: any, config: any) {
    if (score.policy_applied || !config.policy || !config.policy?.penalty) {
      return; // since we update this is place, we better only apply the policy once
    };
   
    //@ts-ignore
    const delta = new Date(score.dt_completed) - new Date(config.due_date || "2199-12-31");
    if (delta < 0) {
      return;
    };
    // there is a policy and score is overdue
    score.score = score.score * (100 - Math.min(config.policy.penalty * Math.ceil(delta/1000/config.policy.per), config.policy.max))/100;
    score.policy_applied = true;
  }

  toggleAttempts() {
    this.showAllAttempts = !this.showAllAttempts;
  }

  goToGradingCenter() {
    this.$emit('jnct:navigation:gotogradingcenter');
  };

  anonymousGrading() {
    this.anonymous = !this.anonymous;
    if (!this.anonymous) {
      this.assessment.students = this.assessment.students.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    };
    if (this.anonymous) {
      this.assessment.students = this.assessment.students.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }
  };

  getStatus(score): string {
    return getLearnosityStatus(score, this.overrides, this.assessment.due_date);
  };

  //format the date for the write view
  formatDate(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    //for non existing dates
    if (year < 1971 || year > 2198) {
      return '';
    };

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours() % 12 || 12).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const meridiem = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${month}/${day}/${year} ${hour}:${minute} ${meridiem}`;

    return formattedDate;
  };

  //to reload the learnosity item for a student
  // Note: there is a watcher on scope.message in the studentReview.js directive
  reloadLearnosityItem (session: any) {
    this.message = {
      session_id: session.session_id, 
      session: session,
      link: this.data.link,
      title: this.data.title,
    };
    this.data.studentReportURL = '';
    this.showAllAttempts = false;
    const testcomp = this.shadowRoot.getElementById('learn') as CustomHTMLElement;
    if (testcomp) {
      testcomp.data = JSON.stringify(this.data);
      testcomp.sessions = JSON.stringify(this.message);
      testcomp.course = JSON.stringify(this.course);
      testcomp.items = this.activity.items || [];
      testcomp.students = this.students;
      testcomp.activityId = this.activity.id;
      this.addEventListener('disabledSaveChanged', (event) => {
        this.disabledSave = event.detail.disabledSave;
      });
    }
  }

  anonymousGradingHandler = this.anonymousGrading.bind(this)

  toggleGraded() {
    this.showGraded = !this.showGraded;
  };

  showGradedHandler = this.toggleGraded.bind(this)

  async selectStudent(id: string) {
    this.selectedStudent = this.assessment.students.find(el => el._id === id);
    await this.getLearnosity();
  };

  save() {
    this.$emit('jnct:learnosity:savescores');
  };
}

GradeByStudent.defineComponent();

export { GradeByStudent };
