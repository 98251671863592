import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="jnct-loader" style="${x => x.primaryLoadColor ? '--primary-load-color: ' +  x.primaryLoadColor + ';' : ''} ${x => x.secondaryLoadColor ? '--secondary-load-color: ' +  x.secondaryLoadColor + ';' :''} ${x => x.textColor ? '--text-load-color: ' +  x.textColor + ';' : ''}">
    <div class="loading-animation"></div>
    <p>
        <slot>Please wait...</slot>
    </p>
</div>
`;

import './styles/layout.scss';
const styles = T.css`.jnct-loader .loading-animation {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
  width: 54px;
  height: 54px;
  border: 5px solid var(--primary-load-color, var(--primary_color, #55BAB7));
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate var(--loader-anim-time, 1s) linear infinite;
}
.jnct-loader .loading-animation::after {
  content: "";
  width: 5px;
  height: 5px;
  background: var(--primary-load-color, var(--primary_color, #55BAB7));
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 37px;
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.jnct-loader .loading-animation:after {
  display: none;
}
.jnct-loader p {
  text-align: center;
  color: var(--text-load-color, inherit);
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { html } from "@microsoft/fast-element"; 

@RWSView('jnct-loading', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class JunctionLoader extends RWSViewComponent  {
    @attr primaryLoadColor: string | null = null;
    @attr secondaryLoadColor: string | null = null;
    @attr textColor: string | null = null;

    constructor() {
      super();
    }  
}

JunctionLoader.defineComponent();

export { JunctionLoader }