
import { LtiSyncManager } from '../../components/ltiSyncManager/component';
import { LtiSetup } from '../../components/ltiSyncManager/children/ltiSetup/component';
import { LtiSyncProcess } from '../../components/ltiSyncManager/children/ltiSyncProcess/component';
import { LtiSyncStudents } from '../../components/ltiSyncManager/children/ltiSyncStudents/component';
import { ltiSyncConfirm } from '../../components/ltiSyncManager/children/ltiSyncConfirm/component';
import { JunctionLtiConfigModal } from '../../components/ltiSyncManager/modals/ltiConfigModal/component';

export default (parted: boolean = false) => {
    if (!parted) {
        LtiSyncManager;
        LtiSetup;
        LtiSyncProcess;
        LtiSyncStudents;
        ltiSyncConfirm;
        JunctionLtiConfigModal;
    }
}
