import { WebChat } from '../../components/webchat/component';
import { JunctionTrainer } from '../../components/trainer/component';
import { ChatButton } from '../../components/chat-button/component';
import { BookLoader } from '../../components/book-loader/component';
import { ChatLoader } from '../../components/chat-loader/component';
import { BookBinder } from '../../components/book-binder/component';
import { LineSplitter } from '../../components/line-splitter/component';
import { ExamplePromptAdmin } from '../../components/example-prompt/admin/component';
import { ExamplePrompts } from '../../components/example-prompt/client/component';
import { ChatHeader } from '../../components/webchat/children/chat-header/component';
import { WebChatConvo } from '../../components/webchat/children/chat-area/component';
import { WebChatConvoFooter } from '../../components/webchat/children/chat-footer/component';
import { WebChatMessage } from '../../components/webchat/children/chat-message/component';
import { ChatNav } from '../../components/webchat/children/chat-nav/component';
import { ChatProfile } from '../../components/chat-profile/component';
import { JunctionPersonas } from '../../components/chat-personas/component';
import { ChatNavInstructor } from '../../components/webchat/children/chat-nav/instructor/component';

export default (parted: boolean = false) => {
    if (!parted) {
        
        WebChat;
        ChatHeader;
        ChatNav;
        ChatNavInstructor;
        WebChatConvo;
        WebChatConvoFooter;
        WebChatMessage;
        JunctionPersonas;

        ChatProfile;

        JunctionTrainer;
        BookLoader;
        ChatLoader;
        BookBinder;  
        LineSplitter;

        ExamplePromptAdmin;
        ExamplePrompts;

        ChatButton;
    }
}
