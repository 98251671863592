import IHyperParameter from './_hyperparam';

interface IAnthropicHyperParams extends IHyperParameter {
    max_tokens_to_sample: number;             
    top_k: number;
    top_p: number;
    anthropic_version: string;
}

export default IAnthropicHyperParams;

const _defaults = {
    max_tokens_to_sample: 300,               
    top_k: 250,
    top_p: 1,
    anthropic_version: 'bedrock-2023-05-31'
};

export {
    _defaults
};