import { IMessage, IRWSPromptJSON } from './children//chat-message/component';
import { ChatSocketData, ControllerActionResponse, IChatProfile} from '@backend-types';
import { WebChat } from './component';

import events from '../../events/events';
import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';

async function callStreamApi(this: WebChat, msg: IMessage): Promise<void> {    
    const llmStream = new ReadableStream()

    const sendMsg: IMessage = {
        userMessage: false,
        author: this.chosenModel.modelName,
        content: '',
        model_id: this.chosenModel.modelId,
        created_at: new Date()
    }

    this.wsService.listenForMessage(
        (wsData: {data: {chapter_number: number}}, isJson?: boolean) => {
          const dbChapterNr: number = wsData.data.chapter_number
          this.chapterNr = dbChapterNr

          console.log('CHAPTER CHANGE', this.chapterNr)

        },
        'chapter_change_' + this.wsId
    )

    this.wsService.listenForMessage(
        (wsData: {data: {_prompt: IRWSPromptJSON}}, isJson?: boolean) => {
            const convoId: string = wsData.data._prompt.var_storage.convo_id

            if (convoId !== this.convoId) {
                this.convoId = convoId
                this.$emit(events.context.convo_id_set, this.convoId)
            }
            this.$emit('chat_typing', false)
        },
        'prompt_response_end_' + this.wsId
    )
    
    const socketPayload = {
        bookId: this.bookId,
        courseId: this.courseId,
        personaId: this.personaId || null,
        chapterNr: this.chapterNr,
        prompt: msg.content,
        modelId: this.chosenModel.modelId,
        convoId: this.convoId,
        chapterChange: this.chapterChangeAllowed,
        reseller: (window as any).labelBundle.reseller || null,
        hyperParams: { 
            temperature: this.customTemperature,
            max_tokens_to_sample: this.customMaxTokensToSample,
            top_k: this.customTopK,
            top_p: this.customTopP,   
            anthropic_version: 'bedrock-2023-05-31' 
        },
        wsId: this.wsId
    }
    
    //console.log({ socketPayload });

    this.wsService.sendMessage<ChatSocketData>('send_msg', socketPayload)

    try {      
        this.messages = [...this.messages, msg, {
            ...sendMsg,
            _stream: llmStream,
        }];                

    } catch(e: Error | any) {
        console.error(e);
    }      
}

function getCSSVar(this: WebChat, varName: string): string {
    const styles = getComputedStyle(document.querySelector('body'))
    return styles.getPropertyValue(varName).trim()
}

function hexToRGBA(hex: string, alpha: number = 1): string {
    // Remove the '#' character if present
    hex = hex.replace('#', '')
  
    // Extract the red, green, and blue components
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

async function getProfile(this: WebChat): Promise<void> {
    const profile = (await this.apiService.back.get<ControllerActionResponse<IChatProfile>>('chat-profile:resource:get', { routeParams: { id: (window as any).labelBundle.reseller } })).data

    this.$emit('jnct:chat-profile:pass', profile)
    this.chatProfile = profile

    this.customTemperature = this.chatProfile.hyperparams?.temperature || 0.3;
    this.customTopK = this.chatProfile.hyperparams?.top_k || 250;
    this.customTopP = this.chatProfile.hyperparams?.top_p || 0.7;
    this.customMaxTokensToSample = this.chatProfile.hyperparams?.max_tokens_to_sample || 1024;
}

export { callStreamApi, getCSSVar, hexToRGBA, getProfile }
