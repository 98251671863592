import {ApiService, ApiServiceInstance, ConfigService, ConfigServiceInstance, RWSService} from '@rws-framework/client';
import {ScoreRecord} from "../types/activities";
import {LateWorkStatus} from "../types/late-work";
import { IConvo } from '@backend-types';

import { WebChat } from '../components/webchat/component';

interface IAPIOptions {
  headers?: Headers,
  routeParams?: {
      [key: string]: string
  }, 
  body?: any,
  onStart?: (options: IAPIOptions) => Promise<void> | void,
  onAborted?: (errorString: string, options: IAPIOptions) => Promise<void> | void
}

type InterruptibleResponse<T> = {
  controller: AbortController,
  interrupt: () => void,
  promise: () => Promise<T>
};

type InterruptibleBag<T> = {[opName: string]: InterruptibleResponse<T>}

type InstructorConvoList = {[userName: string]: IConvo[]};

class ConvoService extends RWSService {
  private _runningInterruptible: InterruptibleBag<any> = {};

  constructor(@ApiService private apiService: ApiServiceInstance, @ConfigService private configService: ConfigServiceInstance) {
    super();
  }

  getLastConvoId(convoKey: string): string
  {    
    return localStorage.getItem(`lesson_${convoKey}_chat_last_convo_id`);  
  }

  setLastConvoId(convoKey: string, convoId: string): void
  {    
    localStorage.setItem(`lesson_${convoKey}_chat_last_convo_id`, convoId);  
  }

  async getConvo(chat: WebChat, id: string, q: string = null): Promise<IConvo | null>
  {      
      const convoData: { data: IConvo } = await this.apiService.get(`${this.configService.get('backendUrl')}/api/talk/convo/${id}${q ? ('?q=' + q) : ''}`);   
  
      return convoData.data;
  }

  async interruptOperation(opName: string){

  }

  async getConvoList(course_id: string, q: string = null): Promise<IConvo[]>
  {
    
    const url = `${this.configService.get('backendUrl')}/api/talk/convo?course_id=${course_id}` + (q ? `&q=${q}` : '');    

    if(!!this._runningInterruptible[this.getConvoList.name]){
      this._runningInterruptible[this.getConvoList.name].interrupt();
      delete this._runningInterruptible[this.getConvoList.name];
    }

    const interruptibleConvoGet: InterruptibleResponse<IConvo[]> = this.interruptibleXHR(url, 'GET');
    this._runningInterruptible[this.getConvoList.name] = interruptibleConvoGet;
    const result: IConvo[] = await interruptibleConvoGet.promise();
    this._runningInterruptible[this.getConvoList.name] = null;
    return result;
  }

  //@TODO Move to RWS client - this.apiService.interruptible.get (etc.)  

  private interruptibleXHR<T>(
    url: string, 
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', 
    options: IAPIOptions = {}
  ): InterruptibleResponse<T> {
    const controller = new AbortController();
    const signal = controller.signal;
    let requestAborted = false;
  
    return {
      controller: controller,
      promise: (): Promise<T> => {
        return new Promise<T>((resolve, reject) => {
          fetch(url, {
            method,
            headers: this.getHeaders(options.headers, (this.apiService as any).token),
            signal: signal,
            body: options.body ? JSON.stringify(options.body) : null
          }).then((response: Response) => {
            if (!response.ok) {
              reject(new Error(`HTTP error! status: ${response.status}`));
              return;
            }
  
            if (options.onStart) {
              options.onStart(options);
            }
  
            response.json().then((results: T) => {
              resolve(results);
            }).catch((error) => {
              if (requestAborted) {
                if(options.onAborted){
                  options.onAborted(error, options);
                }
              } else {
                console.error('Failed to parse JSON:', error);
                reject(error);
              }
            });
          }).catch((error) => {
            if (requestAborted) {
              if(options.onAborted){
                options.onAborted(error, options);
              }
            } else {
              console.error('Fetch request failed:', error);
              reject(error);
            }
          });
        });
      },
      interrupt: (): boolean => {
        if (!requestAborted) {          
          controller.abort('Manual client interruption.');
          requestAborted = true;
          return true;
        }
        return false;
      }
    };
  }

  /** Inside API Service. TMP helper */

  private getHeaders(optHeaders: HeadersInit = {}, token = null): HeadersInit {
      const headers: HeadersInit = { ...optHeaders };                

      if (!('Content-Type' in headers)) {
          this.addHeader(headers, 'Content-Type', 'application/json');
      }            

      if (token) {
          this.addHeader(headers, 'Authorization', `Bearer ${token}`);            
      }        

      if((headers as any)['Content-Type']){
          this.addHeader(headers, 'Accept', '*/*');
      }else{
          this.addHeader(headers, 'Accept', (headers as any)['Content-Type']);
      }

      return headers;
  }

  private addHeader(headers: Headers | [string, string][] | {[key: string]: string}, key: string, val: string)
  {
      if (headers instanceof Headers) {
          headers.append(key, val);
      } else if (Array.isArray(headers)) {
          headers.push([key, val]);
      } else {
          headers[key] = val;
      }
  }

  /** END TMP APIService clones */

  async getInstructorConvoList(course_id: string, q: string = null): Promise<InstructorConvoList>
  {
    const url = `${this.configService.get('backendUrl')}/api/talk/instructor-convo?course_id=${course_id}` + (q ? `&q=${q}` : '');    

    if(!!this._runningInterruptible[this.getConvoList.name]){
      this._runningInterruptible[this.getConvoList.name].interrupt();
      delete this._runningInterruptible[this.getConvoList.name];
    }

    const interruptibleConvoGet: InterruptibleResponse<InstructorConvoList> = this.interruptibleXHR(url, 'GET');

    this._runningInterruptible[this.getConvoList.name] = interruptibleConvoGet;
    const result: InstructorConvoList = await interruptibleConvoGet.promise();
    this._runningInterruptible[this.getConvoList.name] = null;

    return result;
  }

  async deleteConvo(id: string): Promise<void>
  {
    await this.apiService.back.get<IConvo[]>('talk:convo:delete', { routeParams: { id } });
  }

  async renameConvo(id: string, name: string): Promise<void>
  {
    await this.apiService.back.post<IConvo[], {  name: string}>('talk:convo:rename', { name }, { routeParams: {  id } });
  }
}

export default ConvoService.getSingleton();

export {ConvoService as ConvoServiceInstance}
