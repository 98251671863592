// @ts-ignore
const APIversion = window.edrnaConfig.learnosity.version;

export class LearnosityAPI {
  //@ts-ignore
  static csrfToken = Cookies.get('csrf_token'); 

  static async getSignature(request: any) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment/signature/data", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async getItemSignature(request: any) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment/signature/items", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async getScores(signature: object) {
    function getFormData(object: any) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    };

    //we need to change data into Form Data format for Learnosity
    const formdata = getFormData(signature);
    try {
      const response = await fetch('https://data.learnosity.com/' + APIversion + '/sessions/responses', {
        method: "POST",
        body: formdata,
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async regradeUpdate(request: any) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "regrade/update", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async getResponses(courseId: string, load_sessions: any) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    const payload = {
      url: 'sessions/responses',
      activity_id: [],
      user_id: [],
      limit: 50, // extremely important as otherwise we don't recurse and get all the sessions
      session_id: load_sessions.length ? load_sessions : ['blah'],
      include_response_scores: true
    };
    try {
      const response = await fetch(APIprefix + 'grade/' +courseId, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async getMaxScores(items) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + 'regrade/get', {
        method: "POST",
        body: JSON.stringify({ item_references: items }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const all_max_scores = await response.json();

      const max_scores = {};
      items.forEach(item => {
        all_max_scores[item].forEach(question => {
          const v = question.data.validation;
          let max_score = 1;
          if (v.max_score) {
              max_score = v.max_score;
          } else if (v.scoring_type === "exactMatch" || v.scoring_type === "partialMatchV2" || v.scoring_type === "partialMatchElementV2") {
              max_score = v.valid_response.score;
          } else if (v.scoring_type === "partialMatch" && 'value' in v.valid_response && v.valid_response.value.length) {
              max_score = v.valid_response.score * v.valid_response.value.length;
          } else if (v.scoring_type === "partialMatchElement" && 'value' in v.valid_response && v.valid_response.value.length) {
              max_score = v.valid_response.score * v.valid_response.value.flat().length;
          }
          max_scores[question.reference] = max_score;
        });
      });
  
      return max_scores;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async postOutcome(request: any, courseId: string, session: any) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment/outcome/" + courseId + "/" + session.activity_id, {
        method: "POST",
        body: JSON.stringify([request]),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  static async update(update: any, courseId: string) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment/update/" + courseId, {
        method: "POST",
        body: JSON.stringify(update),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      const result = await response.json();
      result['message'] = 'Scores captured. Click Reload in the gradebook to see them.'
      console.log(result['message'])
      return result;
    } catch (error) {
      console.error("Error:", error);
      return {'message': "Error: " + error}
    };
  };
  
  // The whole point of this function is to be SYNCHRONOUS
  // poll, wait, poll, wait, ...., return the status message
  static async recurse(responseObj: any, update: any, attempts: any, courseId: string) {
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment/job_poller", {
        method: "POST",
        body: JSON.stringify(responseObj.jobs),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        }
      });

      //@ts-ignore
      if (response.status == "200") {
        return await this.update(update, courseId);
      } else if (attempts) {
        setTimeout(() => {
          this.recurse(responseObj, update, --attempts, courseId)
        }, 1000);
      } else {
        const e = {'message': 'Scores captured. It is taking a little longer for the gradebook to update but we have the data. No need to wait on this screen.'}
        console.log(e.message)
        return e
      }
    } catch (error) {
      const e = {'message': error}
      console.log(e);
      return e
    };
  };

  static async recurseMaxPoints(responseObj: any, payload: any, attempts: any, courseId: string) {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const jobResponse = await fetch(APIprefix + "assessment/job_poller", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify(responseObj.jobs)
      });
    
      if (!jobResponse.ok) {
        throw new Error('Network response was not ok');
      }
    
      const update = {
        'user_ids': responseObj.user_ids,
        'component_ids': responseObj.component_ids,
      };
      const job = await jobResponse.json();

      if (job.every(j => j.status === "completed")) {
        const updateResponse = await fetch(APIprefix + "assessment/update/" + courseId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': this.csrfToken
          },
          body: JSON.stringify(update)
        });
    
        if (!updateResponse.ok) {
          throw new Error('Update response was not ok');
        }
    
        const updated = await updateResponse.json();
        const message = "Assessment re-graded. Please click Reload to see the updated data.";
        return {message: message, payload: payload, updated: updated}
      } else if (attempts) {
        setTimeout(() => {
          this.recurseMaxPoints(responseObj, payload, --attempts, courseId);
        }, 1000);
      } else {
        const message = "Assessment re-graded. It is taking a little longer for the gradebook to pick these up but we have the data. Please Reload after 5 mins or come back later.";
        return {message: message, payload: payload}
      }
    } catch (error) {
      const message = "Assessment re-graded. It is taking a little longer for the gradebook to pick these up but we have the data. Please Reload after 5 mins or come back later.";
      return {message: message, payload: payload}
    }
  }

  static getAllResponses(questions, students) {
    const all_student_questions = {};
    questions.forEach(qref => {
      all_student_questions[qref] = [];
      Object.keys(students).forEach(student_id => {
        var f = students[student_id].feedback;
        (students[student_id].session.responses||[]).forEach(response => {
          if (response.question_reference === qref && response.max_score) {
            all_student_questions[qref].push({
              'response_id': response.response_id,
              'name': students[student_id].name,
              'id': students[student_id].session.user_id,
              'session_id': students[student_id].session.session_id,
              'feedback_session_id': f && f.feedback_session_id,
              'score': response.score,
              'max_score': response.max_score,
              'type': response.question_type,
              'response': response.response
            });
          }
        })
      })
    })
    return all_student_questions;
  };

  static calculateData(all_student_questions, activityId, max_scores) {
    const entries = {};
    Object.keys(all_student_questions).forEach(question_reference => {
      const question = all_student_questions[question_reference];
      question.forEach(response => {
        if (!entries[response.session_id]) {
          entries[response.session_id] = {
            user_id: response.id, 
            session_id: response.session_id,
            activity_id: activityId,
            responses: []
          }
        };
        const max_score = max_scores[question_reference] || 1;

        entries[response.session_id].responses.push({
          response_id: response.response_id, 
          attempted: true, 
          score: max_score,
          feedback: response.feedback,
          max_score: max_score,
        });
      })
    });

    return entries;
  }
};
