import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="example-prompt-form">    
    <form>
        <div class="form-group">
            <label for="bookSelect">Book</label>
            <fluent-select title="Choose book" id="bookSelect" @change="${ (x, c) => x.changeBook(c.event)}">
                <fluent-option :value="">Choose book</fluent-option>
                ${T.repeat(x => x.books, T.html`<fluent-option value="${(item, c) => item.id}">${item => item.title}</fluent-option>`)}
            </fluent-select>    
        </div>

        <div class="form-group">
            <label for="chapterSelect">Chapter</label>
            <fluent-select id="chapterSelect" :disabled="${ x => x.chapters === 0}" title="Choose chapter" @change="${ (x, c) => x.changeChapter(c.event)}">
                <fluent-option :value="">None</fluent-option>
                ${ x => console.log(x.chaptersList) }
                ${T.repeat(x => x.chaptersList, T.html`<fluent-option value="${(item, c) => c.index}">${(item, c) => c.index + 1}: ${item => item.title}</fluent-option>`,  { positioning: true })}
            </fluent-select>  
        </div>
        
        <div class="form-group">
            <label for="iconSelect">Icon</label>
            <fluent-text-field id="iconSelect" placeholder="Icon" appearance="outline"></fluent-text-field>
        </div>

        <div class="form-group">
            <label for="titleSelect">Prompt title</label>
            <fluent-text-field id="titleSelect" appearance="outline"></fluent-text-field>
        </div>
        
        <div class="form-group max-w">
            <label for="promptText">Prompt</label>                 
            <div class="prompt-control">
                <textarea id="promptText"></textarea>
            </div>
        </div>

        <div class="form-group has-buttons">
            <fluent-button :disabled="${ x => x.selectedBookId === null }" @click="${ x => x.addBinding()}">Save</fluent-button>
        </div>
    </form>    
</div>`;

import './styles/layout.scss';
const styles = T.css`#example-prompt-form {
  width: 100%;
}

fluent-button {
  --neutral-foreground-rest: #000;
}

form {
  display: flex;
  flex-wrap: wrap;
}
form * {
  box-sizing: border-box;
}
form .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px;
}
form .form-group label {
  font-weight: bold;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
form .form-group.has-buttons {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
form .form-group.max-w {
  flex: 1 1 100%;
}
form .form-group fluent-text-field, form .form-group fluent-select {
  min-width: 0px;
  width: 100%;
}
form textarea {
  border: 1px solid var(--neutral-30);
  width: 100%;
  min-height: 150px;
  background: var(--neutral-20);
  margin-top: 15px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { ILesson, IBook, IBookBind, LessonListActionResponse, IChapterDBEntry } from '@backend-types'


export type PromptPayload = {    
    bookId: string,
    chapterNr?: number,
    title: string
    prompt: string,
    icon?: string
}

@RWSView('example-prompt-form', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ExamplePromptForm extends RWSViewComponent  {

    @attr course_id: string;
    
    @observable books: IBook[] = [];
    @observable chapters: number = 0;
    @observable chaptersList: IChapterDBEntry[] = [];
    
    @observable selectedBookId: string| null = null;
    @observable selectedChapterNr:number| null = null;

   

    connectedCallback()
    {
        super.connectedCallback();  
        this.getBooks();        
    }

    private async getBooks(): Promise<void>
    {
        this.apiService.back.get<IBook[]>('train:get:books').then((books: IBook[]) => {
            this.books = books;   
            if( this.books.length){
                this.selectedBookId = this.books[0].id;      
            }           
        });
    }

    changeBook(event: CustomEvent<HTMLSelectElement | null>)
    {
        if(event.detail.value !== ''){
            console.log(event.detail.value);
            const chapters: IChapterDBEntry[] = this.books.find(item => item.id === event.detail.value).chapters;         

            this.chapters = chapters.length;

            const chapterlist: IChapterDBEntry[] = [];

            for(let i = 0; i< this.chapters; i++){
                chapterlist.push(chapters[i]);
            }

            this.chaptersList = chapterlist;            
            this.selectedBookId = event.detail.value;
        }else{
            this.chapters = 0;
            this.chaptersList = []
            this.selectedBookId = null;
        }        
    }

    changeChapter(event: CustomEvent<string | null>)
    {
        if(event.detail !== ''){
            this.selectedChapterNr = parseInt(event.detail);
        }else{
            this.selectedChapterNr = null;
        }        
    }


    addBinding()
    {
        const prompt = (this.$('#promptText') as HTMLInputElement).value;
        const bookId = (this.$('#bookSelect') as HTMLInputElement).value;
        const icon = (this.$('#iconSelect') as HTMLInputElement).value;
        const title = (this.$('#titleSelect') as HTMLInputElement).value;
        const chapterNr = parseInt((this.$('#chapterSelect') as HTMLInputElement).value);

        const payload: PromptPayload = {
            title,
            prompt,
            icon,
            bookId,
            chapterNr
        }        

        this.getShadowRoot().querySelector('form').reset();
        
        this.dispatchEvent(new CustomEvent<PromptPayload>('change', {
            detail: payload
        }))
    }
}

ExamplePromptForm.defineComponent();

export { ExamplePromptForm }