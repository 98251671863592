

import { LateWorkManager } from '../../components/lateWorkManager/lateWorkManagerBoard/component';
import { LwmAcceptModal } from '../../components/lateWorkManager/acceptModal/component';
import { LwmRejectModal } from '../../components/lateWorkManager/rejectModal/component';
import { LateWorkPolicyModal } from '../../components/lateWorkManager/lateWorkPolicyModal/component';

export default (parted: boolean = false) => {
    if (!parted) {
        LateWorkManager;
        LwmAcceptModal;
        LwmRejectModal;
        LateWorkPolicyModal;
    }
}
