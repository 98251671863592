import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section class="box">
  <div class="box__header">
    <div class="section-title header__title">
      <span class="material-symbols-outlined">insert_chart</span> 
      <span>Performance By Learning Objective</span>
    </div>
    <div class="header__actions">
      <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
    </div>
  </div>

  <div class="accordion-list flex flex__columns gap-12">
    <div class="grid-container">
      <div class="header-row">
        <div class="header-cell">
            <div class="header-cell-container">
                <span>Page Title</span>
            </div>
        </div>
        <div class="header-cell">
            <div class="header-cell-container">
                <span>Mastery Level</span>
            </div>
        </div>
        <div class="header-cell">
            <div class="header-cell-container">
                <span>Average Grade</span>
            </div>
        </div>
        <div class="header-cell">
          <div class="header-cell-container">
              <span></span>
          </div>
      </div>
      </div>
    </div>
    <div class="grid-container">
      ${T.repeat(x => x.assessments, T.html`
        <div class="accordion-item" id="${x => x.id}">
          <div class="data-row">
            <div class="data-cell semibold" title="${x => x.title}" :innerHTML="${x => x.title}" tabindex="0"></div>
            <div class="data-cell mastery-pill">
              <div class="mastery-pill-container">
                <div class="mastery-pill-green" style="width: ${x => x.mastery.green + 'px'}"></div>
                <div class="mastery-pill-red" style="width: ${x => (x.mastery.green + x.mastery.red) + 'px'}"></div>
              </div>
            </div>
            <div class="data-cell semibold">${x => x.average_grade}%</div>
            <div
              title="Open list"
              tabindex="0"
              @click="${(x, c) => c.parent.toggleItem(x)}" 
              @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.toggleItem(x) : ''}"
              class="opener-container">
                <span class="material-symbols-outlined accordion-opener">keyboard_arrow_down</span>
            </div>
          </div>
          <div class="hidden-content">
            <div class="grid-container">
              ${T.repeat(x => x.items, T.html`
                <div class="data-row" id="${x => x.id.value}">
                  <div class="data-cell" title="${x => x.title}" :innerHTML="${x => x.title}" tabindex="0"></div>
                  <div class="data-cell mastery-pill">
                    <div class="mastery-pill-container">
                      <div class="mastery-pill-green" style="width: ${x => x.mastery_levels.green + 'px'}"></div>
                      <div class="mastery-pill-red" style="width: ${x => (x.mastery_levels.green + x.mastery_levels.red) + 'px'}"></div>
                    </div>
                  </div>
                  <div class="data-cell">${x => x.avg_score}%</div>
                </div>
              `)}
            </div>
          </div>
        </div>
      `)}
    </div>
  </div>
</section>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  width: 200px;
  min-width: auto;
  font-family: inherit;
}
fluent-select::part(control) {
  width: 200px;
  height: 40px;
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #EEEFF0);
  color: var(--Color-palette-Neutral-60, #5B5C5E);
  font-family: inherit;
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}

.box {
  display: flex;
  overflow: hidden;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 2px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.box__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.box .header__title {
  display: flex;
  gap: 12px;
  flex: 1 0 0;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}
.box .header__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
.box .header__data {
  display: flex;
  gap: 48px;
}
.box .header__data .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--neutral-70);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.box .header__data .item__bold {
  color: var(--neutral-90);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}
.box .accordion-list {
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #eeeff0;
}
.box .accordion-list .grid-container .header-row {
  border-bottom: 2px solid #eeeff0;
  gap: 10px;
  display: grid;
  grid-template-columns: 40% 20% 15% 15% 50px;
  align-items: center;
}
.box .accordion-list .grid-container .header-row .header-cell {
  padding: 8px 0;
}
.box .accordion-list .grid-container .header-row .header-cell:first-child {
  padding-left: 14px;
}
.box .accordion-list .grid-container .header-row .header-cell .header-cell-container {
  text-align: left;
  font-weight: 600;
}
.box .accordion-list .grid-container .accordion-item .data-row {
  gap: 0 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 40% 20% 15% auto;
  transition: all 0.2s ease-in-out;
}
.box .accordion-list .grid-container .accordion-item .data-row .data-cell {
  padding: 16px 0;
}
.box .accordion-list .grid-container .accordion-item .data-row .data-cell:first-child {
  padding-left: 14px;
}
.box .accordion-list .grid-container .accordion-item .data-row .data-cell.mastery-pill .mastery-pill-container {
  width: 100px;
  height: 20px;
  border-radius: 4px;
  background: #D9D9D9;
  position: relative;
  z-index: 1;
}
.box .accordion-list .grid-container .accordion-item .data-row .data-cell.mastery-pill .mastery-pill-container .mastery-pill-green {
  position: absolute;
  width: 60px;
  height: 20px;
  border-radius: 4px;
  left: 0;
  background: #1DB954;
  z-index: 3;
}
.box .accordion-list .grid-container .accordion-item .data-row .data-cell.mastery-pill .mastery-pill-container .mastery-pill-red {
  position: absolute;
  width: 90px;
  height: 20px;
  border-radius: 4px;
  left: 0;
  background: #EA2828;
  z-index: 2;
}
.box .accordion-list .grid-container .accordion-item .data-row .opener-container {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin-left: auto;
  margin-right: 24px;
  transition: all 0.2s ease-in-out;
}
.box .accordion-list .grid-container .accordion-item .data-row .opener-container .accordion-opener {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 24px;
  transition: all 0.2s ease-in-out;
}
.box .accordion-list .grid-container .accordion-item .data-row .opener-container:hover, .box .accordion-list .grid-container .accordion-item .data-row .opener-container:focus-visible {
  cursor: pointer;
  background: var(--neutral-8, rgba(155, 160, 165, 0.08));
}
.box .accordion-list .grid-container .accordion-item:nth-child(odd) {
  background: rgba(155, 160, 165, 0.0784313725);
}
.box .accordion-list .grid-container .accordion-item.open {
  max-height: fit-content;
  overflow: visible;
  transition: all 0.2s ease-in-out;
}
.box .accordion-list .grid-container .accordion-item.open .accordion-opener {
  transform: rotate(180deg);
}
.box .accordion-list .grid-container .accordion-item.open .hidden-content {
  display: block;
}
.box .accordion-list .grid-container .accordion-item.open .hidden-content .data-row .data-cell:first-child {
  padding-left: 34px;
}
.box .accordion-list .grid-container .accordion-item.open .hidden-content .data-row:nth-child(odd) {
  background: rgba(155, 160, 165, 0.0784313725);
}
.box .accordion-list .grid-container .accordion-item .hidden-content {
  display: none;
  width: 100%;
  box-sizing: border-box;
}

.width-100 {
  width: 100%;
}

.semibold {
  font-weight: 500;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView } from "@rws-framework/client";
import { StudyCenterReport } from "src/types/assessments";

@RWSView('performance-by-learning-objective', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class PerformanceByLearningObjective extends RWSViewComponent  {
  @observable learningObjectiveData: StudyCenterReport;
  @observable assessments: any[] = [];
  @observable allAssessments: any[] = [];

  connectedCallback() {
    super.connectedCallback();
  }

  learningObjectiveDataChanged(oldval: any, newVal: any) {
    if (this.learningObjectiveData != undefined) {
      this.learningObjectiveData.lessons.forEach(lesson => {
        this.assessments = [
          ...this.assessments,
          {
            id: lesson.id,
            title: lesson.title,
            average_grade: lesson.avg_score,
            mastery: {
              green: lesson.mastery_levels.green,
              red: lesson.mastery_levels.red
            },
            goto: '',
            items: lesson.pages
          }
        ]
      })
      this.allAssessments = this.assessments;
    }
  }

  search = (value: any) => {
    this.assessments = this.allAssessments.filter((obj) =>
      obj.title.toLowerCase().includes(value.toLowerCase())
    );
  }

  toggleItem(item: any) {
    const selector = `.accordion-item[id='${item.id}']`;
    const element = this.shadowRoot.querySelector(selector);
    if (element) {
        element.classList.toggle('open');
    }
  }
}

PerformanceByLearningObjective.defineComponent();

export { PerformanceByLearningObjective };
