import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="chap"><h3><strong>Chapter ${ (x) => x._nr}:</strong> ${ (x) => x.item.title} (${x => x.item.pageStart} - ${x => x.item.pageEnd})</h3></div>                                                
`;

import './styles/layout.scss';
const styles = T.css`.chap {
  display: block;
}
.chap h3 {
  color: var(--accent-fill-rest);
}
.chap h2 {
  color: black;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent, observable, attr, ApiService, NotifyService } from '@rws-framework/client';

import { IBookInfo, IChapterOutput, IBookPageInput } from '@backend-types'

import '../../../page-element/component';

@RWSView('chapter-viewer-element', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChapterElement extends RWSViewComponent  {  
  @observable item: IChapterOutput;
  @attr checked: boolean = false;
  @attr index: string;
  @observable pages: IBookPageInput[];
  @observable _nr: number;
  @observable _index: number;

  @observable onCheck: (index: number) => void;

  connectedCallback(){
    super.connectedCallback();

    this._index = parseInt(this.index);    
    this._nr = parseInt(this.index) + 1;   
  }
}

ChapterElement.defineComponent();

export { ChapterElement }