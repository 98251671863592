import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<button id="ai_toggle_button" tabindex="0" @click="${(x, c) => x.handleClick(c.event)}">
  ${T.when(x => !x.profile?.icon && !x.profile?.image, T.html`
    <jnct-icon size="22px" class="material-symbols-outlined">smart_toy</jnct-icon>
  `)}
  ${T.when(x => x.profile?.icon && !x.profile.image, T.html`
    <jnct-icon size="22px" class="material-symbols-outlined">${x => x.profile.icon}</jnct-icon>
  `)}
  ${T.when((x, c) => x.profile?.image, T.html`
    <image src="${(x, c) => x.profile.image}" class="icon-image" />
  `)}
    AI Assistant
</button>`;

import './styles/layout.scss';
const styles = T.css`:host {
  display: inline-flex;
  margin-right: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai_toggle_button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #1B1C1E;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#ai_toggle_button .icon-image {
  border-radius: 4px;
  max-width: 25px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr, externalObservable } from "@rws-framework/client";
import { IBookBind, IChatProfile, BookBindFindActionResponse } from "@backend-types";
import { WebChat } from "../webchat/component";

@RWSView('chat-button', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatButton extends RWSViewComponent  {
    @observable show: boolean = false;    
    @observable profile: IChatProfile = null;

    @attr course_id: string = null;
    @attr lesson_id: string = null;
    @attr done: string = 'not-yet';

    private checkI = 0

    course_idChanged(old, newV) {
        this.course_id = newV
        this.checkI++
    }

    lesson_idChanged(old, newV) {
        this.lesson_id = newV
        this.checkI++
    }

    doneChanged(old, newV) {
        if (newV !== 'done') return;
        this.done = newV
        this.checkBookBind()
    }

    constructor() {
        super()

        this.switchHostVisibility(sessionStorage.getItem('chat_button_visible') === 'yes')

        if (sessionStorage.getItem('chat_button_visible') === 'yes') {
            this.styleHost()
        }

        const savedChatProfile = sessionStorage.getItem('webchat_storage_chat_profile') || null

        if (savedChatProfile) {
            this.profile = savedChatProfile ? JSON.parse(savedChatProfile) : null
        }
    }

    private switchHostVisibility(visible: boolean) {
        const rootEl = (this.getShadowRoot().host as HTMLElement).parentElement
        rootEl.style.display = visible? 'flex' : "none"
    }

    async connectedCallback() {
        super.connectedCallback()
        
        document.documentElement.dispatchEvent(new CustomEvent('chat-button-shown', { detail: true }))

        await this.checkBookBind()

        document.documentElement.addEventListener('jnct:chat-profile:pass', (e: any) => {
            if (e.detail) this.profile = e.detail
        })

        const reseller = window.labelBundle.reseller
        const savedChatProfile = sessionStorage.getItem('webchat_storage_chat_profile') || null

        if (!savedChatProfile) {
          // Seems like we do this only to obtain the icon/image from here
          (window.RWS.client.get('ApiService').back.get(
            'chat-profile:resource:get', {'routeParams': {'id': reseller}}
          )).then((response) => {
            sessionStorage.setItem('webchat_storage_chat_profile', JSON.stringify(response.data))
          })
        }

        this.switchHostVisibility(true)
        this.styleHost()
    }

    private styleHost() {
        this.getShadowRoot().host.classList.add('panel__button', 'gap-8', 'ds-btn');        
    }

    private doSuicide() {
        const componentHost = this.getShadowRoot().host as HTMLElement
        sessionStorage.setItem('chat_button_visible', 'no')
        componentHost.parentElement.remove()
    }

    private async checkBookBind(): Promise<IBookBind | null> {
        if (!this.course_id || !this.checkI) return null;

        try {  
            const bindingReturn = (await this.apiService.back.get<BookBindFindActionResponse>(
                'course:book-bindings:find', 
                {
                    routeParams: {
                        courseId: this.course_id,
                        lessonId: this.lesson_id ? this.lesson_id : 'none'
                    }
                }
            )).data

            if (bindingReturn) {
                sessionStorage.setItem('chat_button_visible', 'yes')
            } else {
                this.doSuicide()
            }

        } catch(e) {
            // alert('Error: Node server is not online.');
            this.doSuicide()
        }
    }

    handleClick(e: Event) {
        document.documentElement.dispatchEvent(new CustomEvent<null>('jnct:ai:show'))
    }
}

ChatButton.defineComponent();

export { ChatButton }