import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div>
${T.when(x => x.students.length, T.html`
    <article class="lti__main main">
      <div class="main__title">
        ${T.when(x => !x.showNext, T.html`    
          ${T.when(x => !x.selectedCount, T.html`    
            ${x => x.students.length} students
            (<span tabindex="0" @click="${(x, c) => x.selectAll(true)}" class="link">
              Select all
            </span>)
        `)}

        ${T.when(x => x.selectedCount, T.html`    
          ${x => x.selectedCount} student${x => x.selectedCount > 1 ? 's' : ''} selected (
          <span tabindex="0" @click="${(x, c) => x.selectAll(false)}" class="link">
            Cancel selection
          </span>)
        `)}
      </div>

      <div class="main__table_container">
        <ul class="main__table">
          ${T.repeat(x => x.studentList, T.html`        
          <li class="item">
            <div>
              <fluent-checkbox 
              id="${x => x.id}" 
              :checked="${(x, c) => c.parent.selected[x._id] === true}"
              @click="${(x, c) => c.parent.checkboxChange(x._id)}"
              style="--accent-fill-rest: var(--primary_color);--accent-fill-hover: var(--primary_color);--accent-fill-focus: var(--primary_color);--accent-fill-active: var(--primary_color);"
              ></fluent-checkbox>
            </div>
            <label for="${x => x._id}">
              ${x=> x.surname}, ${x => x.name}
            </label>
            <div>${x => x.email}</div>
            <div>Grades to sync: ${x => x.counter}</div>
          </li>
          `)}
          ${T.repeat(x => x.notfounds_in_lms, T.html`        
          <li class="item">
            <div></div>
            <div>
              ${x => x.name + ' ' + x.surname + ' (' + x.email + ')' }
            </div>
            <div>${x => x.email}</div>
            <div>Student was not found in the LMS</div>
          </li>
          `)}
          ${T.repeat(x => x.notfounds_in_app, T.html`        
          <li class="item">
            <div></div>
            <div for="${x => x._id}">
              ${x => x.name + ' (' + x.email + ')' }
            </div>
            <div>${x => x.email}</div>
            <div>Student was not found in the app</div>
          </li>
          `)}
        </ul>     
      </div>
    </article>`)}
`)}


${T.when(x => !x.students.length && !x.errorMessage, T.html`<lti-sync-confirm 
  icon="info"  
  buttons="false"
  title="No students"            
>
  <p>No students loaded</p>
</lti-sync-confirm>`)}
${T.when(x => x.loading, T.html`<jnct-loading-spinner></jnct-loading-spinner>`)}


${T.when(x => x.errorMessage, T.html`<lti-sync-confirm 
  icon="exclamation"
  iconColor="var(--jnct-error-color)"
  buttons="false"
  title="${x => x.errorMessage}"        
>    
</lti-sync-confirm>`)}

</div>`;

import './styles/layout.scss';
const styles = T.css`.main * {
  box-sizing: border-box;
}
.main__title {
  margin-bottom: 8px;
  padding: 0px 4px;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.main__table_container {
  height: calc(100vh - 375px);
  overflow-y: auto;
  padding: 15px 0;
}
.main__table {
  display: table;
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.main__table .item {
  display: table-row;
  padding: 16px 24px;
  gap: 28px;
  align-items: center;
  align-self: stretch;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.main__table .item > * {
  display: table-cell;
  padding: 8px;
  color: var(--neutral-90);
  font-size: 14px;
}
.main__table .item:nth-child(even) {
  background-color: white;
}
.main__table .item:nth-child(odd) {
  background-color: var(--neutral-8);
}

.lti-alert {
  border: 1px solid var(--jnct-info-color, #247ed3);
  border-radius: 15px;
  padding: 15px;
  color: var(--jnct-info-color, #247ed3);
  text-align: center;
}
.lti-alert.warning {
  border-color: var(--jnct-warning-color, #c59326);
  color: var(--jnct-warning-color, #c59326);
}
.lti-alert.error {
  border-color: var(--jnct-error-color, #ca1515);
  color: var(--jnct-error-color, #ca1515);
}`;

const shadowOptions = {"mode":"open"};

import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { observable } from "@microsoft/fast-element"; 
import { LTIConfigType } from "../../../../types/lti/config";
import { SelectedType } from "../../component";
import { IStudent } from "../../../../types/students"; 
import { AssignmentsType } from "../ltiSyncAssignments/component";
import { er } from "@fullcalendar/core/internal-common";

type LTIRosterType = {
  lmsreg?: IStudent[],
  nonlms?: IStudent[],
  lmsnonreg?: IStudent[],
  error?: any
}

@RWSView('lti-sync-students', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class LtiSyncStudents extends RWSViewComponent  {    
  @observable selectedAll: boolean = false;  
  @observable selected: SelectedType = {};
  @observable course_config: LTIConfigType;
  @observable selectedAssignments: AssignmentsType[] = [];
  @observable scores: any = {};
  @observable selectedCount: number = 0;
  @attr courseId: string = '';
  @observable students: IStudent[] = [];
  @observable studentList: IStudent[] = [];
  @observable notfounds_in_app: IStudent[] = [];
  @observable notfounds_in_lms: IStudent[] = [];
  @observable user_scores: any = {}

  @attr apiPrefix: string;
  @observable errorMessage: string | null = null;

  @observable loading: boolean = false;
  private loaded: boolean = false;
  private studentsFired: boolean = false;
  
  connectedCallback(): void {
    super.connectedCallback()
    this.loaded = true
  }

  selectAll(state: boolean): void {
    this.selectedAll = state
    this.selected = {}

    if (state) {
      this.students.forEach(el => {
        this.selected = {...(this.selected), [el._id]: state}
      })
    }
    
    this.countSelected()
  }

  countSelected(): void {
    this.selectedCount = Object.keys(this.selected).filter((el) => this.selected[el] === true).length
  }

  checkboxChange(id: string): void {            
    if (!Object.keys(this.selected).includes(id) || this.selected[id] === false) {
      this.selected = {...(this.selected), [id]: true}
    } else {
      this.selected = {...(this.selected), [id]: false}
    }                 
  }

  private async checkStudents() {
    if (!(this.apiPrefix && this.courseId && this.loaded && this.course_config) || this.studentsFired) {
      return
    }

    this.studentsFired = true
    this.loading = true

    const throwError = (e: Error | any) => {
      this.students = []
      this.errorMessage = e.message
      console.error('LTI Students call error:', e.message)
      console.log(this.errorMessage)
    };

    try {
      const ltiRoster: LTIRosterType = await this.getStudents()

      if (ltiRoster.error) {        
        throwError(new Error(ltiRoster.error))
        return
      }
      
      // console.log('LTI Students call response:', ltiRoster)
      this.loading = false

      const allStudents: IStudent[] = [...ltiRoster.lmsreg, ...ltiRoster.nonlms, ...ltiRoster.lmsnonreg].sort((a, b) => a.name.localeCompare(b.surname));

      const sortedStudentRoster: LTIRosterType = {
        lmsreg: allStudents.filter(x => ltiRoster.lmsreg.find(imp => imp._id === x._id)),
        nonlms: allStudents.filter(x => ltiRoster.nonlms.find(imp => imp._id === x._id)),
        lmsnonreg: allStudents.filter(x => ltiRoster.lmsnonreg.find(imp => imp._id === x._id))
      }

      this.students = sortedStudentRoster.lmsreg
      this.notfounds_in_lms = sortedStudentRoster.nonlms
      this.notfounds_in_app = sortedStudentRoster.lmsnonreg

      const selectedAssignments = this.selectedAssignments

      // It would be better to keep this all on the angular side for now since the loop is also there
      // and the counts must match or else the counter will hang....
      const scores = this.scores
      const config = this.course_config[0]
      const user_scores = this.user_scores
      this.students.sort((a, b) => a.surname.localeCompare(b.surname));
      this.students.forEach(function(user) {
        user.counter = 0;
        selectedAssignments.forEach(function(assignment, index) {
          // console.log(config, user, user._id, assignment, scores, user_scores, typeof(scores[user._id]?.[assignment.sync_index]))

          if (["total", "total-percent"].includes(config.lti_sync_setup_type) && user_scores[user._id]?.score >= 0) {
            user.counter++
            return
          }

          if (assignment && 
              !["string", "undefined"].includes(typeof(scores[user._id]?.[assignment.sync_index])) &&
              scores[user._id]?.[assignment.sync_index] >= 0
          ) {
            // score is a non-negative number so we can push
            user.counter++;
          }
        })
      })

      // moving it down after the counting
      this.$emit('jnct:ltistudentlist', this.students);

    } catch(e: Error | any) {
      this.loading = false
      throwError(e)
    }
  }

  private emit(): void {
    this.dispatchEvent(new CustomEvent<IStudent[]>('change', {
      detail: Object.keys(this.selected)
                    .filter(sel => this.selected[sel] === true)
                    .map(sel => this.students.find(student => student._id === sel))
    }))
  }

  async getStudents(): Promise<LTIRosterType> {
    try {
      const response = await this.apiService.get<any>(
        this.apiPrefix + "lti1.3/roster/" + this.courseId + "/" + this.course_config[0].guid
      )

      return response
    } catch (error) {
      console.error("Error:", error)
      return { error: error }
    }
  }

  selectedChanged(oldValue?: SelectedType, newValue?: SelectedType) {    
    this.countSelected()
    this.emit()
  }

  attributeChangedCallback(name: any, oldValue: any, newValue: any) {    
    if (name === 'courseid' && newValue) {
      this.courseId = newValue
    }

    if (name === 'apiprefix' && newValue) {
      this.apiPrefix = newValue
    }

    // console.log(name, this.apiPrefix, this.courseId, this.loaded, this.course_config)
    this.checkStudents()
  };

  course_configChanged(oldVal?: LTIConfigType, newValue?: LTIConfigType): void {    
    if (newValue) {             
      this.course_config = newValue 
    }
  }
}

LtiSyncStudents.defineComponent()

export { LtiSyncStudents }
