// TODO: should be used in `Activity`, but needs to be checked if does not break anything
export type ActivityType =
  | "assessment"
  | "board"
  | "comments"
  | "course"
  | "e_reader"
  | "flashcards"
  | "html"
  | "iframe"
  | "lesson"
  | "lti"
  | "page"
  | "pdf"
  | "picture"
  | "project"
  | "video";

export interface Activity {
  id: string,
  title: string,
  instructor_title: string,
  link: string,
  type: string,
  sub_type?: string,
  graded: boolean,
  course_data_only: boolean,
  lessonName?: string,
  lesson?: {
    id: string,
    title: string
  },
  index?: number,
  report_title?: string,
  thumbnailUrl?: string,
  max_score?: number;
  display_due_date?: string,
  open_date?: string, // iso string in UTC
  close_date?: string, // iso string in UTC
  due_date?: string, // iso string in UTC
  submissions?: number,
  scoring_type?: string,
  count_graded?: number,
  count_overdue?: number,
  suppress_until_available?: boolean,
  activity_id?: string,
  items?: any,
  // ... and probably more optionals that I missed
  submission_attempts?: number;
  resume_max_attempts?: number;
  show_score?: ShowScore;
  show?: boolean;
  show_correct_answers?: ShowAnswers;
  show_responses?: ShowAnswers;
  inline?: ShowInline;
  scramble?: boolean;
  lock_back_button?: boolean;
  time_limit?: number;
  requires_comment?: boolean;
  confidence?: boolean;
  studycenter?: boolean;
  policy?: Policy;
  preset_id?: string;
  pickx?: number;
  rubric_id?: string;
  //
  oopen_date?: string; // MM/dd/yyyy shifted to timezone selected by user (local by default)
  ddue_date?: string; // MM/dd/yyyy shifted to timezone selected by user (local by default)
  cclose_date?: string; // MM/dd/yyyy shifted to timezone selected by user (local by default)
  open_time?: string; // HH:mm shifted to timezone selected by user (local by default)
  due_time?: string; // HH:mm shifted to timezone selected by user (local by default)
  close_time?: string; // HH:mm shifted to timezone selected by user (local by default)
  //
  in_advanced?: string; // element is tab in advanced element
  cd_sua?: boolean; // course data suppress until available
  comments_comp?: string;
  actual?: string,
  rollup_id?: string;
  display_type?: string;
  open_date_in_future?: string;
}

export type ActivityWithGradeStats = Activity & { submissions: number; count_graded: number; count_overdue: number };

export const ActivityModel = {
  types: (): Array<Activity["type"]> => [
    "assessment",
    "board",
    "comments",
    "course",
    "custom",
    "e_reader",
    "flashcards",
    "html",
    "iframe",
    "lesson",
    "lti",
    "page",
    "pdf",
    "picture",
    "project",
    "video",
  ],

  displayType: (type: Activity["type"]): string => {
    const mapping = {
      assessment: "Assessment",
      board: "Discussion Board",
      lti: "LTI Link",
      custom: "Custom Column",
      page: "Page",
      lesson: "Lesson",
      html: "HTML",
      video: "Video",
      e_reader: "E-Reader",
      flashcards: "Flashcards",
      iframe: "Embedded URL",
      audio: "Audio",
      pdf: "PDF",
      picture: "Image",
      presentation: "Presentation",
      project: "Project",
      comments: "Comments",
    };

    return mapping[type] || type;
  },
};

export interface ScoreRecord {
  score: number,
  _id?: string,
  session_id?: string,  // learnosity session
  course_id?: string,
  activity_id?: string,
  user_id?: string,
  max_score?: number,
  dt_completed?: string, // date scored
  dt_started?: string, // date created
  overdue_accepted?: boolean | number,
  responses?: Array<Object>,
  custom?: boolean,
  contains_overdue?: boolean | number,
  needs_grading?: boolean,
  manually_rejected?: boolean
  manually_accepted?: boolean
  submitted_score?: number,
  not_displaying_score?: boolean,
  not_displaying_score_yet?: boolean,
  instructor_graded_this?: boolean,
  project_reopened?: boolean,
}

export interface ActivityStudentScore {
  [activity_id: string]: {
    [student_id: string]: ScoreRecord // Last key-value is always average activity score `average: number`
  }
}

export interface AssignmentObject {
  title: string;
  start: string;
  end: string;
  url?: string;
  extendedProps?: {
      type: string;
      assignedDate: string;
      submitted: boolean | string;
      submittedCount: string;
      graded: string;
      gradedCount: string;
      averageGrade: string;
      status: string;
      done: boolean;
      assignmentUrl: string;
  }
}

export enum ShowInline {
  AFTER_DUE_DATE = "After Due Date",
  IMMEDIATELY = "Immediately",
  IMMEDIATELY_WITH_CORRECT_ANSWERS = "Correct Answers Immediately",
  IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE = "Immediately Correct Answers After Due Date",
  AFTER_DUE_DATE_WITH_CORRECT_ANSWERS = "Correct Answers After Due Date",
  NEVER = "Never",
}

export enum HumanlyChoices {
  HOUR = "Hour",
  DAY = "Day",
  WEEK = "Week",
}
export type DefinedPolicy = {
  max: number;
  penalty: number;
  per: number;
  humanly_per: HumanlyChoices;
}
export type Policy =
  | DefinedPolicy
  | {};

export const Policy = {
  isNotEmpty: (policy: Policy): policy is DefinedPolicy => Object.keys(policy).length > 0
}

export enum ShowAnswers {
  NEVER = "Never",
  AFTER_DUE_DATE = "After Due Date",
  AFTER_CLOSE_DATE = "After Close Date",
  AFTER_MAX_ATTEMPTS = "After Max Attempts",
  IMMEDIATELY = "Immediately",
}

export enum ShowScore {
  IMMEDIATELY = "Immediately",
  WITH_RESPONSES = "When responses are available",
}

export enum GradedState {
  GRADED = "Graded",
  NOT_GRADED = "Not Graded",
}

export type ActivityLockStatus = 'locked' | 'unlocked' | 'locked-disabled' | 'none';

export interface LastSubmittedActivityPerStudent {
  [student_id: string]: {
    submission_date: string,
    activity_id: string
  }
}

export interface LessonAssessments {
  [lesson_id: string]: {
    activities: Activity[],
    distribution: any, // TODO not sure where this is used but nice to have it typed
    scores: {
      [activity_id: string]: {
        [student_id: string]: number  // score, not percentage
      }
    }
  }
}
