import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="junction-personas">
    <div class="top-holder">
        <header>
            <h3>Personas</h3>
        </header>
        <aside>
            <fluent-button @click=${(x,c) => x.onAdd()}><jnct-icon size="20px">add_circle</jnct-icon></fluent-button>
        </aside>
    </div>    

    <div class="personas-list">
        ${T.repeat(x => x.personas, T.html`
            <fluent-card class="persona-card">
                <header>
                    <h4>${x => x.title}</h4>
                    <fluent-button @click="${(x, c) => c.parent.deletePersona(x)}"><jnct-icon size="20">delete</jnct-icon></fluent-button>
                </header>
                ${T.when(x => x.image, T.html`<img src="${x => x.image}" alt="${x => x.title}"/>`)}
            </fluent-card>
        `)}
    </div>

    ${T.when(x => x.loading, T.html`<div class="loader-wrap"><jnct-loading-spinner/></div>`)}
</div>

${T.when(x => x.showModal && x.modalConfig, T.html`<jnct-modal
    templateName="junction-persona-edit-modal"
    :genericConfig="${ x => x.modalConfig}"
    style="--max-modal-width: 900px;"
></jnct-modal>`)}`;

import './styles/layout.scss';
const styles = T.css`* {
  box-sizing: border-box;
}

#junction-personas .personas-list {
  display: flex;
  flex-wrap: wrap;
}
#junction-personas .persona-card {
  flex: 0 0 25%;
  max-width: 25%;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  #junction-personas .persona-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  #junction-personas .persona-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  #junction-personas .persona-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
#junction-personas .persona-card img {
  max-width: 100%;
  height: auto;
}
#junction-personas .persona-card header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
#junction-personas .top-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { ControllerActionResponse, IPersona } from '@backend-types'

import './modals/editModal/component';

@RWSView('junction-personas', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class JunctionPersonas extends RWSViewComponent  {
    @observable personas: IPersona[] = [];    
    @observable selectedPersona: IPersona = null;    
    @observable reseller: string = 'locally';
    @observable loading: boolean = false;
    @observable showModal: boolean = false;

    @observable modalConfig = { reseller: null, save: () => {
        // this.savePersona(this.selectedPersona);
      }, hide: () => {        
        this.showModal = false;
        this.selectedPersona = null;
      }
    }

    connectedCallback()
    {
        super.connectedCallback();
        this.reseller = (window as any).labelBundle.reseller;
        this.getPersonas();
        this.on('personas-modal:reload-personas', () => {
            this.getPersonas();
        });

        this.on('personas-modal:hidden', () => {
            this.showModal = false;
            this.selectedPersona = null;
        });
    }  

    async getPersonas() {
        this.personas = (await this.apiService.back.get<ControllerActionResponse<IPersona[]>>('persona:resource:listadmin', { routeParams: { id: this.reseller } })).data;
    }

    editPersona(persona: IPersona) {
        this.selectedPersona = persona;
        // Add logic to open an edit form or a modal
    }

    async deletePersona(persona: IPersona) {
        if(!confirm('Do you want to delete persona?')){
            return;
        }
        this.selectedPersona = null;
        (await this.apiService.back.get<ControllerActionResponse<IPersona[]>>('persona:resource:delete', { routeParams: { id: persona.id } }));
        this.getPersonas();
    }

    onAdd() {
        this.showModal = true;
        this.selectedPersona = {
            id: null,
            title: '',
            character: '',
            image: '',
            prompt: ''                
        };                
    }    
}

JunctionPersonas.defineComponent();

export { JunctionPersonas }