import { IBoxData } from '../../types/box-data';
import { BoundaryModType, DragOptions } from '../ResizeService';
import { getBoxData } from './_box';
export const BUMP_UNIT = 5;

export function bump(target: HTMLElement, targetBox: IBoxData, boundaryBox: IBoxData | null): IBoxData & { edge: string | null}
  {  
    let x: number = targetBox.left;
    let y: number = targetBox.top;
    let edge: string | null = null;

    const leftEdge = targetBox.left <= boundaryBox.left;
    const rightEdge = targetBox.left + targetBox.w >= boundaryBox.left + boundaryBox.w;
    const topEdge = targetBox.top <= boundaryBox.top;
    const bottomEdge = targetBox.top + targetBox.h >= boundaryBox.top + boundaryBox.h;




    if(leftEdge){
      x = boundaryBox.left + BUMP_UNIT;
      y = targetBox.top;
      edge = 'left';      

    }
    
    if(topEdge){
      y = boundaryBox.top + BUMP_UNIT;
      x = targetBox.left;
      edge = 'top';      

    }
    
    if(bottomEdge){
      y = boundaryBox.top + boundaryBox.h - targetBox.h - BUMP_UNIT;
      x = targetBox.left;
      edge = 'bottom';      

    }

    if(rightEdge){
      x = boundaryBox.left + boundaryBox.w - targetBox.w - BUMP_UNIT;
      y = targetBox.top;
      edge = 'right';      
    }

    return {
      ...targetBox,
      left: x, 
      top: y,
      edge
    }
  }

  export function isOutOfBoundary(target: HTMLElement, boundary: IBoxData){
    const targetRect = target.getBoundingClientRect();    

    const isOutOfBounds: boolean = targetRect.left <= boundary.left ||
      targetRect.top <= boundary.top ||
      targetRect.left + targetRect.width >= boundary.left + boundary.w ||
      targetRect.top + targetRect.height >= boundary.top + boundary.h;
    return isOutOfBounds;  
  }

  export function makeBoundary(boundary: HTMLElement | IBoxData, boundaryMod: BoundaryModType | null = null): IBoxData | null
  {
    const hOffset = boundaryMod && boundaryMod.h ? boundaryMod.h : 0;
    const wOffset = boundaryMod && boundaryMod.w ? boundaryMod.w : 0;  
    
    let boundaryReturn: IBoxData | null = null;

    if(boundary){
      if(boundary instanceof HTMLElement){
        boundaryReturn = getBoxData(boundary);

        if(boundaryReturn && hOffset){
          boundaryReturn.h += hOffset;
        }

        if(boundaryReturn && wOffset){
          boundaryReturn.w += wOffset;
        }

      }else{
        boundaryReturn = {
          w: window.innerWidth + wOffset,
          h: document.documentElement.clientHeight + hOffset,
          top: 0,
          left: 0,
        }
      }
    }

    return boundaryReturn;
  }

  export function markBoundaryData(target: HTMLElement, options: DragOptions)
  {
    const boundary: IBoxData = options.boundary ? (options.boundary instanceof HTMLElement ? getBoxData(options.boundary) : options.boundary) as IBoxData : null;

    if (!boundary) {
      return;
    }    
    
    const style = window.getComputedStyle(target);
    const matrix = new WebKitCSSMatrix(style.transform);
    const start_x = matrix.m41;
    const start_y = matrix.m42;

    if(!target.hasAttribute('data-start-x')){
      target.setAttribute('data-start-x', start_x.toString());
    }          
    if(!target.hasAttribute('data-start-y')){
      target.setAttribute('data-start-y', start_y.toString());
    } 
  }