import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="book_clasifier_area">
    <fluent-tabs :activeid="${x => x.active_tab}">
        <fluent-tab id="book_upload">Book upload</fluent-tab>
        <fluent-tab id="book_preview">Book chapters preview</fluent-tab>

        <fluent-tab-panel id="book_upload">
            ${T.when(x => x.loading, T.html`<div style="position: fixed; width: 100%; left: 0; height: 80px; margin: 15px 0;z-index: 1000; display: flex; align-items: center; justify-content: center;"><jnct-loading-spinner></jnct-loading-spinner></div>`)}
            

            <div class="chapter-detect-bg">
                <fluent-switch id="auto-detect-switch" @change="${x => x.onDetectSwitch()}" :checked="${x => x.autoDetect}">
                    <strong>Auto chapter detection</strong>
                    <span slot="checked-message"></span>                    
                    <span slot="unchecked-message">Disabled</span>
                </fluent-switch>

                ${T.when(x=> x.autoDetect, T.html`<div class="index-setup">
                    <fluent-switch id="index-switch" @change="${x => x.onIndexSwitch()}" :checked="${x => x.indexSwitch}">
                        <strong>Detection by index page</strong>
                        <span slot="checked-message">Index page:</span>
                        <span slot="unchecked-message">Disabled</span>
                    </fluent-switch>

                    ${T.when(x=> x.indexSwitch, T.html`
                        <div class="index-ranges">
                            <fluent-text-field type="text" appearance="outline" current-value="${x => x.autoIndexFrom}" id="get_autoindex_from" @change="${x => x.onIndexFromChange()}"></fluent-text-field>
                            <div class="separate-inputs">
                            
                            </div> 
                            <fluent-text-field type="text" appearance="outline" current-value="${x => x.autoIndexTo}" id="get_autoindex_to" @change="${x => x.onIndexToChange()}"></fluent-text-field>
                        </div>
                    `)}
                    
                </div>`)}                
            </div>

            ${T.when(x=> !x.loading, T.html`<div>
            <h2>Chapter list</h2>

            <div class="chapter-detect-bg">
                ${T.when(x=> !x.autoDetect || x.autoDetect && x.chaptersLoaded, T.html`<fluent-button @click="${ x => x.onAddChapter() }" appearance="accent" class="add-chapter">Add chapter</fluent-button>`)}

                ${T.when(x=> x.autoDetect || x.autoDetect && !x.chaptersLoaded, T.html`<strong>AI is loading chapters from PDF...</strong>`)}


                <div class="chapter-list">
                    ${T.repeat(x => x.chapterDefinitions, (item, c) => T.html`            
                        <book-chapter-def :title="${item => item.title}" :startPage="${item => item.pageStart}" :endPage="${item => item.pageEnd}" index="${(item, c) => c.index}" :deleteMethod="${(item, c) => c.parent.removeChapter.bind(c.parent)}"></book-chapter-def>
                    `, { positioning: true })}
                </div>
            </div>
        </div>`)}


            ${T.when(x => !x.max_process_parts && !x.loading, T.html`<div>                
                <rws-uploader :uploadProgress="${x => x.progress}" :uploadParams="${ x => x.uploadParams}" :onStart="${x => x.preUpload.bind(x)}" :onFinish="${x => x.uploadCallback.bind(x)}"></rws-uploader>
            </div>`)}

            ${T.when(x => x.chaptersLoaded, T.html`<fluent-button @click="${ x => x.sendChapters() }" appearance="accent" class="send-chapters-btn">Send loaded chapters</fluent-button>`)}
        </fluent-tab-panel>
        
        <fluent-tab-panel id="book_preview">
            <chapter-viewer :pdffile="${ x => x.pdftmp }" :pdffilename="${ x => x.pdfname }" :bookInfo="${x => x.theBookData}"/>
        </fluent-tab-panel>
    </fluent-tabs>    
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#book_clasifier_area {
  padding: 25px;
  position: relative;
}
#book_clasifier_area .chapter-list {
  margin-top: 25px;
}
#book_clasifier_area #index-switch {
  margin-right: 15px;
}
#book_clasifier_area .parts-loader {
  font-size: 24px;
  margin: 15px auto;
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #424242;
}
#book_clasifier_area .loading_bar {
  margin-top: 20px;
}
#book_clasifier_area .chapter-detect-bg {
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 0px 5px;
  margin: 25px;
  padding: 15px;
}
#book_clasifier_area fluent-button.send-chapters-btn {
  float: right;
}
#book_clasifier_area h2 {
  color: #424242;
}
#book_clasifier_area .index-setup {
  display: inline-flex;
}
#book_clasifier_area .separate-inputs {
  display: inline-flex;
  margin: 0 15px;
}
#book_clasifier_area .separate-inputs::after {
  content: "-";
  display: block;
}
#book_clasifier_area .index-ranges {
  display: inline-flex;
  justify-content: left;
  align-items: center;
}
#book_clasifier_area fluent-text-field {
  display: inline-flex;
}
#book_clasifier_area fluent-switch {
  --neutral-foreground-rest: #000 !important;
}
#book_clasifier_area rws-uploader {
  --rws-uploader-text: rgb(51, 51, 51);
  --rws-uploader-primary: $main_color;
  --rws-uploader-bg: #FFF;
}`;

const shadowOptions = {"mode":"open"};
import { RWSInject, RWSView, RWSViewComponent } from '@rws-framework/client';

import { observable } from '@microsoft/fast-element';
import { IBookInfo, IChapterOutput, ISplitData, ITrainApiResponse, ITrainWsResponse, ProcessPayload } from '@backend-types';
import bookEvents from './events';
import Papa from 'papaparse';

import './children/chapter-def/component';
import '@rws-framework/client/src/components/uploader/component';
import { IRWSPromptJSON } from '@rws-framework/server/src/models/prompts/_prompt';
import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';

@RWSView('trainer-book-classifier', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class BookClassifier extends RWSViewComponent  {
    @observable loading: boolean = false;
    @observable active_tab: string = 'book_upload';

    @observable max_process_parts: number | null = null;
    @observable processed_parts: number = 0;
    @observable autoIndexFrom: number = 1;
    @observable autoIndexTo: number = 1;

    @observable progress: number = 0;
    @observable pdftmp: string = null;
    @observable pdfname: string = null;


    @observable theBookData: IBookInfo;
    @observable uploadParams: any;
    @observable autoDetect: boolean = false;
    @observable indexSwitch: boolean = false;
    @observable chaptersLoaded: boolean = false;
    @observable pages: string[];

    @observable chapterDefinitions: IChapterOutput[] = [];

    private wsId: string | null = null;

    private customPages: string[] = [];
    
    @observable stopIt: boolean = false;

    private pasteListner: (event: any) => void

    constructor(@RWSInject(WSService) private wsService: WSServiceInstance){
        super();
    }


    connectedCallback() {
        super.connectedCallback();

        this.wsService.init();

        this.on(bookEvents.book_classifier.chapter_def_change, (data: CustomEvent<{ index: number, title: string, pageStart: number, pageEnd: number | null }>) => {
            this.chapterDefinitions[data.detail.index].title = data.detail.title;
            this.chapterDefinitions[data.detail.index].pageStart = data.detail.pageStart || 0;
            this.chapterDefinitions[data.detail.index].pageEnd = data.detail.pageEnd || null;            
        });

        this.pasteListner = (event) => {
            event.preventDefault();          
           
            const clipboardTxt: string = event.clipboardData.getData('text');

            this.isCSV(clipboardTxt).then( async (isCsv: boolean) => {
                if(!isCsv){
                    this.notifyService.notify('No CSV detected in clipboard.', 'warning');
                    return;
                }

                const extracted = await this.getCSV(clipboardTxt);                
                
                this.$emit(bookEvents.book_classifier.chapters_csv, {
                    extracted: extracted.data
                });
            });            
        };
        
        this.addEventListener('paste', this.pasteListner);

        this.on(bookEvents.book_classifier.chapters_csv, (data: CustomEvent<{ extracted: [string, string, string][] }>) => {

            this.chapterDefinitions = [];

            data.detail.extracted.forEach((chapterPaste: [string, string, string]) => {
                const [ chapterTitleExtracted, startPageString, endPageString ] = chapterPaste;

                const startPage = parseInt(startPageString);
                const endPage = parseInt(endPageString);

                let chapterTitle = chapterTitleExtracted;

                if(!isNaN(parseInt(chapterTitle))){
                    chapterTitle = `Chapter ${chapterTitle}`;
                }

                this.chapterDefinitions = [
                    ...this.chapterDefinitions, 
                    {
                        title: chapterTitle,
                        pageStart: startPage,
                        pageEnd: endPage
                    }                    
                ];
            });            


        });

        this.on<void>('trainer:reload', (event: CustomEvent<void>) => {
            this.theBookData = null;
            this.max_process_parts = null;
            this.chaptersLoaded = false;
            this.chapterDefinitions = [];
            this.pages = null;
            this.wsId = null;
            this.customPages = [];
            this.active_tab = 'book_upload';
        });        
    }

    disconnectedCallback(): void {
        this.removeEventListener('paste', this.pasteListner);
    }

    onProgress(progress: number): void {
        progress = Math.ceil(progress);

        if (progress <= 100) {
            this.progress = progress;            

            if(progress === 100){
                this.loading = true;
            }
            return;
        }
    }

    private async isCSV(pastedText: string): Promise<boolean>
    {
        try{
            await this.getCSV(pastedText);
            return true;
        }catch(e: Error | any){
            return false;
        }
    }

    private async getCSV(pastedText: string): Promise<{ data: [string, string, string][] }>
    {        
        return new Promise((resolve, reject) => {
            try {
                const records: any = Papa.parse(pastedText);                    
                resolve(records);
              } catch (err) {
                reject(err);
            }
        });
    }

    async preUpload(uploadingFile: File, context: any): Promise<any> {

        if (!this.chapterDefinitions.length && !this.autoDetect) {
            this.notifyService.alert('Error: No chapters defined.');
            return null;
        }       

        try {
            this.stopIt = false;
            this.loading = true;

            if(!this.autoDetect){
                this.indexSwitch = false;
            }

            const partResponse: any = await this.apiService.back.uploadFile('train:part:book', uploadingFile, (progress: number) => {
                context.uploadProgress = progress * 100;                
                this.onProgress(context.uploadProgress);
            }, {}, { pageIndex: this.indexSwitch ? [this.autoIndexFrom, this.autoIndexTo] : null, detect: this.autoDetect });            

            const retData = JSON.parse(partResponse.data);

            this.pdftmp = retData.data.pdfPath;
            this.pdfname = retData.data.pdfName;

            // retData.data.book.forEach((element: ISplitData) => {
            //     this.customPages = [...this.customPages, ...element.pages];
            // });

            return retData;
        } catch (e: Error | any){
            this.loading = false;
            this.notifyService.notify('PDF file upload error', 'error');
            throw e;
        }

        
    }

    handleSwitch() {
        this.autoDetect = !this.autoDetect;
    }

    onDetectSwitch = this.handleSwitch.bind(this);

    handleIndexSwitch() {
        this.indexSwitch = !this.indexSwitch;
    }

    onIndexSwitch = this.handleIndexSwitch.bind(this);

    handleIndexFromChange() {
        this.autoIndexFrom = parseInt((this.$('#get_autoindex_from') as HTMLInputElement).value);
        this.autoIndexTo = parseInt((this.$('#get_autoindex_from') as HTMLInputElement).value);
    }

    onIndexFromChange = this.handleIndexFromChange.bind(this);

    handleIndexToChange() {
        this.autoIndexTo = parseInt((this.$('#get_autoindex_to') as HTMLInputElement).value);
    }

    onIndexToChange = this.handleIndexToChange.bind(this);


    handleSendChapters() {
        this.theBookData = { output: this.chapterDefinitions, input: { pages: this.pages.map((item) => ({text: item})) } };
        this.active_tab = 'book_preview';
    }

    sendChapters = this.handleSendChapters.bind(this);

    onAddChapter() {
        const lastChapter = this.chapterDefinitions.length ? this.chapterDefinitions[this.chapterDefinitions.length - 1] : null;

        this.chapterDefinitions = [
            ...this.chapterDefinitions,
            {
                title: ('Chapter ' + (this.chapterDefinitions.length + 1)),
                pageStart: lastChapter ? (lastChapter.pageEnd + 1) : null,
                pageEnd: null,
            }
        ];
    }

    removeChapter(index: number) {
        this.chapterDefinitions.splice(index, 1);
    }

    async uploadCallback(response: { data: { book: ISplitData[], pdfPath: string }, custom_chapters?: IChapterOutput[] }): Promise<void> {
        const _self = this;        

        const responseData = response.data;

        this.pages = this.customPages ? this.customPages : [];

        this.max_process_parts = responseData.book.length;

        if(response.data.book.length === 1 && response.data.book[0].chapters && this.autoDetect){
            this.chapterDefinitions = response.data.book[0].chapters;
            this.chaptersLoaded = true;
            this.loading = false;
            return;
        }

        if (this.chapterDefinitions.length && !this.autoDetect) {
            this.theBookData = { output: this.chapterDefinitions, input: { pages: this.pages.map((item) => ({text: item})) } };
            this.active_tab = 'book_preview';
            this.loading = false;
            return;
        }

        this.loading = true;
        const processPartResponse: ITrainApiResponse = await this.apiService.back.get<ITrainApiResponse>('train:process:book');

        this.wsId = processPartResponse.data.wsid;        

        let processedParts = 0;                

        if(this.stopIt){
            
            this.loading = false;
            return;
        }      

        this.wsService.listenForMessage(async (params: { data: ITrainWsResponse }) => {   
            if(this.stopIt){
                return;
            }         

            _self.addToChapters(params.data);
            processedParts++;

            if (processedParts === responseData.book.length) {
                processedParts = 0;
                this.processed_parts = 0;
                this.loading = false;
                this.chaptersLoaded = true;
                
                this.loading = false;
                return;
            }

            this.wsService.sendMessage<ProcessPayload>('process_book', { part: responseData.book[processedParts], wsid: this.wsId, lastChapter: params.data.chapters.pop() });
        }, this.wsId);

        this.wsService.listenForError<Error>((data: {error: Error }) => {                    
            processedParts = 0;
            this.processed_parts = 0;
            this.loading = false;
            this.progress = 0;
            this.chaptersLoaded = false;       

            this.notifyService.notify('Book classify error event', 'error');
            this.notifyService.alert(`ERROR STACK: \n\n <pre> ${data.error.stack} </pre>`, 'error', null, {
                width: '60%'
            });
            this.stopIt = true;
            this.loading = false;

        }, 'error_' + this.wsId);

        this.wsService.sendMessage<ProcessPayload>('process_book', { part: responseData.book[processedParts], wsid: this.wsId });
    }

    public addToChapters(processPartResponse: ITrainWsResponse) {
        const chapters = processPartResponse.chapters;        

        this.processed_parts++;        

        this.chapterDefinitions = [...this.chapterDefinitions, ...chapters];
    }
}

BookClassifier.defineComponent();

export { BookClassifier };