import IHyperParameter from './_hyperparam';

interface IAI21HyperParams extends IHyperParameter {
        maxTokens: number;    
        topP: number; 
        stop_sequences: string[]
        countPenalty: { scale: number }, 
        presencePenalty: { 
            scale: number
        }, 
        frequencyPenalty: { scale: number }
}

export default IAI21HyperParams;

const _defaults: IAI21HyperParams = {
    maxTokens: 200, 
    temperature: 0, 
    topP: 250, 
    stop_sequences:[], 
    countPenalty: { scale: 0 }, 
    presencePenalty : { 
        scale:0
    }, 
    frequencyPenalty: { scale: 0 }
};

export {
    _defaults
};