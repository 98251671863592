import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.loaded, T.html`
  <section class="container__loader">
    <jnct-loading-spinner></jnct-loading-spinner>
  </section>
`)}

${T.when(x => x.loaded, T.html`
<section class="report report__container">
  <div class="list-link" href="#" title="Back to the list" @click="${x => x.backToList()}">
    <span class="material-symbols-outlined">arrow_back</span>
    <span>Back to list</span>
  </div>

  <div class="header-section">
    <div class="title-section">
      <span class="subtitle">Assessment Deep Dive</span>
      <h1 :innerHTML="${x => x.assessmentData.title}"></h1>
    </div>
    <div class="buttons">
      <button class="button__primary" @click="${x => x.navigateToGradingCenter()}">Go to Grading Center</button>
      <button class="button__secondary" @click="${x => x.navigateToLiveProgress()}">Live Progress</button>
    </div>
  </div>

  <div class="report__main">
    <report-row
      class="width-100"
      :data_list="${x => x.data_list}">
    </report-row>
  </div>

  <report-chart-row
    class="width-100"
    :chart="${x => x.grade_chart}"
    :data_list="${x => x.grade_data}">
  </report-chart-row>

  <student-submissions 
    class="width-100" 
    :assessmentTitle="${x => x.assessmentData.title}"
    :submissionsData="${x => x.assessmentData.student_submissions}" 
    :id="${x => x.assessmentData.id}"
    :activityId="${x => x.assessmentData.activity_id}"></student-submissions>
  
  <performance-by-item 
    class="width-100"
    :activity="${x => x.assessmentData}"
    :course="${x => x.course}"></performance-by-item>

  ${T.when(x => x.isStudyCenterAvailable, T.html`
    <performance-by-learning-objective class="width-100" :learningObjectiveData="${x => x.learningObjectiveData}"></performance-by-learning-objective>
  `)}
</section>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.container__loader {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.report__container {
  max-width: 1115px;
  margin: 0px auto;
  padding: 14px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.report__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.report .header-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.report .header-section .title-section {
  display: flex;
  flex-direction: column;
}
.report .header-section .title-section .subtitle {
  font-size: 16px;
  font-weight: 300;
}
.report .header-section .title-section h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.report .header-section .buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
.report .list-link {
  display: flex;
  gap: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--neutral-90);
  transition: all 0.2s ease-in-out;
}
.report .list-link:hover {
  cursor: pointer;
  text-decoration: none;
  color: #4b4e54;
}
.report .width-100 {
  width: 100%;
}
.report .pie__chart {
  width: 365px;
}
.report .no__data {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-40);
  text-align: center;
  font-family: inherit;
  font-size: 1.42857rem;
  font-weight: 500;
  line-height: 150%;
}
.report .box {
  display: flex;
  padding: 24px 16px;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
}
.report .box__chart {
  display: flex;
  gap: 68px;
  flex-direction: column;
}
.report .box__chart--title {
  padding: 16px 0px 0px 32px;
  color: var(--gray-1);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}
.report .box__container {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
}
.report .box__container .button__primary {
  margin-left: auto;
}
.report .panel__list {
  margin: 0px;
  padding: 0px;
  max-height: 337px;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  list-style: none;
}
.report .panel__item {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 8px 16px;
  color: var(--link);
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
}
.report .panel__item:nth-child(4n+3), .report .panel__item:nth-child(4n+4) {
  background-color: var(--neutral-8);
}

report-row::part(list) .list__box {
  width: auto;
  min-width: 78px;
  padding: 19px;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, RWSInject } from "@rws-framework/client";
import { Course } from "../../../types/course";
import { Chart } from "../../../types/chart";
import AssessmentsService, { AssessmentsServiceInstance } from "../../../services/AssessmentsService";
import { StudyCenterReport } from "src/types/assessments";

type DataType = {
  main: string,
  text: string,
}

@RWSView('assessment-deep-dive-details', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class AssessmentDeepDiveDetails extends RWSViewComponent  {
  @observable loaded: boolean = false;
  @observable course: Course;
  @observable grade_chart: Chart;
  @observable data_list: any[];
  @observable grade_data: DataType[];
  @observable assessmentData: any;
  @observable learningObjectiveData: StudyCenterReport;
  @observable isStudyCenterAvailable: boolean = false;

  getCourse() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getCourse', (data: Course) => {
        resolve(data)
      });
    })
  };

  constructor(@RWSInject(AssessmentsService) private assessmentService: AssessmentsServiceInstance){
    super();
    this.navigateToLiveProgress = this.navigateToLiveProgress.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    const currentLocation = location.hash.split('/')
    const activityId = currentLocation[currentLocation.length - 1];
    setTimeout(async () => {
      this.assessmentData = await this.assessmentService.getDeepDiveDetailView(activityId);
      this.course = await this.getCourse() as Course;
      this.learningObjectiveData = await this.assessmentService.getPerformanceByLearningObjective(this.course._id);
      this.isStudyCenterAvailable = await this.assessmentService.isStudyCenterAvailable();

      this.data_list = [
        {
          box: this.assessmentData.avg_score_in_percentage + '%',
          title: 'Average Grade',
        },
        {
          box: this.assessmentData.points_available,
          title: 'Points Available',
        },
        {
          box: this.assessmentData.due_date 
          ? (new Date(this.assessmentData.due_date).getFullYear() <= 1970 || new Date(this.assessmentData.due_date).getFullYear() >= 2198 
              ? 'n/a' 
              : new Date(this.assessmentData.due_date)
                  .toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
                  .replace(', ', '<br/>')
                  .replace(/(\d{1,2}:\d{2}\s[AP]M)$/, "<span class='time-text'>$1</span>")
            ) 
          : 'n/a',
          title: 'Due Date',
        },
        {
          box: this.assessmentData.status,
          title: 'Status',
        },
      ];
      this.grade_data =  [
        {
          main: this.assessmentData.avg_score_in_percentage + '%',
          text: 'Average Grade',
        },
        {
          main: this.assessmentData.median_score_in_percentage + '%',
          text: 'Median Grade',
        }
      ];
      
      const grade_range = ['0-59', '60-64', '65-69', '70-74', '75-79', '80-84', '85-89', '90-94', '95-100'];
      this.grade_chart = {
        icon: 'insert_chart',
        title: 'Grade Distribution',
        type: 'column',
        x_data: grade_range,
        y_title: 'Students',
        color: {hover: '#119CBB', select: '#119CBB'},
        series: [
          {
            name: 'Grade in %',
            color: '#0D758C',
            data: Object.values(this.assessmentData.grade_distribution)
          }
        ]
      };
      this.loaded = true;
    }, 30)
  };

  backToList() {
    this.$emit('jnct:navigation:gotoassessmentdeepdive');
  }

  navigateToGradingCenter() {
    this.$emit('jnct:navigation:gotogradingcenter');
  }

  navigateToLiveProgress() {
    window.location.href = `${window.location.href}/live-progress`;
  }
}

AssessmentDeepDiveDetails.defineComponent();

export { AssessmentDeepDiveDetails };
