import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section>
      ${T.when(x => x.isDone, T.html`<lti-sync-confirm 
            icon="check_circle" 
            iconColor="#34802E"
            title="LTI Setup Successful" 
            abortText="Back to Dashboard" 
            proceedText="Go to LTI Sync Manager" 
            singleButton="true"
            @change="${ (x, c) => x.onPostSyncConfirm(c.event) }">
            <p>
            ${T.when(x => ["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.type), T.html`
            Finished syncing the chosen selection
            `)}
            ${T.when(x => !["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.type), T.html`
            Finished syncing ${ x => x.completedSyncs + x.gradeCompletedSyncs} out of ${x => x.syncsCount + x.gradeSyncsCount}
            `)}

            ${T.when(x => x.errors.length, T.html`<ul 
                  style="color: red; text-align: left;" 
                  :innerHTML="${x => x.errors}">
            </ul>`)}

            </p>
      </lti-sync-confirm>`)}
      
      ${T.when(x => !x.isDone && !x.isLoading, T.html`<lti-sync-confirm
            @change="${ (x, c) => x.onFinalConfirm(c.event) }">
            <slot></slot>
      </lti-sync-confirm>`)}

      ${T.when(x => x.isLoading, T.html`<div class="sync-processing">
            <jnct-loading-spinner></jnct-loading-spinner>

            <p>
                  <strong>Sync in progress, do not close this window</strong>
            </p>

            ${T.when(x => ["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.type), T.html`
                  Creating assignments...Please wait...
            `)}
            ${T.when(x => !["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.type), T.html`
                  Creating assignments... (${ x => x.completedSyncs } / ${x => x.syncsCount})
                  ${T.when(x => x.gradeSyncsCount, T.html`<br>
                  Syncing scores... (${ x => x.gradeCompletedSyncs } / ${x => x.gradeSyncsCount})
                  `)}
            `)}

      </div>`)}
</section>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

section {
  padding-top: 60px;
}
section .sync-processing {
  text-align: center;
}`;

const shadowOptions = {"mode":"open"};

import { RWSViewComponent, RWSView, attr, RWSInject } from "@rws-framework/client";
import { observable } from "@microsoft/fast-element"; 
import { LTIConfigType } from "../../../../types/lti/config";

import { IStudent } from "../../../../types/students"; 
import LTISyncService, { LTISyncServiceInstance } from "../../../../services/LTISyncService";
import { LTISyncEventData } from "../../../../types/lti/sync";
import { LTISyncConfirmValue } from "../ltiSyncConfirm/component";
import { AssignmentsType } from "../ltiSyncAssignments/component";


@RWSView('lti-sync-process', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class LtiSyncProcess extends RWSViewComponent  {      
  @observable selectedAssignments: AssignmentsType[] = [];
  @observable selectedStudents: IStudent[] = [];
  @observable course_config: LTIConfigType;

  @attr courseId: string = '';
  @attr apiPrefix: string;
  @attr page: LTISyncEventData['sync_page'] | null = 'manager';
  @attr type: LTISyncEventData['sync_type'] | null = null;

  @observable completedSyncAssignments: any = [];
  @observable completedSyncGrades: any = [];
  @observable completedSyncSelections: any = [];

  @observable completedSyncs: number = 0;
  @observable gradeCompletedSyncs: number = 0;
  @observable syncsCount: number = 0;
  @observable gradeSyncsCount: number = 0;
  @observable isDone: boolean = false;
  @observable isLoading: boolean = false;
  @observable errors: string = '';

  @observable grades: any = [];

  private completedSyncListener: any;
  private completedCountListener: any;
  private maxCountListener: any;

  constructor(@RWSInject(LTISyncService) private ltiSyncService: LTISyncServiceInstance) {
    super()
  }  

  connectedCallback(): void {
    super.connectedCallback()
  }

  cancel() {
    this.$emit('jnct:ltisyncmanager:proceed-cancel', true)
  }

  async proceed() {
    const _self = this

    try {
      _self.completedSyncListener = document.addEventListener('complete-sync-progress', function (e: any) {

        console.log("STATUS UPDATE", e.detail)

        if (e.detail.data.ltiCallbackEventType.data.error) {
          _self.errors += "<li>" + e.detail.data.ltiCallbackEventType.data.error + "</li>"
        }

        switch(e.detail.type) {
          case 'assignments':
            _self.completedSyncAssignments = [...(_self.completedSyncAssignments), e.detail.data]
            _self.completedSyncs++
            break;
          case 'grades':
            _self.completedSyncGrades = [...(_self.completedSyncGrades), e.detail.data]
            _self.gradeCompletedSyncs++
            break;
          case 'selections': 
            _self.completedSyncSelections = [...(_self.completedSyncSelections), e.detail.data]
           break;   
        }
      })

      _self.completedCountListener = document.addEventListener('update-sync-progress:completed', function(e) {
        // Only called once when we've actually completed the process
        if (_self.completedSyncs + _self.gradeCompletedSyncs >= _self.syncsCount + _self.gradeSyncsCount || e.detail == "skip_check") {
          _self.isLoading = false
          _self.isDone = true
          _self.$emit('jnct:ltisyncmanager:sync-done')
        }
      });

      this.isLoading = true

      _self.syncsCount = this.selectedAssignments.length
      _self.gradeSyncsCount = _self.selectedStudents.reduce((a, b) => a + b.counter, 0)

      _self.$emit('jnct:ltisyncmanager:gosync', {
        sync_page: this.page,
        sync_type: this.type,
        config: (this.course_config as any).error ? null : this.course_config[0],
        students: this.selectedStudents,
        assignments: this.selectedAssignments,
        courseId: this.courseId
      })

    } catch(e: Error | any) {
      console.error('jnct:ltisyncmanager:proceed-error', e)
      this.$emit('jnct:ltisyncmanager:proceed-error', e)
    }
  }

  disconnectedCallback(): void {
      document.removeEventListener('complete-sync-progress', this.completedSyncListener);
      document.removeEventListener('update-sync-progress:completed', this.completedCountListener);
  }

  onFinalConfirm(event: CustomEvent<LTISyncConfirmValue>) {
    if (event.detail === 'proceed') {      
      this.proceed()
      return
    }

    this.cancel()
  }

  onPostSyncConfirm(event: CustomEvent<LTISyncConfirmValue>) {
    this.$emit('jnct:ltisyncmanager:reloadLTI', false)
  }

  /**
   * Will revise some of those - may be obsolete
   */
  selectedAssignmentsChanged(oldValue?: AssignmentsType[], newValue?: AssignmentsType[]) {
    if (newValue) {
      this.selectedAssignments = newValue;      
    }
  }

  selectedStudentsChanged(oldValue?: IStudent[], newValue?: IStudent[]) {
    if (newValue) {
      this.selectedStudents = newValue     
    }
  };

  completedSyncAssignmentsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncAssignments = newValue
      //console.log('Completed assignments in RWS:', newValue)
    }
  }

  completedSyncGradesChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncGrades = newValue;
      //console.log('Completed grades in RWS:', newValue)
    }
  }

  completedSyncSelectionsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncSelections = newValue
      //console.log('Completed selections in RWS:', newValue)
    }
  }

  course_configChanged(oldValue: LTIConfigType, newValue: LTIConfigType) {
    if (newValue) {
      this.course_config = newValue 
    }
  }
}

LtiSyncProcess.defineComponent()

export { LtiSyncProcess }
