import { IBoxData } from '../../types/box-data';
import { BoundaryModType, DragOptions } from '../ResizeService';
import { makeBoundary, isOutOfBoundary, bump } from './_boundary';
import { ResizeServiceInstance } from '../ResizeService'
import { getBoxData } from './_box';

export function place(targetBox: IBoxData, boundaryBox: IBoxData): IBoxData {
    let x: number = targetBox.left;
    let y: number = targetBox.top;

    x = boundaryBox.w - targetBox.w - 50;
    y = boundaryBox.h - targetBox.h - 50;

    return {
        ...targetBox,
        left: x,
        top: y
    }
}

export function correctWindow(target: HTMLElement, boundaryData: HTMLElement | IBoxData, boundaryMod?: BoundaryModType, scrollTop: number = 0) {
    const boundary: IBoxData | null = makeBoundary(boundaryData, boundaryMod);

    if (!boundary) {
        return;
    }

    const isOutOfBounds: boolean = isOutOfBoundary(target, boundary);

    if (isOutOfBounds) {
        const newPlace: IBoxData = place(getBoxData(target), boundary);
        target.style.top = scrollTop + newPlace.top + 'px';
        target.style.left = newPlace.left + 'px';
    }
}

export function getRelative(currentPos: { x: number, y: number }, pos: IBoxData & { edge?: string }, targetBox: IBoxData): { x: number, y: number } {
    const result = {
        x: (pos.left - targetBox.left),
        y: (pos.top - targetBox.top)
    };

    if(pos.edge){
        switch (pos.edge) {
            case 'left': result.x += currentPos.x; result.y = currentPos.y; break;
            case 'top': ; result.y += currentPos.y; result.x = currentPos.x; break;
            case 'bottom': ; result.y += currentPos.y; result.x = currentPos.x; break;
            case 'right': ; result.x += currentPos.x; result.y = currentPos.y; break;
            default: break;
        }
    }

    return result;
}

export function onDrag(this: ResizeServiceInstance, event: any, component: any, options: DragOptions) {
    const target: HTMLElement = event.target;
    let x: number = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    let y: number = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    const boundary: IBoxData = makeBoundary(options?.boundary, options?.boundaryMod);


    removeTargetAttr(target)


    if (!boundary) {
        return;
    }

    const isOutOfBounds: boolean = isOutOfBoundary(target, boundary);

    saveTargetAttr(target, x, y)

    if (isOutOfBounds) {
        // event.interaction.stop();
        const targetBox = getBoxData(target);
        const bumpedBox: IBoxData & { edge: string } = bump(target, targetBox, boundary);

        const relPos = getRelative({ x, y }, bumpedBox, targetBox);


        x = relPos.x;
        y = relPos.y;

        
        moveTarget(target, x, y)

        return;
    }

    moveTarget(target, x, y)
}

export function moveTarget(target: HTMLElement, x: number, y: number)
{
    target.style.transform = `translate(${x}px, ${y}px)`;

    saveTargetAttr(target, x, y);
}

export function saveTargetAttr(target: HTMLElement, x: number, y: number)
{
    target.setAttribute('data-x', x.toString());
    target.setAttribute('data-y', y.toString());
}

export function removeTargetAttr(target: HTMLElement)
{
    target.removeAttribute('data-x');
    target.removeAttribute('data-y');
}