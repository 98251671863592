import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<footer>    
    <div id="message_create_holder">
        <textarea ?disabled="${x => x.isTyping()}" @input="${x => x.adjustHeight()}" id="send_chat_message" placeholder="${ x => x.placeholder}"></textarea>
        ${T.when(x => x.sendButtonEnabled, T.html`<button ?disabled="${x => x.isTyping()}"  class="send_button" @click="${(x, c) => x.msgSend(c.event)}" >                
            <jnct-icon size="24px">send</jnct-icon>
        </div>`)}
    </div>
    ${T.when(x => x.dev === 'true', T.html`<fast-switch id="intruder-detect-switch" @change="${x => x.onDetectSwitch()}" :checked="${x => x.intruderEnabled}">
        <strong>Enable intruder prompt</strong>
        <span slot="checked-message">Enabled</span>
        <span slot="unchecked-message">Disabled</span>
    </fast-switch>`)}
    ${T.when(x => x.intruderEnabled, T.html`<div class="intruder-prompt"><fast-divider></fast-divider><textarea disabled="${x => x.typing}" id="send_chat_intruder" placeholder="Type your intruder prompt"></textarea></div>`)}    
    <section class="osha">
        <span class="osha-txt">${x => x.safetyInfo}</span>        
        <span class="osha-txt">Read our 
            ${T.when(x => x.profile?.ppLink, T.html`<a target="_blank" href="${x => x.profile.ppLink}">Privacy Policy</a>`)}             
            ${T.when(x => x.profile?.tosLink, T.html` and <a target="_blank" href="${x => x.profile.tosLink}">Terms of Service</a></span>`)}
    </section>
</footer>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: block;
}

a {
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
}

:host {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

footer {
  padding: 20px 30px 10px 20px;
  background: var(--jtrainer-footer-bg-color, rgb(204, 204, 204));
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
footer #intruder-detect-switch {
  color: #000 !important;
}
footer #intruder-detect-switch strong, footer #intruder-detect-switch span {
  color: #000 !important;
}
footer #message_create_holder {
  position: relative;
}
footer #message_create_holder textarea {
  resize: none;
  display: block;
  width: 100%;
  min-height: 48px;
  max-height: 68px;
  height: auto;
  border-radius: 10px;
  padding: 4px 45px 4px 8px;
  font-size: 13px;
  margin-bottom: 13px;
  background: var(--neutral-20);
  color: var(--jchat-textarea-txt-color, #000);
  border-width: 0;
}
footer #message_create_holder textarea::placeholder {
  color: var(--neutral-50);
}
footer #message_create_holder textarea[disabled] {
  background: #666;
  cursor: not-allowed;
}
footer #message_create_holder button.send_button {
  border: 0;
  outline: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  width: 40px;
  height: calc(100% - 8px);
  border-radius: 5px;
  color: var(--neutral-80);
  margin-left: auto;
  padding: 10px;
  margin-top: 15px;
  justify-content: center;
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: var(--secondary_color, var(--primary_color, --neutral-70));
  margin-top: 4px;
  cursor: pointer;
}
footer #message_create_holder button.send_button svg {
  max-width: 100%;
  max-height: 100%;
}
footer #message_create_holder button.send_button svg path {
  fill: #FFF !important;
}
footer #message_create_holder button.send_button[disabled] {
  background: #999;
  cursor: not-allowed;
}
footer img {
  height: 30px;
  cursor: pointer;
}
footer a {
  text-decoration: none;
  font-weight: normal;
  color: #0F75B8;
  vertical-align: top;
  display: inline-block;
}
footer .controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
}
footer section.osha {
  font-size: 10px;
  margin-top: 10px;
  color: var(--neutral-50);
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, attr, RWSView, NotifyService, DOMService, sanitizedAttr, externalObservable} from '@rws-framework/client';
import { observable } from '@microsoft/fast-element';

import { IMessage } from '../chat-message/component';
import WebChatEvents from '../../events'; 
import { BedrockBaseModel, IChatProfile } from '@backend-types';
import theStrings from '../../../../strings';
import { IBoxHolder, IBoxData } from '../../../../types/box-data';

@RWSView('chat-convo-footer', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class WebChatConvoFooter extends RWSViewComponent  implements IBoxHolder {

  @observable chosenModel: BedrockBaseModel = null;
  @observable intruderEnabled: boolean = false;  
  @observable sendButtonEnabled: boolean = true;  
  @observable boxData: IBoxData;
  @observable profile: IChatProfile = null;
  @observable typing: boolean = false;

  @attr placeholder: string = '';
  @attr safetyInfo = theStrings.osha.safety_info;
  @attr dev = 'false';
  @sanitizedAttr({ fullEncode: true }) textAreaValue: string;

  connectedCallback() {
      super.connectedCallback(); 
      const textarea = this.$<HTMLTextAreaElement>('textarea') as HTMLTextAreaElement;    

      if(textarea){
        textarea.onkeydown = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault(); 

                if(this.isTyping()){
                    return;
                }

                this.msgSend(event);
            }
        };
      }
      
      this.$emit('chat-footer-ready', this.getShadowRoot().host);
  }

  isTyping(): boolean
  {
    return this.typing;
  }

  onDetectSwitch(){
      this.intruderEnabled =!this.intruderEnabled;    
  }

  adjustHeight() {
    const textarea = this.$('textarea') as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }


  msgSend(event: Event)
  {     
      const textareaMsg = this.$<HTMLTextAreaElement>('textarea#send_chat_message') as HTMLTextAreaElement;
      const textareaIntruder = this.$<HTMLTextAreaElement>('textarea#send_chat_intruder') as HTMLTextAreaElement;

      let intruderPrompt = null;

      if(textareaIntruder){      
          intruderPrompt = textareaIntruder.value;
      }

      this.textAreaValue = textareaMsg.value;

      if(!this.textAreaValue || this.textAreaValue === ''){
          this.notifyService.alert('You need to write a message before sending prompt.');
          return;
      }

      const message: IMessage = {
          userMessage: true,
          author: 'User',
          content: this.textAreaValue,
          created_at: new Date(),
          intruder_prompt: intruderPrompt === '' ? null : intruderPrompt
      };    

      this.$emit(WebChatEvents.message.send, {
          item: message
      });

      this.$emit('chat_typing', true);

      textareaMsg.value = '';

      if(textareaIntruder){
          textareaIntruder.value = '';
      }
  }
}

WebChatConvoFooter.defineComponent();

export { WebChatConvoFooter };