import IHyperParameter from './_hyperparam';

interface ICohereTextHyperParams extends IHyperParameter {
    prompt?: string, 
    max_tokens?: number, 
    stop_sequences?: any[], 
    return_likelihood?: string 
}

interface ICohereDocHyperParams extends IHyperParameter {
    texts?: string[], 
    input_type?: string, 
}

const _defaults: { text: ICohereTextHyperParams, doc: ICohereDocHyperParams } = {  
    text: {        
        temperature: 1,
        max_tokens: 100,
        stop_sequences: [],
        return_likelihood: 'GENERATION',
    },
    doc: {
        temperature: 1,
        input_type: 'search_document'
    }
};

export { ICohereTextHyperParams, ICohereDocHyperParams, _defaults };