
import { GradingCenter } from '../../components/gradingCenter/component';
import { GradingCenterModal } from '../../components/gradingCenter/gradingcentermodal/component';
import { GradeByQuestion } from '../../components/gradingCenter/gradeByQuestion/component';
import { GradeByStudent } from '../../components/gradingCenter/gradeByStudent/component';
import { GradingBoard } from '../../components/gradingCenter/gradingBoard/component';
import { GradingInput } from '../../components/gradingCenter/gradingInput/component';
import { GradingHeader } from '../../components/gradingCenter/gradingHeader/component';
import { GradingCenterFilterModal } from '../../components/gradingCenter/gradingCenterFilterModal/component';
import { GradingCenterLearnosityFeedbackModal } from '../../components/gradingCenter/learnosityFeedbackModal/component';
import { LearnosityItem } from '../../components/gradingCenter/learnosity/component';
import { GradebookInstructor } from '../../components/gradebookInstructor/component';
import { GradebookDropdown } from '../../components/gradebookInstructor/gradebookDropdown/component'
import { AdjustGradeModal } from '../../components/gradebookInstructor/adjustModal/component';
import { OverrideAssessmentModal } from '../../components/gradebookInstructor/overrideModal/component';
import { GradebookCustomColumn } from '../../components/gradebookInstructor/customColumn/component';
import { GradebookStudent } from '../../components/gradebookStudent/component';

export default (parted: boolean = false) => {
    if (!parted) {
        GradingCenter;
        GradingCenterModal;
        GradeByQuestion;
        GradeByStudent;
        GradingBoard;
        GradingInput;
        GradingHeader;
        GradingCenterFilterModal;
        GradingCenterLearnosityFeedbackModal;      
        LearnosityItem; 
        
        GradebookInstructor;
        GradebookDropdown;
        AdjustGradeModal;
        OverrideAssessmentModal;
        GradebookCustomColumn;
        GradebookStudent;

        

    }
}
