import {RWSService} from '@rws-framework/client';

class LearnosityService extends RWSService {

  constructor() {
    super()
  }

  async getLearnosity(assessment: any, selectedStudent: any, course: any, returnScores=false) {
    const request: object = {
      activity_id: [assessment.activity_id],
      user_id: [selectedStudent._id],
      include: {"sessions.session_metadata": ["course_id"]}, // very important for filtering below
      session_id: [],
      include_response_scores: true,
      status: ["Completed", "Incomplete"]
    };

    const signature = await this.getSignature(request);

    //getting scores from Learnosity API
    const scores = await this.getScores(signature);

    //getting override from Learnosity
    const override = await this.getOverride(assessment.id, selectedStudent._id, course._id);

    if (override) {
      const filteredScores = scores.data.filter((session: any) => {
        const course_id = course.section_type == 'section' ? course.parent : course._id;
        return session.session_metadata && session.session_metadata.course_id == course_id;
      });
      const data = this.getInfo(filteredScores, override, assessment, selectedStudent, course, returnScores);
      return data;
    }
  };

  async getSignature(request: object) {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');

    try {
      const response = await fetch(APIprefix + "assessment/signature/data", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  async getScores(signature: object) {
    function getFormData(object: any) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    };

    //@ts-ignore
    const APIversion = window.edrnaConfig.learnosity.version;

    //we need to change data into Form Data format for Learnosity
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');
    const formdata = getFormData(signature);
    try {
      const response = await fetch('https://data.learnosity.com/' + APIversion + '/sessions/responses', {
        method: "POST",
        body: formdata,
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  async getOverride(assessmentId: any, selectedStudent: any, courseId: any) {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');

    try {
      const response = await fetch(APIprefix + "assessment_override/" + courseId + '/' + assessmentId + "/" + selectedStudent, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  getInfo(scores: any, overrides: any, assessment: any, selectedStudent: any, course: any, returnScores: boolean) {
   
    overrides['link'] = assessment.link;
    overrides["scores"] = scores;

    Object.keys(overrides).forEach(function(option) {
      if ((option == 'due_date' || option === 'close_date' || option === 'open_date') && overrides[option]) {
        overrides[option] = new Date(overrides[option]);
        if (overrides[option].getFullYear() < 1971 || overrides[option].getFullYear() > 2198) {
          delete overrides[option]
        }
      } else {
        overrides[option] = overrides[option];
      }
    });
    if (overrides["time_limit"] == false) {
      overrides["time_limit"] = undefined;
    };
    overrides.title = assessment.title;

    //AUTODEDUCT injected here as well since scores are direct from learnosity
    scores.forEach((session: any) => this._autoDeduct(session, overrides));
    if (overrides.policy) {
      overrides.policy.humanly_per = overrides.policy.per == 3600? "hour" : overrides.policy.per == 86400? "day" : "week";
      overrides.config = {policy: overrides.policy};
    };

    let session_id = '';
    //console.log(assessment)
    if (assessment?.session_id) {
      session_id = assessment.session_id[0];
    } else {
      //console.log(scores)
      const maxScore = Math.max(...scores.map((a) => a.score));
      session_id = scores.find((a) => a.score === maxScore)?.session_id;
    };
    overrides["studentReportURL"] = session_id;

    const data = overrides;
    let message;
    data.scores = data.scores?.filter((el: { session_id?: any }) => el.session_id);
    data.scores?.forEach((score: any) => {
      score.isProfessor = selectedStudent.name? true : false;
      if (session_id === score.session_id) {
        message = {session_id: session_id, session: score, link: data.link, user_id: score.user_id, title: data.title};
      };
      score.manually_accepted = overrides.manually_accepted;
      score.manually_rejected = overrides.manually_rejected;
      score.scoring_needed = score.responses.some((response: any) => {
      // TODO-AUTOMARKABLE
      const correctType = ['video', 'longtext', 'longtextV2', 'audio', 'plaintext', 'fileupload'].includes(response.question_type);
      return response.dt_score_updated < score.dt_completed &&
            response.max_score && correctType;
      });

      const due_date = (data.config && data.config.due_date) ||data.due_date || "";
      if (score.dt_completed && due_date && score.dt_completed > due_date.toISOString()) {
        score.overdue = true;
      };
    })

    if (data.time_limit) {
      data.time_limit = Math.round(data.time_limit / 60);
    };

    if (returnScores) {
      return {data: JSON.stringify(data), sessions: JSON.stringify(message), course: JSON.stringify(course), scores: JSON.stringify(data.scores)};
    }
    return {data: JSON.stringify(data), sessions: JSON.stringify(message), course: JSON.stringify(course)};
  };

  _autoDeduct(score: any, config: any) {
    if (score.policy_applied || !config.policy || !config.policy?.penalty) {
      return; // since we update this is place, we better only apply the policy once
    };

    //@ts-ignore
    const delta = new Date(score.dt_completed) - new Date(config.due_date || "2199-12-31");
    if (delta < 0) {
      return;
    };
    // there is a policy and score is overdue
    score.score = score.score * (100 - Math.min(config.policy.penalty * Math.ceil(delta/1000/config.policy.per), config.policy.max))/100;
    score.policy_applied = true;
  }
}

export default LearnosityService.getSingleton();

export {LearnosityService as LearnosityServiceInstance}
