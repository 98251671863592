import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="book_chapter_def_element" id="def_index_${x => x.index}">
    <fluent-text-field class="item-title" :value="${ x => x.title }" placeholder="Title" @change="${x => x.changeHandler()}"></fluent-text-field>
    <fluent-number-field
        min="1" 
        class="item-start-page" 
        tabindex="2" 
        :value="${ x => x.startPage === 0 ? '0' : x.startPage }" 
        placeholder="Start page"    
        @change="${x => x.changeHandler()}"        
    ></fluent-number-field>
    <fluent-number-field
        min="1" 
        class="item-end-page" 
        tabindex="2" 
        :value="${ x => x.endPage }" 
        placeholder="End page" 
        @change="${x => x.changeHandler()}"        
     ></fluent-number-field>
    <fluent-button @click="${ x => x.deleteMethod(x.index) }" appearance="accent" class="remove-chapter" @change="${x => x.changeHandler()}">Remove</fluent-button>
</div>`;

import './styles/layout.scss';
const styles = T.css`fluent-button {
  transform: translateY(-15px);
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent } from '@rws-framework/client';

import { observable, attr } from '@microsoft/fast-element';
import bookEvents from '../../events';

@RWSView('book-chapter-def', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class BookChapterDef extends RWSViewComponent  {
    @observable title: string;
    @observable startPage: string;
    @observable endPage: string;

    @attr index: number;
    @observable deleteMethod: (index: number) => void;

    changeHandler = this.onChange.bind(this);

    connectedCallback(): void {
        super.connectedCallback();
        
    }

    

    onChange() {
        const title: string = this.getInput('.item-title');
        const startPage: number = parseInt(this.getInput('.item-start-page'));
        const endPage: number = parseInt(this.getInput('.item-end-page'));

        this.$emit(bookEvents.book_classifier.chapter_def_change, {
            index: this.index,
            title: title,
            pageStart: startPage || 0,
            pageEnd: endPage || null
        });
    }    

    

    private getInput(fieldClass: string): string {
        return this.getEl(fieldClass).value;
    }

    private getEl(fieldClass: string): HTMLInputElement {
        return (this.$('#def_index_' + this.index + ' ' + fieldClass) as HTMLInputElement)
    }
}

BookChapterDef.defineComponent();

export { BookChapterDef };