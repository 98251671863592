import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section class="box">
  <div class="box__header">
    <div class="section-title header__title">
      <span class="material-symbols-outlined">assignment</span> 
      <span>Student Submissions</span>
    </div>
    <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
  </div>
  <span>${x => x.assessments.length} ${x => x.assessments.length === 1 ? 'Submission' : 'Submissions'}</span>
  ${T.when(x => x.assessments.length > 0, T.html`
    <jnct-table
      class="width-100"
      :data="${x => x.assessments}"
      :columnsConfig="${x => x.columns}"
      columnsLayout="25% 10% 20% 25% 200px">
    </jnct-table>
  `)}
</section>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.box {
  display: flex;
  overflow: hidden;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 2px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.box__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.box .header__title {
  display: flex;
  gap: 12px;
  flex: 1 0 0;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}
.box .header__data {
  display: flex;
  gap: 48px;
}
.box .header__data .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--neutral-70);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.box .header__data .item__bold {
  color: var(--neutral-90);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}

.width-100 {
  width: 100%;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, RWSInject, externalObservable } from "@rws-framework/client";
import { ColumnConfig } from "../../../../types/table";
import LearnosityService, { LearnosityServiceInstance } from "../../../../services/LearnosityService";
import EventsService, { EventsServiceInstance } from "../../../../services/EventsService";
import { Course } from "../../../../types/course";
import { StudentSubmissionsDetailsModal } from "./detailsModal/component";

@RWSView('student-submissions', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class StudentSubmissions extends RWSViewComponent  {
  @observable assessmentTitle: string;
  @observable submissionsData: any[] = [];
  @observable assessments: any[] = [];
  @observable currentAssessments: any[] = [];
  @observable course: Course;
  @externalObservable activityId: string;
  @externalObservable id: string;
  @observable isLearnosityModalOpen: boolean = false;
  @observable learnosityModalConfig: any = {
    switch: () => {
      this.isLearnosityModalOpen = !this.isLearnosityModalOpen;
    }
  };
  courseId = location.hash.split('/')[1];


  columns: ColumnConfig[] = [ 
    {
      name: "link_title",
      title: "Student",
      allowSorting: true,
    },
    { 
      name: "score",
      title: "Grade",
      allowSorting: true,
    },
    {
      name: "submitted_date",
      title: "Submitted",
      allowSorting: true,
      date: true,
      fulldate: true
    },
    {
      name: "time_spent",
      title: "Time Spent",
      allowSorting: true
    },
    {
      name: "goto",
      title: '',
      allowSorting: false,
      click: true
    },
  ];

  constructor(
    @RWSInject(LearnosityService) private learnosityService: LearnosityServiceInstance,
    @RWSInject(EventsService) private eventsService: EventsServiceInstance,
  ){
    super();
  }

  async connectedCallback() {
    super.connectedCallback();

    this.course = await this.eventsService.getCourse() as Course;
  }
  
  submissionsDataChanged(oldVal: any, newVal: any) {
    if (this.submissionsData.length > 0) {
      this.submissionsData.forEach((submission, index) => {
        this.assessments[index] = {
          "id": {
            "value": submission.id,
          },
          "session_id": {
            "value": submission.session_id
          },
          "link_title": {
            "value": submission.fullname,
            "link": '#/' + this.courseId + '/report_student_deepdive/' + submission.id
          },
          "score": {
            "value": submission.score_in_percentage ? parseFloat((submission.score_in_percentage).toFixed(1)) + '%' : 0 + '%'
          },
          "submitted_date": {
            "value": submission.submission_date ? new Date(submission.submission_date) : '-',
          },
          "time_spent": {
            "value": submission.time_spent_in_seconds ? new Date(submission.time_spent_in_seconds * 1000).toISOString().slice(11, 19) : '-'
          },
          "goto": {
            "value": 'View Details', 'onClick': this.openModal, 'onClickValue': submission,
          }
        }
      })

      this.currentAssessments = this.assessments;
    }
  }

  search = (value: any) => {
    this.assessments = this.currentAssessments.filter((obj) =>
      obj.link_title.value.toLowerCase().includes(value.toLowerCase())
    );
  }
  
  openModal = this.openModalHandler.bind(this)
  async openModalHandler(submission: any) {
    const modal = document.createElement('student-submissions-detail-modal') as StudentSubmissionsDetailsModal;
    modal.title = submission.onClickValue.fullname;
    modal.assessmentTitle = this.assessmentTitle;

    const assessment = {id: this.id, activity_id: this.activityId, session_id: [submission.onClickValue.session_id]};
    const student = {_id: submission.onClickValue.id};
    const course = this.course;
    const data = await this.learnosityService.getLearnosity(assessment, student, course);
    modal.data = data.data;
    modal.sessions = data.sessions;
    modal.course = course; 
    document.body.appendChild(modal);
  }
}

StudentSubmissions.defineComponent();

export { StudentSubmissions };
