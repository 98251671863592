export class GradebookIcons {
  static createIcon(text: string): HTMLElement {
    const span = document.createElement('span');
    span.classList.add("material-symbols-outlined");
    span.style.fontSize = "16px";
    span.innerHTML = text;
    let tooltip_text = '';
    switch(text) {
      case 'edit':
        span.style.color = "#FF9213";
        tooltip_text = 'Grading Required';
        break;
      case 'assignment_late':
        span.style.color = "#DE1135";
        tooltip_text = 'Late Submission available';
        break;
      case 'schedule':
        span.style.color = "#0F75B8";
        tooltip_text = 'Auto-deduction of Late Work applied';
        break;
      case 'edit_calendar':
        span.style.color = "#0F75B8";
        tooltip_text = 'Overrides Applied';
        break;
      case 'source_notes':
        span.style.color = "#0F75B8";
        tooltip_text = 'Project was Re-opened';
        break;
    };

    // Create the tooltip element
    const tooltip = document.createElement('fluent-tooltip');
    tooltip.setAttribute('position', 'right');
    tooltip.setAttribute('delay', '100');
    tooltip.innerHTML = tooltip_text;

    // Assign an ID to the span for anchoring the tooltip
    const uniqueId = `icon-${text}-${Math.random().toString(36).substr(2, 9)}`;
    span.id = uniqueId;
    tooltip.setAttribute('anchor', uniqueId);
    const container = document.createElement('span');
    container.appendChild(tooltip);
    container.appendChild(span)
    return container;
  }
};
