import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="chat-profile-form">
    <header>
        <h3>AI CHAT PROFILE</h3>
        <fluent-button @click="${(x,c) => x.toggle()}">Toggle</fluent-button>
        ${T.when(x => x.opened, T.html`<chat-profile-configloader :profile="${x => x.profile }" @change="${ (x, c) => x.loadConfig(c.event)}"/>`)}
    </header>
    ${T.when(x => x.loading, T.html`<div class="loader-wrap"><jnct-loading-spinner /></div>`)}

    ${T.when(x => x.opened, T.html`<div class="profile-data-list">                  
            <div class="profile-gr">                
                <fluent-card>
                    <div class="opts">
                        <fluent-radio-group @change="${ (x, c) => x.setType(c.event)}" value="${ x => x.type }"
                            orientation="horizontal">
                            <fluent-radio value="image">Image</fluent-radio>
                            <fluent-radio value="icon">Icon</fluent-radio>
                        </fluent-radio-group>
                    </div>
                    <form>
                        <div>
                            <label>Chat title</label>
                            <fluent-text-field id="titleSelect" value="${ x => x.profile?.title }"
                                appearance="outline"></fluent-text-field>
                        </div>
                        <div>
                            <label>Privacy Policy</label>
                            <fluent-text-field id="ppLink" value="${ x => x.profile?.ppLink }"
                                appearance="outline"></fluent-text-field>
                        </div>
                        <div>
                            <label>Terms of Service</label>
                            <fluent-text-field id="tosLink" value="${ x => x.profile?.tosLink }"
                                appearance="outline"></fluent-text-field>
                        </div>
                        <div>
                            ${T.when(x => x.type === 'icon', T.html`<fluent-text-field id="iconSelect"
                                value="${ x => x.profile?.icon || null }" placeholder="Icon"
                                appearance="outline"></fluent-text-field>`)}
                            ${T.when(x => x.type === 'image', T.html`<div class="image-holder">
                                <h3>Image:</h3>
                                ${T.when(x => x.profile?.image, T.html`<div class="img-prv">
                                    <img class="preview" src="${x => x.profile.image}" />
                                    <fluent-button class="clear" @click="${ x => x.clearVis('image')}"><jnct-icon
                                            size="18px">delete</jnct-icon></fluent-button>
                                </div>`)}
                                <rws-uploader :uploadProgress="${x => x.progress}"
                                    :uploadParams="${ x => x.uploadParams}" :onStart="${x => x.preUpload.bind(x)}"
                                    :onFinish="${x => x.uploadCallback.bind(x)}"></rws-uploader>
                            </div>`)}
                        </div>                        
                    </form>
                </fluent-card>

                <fluent-card>
                    <fluent-button class="save${ x => x.saving ? ' is-saved' : ''}"
                        @click="${ x => x.save()}">Save</fluent-button>
                </fluent-card>
            </div>            
        <div class="profile-gr">
            <fluent-card>
                <chat-profile-hyperparams @change="${ (x, c) => x.setHyperParams(c.event) }" :profile="${ x => x.profile }"></chat-profile-hyperparams>            
            </fluent-card>

            <fluent-card>
                <chat-profile-splitparams @change="${ (x, c) => x.setSplitParams(c.event) }" :profile="${ x => x.profile }"></chat-profile-splitparams>
            </fluent-card>
        </div>
        <div class="profile-gr">
            <fluent-card>
                <chat-profile-query-prompt @change="${ (x, c) => x.setPrompt(c.event) }" partName="intro" :value="${ x  => x.profile?.introPart }" :profile="${ x => x.profile }"></chat-profile-query-prompt>
            </fluent-card>

            <fluent-card>
                <chat-profile-query-prompt @change="${ (x, c) => x.setPrompt(c.event) }" partName="history" :value="${ x  => x.profile?.historyPart }" :profile="${ x => x.profile }"></chat-profile-query-prompt>
            </fluent-card>

            <fluent-card>
                <chat-profile-query-prompt @change="${ (x, c) => x.setPrompt(c.event) }" partName="basic" :value="${ x  => x.profile?.basicPart }" :profile="${ x => x.profile }"></chat-profile-query-prompt>
            </fluent-card>
        </div>
        
    </div>`)}
</div>

`;

import './styles/layout.scss';
const styles = T.css`#chat-profile-form {
  width: 100%;
  position: relative;
}
#chat-profile-form .loader-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  top: 80px;
  background-color: #FFF;
  border-radius: 50%;
}
#chat-profile-form form > div {
  margin-bottom: 15px;
}
#chat-profile-form form > div label {
  clear: both;
  display: block;
  margin-bottom: 5px;
}
#chat-profile-form fluent-card {
  padding: 15px;
  width: 350px;
  margin-bottom: 10px;
}
#chat-profile-form fluent-text-field {
  width: 100%;
}
#chat-profile-form header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#chat-profile-form header h3 {
  font-size: 16px;
  margin-right: 15px;
}
#chat-profile-form p {
  padding: 0 10px;
}
#chat-profile-form .image-holder img.preview {
  height: 60px;
  border: 3px solid var(--secondary_color);
  border-radius: 4px;
}

fluent-button {
  --neutral-foreground-rest: #FFF;
  --neutral-fill-rest: var(--secondary_color);
  --neutral-fill-active: var(--secondary_color);
  --neutral-fill-hover: var(--secondary_color);
}
fluent-button.save {
  display: block;
  width: 100%;
}

rws-uploader {
  --rws-uploader-text: rgb(51, 51, 51);
  --rws-uploader-primary: $main_color;
  --rws-uploader-bg: #FFF;
}

.img-prv {
  display: flex;
}

.profile-data-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  grid-auto-flow: dense;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
}
.profile-data-list .profile-gr {
  break-inside: avoid;
}
.profile-data-list .hyperparams-setup {
  margin-bottom: 15px;
}
.profile-data-list .hyperparams-setup .hyperparam-field {
  margin-top: 25px;
}
.profile-data-list .hyperparams-setup .hyperparam-field label {
  margin-bottom: 10px;
  display: block;
}

.opts {
  margin-bottom: 10px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { IChatProfile, ControllerActionResponse, IAnthropicHyperParams, DEFAULT_CLAUDE2_PARAMS, ChapterQueryParts, IConfigSplitterParams, DEFAULT_CONVO_SPLIT_PARAMS } from '@backend-types'
import { ChatProfileHyperparams } from './children/hyperparams/component';
import { ChatProfileSplitparams } from './children/splitparams/component';
import { ChatProfileQueryPrompt } from './children/query_prompt/component';
import { ChatProfileConfigLoader } from './children/config_loader/component';

ChatProfileSplitparams;
ChatProfileHyperparams;
ChatProfileQueryPrompt;
ChatProfileConfigLoader;

export type ProfilePayload = {    
    title: string
    image?: string | null,
    icon?: string | null
    reseller: string
    tosLink: string
    ppLink: string,
    hyperparams: IAnthropicHyperParams,
    introPart?: string,
    historyPart?: string,
    basicPart?: string,
    splitParams?: IConfigSplitterParams & { partsCount: number } 
}

@RWSView('chat-profile-form', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatProfile extends RWSViewComponent  {    
    @observable profile: IChatProfile = null;
    @observable icon: string | null = null;
    @observable image: string | null = null;   
    @observable stopIt: boolean = false;
    @observable uploadParams: any;
    @observable progress: number = 0;
    @observable saving: boolean = false;
    @observable loading: boolean = false;
    @observable tosLink: string = null;
    @observable ppLink: string = null;

    @observable hyperparams: IAnthropicHyperParams = {
        temperature: DEFAULT_CLAUDE2_PARAMS.temperature,
        top_k: DEFAULT_CLAUDE2_PARAMS.top_k,
        top_p: DEFAULT_CLAUDE2_PARAMS.top_p,
        max_tokens_to_sample: DEFAULT_CLAUDE2_PARAMS.max_tokens_to_sample,
        anthropic_version: 'bedrock-2023-05-31'
    }

    @observable splitParams: IConfigSplitterParams & { partsCount: number } = DEFAULT_CONVO_SPLIT_PARAMS;

    @observable prompts: {
        intro?: string,
        history?: string,
        basic?: string
    } = {intro: ChapterQueryParts.intro, history: ChapterQueryParts.history, basic: ChapterQueryParts.basic};

    @observable type: 'icon' | 'image' = 'icon';

    @observable opened: boolean = false;
    @observable reseller: string = 'locally';

    connectedCallback()
    {
        super.connectedCallback();       
        this.getProfile();        
    }  

    setIcon()
    {

    }

    setImage()
    {

    }

    setType(event: Event)
    {
        this.type = (event.target as HTMLInputElement).value as ('image' | 'icon');
    }

    toggle()
    {
        this.opened = !this.opened;
    }

    addChatProfile(isSave = true)
    {        
        const title = (this.$('#titleSelect') as HTMLInputElement).value;
        this.icon = (this.$('#iconSelect') as HTMLInputElement)?.value;
        this.tosLink = (this.$('#tosLink') as HTMLInputElement)?.value;
        this.ppLink = (this.$('#ppLink') as HTMLInputElement)?.value;
          

        this.icon = this.icon || this.profile?.icon || null;

        if(this.icon === 'null' || this.icon === ''){
            this.icon = null;
        }        

        const payload: ProfilePayload = {
            title,            
            icon: this.icon,        
            image: this.image || this.profile?.image || null,
            reseller: this.reseller,
            ppLink: this.ppLink || this.profile?.ppLink || null,
            tosLink: this.tosLink || this.profile?.tosLink || null,
            hyperparams: this.hyperparams,
            introPart: this.prompts.intro,
            historyPart: this.prompts.history,
            basicPart: this.prompts.basic,
            splitParams: this.splitParams
        }        

        this.profile = {
            ...this.profile,
            title,
            icon: this.icon,
            image: this.image || this.profile?.image || null,
            reseller: this.reseller,
            ppLink: this.ppLink,
            tosLink: this.tosLink,
            hyperparams: this.hyperparams,
            introPart: this.prompts.intro,
            historyPart: this.prompts.history,
            basicPart: this.prompts.basic,
            splitParams: this.splitParams
        }
              
        return payload;
    }

    onProgress(progress: number): void {
        progress = Math.ceil(progress);

        if (progress <= 100) {
            this.progress = progress;            

            if(progress === 100){
                this.loading = true;
            }
            return;
        }
    }

    async save(){        
        this.loading = true;
        this.saving = true;
        const partResponse: any = await this.apiService.back.post('chat-profile:resource:create', this.addChatProfile(true));
        this.saving = false;
        this.loading = false;
    }

    async preUpload(uploadingFile: File, context: any): Promise<any> {              
        try {
            this.stopIt = false;
            this.loading = true;
            const partResponse: any = await this.apiService.back.uploadFile('chat-profile:resource:create', uploadingFile, (progress: number) => {
                context.uploadProgress = progress * 100;                
                this.onProgress(context.uploadProgress);
            }, {}, this.addChatProfile());            

            const retData = JSON.parse(partResponse.data);

            this.progress = 0;
            this.icon = null;
            this.loading = false;

            this.getProfile();

            return retData;
        } catch (e: Error | any){
            this.loading = false;
            this.notifyService.notify('File upload error', 'error');
            throw e;
        }        
    }

    async uploadCallback(response: { data: any }): Promise<void> {
        const _self = this;        

        const responseData = response.data;        
    }

    clearVis(type: string): Promise<void>
    {
        if(type === 'image'){
            this.apiService.back.get('chat-profile:resource:clear-visuals', { routeParams: { id: this.reseller, type: 'image' } });
            this.profile = { ...this.profile, image: null };
        }

        return;
    }

    private async getProfile()
    {
        this.reseller = (window as any).labelBundle.reseller; 
        this.profile = (await this.apiService.back.get<ControllerActionResponse<IChatProfile>>('chat-profile:resource:get', { routeParams: { id: this.reseller } })).data;
        if(this.profile?.image){
            this.type = 'image';
        }

        if(!this.profile.hyperparams){
            this.profile = {...this.profile, hyperparams: DEFAULT_CLAUDE2_PARAMS}            
        }

        if(!this.profile.splitParams){
            this.profile = {...this.profile, splitParams: DEFAULT_CONVO_SPLIT_PARAMS}            
        }

        if(this.profile.basicPart){
            this.prompts = {
                ...this.prompts,
                basic: this.profile.basicPart
            }
        }

        if(this.profile.introPart){
            this.prompts = {
                ...this.prompts,
                intro: this.profile.introPart
            }
        }

        if(this.profile.historyPart){
            this.prompts = {
                ...this.prompts,
                history: this.profile.historyPart
            }
        }

        this.hyperparams = this.profile.hyperparams;
        this.splitParams = this.profile.splitParams;
    }

    setHyperParams(event: CustomEvent<IAnthropicHyperParams>)
    {        
        if(event.detail !== null){
            
            this.hyperparams = event.detail;
            this.fillProfile();
        }
    }

    fillProfile()
    {
        this.profile = {
            ...this.profile, 
            hyperparams: this.hyperparams, 
            splitParams: this.splitParams, 
            introPart: this.prompts.intro, 
            basicPart: this.prompts.basic, 
            historyPart: this.prompts.history
        };
    }

    setSplitParams(event: CustomEvent<IConfigSplitterParams & { partsCount: number }>)
    {        
        if(event.detail !== null){            
            this.splitParams = event.detail;
            this.fillProfile();
        }
    }

    setPrompt(event: CustomEvent<[string, string]>)
    {        
        
        if(event.detail !== null){                  
            const [partName, partValue] = event.detail;            
            this.prompts[partName] = partValue;
            
            this.fillProfile();
        }
    }

    loadConfig(event: CustomEvent<IChatProfile>)
    {        
        
        if(event.detail !== null){                  
            

            this.hyperparams = event.detail.hyperparams;
            this.splitParams = event.detail.splitParams;

            this.prompts = {
                intro: this.profile.introPart,
                basic: this.profile.basicPart,
                history: this.profile.historyPart,
            }

            this.fillProfile();            
            console.log('loaded', this.profile);
        }
    }
}

ChatProfile.defineComponent();

export { ChatProfile }