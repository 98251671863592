import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="hyperparams-setup">
    <h4>AI Hyperparameters</h4>
    <div class="hyperparam-field">
        <label for="temperature_slider">Temperature: <strong>${ x => x.chatTemperature }</strong></label>
        <fluent-slider id="temperature_slider" @change="${ (x, c) => x.setHyperparam('chatTemperature', c.event.target.value) }" min="0"
            max="1" step="0.1" value="${ x => x.chatTemperature }">
            <fluent-slider-label position="0">0</fluent-slider-label>
            <fluent-slider-label position="1">1</fluent-slider-label>
        </fluent-slider>
    </div>
    <div class="hyperparam-field">
        <label for="top_k_slider">Top K: <strong>${ x => x.chatTopK }</strong></label>
        <fluent-slider id="top_k_slider" @change="${ (x, c) => x.setHyperparam('chatTopK', c.event.target.value) }" min="0"
            max="500" step="1" value="${ x => x.chatTopK }">
            <fluent-slider-label position="0">0</fluent-slider-label>
            <fluent-slider-label position="500">500</fluent-slider-label>
        </fluent-slider>
    </div>
    <div class="hyperparam-field">
        <label for="top_p_slider">Top P: <strong>${ x => x.chatTopP  }</strong></label>
        <fluent-slider id="top_p_slider" @change="${ (x, c) => x.setHyperparam('chatTopP', c.event.target.value) }" min="0"
            max="1" step="0.1" value="${ x => x.chatTopP }">
            <fluent-slider-label position="0">0</fluent-slider-label>
            <fluent-slider-label position="1">1</fluent-slider-label>
        </fluent-slider>
    </div>
    <div class="hyperparam-field">
        <label for="max_tokens_slider">Response length: <strong>${ x => x.chatMaxTokensToSample }</strong></label>
        <fluent-slider id="max_tokens_slider" @change="${ (x, c) => x.setHyperparam('chatMaxTokensToSample', c.event.target.value) }" min="0"
            max="2048" step="1" value="${ x => x.chatMaxTokensToSample }">
            <fluent-slider-label position="0">0</fluent-slider-label>
            <fluent-slider-label position="2048">2048</fluent-slider-label>
        </fluent-slider>
    </div>
</div>
<fluent-button class="save"
@click="${ x => x.resetParams()}">Reset</fluent-button>
              `;

import './styles/layout.scss';
const styles = T.css`.hyperparams-setup {
  margin-bottom: 15px;
}
.hyperparams-setup .hyperparam-field {
  margin-top: 25px;
}
.hyperparams-setup .hyperparam-field label {
  margin-bottom: 10px;
  display: block;
}

fluent-button {
  --neutral-foreground-rest: #FFF;
  --neutral-fill-rest: var(--secondary_color);
  --neutral-fill-active: var(--secondary_color);
  --neutral-fill-hover: var(--secondary_color);
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { IChatProfile, ControllerActionResponse, IAnthropicHyperParams, DEFAULT_CLAUDE2_PARAMS } from '@backend-types';


@RWSView('chat-profile-hyperparams', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatProfileHyperparams extends RWSViewComponent  {
    @observable profile: IChatProfile = null;
    @observable chatTemperature: number = DEFAULT_CLAUDE2_PARAMS.temperature;
    @observable chatTopP: number = DEFAULT_CLAUDE2_PARAMS.top_p;
    @observable chatTopK: number = DEFAULT_CLAUDE2_PARAMS.top_k;
    @observable chatMaxTokensToSample: number = DEFAULT_CLAUDE2_PARAMS.max_tokens_to_sample;

    @observable hyperparams: IAnthropicHyperParams = {
        temperature: this.chatTemperature,
        top_k: this.chatTopK,
        top_p: this.chatTopP,
        max_tokens_to_sample: this.chatMaxTokensToSample,
        anthropic_version: 'bedrock-2023-05-31'
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.hyperparams = this.profile.hyperparams;
        this.fillHyperParams(this.profile.hyperparams);
    }

    profileChanged(oldVal: IChatProfile, newVal: IChatProfile){

        if(newVal){
            this.profile = newVal;
            this.hyperparams = newVal.hyperparams;
            this.fillHyperParams(newVal.hyperparams);         
               
        }
    }

    resetParams() {
        this.fillHyperParams(DEFAULT_CLAUDE2_PARAMS)

        this.hyperparams = DEFAULT_CLAUDE2_PARAMS;
    }

    setHyperparam(param: string, value: string) {
        this[param] = parseFloat(value);

        this.hyperparams = {
            temperature: this.chatTemperature,
            top_k: this.chatTopK,
            top_p: this.chatTopP,
            max_tokens_to_sample: this.chatMaxTokensToSample,
            anthropic_version: 'bedrock-2023-05-31'
        };

        this.sendChange();
    }

    private fillHyperParams(hyperparams: IAnthropicHyperParams) {
        this.chatMaxTokensToSample = hyperparams.max_tokens_to_sample;
        this.chatTemperature = hyperparams.temperature;
        this.chatTopK = hyperparams.top_k;
        this.chatTopP = hyperparams.top_p;
    }

    sendChange() {
        this.dispatchEvent(new CustomEvent<IAnthropicHyperParams>('change', {
            detail: this.hyperparams
        }));
    }
}

ChatProfileHyperparams.defineComponent();

export { ChatProfileHyperparams }