import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="splitparams-setup">
    <h4>AI Content Search Parameters</h4>
    <div class="splitparam-field">
        <label for="chunkSize_slider">Split text chunk size: <strong>${ x => x.chunkSize }</strong></label>
        <fluent-slider id="chunkSize_slider" @change="${ (x, c) => x.setParam('chunkSize', c.event.target.value) }" min="350"
            max="8000" step="10" value="${ x => x.chunkSize }">
            <fluent-slider-label position="350">350</fluent-slider-label>
            <fluent-slider-label position="8000">8000</fluent-slider-label>
        </fluent-slider>
    </div>
    <div class="splitparam-field">
        <label for="chunkOverlap_slider">Overlap between chunks: <strong>${ x => x.chunkOverlap }</strong></label>
        <fluent-slider id="chunkOverlap_slider" @change="${ (x, c) => x.setParam('chunkOverlap', c.event.target.value) }" min="50"
            max="3500" step="10" value="${ x => x.chunkOverlap }">
            <fluent-slider-label position="50">50</fluent-slider-label>
            <fluent-slider-label position="3500">3500</fluent-slider-label>
        </fluent-slider>
    </div>
    <div class="splitparam-field">
        <label for="partsCount_slider">Amount of chunks sent to AI: <strong>${ x => x.partsCount  }</strong></label>
        <fluent-slider id="partsCount_slider" @change="${ (x, c) => x.setParam('partsCount', c.event.target.value) }" min="1"
            max="50" step="1" value="${ x => x.partsCount }">
            <fluent-slider-label position="1">1</fluent-slider-label>
            <fluent-slider-label position="50">50</fluent-slider-label>
        </fluent-slider>
    </div>
</div>
<fluent-button class="save"
@click="${ x => x.resetParams()}">Reset</fluent-button>
              `;

import './styles/layout.scss';
const styles = T.css`.splitparams-setup {
  margin-bottom: 15px;
}
.splitparams-setup .splitparam-field {
  margin-top: 25px;
}
.splitparams-setup .splitparam-field label {
  margin-bottom: 10px;
  display: block;
}

fluent-button {
  --neutral-foreground-rest: #FFF;
  --neutral-fill-rest: var(--secondary_color);
  --neutral-fill-active: var(--secondary_color);
  --neutral-fill-hover: var(--secondary_color);
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { IChatProfile, ControllerActionResponse, IAnthropicHyperParams, DEFAULT_CONVO_SPLIT_PARAMS, IConfigSplitterParams } from '@backend-types';


@RWSView('chat-profile-splitparams', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatProfileSplitparams extends RWSViewComponent  {
    @observable profile: IChatProfile = null;

    @observable chunkSize: number = DEFAULT_CONVO_SPLIT_PARAMS.chunkSize;
    @observable chunkOverlap: number = DEFAULT_CONVO_SPLIT_PARAMS.chunkOverlap;
    @observable partsCount: number = DEFAULT_CONVO_SPLIT_PARAMS.partsCount;


    connectedCallback(): void {
        super.connectedCallback();
        this.fillSplitParams(this.profile.splitParams);
    }

    
    profileChanged(oldVal: IChatProfile, newVal: IChatProfile){
        if(newVal){
            this.fillSplitParams(newVal.splitParams);
        }
    }


    resetParams() {
        this.fillSplitParams(DEFAULT_CONVO_SPLIT_PARAMS)        
    }

    setParam(param: string, value: string) {
        this[param] = parseInt(value);       

        this.sendChange();
    }

    private fillSplitParams(splitParams: IConfigSplitterParams & { partsCount: number }) {
        this.chunkSize = splitParams.chunkSize;
        this.chunkOverlap = splitParams.chunkOverlap;
        this.partsCount = splitParams.partsCount;        
    }

    sendChange() {
        this.dispatchEvent(new CustomEvent<IConfigSplitterParams & { partsCount: number }>('change', {
            detail: {
                chunkSize: this.chunkSize,
                chunkOverlap: this.chunkOverlap,
                partsCount: this.partsCount
            }
        }));
    }
}

ChatProfileSplitparams.defineComponent();

export { ChatProfileSplitparams }