import {
  ShowScore,
  ShowAnswers,
  ShowInline,
  Policy,
} from "../../../types/activities";

export type Update = {
  // basic
  graded: boolean;

  // attempts
  submission_attempts: number;
  resume_max_attempts: number;

  // grades and correct answers
  show_score: ShowScore;
  show: boolean;
  // grades and correct answers -> allowStudentToReviewSubmittedAssessment
  show_correct_answers: ShowAnswers;
  show_responses: ShowAnswers;
  // grades and correct answers -> allowStudentToCheck
  inline: ShowInline;

  // question order and restrictions
  scramble: boolean;
  lock_back_button: boolean;
  time_limit: number;

  // discussion board settings
  requires_comment: boolean;
  max_score: number;

  // adaptive recommendation
  confidence: boolean;
  studycenter: boolean;

  // late work policy overrides
  policy: Policy;

  // time
  open_date: string;
  due_date: string;
  close_date: string;
  suppress_until_available: boolean;

  // preset
  preset_id: string;

  // ???
  pickx: number;
  rubric_id: string;

  //
  apply_to: Array<{ _id: string }>;
};

export const UpdateModel = {
  default: () =>
    ({
      open_date: "",
      due_date: "",
      close_date: "",
      suppress_until_available: false,
      graded: false,
      submission_attempts: 0,
      resume_max_attempts: 0,
      inline: ShowInline.NEVER,
      show_score: ShowScore.WITH_RESPONSES,
      time_limit: 0,
      max_score: 0,
      show_correct_answers: ShowAnswers.NEVER,
      show_responses: ShowAnswers.NEVER,
      show: false,
      scramble: false,
      lock_back_button: false,
      requires_comment: false,
      studycenter: false,
      confidence: false,
    } satisfies Partial<Update>),

  /** order of keys in this object does matter when displaying list of changes on confirm dialog */
  labels: () =>
    ({
      open_date: "Open Date",
      due_date: "Due Date",
      close_date: "Close Date",
      suppress_until_available:
        "Student does not see this page until open date",
      preset_id: "Preset",
      graded: "Show in Gradebook as",
      submission_attempts: "Max Submissions Allowed",
      resume_max_attempts: "Re-open Attempts",
      inline: {
        group: "Allow Students to Check Answers During the Assessment",
        showIndicator: "Show Correct/Incorrect Indicator",
        showCorrect: "Show Correct Answer",
      },
      show_correct_answers: {
        group: "Allow Students to Review Submitted Assessment",
        showIndicator: "Show Correct Answer",
      },
      show_responses: {
        group: "Allow Students to Review Submitted Assessment",
        showCorrect: "Show Correct/Incorrect Indicator",
      },
      show_score: "Display Grades",
      show: "Visible in Student Gradebook (Custom Columns Only)",
      scramble: "Shuffle Item Order",
      lock_back_button: "Lock the Back Button",
      time_limit: "Time Limit",
      requires_comment: "Learners Must Post Before Seeing Classmates Posts",
      max_score: "Total Points",
      confidence: "Begin Assessment with studycenter Question",
      studycenter: "Use Assessment to Determine Proficiency (Study Center)",
      policy: "Override Late Work Policy for the Selected Item(s)",
    } satisfies Partial<Record<keyof Update, unknown>>),
};

/**
 * Record defining form grouping in respect to object fields
 */
export const groups = {
  basic: ["graded", "suppress_until_available"],
  attempts: ["submission_attempts", "resume_max_attempts"],
  gradesAndCorrectAnswers: [
    "inline",
    "show_score",
    "show_correct_answers",
    "show_responses",
    "show",
  ],
  questionOrderAndRestrictions: ["scramble", "lock_back_button", "time_limit"],
  discussionBoardSettings: ["requires_comment", "max_score"],
  adaptiveRecommendation: ["confidence", "studycenter"],
  lateWorkPolicy: ["policy"],
  time: ["open_date", "due_date", "close_date", "suppress_until_available"],
  preset: ["preset_id"],
} satisfies Record<string, Array<keyof Update>>;
