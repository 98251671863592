import { ConfirmationModal } from '../../components/confirmationModal/component';
import { Icon } from '../../components/icon/component';
import { JunctionModalHeader } from "../../components/modal/modalHeader/component";
import { JunctionModal } from "../../components/modal/component";
import { JunctionTable } from "../../components/table/component";
import { JunctionTableDropdown } from '../../components/table/tableDropdown/component';
import { JunctionLoader } from '../../components/jnct-loader/component';

import { JunctionDateTimeInput } from "../../components/formComponents/datetimeInput/component";
import { JunctionCheckboxInput } from "../../components/formComponents/checkboxInput/component";
import { JunctionNumberInput } from "../../components/formComponents/numberInput/component";
import { JunctionSelectColorInput } from "../../components/formComponents/selectColortInput/component";
import { JunctionSelectInput } from "../../components/formComponents/selectInput/component";
import { JunctionStringInput } from "../../components/formComponents/stringInput/component";
import { JunctionPresetBadge } from "../../components/activitySettings/presetBadge/component";
import { JunctionPresetsListModal } from "../../components/activitySettings/presetsListModal/component";
import { JunctionBatchUpdateModal } from '../../components/activitySettings/batchUpdate/component';
import { JunctionShowHideColumnsModal } from '../../components/activitySettings/showHideColumns/component';
import { DashboardActions } from '../../components/dashboardActions/component';
import { JunctionDropdown } from "../../components/formComponents/dropdown/component";
import { JunctionSorting } from "../../components/formComponents/sortingIcon/component";

import { FullCalendar } from '../../components/calendar/component';
import { SubscribeModal } from '../../components/calendar/subscribe_modal/component';
import { TextModal } from '../../components/modal/textModal/component';

import { PerformanceReport } from '../../components/performanceReport/component';
import { EmailStudentsModal } from '../../components/performanceReport/modal/component';
import { ChartBar } from "../../components/chartBar/component";
import { UsersTable } from "../../components/usersTable/component";

import { RollupManager } from '../../components/rollupManager/component';
import { RollupModal } from '../../components/rollupManager/modal/component';


import { VideoErrorOverlay } from '../../components/videoErrorOverlay/component'
import { SubmitLearnosityModal } from '../../components/submitLearnosity/component';
import { LoginDetailsModal } from '../../components/dashboardLogins/loginDetailsModal/component';
import { DashboardLogins } from '../../components/dashboardLogins/component';


export default (parted: boolean = false) => {
    if (!parted) {
        RollupManager;
        RollupModal;
        JunctionDropdown;
        JunctionSorting;
        ConfirmationModal;
        Icon;
        FullCalendar;
        JunctionPresetBadge;
        JunctionPresetsListModal;
        JunctionModal;
        JunctionTable;
        JunctionTableDropdown;
        JunctionModalHeader;
        JunctionLoader;
        JunctionBatchUpdateModal;
        JunctionShowHideColumnsModal;
        JunctionDateTimeInput;
        JunctionCheckboxInput;
        JunctionNumberInput;
        JunctionSelectColorInput;
        JunctionSelectInput;
        JunctionStringInput;
        SubscribeModal;
        TextModal;
        ChartBar;
        PerformanceReport;
        EmailStudentsModal;
        UsersTable;

        DashboardActions;

        DashboardLogins;
        LoginDetailsModal;
        SubmitLearnosityModal;
        VideoErrorOverlay;

    }
}
