import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<content>
  <div class="wrapper">
    <div class="item">
      <div class="grey_background">
        <span class="font--bold-large">
          ${x => x.student.avg_score}%
        </span>
      </div>
    </div>
    <div class="item grey_background">
      <jnct-date-tabular-display
        :date="${x => x.student.lastTimeActive}"
        :boldDate="${x => true}"
      ></jnct-date-tabular-display>
    </div>
    <div class="item grey_background">
      ${(x => x.student.lastSubmittedAssignment ? T.html`<a
        class="line-clamp"
        href="/${x => x.student.lastSubmittedAssignment.link}"
        :innerHTML="${x => x.student.lastSubmittedAssignment.title}"></a
      >`: T.html`<span>-</span>`)}
    </div>
    <div class="item grey_background">
      ${(x => x.student.lastPageVisited ? T.html`<a
        class="line-clamp"
        href="/${x => x.student.lastPageVisited.link}"
        :innerHTML="${x => x.student.lastPageVisited.title}"></a
      >` : T.html`<span>-</span>`)}
    </div>
    <div class="item">Average Grade</div>
    <div class="item">Last Time Active</div>
    <div class="item">Last Assignment Submitted</div>
    <div class="item">Last Page Visited</div>
  </div>
</content>
`;

import './styles/layout.scss';
const styles = T.css`#jnct-users-table {
  display: flex;
}

.wrapper {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.0784313725);
  border-radius: 16px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr min-content;
  gap: 8px 16px;
}
.wrapper .item {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  align-self: center;
  justify-self: center;
}
.wrapper .grey_background {
  border-radius: 12px;
  border: 1px solid var(--neutral-30);
  box-sizing: border-box;
  display: flex;
  min-width: 78px;
  min-height: 78px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  text-align: center;
  color: #4f4f4f;
}
.wrapper .grey_background a {
  text-decoration: none;
  color: #0f75b8;
}
.wrapper .font--bold {
  color: #1b1c1e;
  text-align: center;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-transform: none;
}
.wrapper .font--bold-large {
  color: #1b1c1e;
  text-align: center;
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  text-transform: none;
}
.wrapper jnct-date-tabular-display {
  --font-size: 16px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
}`;

const mode = "open";
import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { StudentDeepDiveListItem } from "../../../types/reports";

@RWSView('jnct-student-overall', null, { template: rwsTemplate, styles, options: {mode} })
class StudentOverall extends RWSViewComponent  {
  @attr student: StudentDeepDiveListItem;
}

StudentOverall.defineComponent();

export { StudentOverall };
