import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="chapter_viewer_area">
    ${T.when(x => x.loading, T.html`<div class="during-save">Saved chapters: <strong>${ x => x.currentIndex}</strong> / ${ x => x.currentChaptersList.length}</div>`)}
    <rws-progress :value="${x => (x.currentIndex / x.currentChaptersList.length) * 100}" max="100"></rws-progress>
    ${T.when(x => !x.loading, T.html`<div class="intruder-detect-bg">
        <fluent-switch id="intruder-detect-switch" @change="${x => x.onDetectSwitch()}" :checked="${x => x.intruderEnabled}">
            <strong>Enable intruder prompt</strong>
            <span slot="checked-message">Enabled</span>
            <span slot="unchecked-message">Disabled</span>
        </fluent-switch>
    </div>`)} 

    ${T.when(x => !x.loading, T.html`<div class="dataset-meta">
        <fluent-text-field placeholder="Dataset name" class="dataset_name"></fluent-text-field>
        ${T.when(x => x.intruderEnabled, T.html`<div class="intruder-prompt"><hr/><textarea id="send_chat_intruder" placeholder="Type your intruder prompt"></textarea></div>`)}
        <fluent-button @click="${ x => x.onSaveBound() }" appearance="accent" class="save_book">Save data</fluent-button>
    </div>`)}

    ${T.when(x => !x.loading, T.html`<div>
        <h2 class="section-info">Chapters to be processed and indexed</h2>
        <section>
            ${T.repeat(x => x.currentChaptersList, (item, c) => T.html`                            
                <chapter-viewer-element :onCheck="${(item, c) => c.parent.removeFromChapterList.bind(c.parent)}" checked="${true}" :item="${(item, c) => item}" index="${(item, c) => c.index}" :pages="${(item, c) => c.parent.processChapterPages(item)}" />
            `, { positioning: true })}
        </section>
    </div>`)}

    ${T.when(x => x.loading, T.html`<div style="width: 100vw; height: 100vh; top: 50%; left: 0; position: fixed;"><jnct-loading-spinner></jnct-loading-spinner></div>`)}
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#chapter_viewer_area {
  background-color: #FFF;
  position: relative;
  padding: 10px;
}
#chapter_viewer_area h2 {
  color: black;
}
#chapter_viewer_area h2.section-info {
  color: #000;
  background: var(--neutral-fill-input-rest);
  padding: 10px;
  border-radius: 5px;
}
#chapter_viewer_area .intruder-detect-bg fluent-switch strong {
  color: #000;
}
#chapter_viewer_area textarea {
  resize: none;
  border: none;
  display: block;
  width: 100%;
  height: 80px;
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  margin-bottom: 13px;
}
#chapter_viewer_area textarea::placeholder {
  color: #ddd;
}
#chapter_viewer_area .dataset-meta {
  display: flex;
  direction: row;
  justify-items: center;
  margin-top: 15px;
}

.during-save {
  text-align: center;
  color: #000;
  font-size: 21px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent, observable, attr, ApiService, NotifyService, RWSInject } from '@rws-framework/client';
import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';

import { IBookInfo, IChapterOutput, IBookPageInput, ITrainWsResponse, ProcessPayload, IBookSavePayload, IBookSaveWsResponse, BaseWsResponse, ErrorWsResponse, ISplitData } from '@backend-types';

import '../../../page-element/component';

import WebChatEvents from '../../../../events/events';

import {ChapterElement} from '../chapter-viewer-element/component';
import { IRWSPromptJSON } from '../../../webchat/children/chat-message/component';

ChapterElement;

@RWSView('chapter-viewer', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChapterViewer extends RWSViewComponent  {
    private wsId: string;

    @attr bookInfo: IBookInfo;
    @attr pdffile: string = '';
    @attr pdffilename: string = '';

    @observable loading: boolean = false;

    @observable intruderEnabled: boolean = false;

    @observable currentChaptersList: IChapterOutput[] = [];
    @observable droppedChaptersList: IChapterOutput[] = [];

    @observable currentIndex :number = 0;
    bookId: string = null;

    constructor(@RWSInject(WSService) private wsService: WSServiceInstance){
        super();
    }

    onDetectSwitch() {
        this.intruderEnabled = !this.intruderEnabled;
    }

    async onSave(): Promise<void> {
        const dataSetDOMOBj = this.$('.dataset_name') as HTMLInputElement;
        const datasetName = dataSetDOMOBj.value;
        let success = false;    

      if(!datasetName || datasetName === ''){
          alert('Error: No book name entered.');
          return;
      }

        const textareaIntruder = this.$<HTMLTextAreaElement>('textarea#send_chat_intruder') as HTMLTextAreaElement;

        let intruderPrompt = null;

      if(textareaIntruder){      
        intruderPrompt = textareaIntruder.value;
      }

        this.loading = true;

        const saveCall = await this.apiService.back.post<{ success: boolean, data: { wsid: string, bookPages: IBookPageInput[] } }>('train:save:book', {
            pdffile: this.pdffile            
        });

        this.wsId = saveCall.data.wsid;    
        
        this.wsService.listenForMessage((wsData: {
            data: {_prompt: IRWSPromptJSON }
          }, isJson?: boolean) => {                  
              
        }, 'error_' + this.wsId);

        this.wsService.listenForError<Error>((data: {error: Error }) => {                                
            this.currentIndex = 0;
            this.loading = false;    

            this.notifyService.notify('Book save error event', 'error')
            this.notifyService.alert(`ERROR STACK: \n\n <pre> ${data.error.stack} </pre>`, 'error', null, {
                width: '60%'
            });

        }, 'error_' + this.wsId);
      
        let chapterPages = this.filterChapterPages(saveCall.data.bookPages, this.currentChaptersList[0]);

        const wsPayload: IBookSavePayload = {
            wsid: this.wsId,
            bookName: datasetName,
            intruderPrompt,
            chapter: this.currentChaptersList[0],
            pages: chapterPages,
            chapterKey: 0,
            pdfFileName: this.pdffilename,
            final_call: this.currentChaptersList.length === 1
        };        

        try {
            await this.sendToWs(wsPayload, this.currentChaptersList.length, saveCall.data.bookPages);     
                                  
            this.loading = false;
            this.currentChaptersList = [];
            this.droppedChaptersList = []; 
            this.currentIndex++;

            await this.apiService.back.get('train:describe:book', {
                routeParams: { bookId: this.bookId, start: '0'}
            });

            this.notifyService.alert('Book saved');  
            
            this.$emit('trainer:reload');    
        } catch(e: Error | any){
            this.loading = false;
            throw new Error('save response error', e)
        }       
    } 

    onSaveBound = this.onSave.bind(this);

    async sendToWs(wsPayload: IBookSavePayload, chaptersCount: number, allPages: IBookPageInput[]): Promise<IBookSaveWsResponse | null> 
    {
        let lastResult: IBookSaveWsResponse = null;
        let lastChapter: IChapterOutput = null;

        return new Promise((resolve, reject) => {
            this.wsService.listenForMessage(async (params: BaseWsResponse<IBookSaveWsResponse>) => {
                lastResult = params.data;

                if(!this.bookId){
                    this.bookId = params.data.bookId;
                }

                if (params.error || !params) {

                    this.notifyService.notify(params.error.message, 'error');

                    //clean up states
                    console.error(params.error);
                    reject(params.error);
                }
                            
                const nextStepIndex = params.data.currentKey + 1;        
                
       
                this.currentIndex = nextStepIndex;

                if(nextStepIndex < chaptersCount){             
            
                    const nextStepPayload: IBookSavePayload = {
                        wsid: this.wsId,              
                        chapter: this.currentChaptersList[nextStepIndex],                    
                        last_chapter: lastChapter,
                        chapterKey: nextStepIndex,
                        bookId: params.data.bookId,
                        pages: this.filterChapterPages(allPages, this.currentChaptersList[nextStepIndex]),
                        final_call: this.currentIndex == chaptersCount - 1
                    };                  
                

                    lastChapter = this.currentChaptersList[nextStepIndex];

                    console.log('sent', this.currentIndex == chaptersCount - 1);
                    
                    this.wsService.sendMessage<IBookSavePayload>('save_book', nextStepPayload);       
                }else{                    
                    resolve(lastResult);
                }              
            }, this.wsId);
            lastChapter = wsPayload.chapter;            

            this.wsService.sendMessage<IBookSavePayload>('save_book', wsPayload);         
        });
    }

    processChapterPages(chapter: IChapterOutput): string[] {
        const thePages: string[] = this.bookInfo.input.pages
            .map((item: IBookPageInput) => item.text.replace(/<page .*>|<\/page>/g, ''))
            .slice(chapter.pageStart, chapter.pageEnd + 1)
            ;

        return thePages;
    }

    bookInfoChanged(oldVal: IBookInfo | undefined, newVal: IBookInfo | string) {
        if (typeof newVal === 'string') {
            this.bookInfo = JSON.parse(newVal).data;
        }

        if (this.bookInfo && this.bookInfo.output) {
            this.currentChaptersList = this.bookInfo.output;            
            this.droppedChaptersList = [];
        }
    }

    removeFromChapterList(index: number): void {
        const movel: IChapterOutput = this.currentChaptersList[index];        

        this.droppedChaptersList = [...this.droppedChaptersList, movel];

        this.currentChaptersList = [...this.currentChaptersList.filter((item: IChapterOutput) => item.title !== movel.title)];        
    }


    connectedCallback() {
        super.connectedCallback();

        this.on<{ item: number }>(WebChatEvents.trainer.chapter.check, (event: CustomEvent<{ item: number }>) => {
            this.removeFromChapterList(event.detail.item);
        });       
    }

    private filterChapterPages(pages: IBookPageInput[], chapter: IChapterOutput): IBookPageInput[]
    {
        return pages.filter((page: IBookPageInput, key: number) => {
            return key >= chapter.pageStart -1 && key < chapter.pageEnd;
        });
    }
}

ChapterViewer.defineComponent();

export { ChapterViewer };