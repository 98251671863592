import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="dash__loading" style="display: ${x => x.isLoading ? 'flex':'none'}">
    <img
      src="/images/loading_circle.png"
      alt="loading"
      class="dash__spinner" />
      Please wait...
</div>
<style>
.subscribe-div {
    display: ${T.when(x => x.allow_subscribe(), T.html`inline`)}${T.when(x => !x.allow_subscribe(), T.html`none`)};
    margin-left: 20px;
    position: relative;
    top: -10px;
}
</style>
<div class="layout__container" style="display: ${x => x.isLoading ? 'none':'block'}">
    <div class="top-bar arrr">
        <div class="tabs-wrapper">
            <div class="tabs">
                <button id="calendar-tab" tabindex="0" class="tab box">Calendar</button>
                <button id="list-tab" tabindex="0" class="tab box">List</button>
            </div>
        </div>

        <div class="tabs-wrapper" style="display: inline-flex;">
    		<div class="top-bar__assignment-counter" style="display: inline">
    			${x => x.assignmentCountLabel}
    		</div>
            <div class="top-bar__assignment-counter subscribe-div">
                ${T.when(x => x.showSub, T.html`
                <jnct-modal templateName="subscribe-modal" :genericConfig="${x => x.subConf }"></jnct-modal>
                `)}
                <button class="button__secondary" @click="${x => x.toggleSub()}">
                    Sync Calendar
                </button>
            </div>
        </div>
    </div>

    <div id="calendar" class="box"></div>
    <div id="details-container">
        <div id="minicalendar"></div>
        <div id="details">
            <h2 id="details-title"></h2>
            <table>
                <tr>
                    <td>Type:</td>
                    <td id="details-type"></td>
                </tr>
                <tr>
                    <td>Opens on:</td>
                    <td id="details-opens"></td>
                </tr>
                <tr>
                    <td>Due on:</td>
                    <td id="details-due"></td>
                </tr>
                <tr>
                    <td>Submitted:</td>
                    <td id="details-submitted"></td>
                </tr>
                <tr>
                    <td>Submitted:</td>
                    <td id="details-submitted-count"></td>
                </tr>
                <tr>
                    <td>Graded:</td>
                    <td id="details-graded"></td>
                </tr>
                <tr>
                    <td>Average Grade:</td>
                    <td id="details-average"></td>
                </tr>
            </table>
        </div>
        <a id="details-btn" tabindex="0" class="details-btn">Go to Activity</a>
    </div>
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.box {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.layout__container {
  box-sizing: border-box;
  padding: 24px 32px;
  overflow: hidden auto;
}

.fc#calendar {
  width: 100%;
  border-radius: 0 12px 12px 12px;
  border-top: 0;
  height: 85vh;
}
.fc#calendar .fc-header-toolbar {
  width: 100%;
  margin-bottom: auto;
}
.fc#calendar .fc-header-toolbar .fc-button:focus {
  box-shadow: unset;
}
.fc#calendar .fc-header-toolbar .fc-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:first-of-type {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:first-of-type h2 {
  margin-top: 5px;
  margin-left: 0.75rem;
  width: 260px;
  text-align: center;
  font-weight: 400;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button-primary {
  background: unset;
  color: #000;
  border: 0;
  outline: none;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-button-primary:focus {
  outline: none;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) .fc-button {
  padding: 10px 40px;
  color: #000;
  background: unset;
  border: 0;
  border-bottom: 2px solid #efefef;
  border-radius: 0;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.5;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) .fc-button.fc-button-active {
  opacity: 1;
  border-bottom-color: var(--primary_color);
}
.fc#calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) .fc-button:hover {
  opacity: 1;
  border-bottom-color: var(--primary_color);
}
.fc#calendar .fc-header-toolbar .fc-today-button {
  height: 40px;
  padding: 5px 60px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid var(--neutral-30);
  background: #fff;
  border-radius: 10px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.fc#calendar .fc-header-toolbar .fc-today-button:disabled {
  opacity: 0.5;
}
.fc#calendar .fc-header-toolbar .fc-today-button:disabled:hover {
  background: #fff;
}
.fc#calendar .fc-header-toolbar .fc-today-button:hover {
  background: #f8f8f8;
}
.fc#calendar .fc-header-toolbar .fc-col-header {
  width: 100% !important;
}
.fc#calendar .fc-daygrid-body {
  width: 100% !important;
}
.fc#calendar .fc-daygrid-body .fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc#calendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-event-title .date-section {
  font-size: 10px;
}
.fc#calendar.day-view {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fc#calendar.day-view .fc-header-toolbar {
  width: 100%;
}
.fc#calendar.day-view .fc-view-harness {
  width: 35vw;
  height: 70vh !important;
}
.fc#calendar.day-view .fc-view-harness .fc-listDay-view {
  width: 100%;
}
.fc#calendar.day-view .fc-view-harness .fc-listDay-view .fc-list-table .fc-event:hover a {
  text-decoration: none;
}
.fc#calendar.day-view .fc-view-harness .fc-listDay-view .fc-list-table .fc-event .fc-list-event-graphic {
  display: none;
}
.fc#calendar.day-view .fc-view-harness .fc-listDay-view .fc-list-table .fc-event .fc-list-event-title .date-section {
  font-size: 12px;
}
.fc#calendar.day-view #details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25vw;
}
.fc#calendar.day-view #details-container #minicalendar {
  width: 100%;
  height: 40vh;
}
.fc#calendar.day-view #details-container #minicalendar .fc-header-toolbar {
  margin: 5px 0 10px;
}
.fc#calendar.day-view #details-container #minicalendar .fc-header-toolbar .fc-toolbar-title {
  font-size: 18px;
  font-weight: 500;
}
.fc#calendar.day-view #details-container #minicalendar .fc-col-header {
  width: 100% !important;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body {
  width: 100% !important;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day {
  height: 34px !important;
  border: 0;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame {
  height: 100% !important;
  display: flex;
  justify-content: center;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame .fc-daygrid-day-top {
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin-left: 0;
  transition: all 0.2s ease-in-out;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame .fc-daygrid-day-top .fc-daygrid-day-number {
  margin: 0;
  font-size: 16px;
  padding: 5px 11px;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame .fc-daygrid-day-top:hover {
  background: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  color: #000;
  cursor: pointer;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame .fc-daygrid-day-bg .fc-daygrid-bg-harness {
  display: flex;
  justify-content: center;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day .fc-daygrid-day-frame .fc-daygrid-day-bg .fc-daygrid-bg-harness .fc-highlight {
  background: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  border-radius: 23px;
  color: #000;
  cursor: pointer;
  border: 0;
  width: 30px;
  height: 30px;
  position: relative;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day:hover {
  background: #fff;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body .fc-scrollgrid-sync-table .fc-day.active .fc-daygrid-day-top {
  background: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  color: #000;
}
.fc#calendar.day-view #details-container #minicalendar .fc-view-harness {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.fc#calendar.day-view #details-container #minicalendar .fc-view-harness .fc-dayGridMonth-view {
  overflow: unset;
}
.fc#calendar.day-view #details-container #minicalendar .fc-view-harness .fc-dayGridMonth-view .fc-scrollgrid tbody .fc-scrollgrid-section {
  height: auto;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-body-balanced .fc-daygrid-day-events {
  display: none;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day > .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events * {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events {
  min-height: 1px;
  height: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-event {
  background: transparent;
  border: none;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-event .fc-daygrid-event-dot {
  display: block;
  border: 3px solid var(--color-palette-primary-50, #55BAB7);
  outline: 2px solid white;
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-event .fc-event-time, .fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-event .fc-event-title {
  display: none;
}
.fc#calendar.day-view #details-container #minicalendar .fc-day.fc-day-today.fc-daygrid-day .fc-daygrid-day-events .fc-daygrid-event-dot {
  border-color: var(--Color-palette-Action-Notification, #EB5757);
}
.fc#calendar.day-view #details-container #minicalendar .fc-daygrid-day-bottom {
  display: none;
}
.fc#calendar.day-view #details-container #details {
  display: none;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 30vh;
  width: 100%;
  padding: 10px 0;
  overflow: auto;
}
.fc#calendar.day-view #details-container #details.chosen {
  display: block;
}
.fc#calendar.day-view #details-container #details #details-title {
  font-weight: 500;
  font-size: 16px;
  padding: 0 10px;
  margin: 0;
}
.fc#calendar.day-view #details-container #details table {
  border-collapse: separate;
  border-spacing: 10px;
}
.fc#calendar.day-view #details-container #details table tr td {
  border: 0;
}
.fc#calendar.day-view #details-container #details table tr td:first-child {
  opacity: 0.5;
  width: 110px;
}
.fc#calendar.day-view #details-container #details table tr.hidden {
  display: none !important;
}
.fc#calendar.day-view #details-container #details-btn {
  display: none;
  color: #092F2E;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border-radius: 12px;
  background: var(--Color-palette-Primary-30, #7BD8D5);
  transition: all 0.2s ease-in-out;
}
.fc#calendar.day-view #details-container #details-btn.chosen {
  display: block;
}
.fc#calendar.day-view #details-container #details-btn:hover {
  cursor: pointer;
  background: #54ccc8;
}
.fc#calendar .fc-day-today {
  background: #fff;
}
.fc#calendar .fc-day-today .fc-daygrid-day-top {
  width: 30px;
}
.fc#calendar .fc-day-today .fc-daygrid-day-top a {
  background: #EB5757;
  color: #fff;
  border-radius: 50%;
  padding: 0.2em 0.7em;
  transition: background-color 0.2s ease-in-out;
}
.fc#calendar .fc-day-today .fc-daygrid-day-top:hover a {
  color: #000;
  background: #f7f7f7 !important;
}
.fc#calendar .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: #fff;
  border-radius: 8px;
  background: var(--Color-palette-Action-Notification, #EB5757);
}
.fc#calendar .fc-listYear-view {
  border: 0;
}
.fc#calendar .fc-listYear-view .width-170 {
  width: 170px;
  flex: unset !important;
}
.fc#calendar .fc-listYear-view .width-100 {
  width: 100px;
  flex: unset !important;
}
.fc#calendar .fc-listYear-view tbody {
  display: flex;
  flex-direction: column;
  border-collapse: separate;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day {
  display: flex;
  overflow: hidden;
  margin: 20px;
  border-radius: 8px;
  position: sticky;
  top: 0;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day th {
  flex: 1;
  text-align: left;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day th .fc-list-day-cushion {
  background: #E6E6E6;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day th a {
  color: #000;
  font-weight: 500;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day th a:hover {
  text-decoration: none;
}
.fc#calendar .fc-listYear-view tbody .fc-list-day:first-of-type {
  z-index: 2;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event {
  display: flex;
  margin: 4px 20px;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td {
  flex: 1;
  text-align: left;
  border: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td jnct-status-badge {
  width: 142px;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td .date-element {
  border: 1px solid var(--Color-palette-Neutral-30, #BCBCBD);
  text-wrap: wrap;
  border-radius: 8px;
  width: 75px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td .date-element:first-line {
  font-weight: 500;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td .title-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event td .title-container .title-element:nth-child(2) {
  font-weight: 500;
  color: #0F75B8;
}
.fc#calendar .fc-listYear-view tbody .fc-list-event:hover td {
  background: #fff;
}
.fc#calendar .fc-daygrid-event-harness:before,
.fc#calendar .fc-daygrid-day-frame:before,
.fc#calendar .fc-daygrid-day-events:before {
  display: none;
}
.fc#calendar .fc-daygrid-event-harness:after,
.fc#calendar .fc-daygrid-day-frame:after,
.fc#calendar .fc-daygrid-day-events:after {
  display: none;
}
.fc#calendar .fc-dayGridMonth-view {
  overflow: auto;
}
.fc#calendar .fc-dayGridMonth-view .fc-scrollgrid tbody .fc-scrollgrid-section-body {
  height: 80vh;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-events {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2px;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom .fc-daygrid-more-link {
  width: 100%;
  padding: 5px;
  border-radius: var(--Medium, 4px);
  background: var(--neutral-20);
  transition: all 0.2s ease-in-out;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom .fc-daygrid-more-link:hover {
  background: #e0e2e4;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom::before, .fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom::after {
  display: none;
}
.fc#calendar .fc-dayGridMonth-view .fc-daygrid-day-frame .fc-daygrid-day-bg {
  display: none;
}
.fc#calendar .fc-popover .fc-popover-body .fc-event {
  transition: all 0.2s ease-in-out;
}
.fc#calendar .fc-popover .fc-popover-body .fc-event:hover {
  cursor: pointer;
  color: #0F75B8;
}

.top-bar {
  display: flex;
  justify-content: space-between;
}
.top-bar__assignment-counter {
  text-transform: capitalize;
}

.tabs-wrapper {
  display: inline-block;
}
.tabs-wrapper .tabs {
  display: flex;
  gap: 5px;
  justify-content: left;
  flex-direction: row;
}
.tabs-wrapper .tabs .tab {
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  padding: 10px 30px;
  background: var(--neutral-20);
  box-shadow: none;
  border-bottom: 0;
  transition: all 0.2s ease-in-out;
}
.tabs-wrapper .tabs .tab.active {
  font-weight: 500;
  background: #fff;
}
.tabs-wrapper .tabs .tab.active:hover {
  cursor: pointer;
  background: #fff;
}
.tabs-wrapper .tabs .tab:hover {
  cursor: pointer;
  background: #f7f7f7;
}

.fc td.fc-day {
  transition: all 0.2s ease-in-out;
}
.fc td.fc-day .fc-daygrid-day-top {
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
  margin-left: 4px;
}
.fc td.fc-day .fc-daygrid-day-top .fc-daygrid-day-number {
  color: #000;
  padding: 0;
}
.fc td.fc-day .fc-daygrid-day-top .fc-daygrid-day-number:hover {
  text-decoration: none;
}
.fc td.fc-day .fc-daygrid-day-events {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event {
  background: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  border-radius: 5px;
  padding: 0 0 0 5px;
  border: 2px solid var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  transition: all 0.2s ease-in-out;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event .fc-daygrid-event-dot {
  display: none;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event .fc-event-title {
  font-weight: 300;
  color: var(--neutral-90);
  margin-top: 2px;
  text-overflow: ellipsis;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event .fc-event-title.weekly {
  white-space: normal;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event.active {
  border-color: var(--color-palette-primary-30, #7BD8D5);
  background: var(--color-palette-primary-36, rgba(85, 186, 183, 0.36));
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event.fc-h-event {
  display: none;
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event:hover {
  cursor: pointer;
  background: var(--color-palette-primary-36, rgba(85, 186, 183, 0.36));
}
.fc td.fc-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-event:hover td {
  background: transparent;
}
.fc .fc-scrollgrid table {
  border-collapse: separate;
  border-spacing: 5px;
  width: 100% !important;
}
.fc .fc-scrollgrid table td.fc-day {
  border-radius: 10px;
  height: 15vh !important;
}
.fc .fc-scrollgrid-liquid {
  border: 0;
}
.fc .fc-scrollgrid-section-liquid > td {
  border: 0;
}
.fc th {
  border: 0;
}
.fc th .fc-scroller-harness:first-child .fc-scroller:first-child {
  overflow: hidden !important;
}
.fc th .fc-col-header-cell-cushion {
  color: #000;
  font-weight: 500;
  font-size: 16px;
}
.fc th .fc-col-header-cell-cushion:hover {
  text-decoration: none;
}

.fc-listDay-view {
  width: 50vw;
  border-radius: 15px;
  overflow: hidden;
  border: 0 !important;
}
.fc-listDay-view .fc-list-table {
  display: block;
}
.fc-listDay-view .fc-list-table tbody {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fc-listDay-view .fc-list-table tbody .fc-list-day th {
  display: block;
  margin: 0 auto;
}
.fc-listDay-view .fc-list-table tbody .fc-list-day th .fc-list-day-cushion {
  background: #fff;
  text-align: center;
}
.fc-listDay-view .fc-list-table tbody .fc-list-day th .fc-list-day-cushion .fc-list-day-text {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  float: unset;
}
.fc-listDay-view .fc-list-table tbody .fc-event {
  background: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  border-radius: 5px;
  margin-bottom: 5px;
  border: 2px solid var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  transition: all 0.2s ease-in-out;
}
.fc-listDay-view .fc-list-table tbody .fc-event.active {
  border-color: var(--color-palette-primary-30, #7BD8D5);
  background: var(--color-palette-primary-36, rgba(85, 186, 183, 0.36));
}
.fc-listDay-view .fc-list-table tbody .fc-event.active .fc-list-event-graphic {
  display: table-cell !important;
}
.fc-listDay-view .fc-list-table tbody .fc-event.active .fc-list-event-graphic .fc-list-event-dot {
  border-color: var(--color-palette-primary-36, rgba(85, 186, 183, 0.36));
}
.fc-listDay-view .fc-list-table tbody .fc-event:hover {
  cursor: pointer;
  background: var(--color-palette-primary-36, rgba(85, 186, 183, 0.36));
}
.fc-listDay-view .fc-list-table tbody .fc-event:hover td {
  background: transparent;
}
.fc-listDay-view .fc-list-table tbody td {
  padding: 2px 8px;
  border: 0;
}
.fc-listDay-view .fc-list-table tbody th {
  border: 0;
}

#details-container {
  display: none;
}

.dash__loading {
  height: calc(100vh - var(--header_height) - var(--full_footer_height) - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  background-color: #fff;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dash__spinner {
  animation: rotate 1s linear infinite;
  background-position: center center;
  background-repeat: no-repeat;
  height: 64px;
  width: 64px;
}`;

const mode = "open";
import { RWSViewComponent, RWSView, RWSInject, observable, attr, ngAttr} from "@rws-framework/client";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import ActivitiesService, {ActivitiesServiceInstance} from '../../services/ActivitiesService';
import CalendarService, {CalendarServiceInstance} from "../../services/CalendarService";
import {CalendarEvent, InstructorCalendarActivity, StudentCalendarActivity} from "../../types/calendar";
import EventsService, {EventsServiceInstance} from "../../services/EventsService";
import { CalendarView, DateComparator, compareDay, compareMonth, compareWeek, compareYear, isCalendarView } from './utils';

@RWSView('jnct-full-calendar', null, { template: rwsTemplate, styles, options: {mode} })
class FullCalendar extends RWSViewComponent  {
    @ngAttr courseId: string;
    ics: string;
    calendarView: CalendarView = 'dayGridMonth';
    calendarEl: HTMLElement | null;
    miniCalendarEl: HTMLElement | null;
    detailsContainer: HTMLElement | null;
    detailsBox: HTMLElement | null;
    detailsBtn: HTMLElement | null;
    calendar: Calendar | null;
    miniCalendar: Calendar | null;
    listViewHeaderDates: Set<string> = new Set();
    assignments: any[] = [];
    @attr isLoading: boolean = true;
    @attr mode: string;
    @observable calendar_data: StudentCalendarActivity[] | InstructorCalendarActivity[]
    @observable assignmentCountLabel: string = '';
    @observable showSub: boolean = false  
    @observable subConf = {}
    constructor(
        @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
        @RWSInject(CalendarService) protected calendarService: CalendarServiceInstance,
        @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
    ) {
        super();
    }

    connectedCallback () {
        super.connectedCallback();
        this.calendarView = FullCalendar.getLocalView();
        this.calendarEl = this.shadowRoot!.getElementById('calendar');
        this.miniCalendarEl = this.shadowRoot!.getElementById('minicalendar');
        this.detailsContainer = this.shadowRoot!.getElementById('details-container');
        this.detailsBox = this.shadowRoot!.getElementById('details');
        this.detailsBtn = this.shadowRoot!.getElementById('details-btn');

        setTimeout(() => {
            this.fetchData();
        }, 0)
    }

    async fetchData() {
        this.mode = await this.eventsService.getCalendarMode()
        this.calendar_data = await this.calendarService.getCalendarData(this.mode);

        this.calendar_data.forEach((activity, index) => {
          const totalGradedCount = activity.gradedCount > activity.submittedCount ? activity.gradedCount : activity.submittedCount;
          this.assignments[index] = {
            title: this.future(activity.open_date) ? activity.report_title + `<br/><span class='date-section'>Opens on: ` + new Date(activity.open_date).toLocaleString(
              `en-US`, 
              { 
                month: `short`, 
                day: `2-digit`, 
                year: `numeric`,
                hour: `2-digit`,
                minute: `2-digit`,
                hour12: true
              }) + `</span>` : activity.report_title,
            start: activity.due_date,
            url: activity.link,
            index: index,
            extendedProps: {
              type: activity.type,
              assignedDate: activity.open_date,
              submitted: activity.submissionDate,
              submittedCount: activity.submittedCount + '/' + activity.rosterCount,
              graded: activity.graded,
              gradedCount: activity.gradedCount + '/' + totalGradedCount,
              avg_score: activity.avg_score ? activity.avg_score.toString() + '%' : '0%',
              score: activity.score ? activity.score.toString() + '%' : '-',
              lesson_title: activity.lesson_title,
              open_date_in_future: activity.open_date_in_future,
              done: activity.done,
              status: activity.status
            }
          }

          // @ts-ignore
          this.ics = window.edrnaConfig.calendar.ics

          this.subConf = {
              switch: () => { this.toggleSub() },
              'https': this.ics + this.courseId + ".ics",
              'webcal': this.ics.replace('https', 'webcal') + this.courseId + ".ics"
          }

        });

        this.renderCalendar(this.calendarView);

        if ((FullCalendar.getLocalView() === 'listDay') && (this.miniCalendar == undefined)) {
          this.renderMiniCalendar();
        }
        this.shadowRoot!.querySelector('#calendar-tab')!.addEventListener('click', () => {
          this.renderCalendar('dayGridMonth');
        });
        this.shadowRoot!.querySelector('#list-tab')!.addEventListener('click', () => {
          this.renderCalendar('listYear');
        });

        this.isLoading = false;

        // Fix for proper events displaying in fullcalendar
        // it is a known issue, but not yet fixed by the devs
        setTimeout( function() {
          window.dispatchEvent(new Event('resize'))
        }, 1)
    }

    renderCalendar(view: any) {
        this.calendarView = view;
        if (this.calendar) this.calendar.destroy();
        this.calendar = new Calendar(this.calendarEl!, {
            plugins: [ dayGridPlugin, listPlugin, interactionPlugin, googleCalendarPlugin ],
            initialView: this.calendarView,
            initialDate: undefined,
            themeSystem: 'bootstrap5',
            timeZone: 'local',
            firstDay: 0,
            defaultTimedEventDuration: '00:00:00',
            aspectRatio: 2,
            views: {
                listDay: {
                    buttonText: 'Day',
                    eventClick: (e: any) => {
                        this.fetchAssignmentDetails(e);

                        let activeEvents = this.shadowRoot!.querySelectorAll('.fc-event.active');
                        activeEvents.forEach((event) => {
                            event.classList.remove('active');
                        });
                        e.el.classList.add('active');
                    }
                },
                dayGridMonth: {
                    dayMaxEvents: 2,
                    eventInteractive: true,
                },
                dayGridWeek: {
                  dayHeaderContent: (info: any) => {
                    // text: "Wed 7/31"
                    const weekDay = info.text.split(' ')[0];
                    const dayNumber = info.text.split('/')[1]
                    return weekDay + ' ' + dayNumber;
                  }
                }
            },
            headerToolbar: (this.calendarView == 'listYear' ? false : {
                left: 'prev title next',
                center: 'dayGridMonth,dayGridWeek,listDay',
                right: 'today'
            }),
            dayMaxEvents: true,
            fixedWeekCount: false,
            events: this.assignments,
            eventOrder: function(a: any, b: any) {return a.index > b.index? 1 : -1},
            eventOrderStrict: true,
            eventContent: (info) => {
              if (info.view.type !== 'listDay') {
                const additionalClass = info.view.type === "dayGridWeek" ? 'weekly' : '';
                return { html: `<div class="fc-event-title ${additionalClass}">${info.event.title}</div>` };
              } else {
                return { 
                  html: 
                    `<td class="fc-list-event-time">${info.timeText}</td>
                    <td aria-hidden="true" class="fc-list-event-graphic"></td>
                    <td class="fc-list-event-title">
                      <a href=${info.event._def.url}>${info.event.title}</a>
                    </td>` 
                };
              }
            },
            progressiveEventRendering: true,
            datesSet: (view: any) => {
                localStorage.setItem('fcLastVisitedDate', this.calendar!.getDate().toISOString());
                let currentView = view.view.type;
                localStorage.setItem("fcCurrentView", currentView);
                if (currentView === 'listDay') {
                    this.detailsBox!.classList.remove('chosen');
                    this.detailsBtn!.classList.remove('chosen');
                    if (this.miniCalendar == undefined) this.renderMiniCalendar();
                    this.calendarEl!.appendChild(this.detailsContainer!);
                    this.calendarEl!.classList.add('day-view');
                    this.miniCalendar!.gotoDate(view.startStr);
                } else {
                    this.calendarEl!.classList.remove('day-view');
                    if (this.miniCalendar) {
                        this.miniCalendar.destroy();
                        this.detailsContainer!.remove();
                        this.miniCalendar = null;
                    }
                }
                this.calendarView = view.view.type as CalendarView;
                this.updateAssignmentCountLabel();
            },
            eventDidMount: (info: any) => {
                // Workaround full calendar library applying timezone difference twice
                const displayDate = new Date(info.event._instance.range.start.getTime() + info.event._instance.range.start.getTimezoneOffset() * 60000);
                if (info.view.type === 'listYear') {
                    const calendarEvent: CalendarEvent = {
                        date: displayDate.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true }),
                        title: info.event._def.title,
                        lesson_title: info.event._def.extendedProps.lesson_title,
                        submitted: info.event._def.extendedProps.submitted,
                        submittedCount: info.event._def.extendedProps.submittedCount,
                        avg_score: info.event._def.extendedProps.avg_score,
                        score: info.event._def.extendedProps.score,
                        gradedCount: info.event._def.extendedProps.gradedCount,
                        status: info.event._def.extendedProps.status,
                        done: info.event._def.extendedProps.done,
                        open_date_in_future: info.event._def.extendedProps.open_date_in_future,
                        type: info.event._def.extendedProps.type.charAt(0).toUpperCase() + info.event._def.extendedProps.type.slice(1),
                        url: info.event._def.url,
                    }

                    info.el.innerHTML = '';
                    for (let i = 0; i < 4; i++) {
                        const columnElement = document.createElement('td');
                        columnElement.classList.add('event-column');

                        switch(i) {
                            case 0:
                                const firstElement = document.createElement('span');
                                firstElement.classList.add('date-element');
                                firstElement.textContent = calendarEvent.date;
                                const textContainer = document.createElement('div');
                                textContainer.classList.add('title-container');
                                const secondElement = document.createElement('span');
                                secondElement.classList.add('title-element');
                                secondElement.innerHTML = calendarEvent.lesson_title;

                                var e = 'a'
                                if (this.mode === 'student' && !!calendarEvent.open_date_in_future) {
                                    e = 'span'
                                }
                                const thirdElement = document.createElement(e);
                                if (e === 'a') {
                                    // @ts-ignore
                                    thirdElement.href = calendarEvent.url;
                                    thirdElement.classList.add('title-element');
                                }

                                if (!!calendarEvent.open_date_in_future) {
                                    calendarEvent.title = '<span class="material-symbols-outlined">lock</span> ' + calendarEvent.title
                                }

                                thirdElement.innerHTML = calendarEvent.title;

                                textContainer.appendChild(secondElement);
                                textContainer.appendChild(thirdElement);

                                columnElement.appendChild(firstElement);
                                columnElement.appendChild(textContainer);

                                break;
                            case 1:
                                columnElement.classList.add('width-170')
                                columnElement.textContent = this.mode === 'student' ? calendarEvent.submitted : calendarEvent.submittedCount;
                                break;
                            case 2:
                                columnElement.classList.add('width-100')
                                columnElement.textContent = this.mode === 'student' ? calendarEvent.score : calendarEvent.gradedCount;
                                break;
                            case 3:
                                columnElement.classList.add('width-170')
                                columnElement.innerHTML = this.mode === 'student' ? (calendarEvent.status ? `<jnct-status-badge type="${calendarEvent.status}"></jnct-status-badge>` : `<fluent-checkbox checked="${calendarEvent.done}" disabled></fluent-checkbox>Done`) : calendarEvent.avg_score;
                                break;
                            default:
                                break;
                        }
                        info.el.append(columnElement);
                    }

                    // Headers
                    const listEvents = this.shadowRoot!.querySelectorAll('tr.fc-list-event');
                    const headerRows = this.shadowRoot!.querySelectorAll<HTMLElement>('.fc-list-day');

                    const headerDate = displayDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
                    this.listViewHeaderDates.add(headerDate)

                    listEvents.forEach((eventRow, rowIndex) => {
                        const maxCol = eventRow.children.length;
                        headerRows.forEach((headerRow, headerIndex) => {
                          const headerDate = this.listViewHeaderDates.size > headerIndex ? Array.from(this.listViewHeaderDates)[headerIndex] : "";
                          const eventHeaders = this.mode === 'student' ? [headerDate, 'Submitted', 'Grade', 'Status'] : [headerDate, 'Submitted', 'Graded', 'Average Grade'];
                          headerRow.innerHTML = '';
                          headerRow.style.zIndex = (headerIndex + 1).toString();
                          for (let i = 0; i < maxCol; i++) {
                              const headerCell = document.createElement('th');
                              const div = document.createElement('div');
                              const linkWrapper = document.createElement('a');
                              div.classList.add('fc-list-day-cushion', 'fc-cell-shaded');
                              linkWrapper.textContent = eventHeaders[i].charAt(0).toUpperCase() + eventHeaders[i].slice(1);
                              div.appendChild(linkWrapper);
                              headerCell.appendChild(div);
                              if (i === 1 || i === 3) {
                                headerCell.classList.add('width-170')
                              }
                              if (i === 2) {
                                headerCell.classList.add('width-100')
                              }
                              headerRow.appendChild(headerCell);
                          }
                        });
                    });
                }
            },
            moreLinkClick() {
              return "day"
            },
            dateClick: (info: any) => {
              this.calendar.changeView("listDay", info.dateStr)
            }
        });
        this.calendar.render();

        if (this.calendarView === 'listYear') {
            this.shadowRoot!.getElementById('list-tab')!.classList.add('active');
            this.shadowRoot!.getElementById('calendar-tab')!.classList.remove('active');
        } else {
            this.shadowRoot!.getElementById('calendar-tab')!.classList.add('active');
            this.shadowRoot!.getElementById('list-tab')!.classList.remove('active');
        }
    }

    renderMiniCalendar() {
        this.miniCalendar = new Calendar(this.miniCalendarEl!, {
            plugins: [ dayGridPlugin, listPlugin, interactionPlugin ],
            initialView: 'dayGridMonth',
            themeSystem: 'bootstrap5',
            timeZone: 'local',
            firstDay: 0,
            defaultTimedEventDuration: '00:00:00',
            fixedWeekCount: false,
            events: this.assignments,
            dayMaxEvents: 1,
            headerToolbar: {
                left: '',
                center: 'title',
                right: ''
            },
            titleFormat: { month: 'long' },
            selectable: true,
            dayHeaderContent: (arg: any) => {
                return arg.text[0]
            },
            select: (info: any) => {
                this.navigateToDate(info.startStr);
            },
            datesSet: () => {
              const lastVisitedDate = new Date(localStorage.getItem('fcLastVisitedDate'));

              let activeEvents = this.shadowRoot!.querySelectorAll('.fc-day.active');
              activeEvents.forEach((event) => {
                event.classList.remove('active');
              });

              const targetDate = lastVisitedDate.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
              const matchingElement = this.shadowRoot!.querySelector(`td[data-date="${targetDate}"]`);
              if (matchingElement) {
                matchingElement.classList.add('active')
              }
            },
            eventClick: function(info) {
              info.jsEvent.preventDefault();
            }

        });
        this.miniCalendar.render();
    }

    navigateToDate(date: any) {
        this.detailsBox!.classList.remove('chosen');
        this.detailsBtn!.classList.remove('chosen');
        this.calendar!.gotoDate(date);
    }

    fetchAssignmentDetails(e: any) {
        e.jsEvent.preventDefault();
        this.detailsBox!.classList.add('chosen');
        this.detailsBtn!.classList.add('chosen');

        const title = this.shadowRoot!.getElementById('details-title');
        const type = this.shadowRoot!.getElementById('details-type');
        const opens = this.shadowRoot!.getElementById('details-opens');
        const due = this.shadowRoot!.getElementById('details-due');
        const submitted = this.shadowRoot!.getElementById('details-submitted');
        const submitted_count = this.shadowRoot!.getElementById('details-submitted-count');
        const graded = this.shadowRoot!.getElementById('details-graded');
        const average = this.shadowRoot!.getElementById('details-average');
        if (this.mode === 'instructor') {
          submitted!.parentElement.classList.add('hidden');
        } else {
          type!.parentElement.classList.add('hidden');
          submitted_count!.parentElement.classList.add('hidden');
          graded!.parentElement.classList.add('hidden');
          average!.parentElement.classList.add('hidden');
        }

        if (!e.event._def.extendedProps.assignedDate || e.event._def.extendedProps.assignedDate < "1970-01-02T23:59:59.999Z") {
          opens!.parentElement.classList.add('hidden')
        }
        
        title!.innerHTML = e.event._def.title;
        type!.textContent = e.event._def.extendedProps.type.charAt(0).toUpperCase() + e.event._def.extendedProps.type.slice(1);
        opens!.innerHTML = new Date(e.event._def.extendedProps.assignedDate).toLocaleString(
          'en-US', 
          { 
            month: 'short', 
            day: '2-digit', 
            year: 'numeric',
            hour: `2-digit`,
            minute: `2-digit`,
            hour12: true
          });
        due!.textContent = e.event._instance.range.end.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
        submitted!.textContent = e.event._def.extendedProps.submitted;
        submitted_count!.textContent = e.event._def.extendedProps.submittedCount;
        graded!.textContent = e.event._def.extendedProps.gradedCount;
        average!.textContent = e.event._def.extendedProps.avg_score;
        this.detailsBtn!.setAttribute('href', e.event._def.url);
    }

    private future(open_date): boolean {
        if (!open_date || open_date == "" || open_date < new Date().toISOString()) return false;
        return true
    }

    private static getLocalView(): CalendarView {
      const localView = localStorage.getItem("fcCurrentView");

      if (!isCalendarView(localView)) return 'dayGridMonth';
      return localView;
    };

    private getEventsInView() {
      const events = this.calendar.getEvents();
      const viewDate = this.calendar.getDate();

      const viewCompareFunctions = {
          listYear: compareYear,
          dayGridMonth: compareMonth,
          dayGridWeek: compareWeek,
          listDay: compareDay,
      } satisfies Record<CalendarView, DateComparator>;
      const compare = viewCompareFunctions[this.calendarView];

      // NOTE: the week filter works properly as long as the calendar's firstDay
      // property is unset so it defaults to localised first day of the week.
      // If this becomes a setting configured by the user, it will be necessary
      // to include the firstDay's value provided in the renderCalendar method
      // here and change the compareWeek function accordingly

      return events.filter(event => compare(event.start, viewDate));
  }

  private updateAssignmentCountLabel() {
      const viewUnits = {
          listYear: 'year',
          dayGridMonth: 'month',
          dayGridWeek: 'week',
          listDay: 'day',
      } satisfies Record<CalendarView, string>;
      const unit = viewUnits[this.calendarView];

      const count = this.getEventsInView().length;
      const label = `${count} assignment${count === 1 ? '' : 's'} this ${unit}`;

      this.assignmentCountLabel = label;
  }

  allow_subscribe() {
    return !window.labelBundle.suppress_insights_calendar
  }

  toggleSub() {
    this.showSub = !this.showSub
  }
}

FullCalendar.defineComponent();

export{
    FullCalendar
}
