import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<i style="--jnct-icon-font-size: ${ x => x.size}; width: ${ x => x.size}; height: ${ x => x.size}; --icon-color: ${ x=> x.color}; --icon-weight:${x => x.weight};"><slot></slot></i>
${T.when(x => x.familyName, T.html`--jnct-icon-font-family: ${ x => x.familyName}; `)}`;

import './styles/layout.scss';
const styles = T.css`i {
  font-family: var(--material-font-family, "Material Icons");
  font-weight: normal;
  font-style: normal;
  font-size: var(--jnct-icon-font-size, 24px);
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
  font-weight: var(--icon-weight, "normal") !important;
  color: var(--icon-color, "inherit");
}

span {
  font-weight: var(--icon-weight, "normal") !important;
  margin-left: 8px;
  color: var(--icon-color, "inherit");
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent, attr } from '@rws-framework/client';

@RWSView('jnct-icon', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class Icon extends RWSViewComponent  {
    @attr iconFamily: string | null = null; 
    @attr size: string = '32px';
    @attr weight: string = 'normal';   
    @attr color: string = 'inherit';
}

Icon.defineComponent();

export { Icon };