import {ApiService, ApiServiceInstance, RWSService} from '@rws-framework/client';
import {ScoreRecord} from "../types/activities";
import {LateWorkStatus} from "../types/late-work";
import { IConvo } from '@backend-types';
import { WebChat } from '../components/webchat/component';

type PYApiOptions = {
  method?: string,
  headers: {
    [key: string]: unknown
  }
}

class PyBackApiService extends RWSService {
  // constructor(@ApiService private apiService: ApiServiceInstance) {
  //   super();
  // }

  async get<T = unknown>(url: string, options: PYApiOptions = { headers: {}, method: 'GET' }): Promise<T>
  {
    const APIprefix = (window as any).edrnaConfig.host + (window as any).edrnaConfig.serverPrefix;
     
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    const requestOptions = {
      method: options.method,
      headers: {
        ...(options.headers),
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    };

    const response = await fetch(
      APIprefix + url,
      requestOptions
    );

    return await response.json();
  }
}

export default PyBackApiService.getSingleton();

export {PyBackApiService as PyBackApiServiceInstance}
