import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="header">
  <h1 id="header">
    <span class="material-symbols-outlined" aria-hidden="true"> grading </span>
    Gradebook
  </h1>
  ${T.when(x => x.isAdmin, T.html`
    <fluent-tooltip
      anchor="header"
      position="right"
      delay="100">
      Cached on ${x => x.cached}
    </fluent-tooltip>
  `)}
</header>

<section class="gradebook">
  ${T.when(x => !x.loaded, T.html`
    <section class="loader">
      <jnct-loading-spinner></jnct-loading-spinner>
    </section>
  `)}
  ${T.when(x => x.loaded, T.html`
  <div class="gradebook__actions">
    <jnct-dropdown
      name="Actions"
      :action="${x => x.actionsHandler}"
      :options="${x => x.actionsList}">
    </jnct-dropdown>

    <div class="actions">
      ${T.when(x => x.selectedall || x.selected_students.length, T.html`
        <button title="Unenroll Selected Students" class="button__secondary" @click="${x => x.unenroll()}">
          Unenroll
        </button>
        <button title="Send Email to Selected Students" class="button__secondary" @click="${x => x.sendEmail()}">
          Send Email
        </button>
        ${T.when(x => x.isAdmin, T.html`
        <button title="Purge Selected Students Caches" class="button__secondary" @click="${x => x.purge()}">
          Re-Load Cache
        </button>
        `)}
      `)}
      <div class="actions__switch">
        <div
          class="actions__switch--item ${x => x.viewP == true ? '' : 'active'}"
          role="button"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.toggleViewP() : ''}"
          @click="${x => x.toggleViewP()}">
          Points
        </div>
        <div
          class="actions__switch--item ${x => x.viewP == true ? 'active' : ''}"
          role="button"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.toggleViewP() : ''}"
          @click="${x => x.toggleViewP()}">
          Percentage
        </div>
      </div>
      ${T.when(x => (!x.no_total_column && x.assessments_length > 1) || (x.no_total_column == true && x.assessments_length > 0), T.html`
      <fluent-select value="${x => x.viewL}" @change="${(x, c) => x.toggleGroupBy(c.event)}">
        ${T.repeat(x => x.groupByList, T.html`
        <fluent-option
          class="dropdown__option"
          value="${x => x.value}">
            ${x => x.name}
        </fluent-option>
        `)}
      </fluent-select>
      `)}
      <button
        class="button__secondary"
        tabindex="0"
        @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.reloadData() : ''}"
        @click="${x => x.reloadData()}">
        <span class="material-symbols-outlined" aria-hidden="true"> refresh </span>
        Reload
      </button>
    </div>
  </div>
  `)}
</section>

<div class="container"> 
  <table class="gradebook-table gradebook-table-main ${x => x.loaded ? '' : 'none'}"  id="gradebook">
    <tr class="head-label">
      <td
        class="head-label-td col-student-name ${x => x.viewL ? 'higher' : ''}"
        data-name="student"
        aria-label="sort by student"
        tabindex="0">
        <div class="head-label-flex">
        <jnct-searchbar :handleChange="${x=>x.search}" size="medium"></jnct-searchbar>
        <div class="flex__center p-left-16">
          <div class="flex__gap-8">
            <fluent-checkbox  @change="${x => x.toggleSelectAll()}"></fluent-checkbox>
            Student Name
          </div>
          <jnct-sort
            :isActive="${x => x.selected.sort_column === 'student'}"
            :currentSorting="${x => x.selected.sort_column == 'student' ? x.sort : ''}"
            :sortData="${x => x.setActiveAndSortHandler}"
            value="student"
            class="flex">
          </jnct-sort>
        </div>
      </div>
      </td>
      <td class="hiddenscroller"></td>
      <td class="assessment-data" rowspan="${x => x.sizeOf()}">
        <div class="empty ${x => !x.no_total_column && x.assessments_length > 1 ? 'none' : ''} ${x => x.no_total_column == true && x.assessments_length > 0 ? 'none' : ''}">
          No Graded Activities
        </div>
        <div class="v-scroll-wrapper">
          <table class="gradebook-table ${x => !x.no_total_column && x.assessments_length == 1 ? 'none' : ''} ${x => x.assessments_length == 0 ? 'none' : ''}">
            <tr class="head-label ${x => x.viewL ? 'higher' : ''}">
              ${T.repeat(x => x.assessments, T.html`
              <td
                data-name="assessment-${(x, c) => c.index}"
                class="head-label-td ${(x, c) => c.parent.selected.column == 'assessment-' + c.index ? 'active' : ''} ${(x, c) => x.no_show ? 'no_show_assessment' : ''} ${(x, c) => (c.parent.viewL ? x.expanded : '') + ' rollup_color_' + x.id}"
                tabindex="0">
                 
                ${T.when(x => x.total_type === 'total', T.html`
                  <div class="header__total">
                    <div>${(x, c) => c.parent.viewP ? '' : 'Total Grade'}</div>
                    <div class="flex__center">
                      <div>${(x, c) => c.parent.viewP ? 'Average Grade' : 'Out of ' + x.max_score}</div>
                      <jnct-sort
                        :isActive="${(x, c) => c.parent.selected.sort_column === 'assessment-' + c.index}"
                        :currentSorting="${(x, c) => c.parent.selected.sort_column == 'assessment-' + c.index ? c.parent.sort : ''}"
                        :sortData="${(x, c) => c.parent.setActiveAndSortHandler}"
                        value="${(x, c) => c.index}"
                        class="flex">
                    </jnct-sort>
                    </div>
                  </div>
                `)}

                ${T.when(x => x.total_type !== 'total', T.html`
                  <div class="header__flex ${x => x.type === 'lesson' ? 'height-134' : ''}">
                    ${T.when(x => x.type === 'lesson', T.html`
                      <div class="lesson__flex">
                        <span :innerHTML="${x => x.report_title}" class="lesson__ellipsis" id="${x => x.id}"></span>
                        <a
                          href
                          @click="${(x, c) => { c.event.preventDefault(); c.parent.toggleLesson(x, 'assessment-' + c.index); }}" 
                          @keyup="${(x, c) => { if ((c.event as KeyboardEvent).keyCode === 13) { c.event.preventDefault(); c.parent.toggleLesson(x, 'assessment-' + c.index); } }}"
                          aria-label="toggle columns"
                          class="expand link rotated">
                          <span class="material-symbols-outlined">${x => x.state === 'open' ? 'expand' : 'unfold_less'}</span>
                        </a>
                      </div>
                      <fluent-tooltip
                        anchor="${x => x.id}"
                        class="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : ''}"
                        position="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : 'right'}"
                        delay="100" 
                        :innerHTML="${x => x.report_title}">
                      </fluent-tooltip>
                    `)}

                    <div class="header__due">
                      ${T.when(x => x.display_due_date !== '-/-' && x.type !== 'lesson', T.html`
                        Due <span class="color-90">${x => x.display_due_date}</span>
                      `)}
                      ${T.when(x => x.type === 'lesson', T.html`
                        ${x => x.pages?.length} items
                      `)}
                    </div>
                    ${T.when(x => x.type === 'lesson', T.html`
                      <span class="link__ellipsis"></span>
                    `)}
                    ${T.when(x => x.type !== 'lesson' && x.actual !== 'custom', T.html`
                      <a 
                        href="${(x, c) => x.link}"
                        target="_blank"
                        class="link">
                        <span  class="link__ellipsis">
                          ${(x, c) => ((!c.parent.viewL && x.lesson_num && !c.parent.no_lesson_prefix) ? c.parent.lesson_unit + x.lesson_num + ': ' : '')}
                          <span :innerHTML="${x => x.report_title}" id="${x => x.id}"></span>
                        </span>

                        <fluent-tooltip
                          anchor="${x => x.id}"
                          class="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : ''}"
                          position="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : 'right'}"
                          delay="100" 
                          :innerHTML="${x => x.report_title}">
                        </fluent-tooltip>
                      </a>
                    `)}
                    ${T.when((x, c) => x.type !== 'lesson' && x.actual === 'custom', T.html`
                    <a 
                      href
                      @click="${(x, c) => c.parent.assistants_mgr_rights? c.parent.editColumn(c.index) : null}"
                      target="_blank"
                      class="link">
                      <span  class="link__ellipsis">
                        ${(x, c) => ((!c.parent.viewL && x.lesson_num && !c.parent.no_lesson_prefix) ? c.parent.lesson_unit + x.lesson_num + ': ' : '')}
                        <span :innerHTML="${x => x.report_title}" id="${x => x.id + '1'}"></span>
                      </span>

                      <fluent-tooltip
                        anchor="${x => x.id + '1'}"
                        class="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : ''}"
                        position="${(x, c) => c.index > c.parent.assessments.length - 3 ? 'left' : 'right'}"
                        delay="100" 
                        :innerHTML="${x => x.report_title}">
                      </fluent-tooltip>
                    </a>
                  `)}
                    <div class="flex__center">
                      Out of ${x => x.max_score || '-'}
                      <jnct-sort
                        :isActive="${(x, c) => c.parent.selected.sort_column.includes(c.index)}"
                        :currentSorting="${(x, c) => c.parent.selected.sort_column.includes(c.index) ? c.parent.sort : ''}"
                        :sortData="${(x,c) => c.parent.setActiveAndSortHandler}"
                        value="${(x, c) => c.index}"
                        class="flex">
                      </jnct-sort>
                    </div>
                  </div>
                `)}
              </td>
              `, { positioning: true, recycle: true })}
            </tr>
            
            <tr class="head-desc">
              ${T.repeat(x => x.assessments, T.html`
              <td
                data-name="assessment-${(x, c) => c.index}"
                class="assessment ${(x, c) => !c.index ? 'total' : ''} ${(x, c) => c.parent.selected.column == 'assessment-' + c.index ? 'active' : ''} ${(x, c) => x.no_show ? 'no_show_assessment' : ''} ${(x, c) => (c.parent.viewL ? x.expanded : '') + ' rollup_color_' + x.id}">
                  ${T.when((x, c) => c.index && x.type != 'lesson', T.html`
                    <span class="bold">${(x, c) => c.parent.viewP ? (((x.average*100)/x.max_score).toFixed(1)+ '%') : x.average.toFixed(1)}<span>
                  `)}
                  ${T.when((x, c) => !c.index && x.type != 'lesson', T.html`
                    <span>
                      ${(x, c) => x.percent.toFixed(1) + '%'}
                    </span>
                  `)}
                  ${T.when((x, c) => x.type == 'lesson', T.html`
                    <span>
                      ${(x, c) => x.percent.toFixed(1) + '%'}
                    </span>
                  `)}
              </td>
              `, { positioning: true, recycle: true })}
            </tr>
            <tr class="student-data">
              <td
                class="student-data-td"
                colspan="${x => x.assessments_length}"
                style="height: ${x => x.tableHeight + 'px'}">
                <div class="h-scroll-wrapper top">
                  <table class="data-table gradebookcells" id="gradebookcells"><tbody></tbody></table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>

    <tr class="head-desc">
      <td data-name="student" class="assessment assessment__class">Class Average</td>
      <td class="hiddenscroller"></td>
    </tr>
    <tr class="student-data">
      <td
        colspan="2"
        class="student-data-td tdabovebottom"
        :style="height: ${x => x.tableHeight + 'px'}">
        <div class="h-scroll-wrapper bottom">
          <table class="data-table" style="width: 238px;">
            ${T.repeat(x => x.order, T.html`
            <tr class="student-row" style="width: 238px;">
              <td data-name="student" class="col-student padding-0-24">
                <fluent-checkbox :checked="${(x, c) => c.parent.selectedall}" @change="${(x, c) => c.parent.selectSudent(c.parent.students[x])}"></fluent-checkbox>
                <span class="link" @click="${(x, c) => c.parent.gotostudent(c.parent.students[x])}">${(x, c) => c.parent.students[x]?.lastname }, ${(x, c) => c.parent.students[x]?.firstname }</span>
              </td>
            </tr>
            `, { positioning: true, recycle: true })}
          </table>
        </div>
      </td>
    </tr>
  </table>
</div>

${T.when(x => x.showModal && x.modalConfig, T.html`
  <jnct-modal
    templateName="jnct-gradebook-custom-column"
    :genericConfig="${x=>x.modalConfig}"
  ></jnct-modal>
`)}`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  display: flex;
  width: 207px;
  min-width: 207px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: none;
}
fluent-select.big {
  width: 100%;
}

fluent-select::part(control), fluent-select::part(listbox) {
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

fluent-select::part(control) {
  height: 40px;
}
fluent-select::part(control):hover {
  border: 1px solid var(--neutral-30);
}
fluent-select::part(control):focus, fluent-select::part(control):active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

fluent-select::part(listbox) {
  padding: 2px 0px;
  background: white;
  border: none;
}

fluent-option {
  padding: 8px 12px;
  border-radius: unset;
  border: none;
}
fluent-option.selected {
  font-weight: 500;
  border-left: 2px solid #00BBB5;
  background: rgba(0, 193, 185, 0.08);
}
fluent-option.selected::before {
  display: none;
}
fluent-option:not(.selected):hover {
  font-weight: 400;
  background: var(--Color-Neutral-Transparent-neutral-a8, rgba(153, 160, 167, 0.08));
  background: var(--Color-Neutral-Transparent-neutral-a8, color(display-p3 0.6078 0.6275 0.6471/0.08));
}

fluent-tooltip::part(tooltip) {
  display: block;
  position: relative;
  padding: 8px 16px;
  left: 12px;
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--neutral-80);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
  font-family: inherit;
}

fluent-tooltip::part(tooltip)::after {
  left: -6px;
  background-color: var(--neutral-80);
  border: none;
}

fluent-tooltip.left::part(tooltip)::after {
  right: -6px;
  left: unset;
  background-color: var(--neutral-80);
  border: none;
}

* {
  box-sizing: border-box;
}

.header {
  margin: 14px 22px 32px;
  display: flex;
  justify-content: space-between;
}
.header h1 {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin: 0px;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.gradebook__actions {
  margin-bottom: 24px;
  display: flex;
  padding: 0px 22px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.gradebook .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.gradebook .actions__switch {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--neutral-20, #EEEFF0);
}
.gradebook .actions__switch--item {
  display: flex;
  width: 106px;
  padding: 4px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: var(--neutral-90);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}
.gradebook .actions__switch--item.active {
  background-color: white;
  color: var(--neutral-90);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

fluent-select::part(control) {
  color: var(--neutral-90);
  font-size: 1.14286em;
  font-weight: 500;
  line-height: 160%;
}
fluent-select::part(control)::before {
  content: "Group by:";
  color: var(--neutral-70);
  font-weight: 400;
  margin-right: 4px;
}

.container {
  height: calc(100vh - 178px);
}

.gradebook-table {
  box-sizing: border-box;
  width: calc(100vw - var(--navigation));
  max-width: calc(100% + 20px);
  table-layout: fixed;
  border-spacing: initial;
}
.gradebook-table-main {
  margin: 0px -10px;
}
.gradebook-table.none {
  display: none;
}
.gradebook-table .head-label-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.gradebook-table .head-label > td {
  box-sizing: border-box;
  height: 112px;
  width: 146px;
  padding: 8px;
  border: 1px solid var(--neutral-20);
  color: var(--neutral-60);
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  white-space: nowrap;
}
.gradebook-table .head-label > td.opened {
  border-top: 2px solid var(--primary_color);
}
.gradebook-table .head-label > td.lesson_assessment {
  padding: 40px 0px 0px 0px;
  height: 154px;
  border: none;
  border-top: 2px solid var(--primary_color);
}
.gradebook-table .head-label > td.lesson_assessment .header__flex {
  box-sizing: border-box;
  border-top: 1px solid var(--neutral-20);
  border-right: 1px solid var(--neutral-20);
  padding: 8px 8px 8px 8px;
}
.gradebook-table .head-label > td.total {
  cursor: default;
}
.gradebook-table .head-label > td a.expand {
  overflow: visible;
}
.gradebook-table .head-label > td a.expand:hover, .gradebook-table .head-label > td a.expand.active, .gradebook-table .head-label > td a.expand.active a:hover, .gradebook-table .head-label > td a.expand.active a:focus, .gradebook-table .head-label > td a.expand:focus {
  text-decoration: none;
}
.gradebook-table .head-label > td a.expand .material-symbols-outlined {
  font-size: 18px;
  font-weight: 600;
}
.gradebook-table .head-label > td.assessment-data {
  width: 100%;
  background-color: white;
  padding: 0px 15px 0px 0px;
  position: relative;
  overflow: visible;
}
.gradebook-table .head-label > td.assessment-data .v-scroll-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
}
.gradebook-table .head-label > td.assessment-data .gradebook-table {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.gradebook-table .head-label > td.assessment-data .gradebook-table .data-table {
  width: calc(100% + 2px);
  border-spacing: initial;
}
.gradebook-table .head-label > td.assessment-data .gradebook-table .data-table .col-student {
  text-align: center;
  margin: 0;
  width: 238px;
}
.gradebook-table .head-label > td.assessment-data .gradebook-table .data-table .col-student.total {
  cursor: default;
}
.gradebook-table .head-label > td.assessment-data .gradebook-table .no_show_assessment {
  display: none;
}
.gradebook-table .header__total {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.gradebook-table .header__flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.gradebook-table .header__flex.height-134 {
  height: 134px;
}
.gradebook-table .header__due {
  height: 1.7em;
}
.gradebook-table td.hiddenscroller {
  padding: 0;
  width: 0px;
}
.gradebook-table .link {
  white-space: normal;
  overflow: hidden;
}
.gradebook-table .link__ellipsis {
  text-overflow: ellipsis;
  height: 3.2em;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-box-orient: vertical;
}
.gradebook-table .assessment {
  box-sizing: border-box;
  height: 48px;
  background-color: var(--neutral-a8, rgba(155, 160, 165, 0.08));
  color: var(--neutral-60);
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  padding: 0px 16px;
}
.gradebook-table .assessment.total {
  padding: 0px 24px;
}
.gradebook-table .assessment .bold {
  color: var(--neutral-90);
  font-weight: 500;
}
.gradebook-table .assessment__class {
  color: var(--neutral-90);
  padding: 12px 12px 12px 56px;
}
.gradebook-table .student-data-td {
  position: relative;
  overflow: hidden;
}
.gradebook-table .student-data-td .h-scroll-wrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.gradebook-table .student-data-td .h-scroll-wrapper.top::-webkit-scrollbar {
  width: 0 !important;
}
.gradebook-table .data-table {
  table-layout: fixed;
  border-spacing: 0px;
}
.gradebook-table .student-row {
  height: 48px;
}
.gradebook-table .student-row > td {
  height: 48px;
  width: 146px;
  padding: 0px 16px;
  border-right: 1px solid var(--neutral-20);
  border-bottom: 1px solid var(--neutral-20);
  color: var(--neutral-90);
  font-family: inherit;
  font-size: 1em;
  font-weight: 500;
  line-height: 170%;
}
.gradebook-table .student-row > td.input-container {
  padding: 0px 11px;
}
.gradebook-table .student-row .total {
  padding: 0px 24px;
  font-weight: 400;
}
.gradebook-table .col-student {
  box-sizing: border-box;
  width: 238px;
  height: 48px;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-right: 1px solid var(--neutral-20);
  border-bottom: 1px solid var(--neutral-20);
}
.gradebook-table .col-student.padding-0-24 {
  width: 100%;
  padding: 0px 24px;
}
.gradebook-table .col-student-name.head-label-td {
  width: 238px;
  color: var(--neutral-60);
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.gradebook-table .col-student-name.head-label-td.higher {
  height: 154px;
}

.higher {
  height: 154px;
}

.lesson__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 8px;
}
.lesson__ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-left-16 {
  padding-left: 16px;
}

.flex {
  display: flex;
}
.flex__sp-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.flex__gap-4 {
  display: flex;
  align-items: center;
  gap: 4px;
}
.flex__gap-8 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.flex__center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.color-90 {
  color: var(--neutral-90);
}

.dropdown__icon {
  opacity: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  font-weight: 300;
  border-radius: 8px;
  cursor: pointer;
}
.dropdown__icon:hover {
  background: var(--neutral-20);
}
.dropdown:hover .dropdown__icon {
  opacity: 1;
}

.rotated {
  transform: rotate(90deg);
}

.empty {
  color: var(--neutral-40);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
}

.none {
  display: none;
}

.span__flex {
  display: flex;
  gap: 4px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, RWSInject, observable, attr, ngAttr } from "@rws-framework/client";
import EventsService, {EventsServiceInstance} from "../../services/EventsService";
import TimeFormatService, {TimeFormatServiceInstance} from "../../services/TimeFormatService";
import JunctionUserService, {JunctionUserServiceInstance} from "../../services/JunctionUserService";
import {RosterRecord} from "../../types/users";
import {Course} from "../../types/course";
import {ListType} from "../../types/list";
import { GradebookDropdownActions } from './dropdownActions';
import { GradebookIcons } from './icons';
import { ConfirmationModal } from '../confirmationModal/component';

interface Selected {
  students: {};
  column: string;
  sort_column: string;
  all: boolean;
  some: boolean;
  custom?: any;
  assessment?: any;
}

@RWSView('gradebook-instructor', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradebookInstructor extends RWSViewComponent  {
  @observable loaded: boolean = false;
  //@ts-ignore
  @observable viewP: boolean = "true" === Cookies.get('viewP');
  //@ts-ignore
  @observable viewL:any = Cookies.get('viewL') || '';
  //@ts-ignore
  @observable lesson: string = window.labelBundle.lesson || 'Lesson';
  //@ts-ignore
  @observable no_lesson_prefix: any = window.labelBundle.no_lesson_prefix;
  //@ts-ignore
  @observable lesson_unit: string = window.labelBundle.lesson_unit || 'L';
  @observable selected: Selected = {
      students: {},
      column: '',
      sort_column: 'student',
      all: false,
      some: false
  };
  @observable tableHeight: number = window.innerHeight - 109;
  //change it
  @observable assessments_length: any;
  @observable actionsList: ListType[] = [
    {
      name:'Add a Custom Column',
      value:'addColumn'
    },
    {
      name: 'Edit Custom Groups',
      value:'editGroups',
    },
    {
      name: 'Download CSV',
      value:'downloadCSV',
    }
  ];
  @observable groupByList: ListType[] = [
    {
      name: 'Activity',
      value: '',
    },
    {
      name: this.lesson,
      value: 'lesson',
    },
  ];
  @observable assessments: any = [];
  @observable students: any;
  @observable order: any = [];
  @observable course: Course;
  @observable has_rollup: boolean;
  @observable cached: string;
  @observable myself: any;
  @observable sessions: any = {};
  @observable reports: any = {};
  @observable lesson_star: any = {};
  @observable averageTime: any;
  @observable sort: string = 'asc';
  @observable user_scores: any;
  //TODO delete after moving email, unenroll, reload cache events and delete checkboxes in html
  @observable selectedall: boolean = false;
  //TODO delete after moving email, unenroll, reload cache events
  @observable selected_students: any[] = [];
  @observable originalOrder: any = [];
  @observable isAdmin:boolean = false;
  @observable assistants_mgr_rights:boolean = false;
  @observable showModal: boolean = false;
  @observable modalConfig: {};
  save_points_handler = this.save_points.bind(this);
  actionsHandler = this.actions.bind(this);
  reloadDataHandler = this.reloadData.bind(this);
  no_total_column: boolean
  constructor(
    @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
    @RWSInject(TimeFormatService) protected timeFormatService: TimeFormatServiceInstance,
    @RWSInject(JunctionUserService) protected junctionUserService: JunctionUserServiceInstance,
  ) {
    super();
  }


  async connectedCallback () {
    super.connectedCallback()

    document.documentElement.addEventListener('gradebook:reload:data', this.reloadDataHandler, true);

    setTimeout(() => {
      this.getCourseData();
    }, 0)
  };

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.documentElement.removeEventListener('gradebook:reload:data', this.reloadDataHandler, true);
  }

  setActiveAndSortHandler = this.setActiveAndSort.bind(this);

  toggleViewP() {
    this.viewP = !this.viewP;
    //@ts-ignore
    Cookies.set('viewP', this.viewP);
    this._render_table(this.order, this.assessments)
  };

  reloadData(keepOrder:boolean = false, fromSwitch:boolean = false) {
    this.$emit('jnct:dashboardService:gradebookflush', fromSwitch);
    this.getData(keepOrder, fromSwitch);
  };

  // keep instructors logged in as long as their devices do not go offline (eg sleep)
  loggedIn = setInterval(() => {
    if (window.location.hash.indexOf("reports/grades") < 0) {
      clearInterval(this.loggedIn);
      return;
    };
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    fetch(APIprefix + 'noop', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result?.status === 'ok') {
        return;
      }
      clearInterval(this.loggedIn);
      this.$emit('jnct:junctionuser:logout');
    })
    .catch(() => {
      console.log("Probably not good that we cannot get there but may be a temporary hiccup")
    })
  }, 600000);

  // helper to set the correct rowspan on the left student wrapper table td
  sizeOf () {
    if (!this.students) {
      return 4;
    }
    return Object.keys(this.students).length + 2;
  };

  //actions dropdown button
  actions(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as HTMLSelectElement;
    const value = target.value;
    switch(value) {
      case 'addColumn':
        const config = GradebookDropdownActions.getModalConfig(this.course, this.assessments);
        if (!config) return;
        this.modalConfig = {
          switch: () => { this.showModal = false},
          config,
          courseId: this.course._id
        }
        this.showModal = true;
        break;
      case 'editGroups':
        const link = '#/' + this.course._id + '/rollupmanager';
        this.$emit('jnct:navigation:rollupmanager');
        break;
      case 'downloadCSV':
        GradebookDropdownActions.getCSV(this.assessments, this.viewL, this.viewP, this.reports, this.myself, this.students, this.course.title);
        break;
    };

    //reset the selected option
    target.value = '';
    return;
  };

  editColumn(index: string) {
    const config = GradebookDropdownActions.getModalConfig(this.course, this.assessments, index);
    if (!config) return;
    this.modalConfig = {
      switch: () => { this.showModal = false},
      config,
      courseId: this.course._id
    }
    this.showModal = true;
  }

  toggleGroupBy(e) {
    const view = e.target? e.target.value : e;
    const temp_view = this.viewL;
    //@ts-ignore
    Cookies.set('viewL', view);
    this.viewL = view;
    for (let i = 0; i < this.assessments.length; i++) {
      let activity = this.assessments[i];
      activity.no_show = this.viewL ? (activity.lesson && activity.type !== 'lesson') : (activity.type == 'lesson');

      const assessmentElements = this.shadowRoot.querySelectorAll('td[data-name="assessment-' + i + '"]');
      for (let j = 0; j < assessmentElements.length; j++) {
        const e = assessmentElements[j];
        if (activity.no_show) {
          e.classList.add('no_show_assessment');
        } else {
          e.classList.remove('no_show_assessment');
        };
        if (!this.viewL) {
          activity.state = 'closed';
          e.classList.remove('lesson_assessment');
          e.classList.remove('opened');
        }
      }
    };

    this.assessments_length = this.assessments.filter(a => !a.no_show).length;
	  if (view == "custom" && temp_view !== "custom" || view !== "custom" && temp_view === "custom") {
      // keep sort order and signal that it comes from the switch
      this.loaded = false;
      this.reloadData(true, true);
    }
  };

  gotostudent(student: RosterRecord):void {
    const link = '#/' + this.course._id + '/report_student_deepdive/' + student.id;
    this.$emit('jnct:openlink:blank', link)
  }

  sortByAssessment(assessmentIndex: string): void {
    const newOrder = [...this.order];

    newOrder.sort((a, b) => {
      let aMark = this.reports[a][assessmentIndex];
      let bMark = this.reports[b][assessmentIndex];

      // Handle special case for assessmentIndex '0'
      if (assessmentIndex === '0' && this.viewP) {
        aMark = this.students[a].percent;
        bMark = this.students[b].percent;
      }

      // Treat '-' as undefined
      if (aMark === '-') aMark = undefined;
      if (bMark === '-') bMark = undefined;

      if (aMark === undefined) return 1;
      if (bMark === undefined) return -1;

      // Compare numerical values
      if (aMark === bMark) return 0;
      if (aMark > bMark) return this.sort === 'desc' ? 1 : -1;
      if (aMark < bMark) return this.sort === 'desc' ? -1 : 1;
    });
    const instructor_inx = newOrder.indexOf(this.myself.id);
    let element = newOrder.splice(instructor_inx, 1)[0];
    
    // Push the removed instructor to the end of the array
    newOrder.push(element);
    this.order = newOrder;
    this._render_table(newOrder, this.assessments);
  };

  sortByStudentData(attr: string, attr2: string): void {
    const newOrder = this.order;
    newOrder.sort((a, b) => {
      let aName = this.students[a][attr] + (attr2?this.students[a][attr2]:"");
      let bName = this.students[b][attr] + (attr2?this.students[b][attr2]:"");

      return aName.localeCompare(bName);
    });

    if (this.sort === 'asc') {
      newOrder.reverse();
    } else {
      newOrder.push(newOrder.shift());
    }

    this.order = newOrder;
    this._render_table(newOrder, this.assessments)
  };

  setActiveAndSort(sort: string): void {
    if (sort === 'student') {
      this.setActive(sort, false);
      this.sortByStudentData('lastname', 'firstname');
    } else {
      const assessment = 'assessment-' + sort;
      this.setActive(assessment, false);
      this.sortByAssessment(sort);
    }
  };

  // toggle the expanded/collapsed view of the lesson/custom rollup column
  toggleLesson(rollup_column, label) {
    // This *obviously* makes all the other columns not active in the sense that the expanded widths are removed
		// Slightly different queries but akin to calling align_gradebook_columns for all but the clicked rollup column
    this.setActive(label, true);
    let h = this.shadowRoot.querySelectorAll('.gradebook-table tr.head-label > td[data-name^=assessment]:not([data-name='+label+'].active)');
    let c = this.shadowRoot.querySelectorAll('.gradebook-table tr.student-row td[data-name^=assessment]:not([data-name='+label+'].active)');
    for (let i = 0; i < h.length; i++) {
      //@ts-ignore
      h[i].style.width = "";
    }
    for (let i = 0; i < c.length; i++) {
      //@ts-ignore
      c[i].style.width = "";
    }
		// rollup_column here is the rolled up column (lesson, custom)
    rollup_column.state = rollup_column.state === 'closed'? 'open' : 'closed';
    let expanded = rollup_column.state == 'closed'? undefined : (rollup_column.lesson_num % 2)? 'colored-alternate' : 'colored';
    if (expanded && rollup_column.hasOwnProperty('rollup_id')) { // ROLLUP special color treatment
      expanded = "rollup_color_" + rollup_column.id;
    }
		this.assessments.forEach((entry,i) => {
      // ROLLUP check for rollup_id here to cover that case
      if (entry.lesson && (entry.lesson.id === rollup_column.id || entry.rollup_id === rollup_column.id)) {
        entry.expanded = expanded;
        entry.no_show = !expanded;

        const el = this.shadowRoot.querySelector('td[data-name="assessment-' + i + '"]');
        if (entry.type === 'lesson') {
          entry.no_show? el.classList.remove('opened') : el.classList.add('opened');
        };

        if (entry.id != rollup_column.id || (entry.id === rollup_column.id && entry.type === 'custom')) {
          entry.no_show ? el.classList.remove(entry.expanded) : el.classList.add(entry.expanded);
          this.shadowRoot.querySelectorAll('td[data-name="assessment-' + i + '"]').forEach(e => {
            entry.no_show? e.classList.add('no_show_assessment') : e.classList.remove('no_show_assessment');
            entry.no_show? e.classList.remove('lesson_assessment') : e.classList.add('lesson_assessment');
          })
        }
      }
		});

    rollup_column.no_show = false;
    this.assessments_length = this.assessments.filter(a => !a.no_show).length;

  };

  // used in the compile of 2nd table header
  asP (c) {
    if (!(this.viewP && c.max_score)) return c.average_or_due_date;
    let x = c.average_or_due_date? c.average_or_due_date.indexOf("<br>") : 0;
    x = x > 0? c.average_or_due_date.substr(x) : '';
    return parseFloat((c.average*100/c.max_score).toFixed(1)) + '%' + x;
  }

  // promise to get course and user data
  async getCourseData() {
    this.course = await this.eventsService.getCourse();
    // TODO CONT ENROLL course.continuous_enrollment should be redirected somewhere else as well
    if (this.course.drilldown_enabled) {
      this.$emit('jnct:gradebook:drilldown');
      return;
    };
    const user = await this.junctionUserService.getUser();
    const my_id = user.mongoId;
    this.no_total_column = this.course.no_total_column

    this.isAdmin = this.junctionUserService.isAdmin(user);
    this.assistants_mgr_rights = true;
    if (this.course.assistants.includes(my_id) && this.course.assistants_no_mgr_rights) {
      this.assistants_mgr_rights = false;
    }

    const hasProfessorRights = this.junctionUserService.hasProfessorRights(this.course, user);
    let mode = this.eventsService.getCourseMode(this.course._id);

    if (hasProfessorRights && mode !== 'student') {
      this.myself = {
        id: user.mongoId,
        _id: user.mongoId,
        name: user.firstname + " " + user.lastname,
        firstname: user.firstname,
        lastname: '🎓 ' + user.lastname,
        email: user.email,
        active: false,
        time: "--:--",
        lesson: {}
      };
      // courseData.resolve(this.course)
    } else {
      this.$emit('jnct:navigation:gotogradebook')
      return;
    }
    this.getData(false, false)
  };

	// MAIN FUNCTION GETTING TABLE DATA AND RENDERING
  async getData(keepOrder, fromSwitch) {
    let keepState;
    const DashboardService: any = {};
    DashboardService.courseId = await this.eventsService.getServiceCourseId();

    // this should prevent direct navigation to accidentally provide the wrong cache
    if (DashboardService.courseId !== this.course._id) {
      this.$emit('jnct:dashboardService:gradebookflush');
      DashboardService.courseId = this.course._id;
    };

    const [roster, average, lessons]: [RosterRecord[], any, any] = await Promise.all([
      this.eventsService.getRoster(),
      this.eventsService.getAverage(true),
      this.eventsService.getLessons(),
    ]);

    // if (roster.errno == 'not-logged') {
    //   //@ts-ignore
    //   Cookies.set('redirect', window.location.pathname);
    //   window.location.href = '/logout';
    //   return;
    // };

    const students = {};
    const order = [];

    let activities = average['activity_manager']
      .filter(activity => {
        return (!activity.suppress_until_available || (activity.suppress_until_available && activity.open_date_in_future)) && (
            activity.graded == true || (activity.type == 'lesson' && !activity.course_data_only)
          )
      })
      // perform shallow copy as objects may be mutated later; add id to link in the shallow copy
      .map(activity => {
        activity = { ...activity };
        activity.link += "/" + activity.id; // TODO check if that's always safe to add (eg, can't do it twice)
        return activity
      });
    const course_max_score = average['course_max_score'];
    const course_percent = average['percent'];
    const user_scores = average['user_scores'];
    const activity_student_score = average['activity_student_score'];
    const using_custom_rollup = average['using_custom_rollup']; // this should be true iff viewL == custom
    this.has_rollup = average['has_rollup'];

    if (this.has_rollup && !this.groupByList.some(el => el.value === 'custom')) {
      this.groupByList.push({name: 'Custom', value: 'custom'})
    }

    if (!this.has_rollup && this.viewL == "custom") {
      this.viewL = '';
    };
		if (this.viewL == "true") this.viewL = "lesson";
		if (this.viewL == "false") this.viewL = "";

    this.cached = this.timeFormatService.longDateTime(average['cached']);

    const data = {
      report: roster.map(student => {
        return {
          id: student._id,
          name: student.name,
          firstname: student.firstname,
          lastname: student.lastname,
          email: student.email,
          active: false,
          time: "--:--",
          lesson: {},
          // percentage score for the course for the student (belongs in student total really)
          percent: (user_scores[student._id]||{}).percent||0,
          attempted: 0,
          submittedLate: 0,
        }
      })
    };

    // we add the current user record here
    this.myself.percent = (user_scores[this.myself._id]||{}).percent || 0,
    data.report.push(this.myself);

    // if we switch custom rollup ON then do NOT attempt to keep state
    if (!(fromSwitch && using_custom_rollup)) {
      keepState = this.assessments.map((assessment) => {
        return {
          expanded: assessment.expanded,
          no_show: assessment.no_show,
          state: assessment.state
        }
      });
    };

    this.assessments = [];

    // scope.assessments are the columns in the gradebook (minus the fixed ones on the left)
    // and match activities (minus the Total column - index 0)
    // NO aborting here -- want to show the roster if if there are no columns
    if (!activities.length || !data.report.length) {
      this.loaded = true
    }

    /* Split/convert backend object to front-end one for easier management */
    for (const student of data.report) {
      // reports are the rows in the gradebook (minus the 2 fixed header rows)
      this.reports[student.id] = [];

      // append student activities aka scores
      for (const activity of activities) {
        if (activity.graded) {
          let student_score: any = -1;
          // LTI, board, custom (with or without a lesson) uses the component_id (in the id field)
          const aid = (activity.type == "assessment")? activity.activity_id : activity.id;
          if (activity_student_score[aid][student.id]) {
            if (activity_student_score[aid][student.id].contains_overdue) {
              student.submittedLate++;
            } else {
              if (activity.type === "assessment" && activity_student_score[aid][student.id].session_id) {
                student.attempted++
              } else if (activity.type !== "assessment" && activity_student_score[aid][student.id].score >= 0) {
                student.attempted++;
              }
            }
            student_score = activity_student_score[aid][student.id].score;
            this.sessions[activity.id+student.id] = activity_student_score[aid][student.id];
          }
          if (["assessment", "project", "comments", "board"].includes(activity.type) ) {
            student_score = ((student_score || student_score == 0) && student_score >= 0)? student_score : "-";
          } else if (student_score == "-" || student_score == undefined || student_score == -1) {
            student_score = "";
          }
          this.reports[student.id].push(student_score);
        } else {
          // this is a lesson column for lesson._id = activity.id and we use _ in front of the
          // lesson_id to distinguish it from the lesson custom column
          const rollup_id = (using_custom_rollup? "" : "_") + activity.id;
          if ((activity_student_score[rollup_id]||{}).hasOwnProperty(student.id)) {
            this.reports[student.id].push(activity_student_score[rollup_id][student.id].score);
            this.lesson_star[activity.id + student.id] = {
              needs_grading: activity_student_score[rollup_id][student.id].needs_grading,
              contains_overdue: activity_student_score[rollup_id][student.id].contains_overdue,
              override_applied: activity_student_score[rollup_id][student.id].override_applied,
              project_reopened: activity_student_score[rollup_id][student.id].project_reopened,
            }
            // percent score for the student in the lesson (undefined produces a '-' as desired on the template)
            student.lesson[activity.id] = activity_student_score[rollup_id][student.id].percent;
          } else {
            this.reports[student.id].push('-');
          }
        }
      };

      this.selected.students[student.id] = false;

      // pre-pend the total obtained by the student
      if (!(using_custom_rollup && this.course.no_total_column)) {
        this.reports[student.id] = [+(((user_scores[student.id]||{}).score||0).toFixed(1))].concat(this.reports[student.id]);
      }

      students[student.id] = {...student};
      order.push(student.id);
    };


    /* Set second header row */
    for (const activity of activities) {
      const denominator = activity.max_score? activity.max_score : '-';
      let activity_id = activity.activity_id;
      if (activity.type == 'lesson') {
        activity_id = activity.id;
      } else {
        if (!activity_id) { // all types of custom columns other than the aggregator (like lesson)
          activity.actual = activity.actual || activity.type;
          if (activity.type !== "project") {
            activity.type = 'custom';
          }
          activity_id = activity.id;
        }
        activity.average = activity_student_score[activity_id].average || 0;
        if (activity.display_due_date !=='-/-') {
          activity.display_due_date = this.timeFormatService.shortMonth(activity.display_due_date);
        }
        activity.average_or_due_date = activity.average.toFixed(1) + " / " + denominator;
      }
    };

    if (!(using_custom_rollup && this.course.no_total_column)) {
      activities = [
        {
          report_title: "Total Points",
          max_score: course_max_score,
          percent: course_percent,
          total_type: 'total',
        }
      ].concat(activities);
    }

    this.registerEvents();
    activities.forEach((activity, i: number) => {
      if (this.viewL && keepState?.length == activities.length && !keepOrder) {
        activity.expanded = keepState[i].expanded;
        activity.no_show = keepState[i].no_show;
        activity.state = keepState[i].state;
      } else if (this.viewL) {
        activity.no_show = activity.lesson && activity.type !== 'lesson';
      } else {
        activity.no_show = activity.type == "lesson"
      }

      if (activity.lesson && activity.type !== 'lesson' && this.viewL !== 'custom') {
        const lesson = activities.find(el => el.id === activity.lesson.id);
        if (!lesson.pages) lesson.pages = [];
        if (!lesson.pages.some(page => page.id === activity.id)) {
          lesson.pages.push(activity);
        }
      }

      if ((activity.rollup_id || activity.rollup_id === null)  && activity.type !== 'lesson' && this.viewL === 'custom') {
        //to make sure we count Ungrouped which id is null
        const lesson = activities.find(el => el.id == activity.rollup_id && el.total_type !== 'total');

        //to not add course assessment
        if (!activity.lesson) return;
        if (!lesson.pages) lesson.pages = [];

        if (!lesson.pages.some(page => page.id === activity.id)) {
          lesson.pages.push(activity);
        }
      }

      //making sure to close all the lessons when switching between custom and lesson view
      this.shadowRoot.querySelectorAll('td[data-name="assessment-' + i + '"]').forEach(e => {
        if (activity.type === 'lesson') {
          e.classList.remove('lesson_assessment');
          e.classList.remove('opened');
        }
      })
    });

    this.assessments_length = activities.filter(a => {
      return !a.no_show
    }).length;
    if (order?.length * 48 < window.innerHeight - 109) {
      this.tableHeight = order.length * 48;
    } else {
      this.tableHeight = window.innerHeight - 339;
    }

    //making sure to apply colors to custom grroups
    if ((using_custom_rollup && fromSwitch) || this.viewL === 'custom') {
      this.tickleMePink(activities);
    }

    this.averageTime = "00:00";
    this.order = order;
    this.assessments = activities;
    this.students = students;
    this.setActive('student', true);
    this.sort = 'desc';
    this.user_scores = user_scores;

    // this will write the data into the template
    this.sortByStudentData('lastname', 'firstname');

    this.loaded = true;
  };

  search = (value: string) => {
    if (!this.originalOrder.length) {
      this.originalOrder = this.order;
    };
		this.order = this.originalOrder;
    const filtered = [];
      for (const studentId of this.order) {
      const name = this.students[studentId].name.toLowerCase();
      name.match(value.toLowerCase()) && filtered.push(studentId);
    };
    this.order = filtered;
    this._render_table(this.order, this.assessments);
    this.setActive(this.selected.column, false);
  };

  // getData helper called to sync scrolling between tables
  registerEvents() {
    // Select all elements with the class 'h-scroll-wrapper'
    const scrollContainers = this.shadowRoot.querySelectorAll('.h-scroll-wrapper');

    // Attach the scroll event listener to each scrollable container
    scrollContainers.forEach(container => {
      container.addEventListener('scroll', (event) => {
        // Get the scroll position of the currently scrolling container
        const scrollTop = event.target.scrollTop;

        // Synchronize the scroll position with all other containers
        scrollContainers.forEach(otherContainer => {
          if (otherContainer !== event.target) {
              otherContainer.scrollTop = scrollTop;
          }
        });
      });
    });
  };

  // sets the active state on a column; one of the more interesting functions in the code-base
  setActive(label, keepOrder) {
    this.selected.column = label;
    // clicking on a non-assessment column header or a selected assessment header
    if (label.indexOf("assessment-") !== 0 || this.selected.column === label) {
      this.selected = {...this.selected, sort_column:label};
      if (keepOrder) {
        this.sort = 'desc';
      } else {
        this.sort = this.selected.column != label ? 'desc' : (this.sort == 'desc' ? 'asc' : 'desc');
      }
    }
    this.selected.custom = undefined;
    this.selected.assessment = undefined;
    if (label.indexOf("assessment-") == 0) {
      let i = label.indexOf("-");
      i = parseInt(label.substr(i+1,label.length-i));
      if (this.assessments[i].type === 'custom') {
        // Only give the ability to edit custom columns (not lti or boards any longer)
        if (this.assessments[i].actual == "custom") this.selected.custom = i;
      } else {
        this.selected.assessment = this.assessments[i];
      }
    }
  };

  _render_table(order, activities) {
    const tBody = document.createElement('tbody');
    const fragment = new DocumentFragment();
    fragment.appendChild(tBody);
    let i, index;
    for(i = 0; i < order.length; i++) {
      const sid = order[i];
      const TR = document.createElement('tr');
      TR.classList.add('student-row');
        for(index = 0; index < activities.length; index++) {
          const assessment = activities[index];
          const this_session = this.sessions[assessment.id + sid] || {};
          const viewP = this.viewP;
          const TD = document.createElement('td');
          if (this.viewL) TD.classList.add(assessment.expanded + "-bottom");
          if (assessment.no_show) TD.classList.add('no_show_assessment'); // display: none
          TD.setAttribute('data-name', "assessment-" + index);
          if (index === 0) {
            TD.setAttribute('class', 'total')
          };

          if (assessment.type === "custom" && !['board', 'comments'].includes(assessment.actual) && !viewP) {
            const inputElement = document.createElement('grading-input');
            //@ts-ignore
            inputElement.point = this.reports[sid][index] ?? null;
            //@ts-ignore
            inputElement.maxPoints = assessment.max_score || 999;
            //@ts-ignore
            inputElement.hoverEffect = true;
            //@ts-ignore
            inputElement.studentid = sid + '-' + index;
            //@ts-ignore
            inputElement.changePoint = this.save_points_handler;
            inputElement.setAttribute('tabindex', index);
            inputElement.setAttribute('name', sid + '-' + index);
            inputElement.classList.add('gradebook-input');
            const div = document.createElement('div');
            div.appendChild(inputElement);
            TD.classList.add('input-container');
            TD.appendChild(div);
            if (assessment.scoring_method) {
              let icon = this_session.needs_grading? GradebookIcons.createIcon('edit') : (this_session.contains_overdue? GradebookIcons.createIcon('assignment_late') : '');
              const spanElement = document.createElement('span');
              spanElement.classList.add('span__flex');
              if (typeof icon !== 'string') {
                spanElement.appendChild(icon);
                div.classList.add('flex__gap-4');
                //@ts-ignore
                inputElement.width = '108px';
                div.appendChild(spanElement);
              }
            };
          };

          if (assessment.type && !['custom', 'lesson', 'lti', 'board', 'project'].includes(assessment.type)) {
            const p_score = this.asPHelper(this.reports[sid][index], assessment.max_score);
            const pencil = this_session.needs_grading ? GradebookIcons.createIcon('edit') : '';
            const clock = this_session.contains_overdue === true ? GradebookIcons.createIcon('assignment_late') : (this_session.contains_overdue == 1 ? GradebookIcons.createIcon('schedule') : '');
            const override = this_session.override_applied ? GradebookIcons.createIcon('edit_calendar') : '';
            let span = document.createElement('span');
            span.setAttribute('name', sid + '-' + index);
            span.setAttribute('tabindex', index);
            span.innerHTML = p_score;
            if (typeof pencil !== 'string') span.appendChild(pencil);
            if (typeof clock !== 'string') span.appendChild(clock);
            if (typeof override !== 'string') span.appendChild(override);
            span.classList.add('span__flex');
            let div = document.createElement('div');
            div.classList.add('flex__sp-b');
            div.appendChild(span);
            TD.classList.add('dropdown');
            const session = this_session;
            const assessment_id = assessment.activity_id || assessment.id;
            GradebookDropdownActions.createDropdownELement(div, TD, this.assessments, this.students[sid], assessment_id, session, this.course);
          }
          if (assessment.type === 'lesson') {
            const slesson = this.students[sid].lesson[assessment.id];
            const percent = viewP? (slesson !== undefined? (parseFloat(slesson.toFixed(1)) + '%') : '-') : this.reports[sid][index];
            const star = this.lesson_star[assessment.id + sid] || {};
            const pencil = star.needs_grading? GradebookIcons.createIcon('edit'): '';
            const clock = star.contains_overdue === true ? GradebookIcons.createIcon('assignment_late') : (star.contains_overdue == 1 ? GradebookIcons.createIcon('schedule') : '');
            const override = star.override_applied ? GradebookIcons.createIcon('edit_calendar') : '';
            const reopened = star.project_reopened ? GradebookIcons.createIcon('source_notes') : '';
            const spanElement = document.createElement('span');
            spanElement.innerHTML = percent !== undefined ? percent : '';
            if (typeof pencil !== 'string') spanElement.appendChild(pencil);
            if (typeof override !== 'string') spanElement.appendChild(override);
            if (typeof clock !== 'string') spanElement.appendChild(clock);
            if (typeof reopened !== 'string') spanElement.appendChild(reopened);
            spanElement.classList.add('span__flex');
            TD.appendChild(spanElement);
          }
          if (assessment.type === 'project' || assessment.actual === 'comments') {
            const p_score = this.asPHelper(this.reports[sid][index], assessment.max_score);
            let pencil: string | HTMLElement = '';
            if (assessment.type === 'project') pencil = (this_session.needs_grading && !this_session.manually_rejected) ? GradebookIcons.createIcon('edit') : '';
            if (assessment.actual === 'comments') pencil = this_session.needs_grading && this_session.activity_id? GradebookIcons.createIcon('edit') : '';
            const clock = this_session.contains_overdue === true && !this_session.project_reopened && !this_session.manually_accepted && !this_session.manually_rejected ? GradebookIcons.createIcon('assignment_late') : (this_session.overdue_accepted === 1 ? GradebookIcons.createIcon('schedule') : '');
            const override = this_session.override_applied ? GradebookIcons.createIcon('edit_calendar') : '';
            const reopened = this_session.project_reopened ? GradebookIcons.createIcon('source_notes') : '';
            const span = document.createElement('span');
            span.setAttribute('name', sid + '-' + index);
            span.setAttribute('tabindex', index);
            span.innerHTML = p_score;
            if (typeof pencil !== 'string') span.appendChild(pencil);
            if (typeof clock !== 'string') span.appendChild(clock);
            if (typeof override !== 'string') span.appendChild(override);
            if (typeof reopened !== 'string') span.appendChild(reopened);
            span.classList.add('span__flex');
            let div = document.createElement('div');
            div.classList.add('flex__sp-b');
            div.appendChild(span);
            TD.classList.add('dropdown');
            const session = this_session;
            const assessment_id = assessment.activity_id || assessment.id;
            const noComment = assessment.actual === 'comments' && !this_session.activity_id;
            if (noComment || this_session.project_reopened) {
              TD.appendChild(div)
            } else {
              GradebookDropdownActions.createDropdownELement(div, TD, this.assessments, this.students[sid], assessment_id, session, this.course);
            }
          };
          if (assessment.type === 'custom' && !['board', 'comments'].includes(assessment.actual) && viewP) {
            const maxs = this.asPHelper(this.reports[sid][index], assessment.max_score);
            const spanElement = document.createElement('span');
            spanElement.innerHTML = maxs;
            TD.appendChild(spanElement);
          }

          if (assessment.actual === 'board' && assessment.type === "custom") {
            const p_score = this.asPHelper(this.reports[sid][index], assessment.max_score);
            let pencil: string | HTMLElement = '';
            pencil = this_session.needs_grading && this_session.activity_id? GradebookIcons.createIcon('edit') : '';
            const span = document.createElement('span');
            span.setAttribute('name', sid + '-' + index);
            span.setAttribute('tabindex', index);
            span.innerHTML = p_score;
            if (typeof pencil !== 'string') span.appendChild(pencil);
            span.classList.add('span__flex');
            let div = document.createElement('div');
            div.classList.add('flex__sp-b');
            div.appendChild(span);
            TD.classList.add('dropdown');
            const session = this_session;
            const assessment_id = assessment.activity_id || assessment.id;
            const noComment = !this_session.activity_id;
            if (noComment) {
              TD.appendChild(div)
            } else {
              GradebookDropdownActions.createDropdownELement(div, TD, this.assessments, this.students[sid], assessment_id, session, this.course);
            }
          };

          if (!assessment.type) {
            const percent = viewP? (parseFloat(this.students[sid].percent?.toFixed(1)) + '%') : this.reports[sid][index];
            const spanElement = document.createElement('span');
            spanElement.innerHTML = percent;
            TD.appendChild(spanElement);
          }
        TR.appendChild(TD);
      }
      tBody.appendChild(TR)
    }
    const table = this.shadowRoot.querySelector('#gradebookcells');
    if (!table) return;
    //@ts-ignore
    table.replaceChild(fragment, table.tBodies[0]);
    // $('.gradebook-input').keyup(this.save_poxints_handler).blur(this.save_points_handler);
    const t = new Date().getTime();
    setTimeout(() => {
      console.log(new Date().getTime() - t)
    });
  }


  // getData helper called to listen to input field keyup and blur
  // TODO if you double click enter then 2 put requests are called; add a debounce!
  save_points(value: any, id: string) {
    const parts = id.split('-');
    // Assign the first part to 'id' and convert the second part to a number for 'index'
    const sid = parts[0];
    const assessmentIndex = parseInt(parts[1]);
    const grade = parseFloat(value);

    // e.target.value is either a float or "" and parseFloat("") == NaN, so cannot distinguish between "1--9" and ""
    if (grade === this.reports[sid][assessmentIndex] || isNaN(grade) && this.reports[sid][assessmentIndex] === "") {
      if (!(grade === 0 && this.reports[sid][assessmentIndex] === 0)) {
        return;
      } else {
        console.log("Zeroes are heroes!");
      }
    }
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');

    fetch(APIprefix + 'gradebook/' + this.course._id + '/' + sid, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        grade: grade,
        student_id: sid,
        type: this.assessments[assessmentIndex].actual,
        component_id: this.assessments[assessmentIndex].id,
        title: this.assessments[assessmentIndex].report_title,
        max_score: this.assessments[assessmentIndex].max_score
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Grade could not be saved. Perhaps make sure the grade is less than the max score.');
      }
      return response.json();
    })
    .then(() => {
      this.reports[sid][assessmentIndex] = grade; // this is just to remember it across sorting
      const container = this.shadowRoot.querySelector('[name="' + id + '"]');
      const element = container.shadowRoot.querySelector('.body__input');
      //@ts-ignore
      element.style['border'] = "2px solid green";
      setTimeout(() => {
        //@ts-ignore
        element.style['border'] = 'none';
      }, 1500);
    })
    .catch(error => {
      this.$emit('jnct:notifications:error', error.message);
    });
  };

  // render table helper
	asPHelper(a,b) {
    if (this.viewP && a === '') return '-';
    if (isNaN(a) || a === '') return a; // apparently '' is a number (it isn't isNaN)
    if (!this.viewP) return parseFloat((a).toFixed(1)); // it should be able to parseFloat here
    if (!b) return "-";
    return parseFloat(((a*100)/b).toFixed(1)) + '%';
	};

  // getData helper called to apply custom roll-up colors
  tickleMePink(assessments) {
    const shadowRoot = this.shadowRoot;
    if (!shadowRoot) {
      console.error('Shadow root not found.');
      return;
    }

    let style = shadowRoot.getElementById('rollup_styles');
    let exists = !!style;

    if (exists) return;

    if (!exists) {
      style = document.createElement('style');
      style.id = 'rollup_styles';
      //@ts-ignore
      style.type = 'text/css';
      shadowRoot.appendChild(style);
    }

    style.innerHTML = ''; // Clear existing styles
    assessments.forEach((a) => {
      if (a.hasOwnProperty('rollup_id') && a.color) {
        style.innerHTML += `
          .rollup_color_${a.id} {
            border-top: 2px solid ${a.color} !important;
          }
        `;
      }
    });
  };

  //TODO delete after moving email, unenroll, reload cache events
  toggleSelectAll() {
    this.selectedall = !this.selectedall;
  };

  //TODO delete after moving email, unenroll, reload cache events
  selectSudent(student: RosterRecord): void {
    const order = [...this.selected_students];
    if (!this.selected_students.length) {
      order.push(student);
      this.selected_students = [...order]
      return;
    };
    const ind = this.selected_students.findIndex(el => el.id === student.id)
    if (ind > -1) {
      order.splice(ind, 1);
    } else {
     order.push(student)
    }

    this.selected_students = [...order];
  };

  //TODO delete after moving emails
  sendEmail() {
    const emails = this.selected_students.map(student => student.email);
    const send_emails = emails.join(',');
    const emailLink = `mailto:${send_emails}`;
    window.open(emailLink);
  };

  //TODO delete after moving reload cache event
  async reloadCache() {
    const ids = this.selected_students.map(student => student.id);
    const payload = {
      'user_ids': ids,
      'all': ids.length == this.order.length,
    };

    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "stats/purgecache/" + this.course._id, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });
      if (!response.ok) {
        const error_message = 'Unexpected problem occurred. (' + JSON.stringify(response) + ')';
        this.$emit('jnct:notifications:error', error_message);
       }
       this.loaded = false;
       this.selected_students = [];
       this.selectedall = false;
       const info_message = 'Cache purged. Please wait while the data is being re-loaded...';
       this.$emit('jnct:notifications:info', info_message);
       this.reloadData();
     } catch (error) {
        this.loaded = false;
        this.selected_students = [];
        this.selectedall = false;
        const error_message = 'Unexpected problem occurred. (' + JSON.stringify(error) + ')';
        this.$emit('jnct:notifications:error', error_message);
        console.error("Error:", error);
     };
  };

  reloadCacheHandler = this.reloadCache.bind(this);
  // re-load cache button - only for admins
  purge() {
    const ids = this.selected_students.map(student => student.id);
    let msg = 'Are you sure you want to re-load the cache for these ' + ids.length + ' students?';
    if (ids.length > 10) msg += ' It could take a while!';
    if (ids.length == 1) msg = 'Are you sure you want to re-load the cache for this 1 student?';
    const modal = document.createElement('confirmation-modal') as ConfirmationModal;
    modal.title = 'Reload Cache';
    modal.text = msg;
    modal.button__text = 'Reload';
    modal.button__function = this.reloadCacheHandler;
    document.body.appendChild(modal);
    return;
  };

  //TODO delete after moving unenroll event
  async unenrollStudents() {
    const ids = this.selected_students.map(st => st.id);
     //@ts-ignore
     const csrfToken = Cookies.get('csrf_token');
     //@ts-ignore
     const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
     try {
       const response = await fetch(APIprefix + "unenroll_student/", {
         method: "POST",
         body: JSON.stringify({
          courseId: this.course._id,
          students: ids,
         }),
         headers: {
           'Content-Type': 'application/json',
           'X-CSRF-Token': csrfToken,
         }
       });
       if (!response.ok) {
          const message = 'Could not unenroll students (server error).';
          this.$emit('jnct:notifications:error', message);
        }
        this.loaded = false;
        const message = 'Selected students have been removed';
        this.selected_students = [];
        this.selectedall = false;
        this.$emit('jnct:notifications:success', message);
        this.reloadData();
      } catch (error) {
        const message = 'Could not unenroll students (server error).';
        this.$emit('jnct:notifications:error', message);
        console.error("Error:", error);
      };
  }

  unenrollStudentsHandler = this.unenrollStudents.bind(this)

  unenroll() {
    const modal = document.createElement('confirmation-modal') as ConfirmationModal;
    modal.title = 'Unenroll Selected';
    modal.text = 'Are you sure you want to unenroll selected students?';
    modal.button__text = 'Unenroll';
    modal.button__function = this.unenrollStudentsHandler;
    document.body.appendChild(modal);
    return;
  }
}

GradebookInstructor.defineComponent();

export{ GradebookInstructor };
