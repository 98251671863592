import { IConvo, IExamplePrompt } from '@backend-types';
import { IMessage } from './children/chat-message/component';
import { WebChat } from './component';
import events from './events';
import WebChatEvents from './events';
import { observeResizeChanges } from './box'; 

function changeTemperature(this: WebChat) {    
    const changedVal: number = parseFloat((this.$('fast-slider#temperature') as HTMLInputElement).value)
    this.customTemperature = changedVal
}

function changeTopK(this: WebChat) {
    const changedVal: number = parseFloat((this.$('fast-slider#top_k') as HTMLInputElement).value)
    this.customTopK = changedVal
}

function changeTopP(this: WebChat) {
    const changedVal: number = parseFloat((this.$('fast-slider#top_p') as HTMLInputElement).value)
    this.customTopP = changedVal
}

function changeMaxTokensToSample(this: WebChat) {    
    const changedVal: number = parseFloat((this.$('fast-slider#max_tokens') as HTMLInputElement).value)
    this.customMaxTokensToSample = changedVal
}

function toggleMinified(this: WebChat) {
    this.minified = !this.minified

    this.$emit(WebChatEvents.global.chat.toggled, { minified: this.minified })

    sessionStorage.setItem('_ai_last_chat_minified', this.minified ? 'true' : 'false')
}

function handleEvents(this: WebChat): void {
    observeResizeChanges(this, (w: number, h: number) => {        
        if (w !== 0 && h !== 0) {
            this.recalcArea()
        }        
    })

    this.on<{ item: IMessage }>(WebChatEvents.message.send, (event: CustomEvent<{ item: IMessage }>) => {            
        this.streamCall(event.detail.item)
    });                   

    this.on(events.context.convo_id_set, (event: CustomEvent<string>) => {
        this.convoService.setLastConvoId(this.lessonId, event.detail)
        this.getConvos()
    })

    this.on('chat_typing', (event: CustomEvent<boolean>) => {
        this.typing = event.detail
    })

    this.on('convo.list', (event: CustomEvent<IConvo[]>) => {
        const convos = event.detail
        this.convoList = convos
        // this.checkLastConvo(convos);
    })

    this.on('example.prompt', (event: CustomEvent<IExamplePrompt>) => {
        this.streamCall({
            userMessage: true,
            author: 'User',
            content: event.detail.prompt,
            created_at: new Date(),
            intruder_prompt: null
        })
    })

    this.on('chat.close', (event: CustomEvent<IConvo[]>) => {
        this.minified = true
        sessionStorage.setItem('_ai_last_chat_minified', this.minified ? 'true' : 'false')
    })

    this.on('chat.nav.toggle', (event: CustomEvent<IConvo[]>) => {
        const chatWindow = this.getShadowRoot().host as HTMLElement

        this.navOpened = !this.navOpened

        if (this.navOpened) {
            chatWindow.style.minWidth = '625px'
        } else {
            chatWindow.style.minWidth = null
        }    
    })

    // eheader sends the event.detail=null if no courseId in stateParams, otherwise it
    // creates the chat-loader which then sends the event with detail = bound book data
    document.documentElement.addEventListener('jnct:ai-binding:set', (event: CustomEvent<any>) => {
        if (event.detail === null) {
            sessionStorage.removeItem('webchat_storage_ai_binding')
            return
        }

        if (this.isInstructor) return;

        sessionStorage.setItem('_ai_last_chat_minified', this.minified ? 'true' : 'false')
       
        if (this.minified) this.getShadowRoot().host.remove()
    })

    document.documentElement.addEventListener('jnct:ai:show', (event: CustomEvent<any>) => {    
        if (this.isInstructor) return;

        this.minified = !this.minified
        sessionStorage.setItem('_ai_last_chat_minified', this.minified ? 'true' : 'false')
    })

    this.on('chat.new', (event: CustomEvent<IConvo[]>) => {
        this.convoId = null
        this.messages = []
        
        if (!this.customchapternr) {
            this.chapterNr = null
        }

        this.clearConvoList()
    })

    this.on('convo.propagation', async (event: CustomEvent<string>) => {
        this.convoId = event.detail      
    
        const convoData: IConvo = await this.convoService.getConvo(this,this.convoId, this.query)
        this.currentConvo = convoData
        this.messages = []

        if (convoData) {
            this.messages = convoData.messages.map((item) => ({...item, done: true}))
        }
    })

    this.on('chat.search.query', (event: CustomEvent<string>) => {
        this.query = event.detail;
    });

    this.on('webchat:instructor:set-inspected', (event: CustomEvent<string>) => {
        this.inspectedUser = event.detail
    })
}

interface IWebChatHandlers {
    minifiedHandler: (this: WebChat) => void
    temperatureHandler: (this: WebChat) => void
    topKHandler: (this: WebChat) => void
    topPHandler: (this: WebChat) => void
    maxTokensToSampleHandler: (this: WebChat) => void
}

export { IWebChatHandlers, handleEvents }

function getHandlers(this: WebChat): IWebChatHandlers {
    return {
        minifiedHandler: toggleMinified.bind(this),
        temperatureHandler: changeTemperature.bind(this),
        topKHandler: changeTopK.bind(this),
        topPHandler: changeTopP.bind(this),
        maxTokensToSampleHandler: changeMaxTokensToSample.bind(this),        
    }
}

export default getHandlers