import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div
  class="modal-content ${x => x.selectedAttemptId && x.selectedAttempt ? 'full-height' : ''}"
>
  <div class="header">
    <span class="title">${x=>x.name}</span>
    <span class="exit" @click="${x=>x.genericConfig.switch()}">Exit</span>
    <span class="subtitle" :innerHTML="${x=>x.title}"></span>
  </div>

  <div class="table-wrapper">
    ${x => x.loading ? T.html`
    <div class="spinner"><jnct-loading-spinner></jnct-loading-spinner></div>
    ` : T.html`
    <jnct-assessment-detail-attempt-table
      due_date="${x => x.genericConfig.assignment?.due_date}"
      :attempts="${x => x.attempts}"
      :selectedAttemptId="${x => x.selectedAttemptId}"
      :onShowDetails="${x => x.onShowDetails}"
      :activityId="${x => x.genericConfig.assignment?.id}"
      :activity_student_score="${x => x.genericConfig.activity_student_score}"
      :courseId="${x => x.genericConfig.courseId}"
    ></jnct-assessment-detail-attempt-table>
    `}
  </div>

  <div class="lernsoity-frame">
    ${x => x.selectedAttemptId && x.selectedAttempt ? T.html`
    <jnct-assessment-attempt-learnosity-iframe
      :attempt="${x => x.selectedAttempt}"
    >
    </jnct-assessment-attempt-learnosity-iframe>
    ` : undefined}
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.header {
  display: grid;
  grid-template-areas: "a b" "c c";
  justify-content: space-between;
  z-index: 2;
}
.header .title {
  grid-area: a;
  color: var(--neutral-70, #3b3c3e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.header .exit {
  grid-area: b;
  color: var(--Link, #0f75b8);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
  text-align: end;
}
.header .subtitle {
  grid-area: c;
  color: var(--neutral-90, #1b1c1e);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.modal-content {
  display: flex;
  width: 720px;
  flex-direction: column;
  border: var(--account, 1px) solid var(--neutral-20, #eeeff0);
  max-height: calc(100vh - 54px - 48px);
  padding: 24px;
  background: #fff;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.05);
}
.modal-content.full-height {
  height: calc(100vh - 54px - 48px);
}
.modal-content .lernsoity-frame {
  flex-grow: 1;
}

.table-wrapper {
  margin: 32px 0px;
}

.spinner {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
  RWSInject,
} from "@rws-framework/client";
import {
  StudentDeepDiveAssignmentRecord,
  AssessmentAttempt,
} from "../../../types/reports";
import { RosterRecord } from "../../../types/users";
import StudentDeepDiveLearnosityService, {
  StudentDeepDiveLearnosityServiceInstance,
} from "../../../services/StudentDeepDiveLearnosityService";

export type JunctionAssessmentDetailModalConfig = {
  switch: () => void;
  assignment: StudentDeepDiveAssignmentRecord;
  student: RosterRecord;
  activity_student_score: any;
  courseId: string
};

@RWSView('jnct-assessment-detail-modal', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionAssessmentDetailModal extends RWSViewComponent  {
  @attr genericConfig: JunctionAssessmentDetailModalConfig;

  @observable selectedAttemptId: AssessmentAttempt["id"] = undefined;
  @observable attempts: AssessmentAttempt[] = [];
  @observable loading = true;

  constructor(
    @RWSInject(StudentDeepDiveLearnosityService)
    protected service: StudentDeepDiveLearnosityServiceInstance
  ) {
    super();
  }

  get name() {
    return this.genericConfig?.student?.name ?? "";
  }

  get title() {
    return this.genericConfig?.assignment?.title ?? "";
  }

  get selectedAttempt() {
    return this.attempts.find((a) => a.id === this.selectedAttemptId);
  }

  onShowDetails = (attempt: AssessmentAttempt) => {
    this.selectedAttemptId = attempt.id;
  };

  loadAttempts() {
    const studentId = this.genericConfig.student._id;
    const activityId = this.genericConfig.assignment.activity_id;

    return this.service
      .getScores(activityId, studentId)
      .then((scores) => {
        this.attempts = scores;
        this.loading = false;
        this.selectedAttemptId =
          JunctionAssessmentDetailModal.findBestAttemptId(scores);
      })
      .catch((err) => {
        this.$emit(
          "jnct:notifications:error",
          "Failed to load student detail deep dive data"
        );
        console.error(err);
      });
  }

  static findBestAttemptId(
    attempts: AssessmentAttempt[]
  ): AssessmentAttempt["id"] {
    const maxScore = Math.max(...attempts.map((a) => a.score));

    return attempts.find((a) => a.score === maxScore)?.id;
  }

  connectedCallback() {
    super.connectedCallback();
    this.$emit("modal:shown");

    this.loadAttempts();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.selectedAttemptId = undefined;
  }
}

JunctionAssessmentDetailModal.defineComponent();

export { JunctionAssessmentDetailModal };
