import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="layout__header">
  <div class="title">
    <span class="material-symbols-outlined icon"> assignment </span>
    Assignments
  </div>
  <div class="filters">
    <jnct-student-deep-dive-chapter-select
      :chapters="${x => x.assignments}"
      :selectedChapterId="${x => x.selectedChapter}"
      :onChange="${x => x.setByChapter}"
    >
    </jnct-student-deep-dive-chapter-select>
    <div class="search">
      <span class="material-symbols-outlined search__icon" aria-hidden="true"
        >search</span
      >
      <input
        type="text"
        aria-label="Search by Assignment..."
        placeholder="Search Assignment..."
        class="search__input"
        id="auto-detect-title"
        @input="${(x, c) => x.inputChange(c.event)}"
      />
    </div>
  </div>
</header>
<content>
  <div class="table_wrapper">
    <span class="assignments_number">
      ${x=>x.numberOfAssignments} Assignments
    </span>
    <div class="table">
      <div class="header_wrapper">
        <div class="row header">
          <div class="sortable_column" @click="${x => x.toggleSort('status')}">
            Status
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'status' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'status' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div class="sortable_column" @click="${x => x.toggleSort('title')}">
            Assignment Title
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'title' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'title' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          
          
          <div class="sortable_column" @click="${x => x.toggleSort('grade')}">
            Grade
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'grade' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'grade' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div
            class="sortable_column"
            @click="${x => x.toggleSort('submitted')}"
          >
            Submitted
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'submitted' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'submitted' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="data_wrapper">
        ${T.repeat(x => x.showAssignments, T.html`
        <div class="row data">
          <div>
            
            ${T.when(x=> !['no-pill', 'practice'].includes(x.status), T.html`
            <jnct-status-badge type="${x => x.status}"></jnct-status-badge>
            `)}
          </div>
          <div>
            <a :href="${x => '/' + x.link}" :innerHTML="${x => x.title}"></a>
          </div>
          
          
          
          
          
          

          <div>
            <div class="multirow">
              <div>${ x=> parseFloat((x.score_in_percentage).toFixed(1)) }%</div>
            </div>
          </div>
          <div>
            <div class="multirow">
              <jnct-date-tabular-display
                :date="${x => x.submission_date}"
              ></jnct-date-tabular-display>
            </div>
          </div>
          <div>
            ${x => x.type === 'assessment' ? T.html`
            <div class="multirow">
              <div>
                ${T.when((x, c) => c.parent.onShowDetails, T.html`
                <button
                  class="button__secondary"
                  @click="${(x, c) => c.parent.showDetails(x)}"
                >
                  View Details
                </button>
                `)}
              </div>
            </div>
            ` : undefined}
          </div>
        </div>
        `)}
      </div>
    </div>
  </div>
</content>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#jnct-users-table {
  display: flex;
}

.layout__header {
  padding: 4px 0px 4px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.layout__header .title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  gap: 8px;
}
.layout__header .search__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 17px;
  left: 28px;
  z-index: 2;
  cursor: pointer;
  color: var(--neutral-50);
  line-height: 26px;
  font-size: 20px;
}
.layout__header .filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  color: #5b5c5e;
  font-size: 16px;
  font-weight: 300;
  line-height: 25.6px;
}
.layout__header .filters jnct-student-deep-dive-chapter-select {
  min-width: 250px;
}
.layout__header .search {
  display: flex;
  justify-content: center;
  width: 336px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.layout__header .search__icon {
  top: 7px;
  left: 12px;
  width: 26px;
  height: 26px;
  font-weight: 400;
  position: absolute;
  z-index: 101;
  filter: invert(1);
}
.layout__header .search__input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px 0 40px;
  border-radius: 12px;
  background-color: #eeeff0;
  color: #5b5c5e;
  font-size: 16px;
  font-weight: 300;
  line-height: 25.6px;
  border: none;
  cursor: pointer;
  z-index: 100;
  transition: padding 0.4s;
}

.table_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.table_wrapper .assignments_number {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
}
.table_wrapper .table {
  width: 100%;
  border: 1px solid #eeeff0;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}
.table_wrapper .table .data_wrapper {
  height: 450px;
  overflow-y: scroll;
}
.table_wrapper .table .data_wrapper .row:nth-child(odd) {
  background: rgba(155, 160, 165, 0.0784313725);
}
.table_wrapper .table .header_wrapper {
  padding-right: 15px;
  border-bottom: 2px solid #eeeff0;
}
.table_wrapper .table .sortable_column {
  cursor: pointer;
}
.table_wrapper .table .sortable_column_options .active {
  color: #3b3c3e;
}
.table_wrapper .table .row {
  display: grid;
  grid-template-columns: 3fr 4fr 2fr 3fr 3fr;
  padding: 0px 16px 0px 16px;
}
.table_wrapper .table .row div {
  align-items: center;
  display: flex;
  padding: 10px 30px 10px 0px;
  border: 1px 0px 0px 1px;
}
.table_wrapper .table .row div div {
  padding: 0;
}
.table_wrapper .table .row div .date {
  display: block;
}
.table_wrapper .table .row div .status {
  font-size: 12px;
  padding: 2px 8px 2px 8px;
}
.table_wrapper .table .row div .status .material-symbols-outlined {
  font-size: 16px;
}
.table_wrapper .table .row div .status_submitted {
  border-radius: 12px;
  gap: 4px;
  border: 1px solid #028e43;
  background: #def9ea;
  color: #028e43;
  font-weight: 400;
}
.table_wrapper .table .row div .status_late {
  border-radius: 12px;
  gap: 4px;
  border: 1px solid #004466;
  background: #e9f5fb;
  color: #004466;
  font-weight: 400;
}
.table_wrapper .table .row div .status_not_submitted {
  border-radius: 12px;
  gap: 4px;
  border: 1px solid #ce0505;
  background: #f9dede;
  color: #ce0505;
  font-weight: 400;
}
.table_wrapper .table .row div .multirow {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 10px 0px;
}
.table_wrapper .table .row div .multirow div {
  height: 40px;
}
.table_wrapper .table .row .sortable_column {
  cursor: pointer;
}
.table_wrapper .table .row .sortable_column_options {
  display: flex;
  flex-direction: column;
}
.table_wrapper .table .row .sortable_column_options span {
  line-height: 7px;
  color: rgba(27, 28, 30, 0.2);
  font-size: 28px;
}
.table_wrapper .table .row .sortable_column_options .active {
  color: #3b3c3e;
}
.table_wrapper .table .row a {
  text-decoration: none;
  color: #0f75b8;
  cursor: pointer;
}
.table_wrapper .table .header {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.table_wrapper .table .data {
  min-height: 80px;
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
}`;

const mode = "open";
import {
  observable,
  RWSViewComponent,
  RWSView,
  attr,
} from "@rws-framework/client";
import { StudentDeepDiveAssignmentRecord } from "../../../types/reports";
import { sortBy } from "../../../helpers/utils";

@RWSView('jnct-assignments-table', null, { template: rwsTemplate, styles, options: {mode} })
class AssignmentsTable extends RWSViewComponent  {
  @attr assignments: StudentDeepDiveAssignmentRecord[] = [];
  @attr onShowDetails: ((a: StudentDeepDiveAssignmentRecord) => void) | null =
    null;

  @observable search = "";
  @observable selectedChapter: string | undefined;
  @observable sort: "status" | "title" | "grade" | "submitted" = "submitted";
  @observable order: "asc" | "desc" = "desc";

  get showAssignments() {
    const searched = this.search
      ? this.assignments.filter((value) =>
          value.title.toLowerCase().includes(this.search)
        )
      : [...this.assignments];

    const byChapter = this.selectedChapter
      ? searched.filter((s) => s.lesson_id === this.selectedChapter)
      : searched;

    const sorted = sortBy(byChapter, (a) => {
      switch (this.sort) {
        case "status": {
          return a.status;
        }
        case "title": {
          return a.title;
        }
        case "grade": {
          return a.score_in_percentage;
        }
        case "submitted": {
          const date = new Date(a.submission_date);

          return date.getTime();
        }
      }
    });

    // TODO: should we filter by `type`?
    return this.order === "asc" ? sorted : sorted.reverse();
  }

  get numberOfAssignments() {
    return this.assignments.length;
  }

  setByChapter = (
    value: { lesson_id: string; lesson_title: string } | null
  ) => {
    this.selectedChapter = value?.lesson_id || undefined;
  };

  inputChange(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    this.search = target.value.toLowerCase();
  }

  showDetails(assignment: StudentDeepDiveAssignmentRecord) {
    if (this.onShowDetails) this.onShowDetails(assignment);
  }

  toggleSort(field: "status" | "title" | "grade" | "submitted") {
    const isFieldAlreadySorted = this.sort === field;
    const order = isFieldAlreadySorted
      ? this.order === "asc"
        ? "desc"
        : "asc"
      : "asc";

    this.sort = field;
    this.order = order;
  }
}

AssignmentsTable.defineComponent();

export { AssignmentsTable };
