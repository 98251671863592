import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="trainer_area">
    <trainer-book-classifier></trainer-book-classifier>
</div>`;

import './styles/layout.scss';
const styles = T.css`#trainer_area {
  padding: 25px;
  background: #eff3f7;
  border-radius: 5px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, RWSViewComponent } from '@rws-framework/client';

import './children/book-classifier/component';
import './children/chapter-viewer/component';

@RWSView('junction-trainer', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class JunctionTrainer extends RWSViewComponent  {
  
    connectedCallback() {
        super.connectedCallback();            
    }
}

JunctionTrainer.defineComponent();

export { JunctionTrainer };