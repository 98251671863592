import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div
  class="modal-content">
<header class="header">
  <div class="header__title">
    <span class="material-symbols-outlined" aria-hidden="true"> add_box </span>
    <span class="title">${x => x.genericConfig.config?.id ? 'Edit a Custom Column' : 'Add a Custom Column'}</span>
  </div>
 
  <button
    class="button__secondary"
    @click="${x => x.genericConfig.switch()}">
    Cancel
  </button>
  <button
    :disabled="${x => x.hasError}"
    class="${x => x.hasError ? 'disabled' : ''} button__primary"
    @click="${x => x.hasError ? '' : x.create()}">
    ${x => x.genericConfig.config?.id ? 'Save' : 'Add'}
  </button>
</header>

<div class="description">
  <p class="margin">When a Custom Activity is created, a column is added to the Instructor Gradebook. 
    If you would like this to also show in the Student Gradebook, check “Show in the Student Gradebook.” 
  </p>
  <p class="margin">
    You may add only one Custom Assessment per lesson. You may add one Custom Assessment for the overall course. 
    You must assign points for Custom Assessments to appear in the Gradebook; Custom Assessments with zero points will not appear.
  </p>
</div>

<section class="main">
  <div>Details</div>
  <div class="main__details">
    <div class="detail">
      <label for="" class="detail__label">Lesson</label>
      <fluent-select  :value="${x => x.genericConfig.config?.lesson?.id || ''}"  @change="${(x, c) => x.updateColumn(c.event, 'lesson')}" title="Select Lesson" class="big">
        <fluent-option value=''>Select Lesson</fluent-option>       
        ${T.repeat(x => x.lessons,T.html`          
          <fluent-option value="${x => x.id}" :innerHTML="${x => x.title}"></fluent-option>
        `)}
      </fluent-select>    
    </div>
    <div class="detail" class="detail__label">
      <label for="title">Title</label>
      <input
        type="text"
        class="custom__input width"
        :value="${x => x.genericConfig.config?.title ?? null}"
        name="title"
        @change="${(x, c) => x.updateColumn(c.event, 'title')}">

      ${T.when(x => x.hasError, T.html`
        <div class="text__error error__flex">
          <span class="material-symbols-outlined"> info </span>
          Custom Column title cannot be empty.
        </div>
      `)}
    </div>
    <div class="detail">
      <label for="grade" class="detail__label">Max Grade (Points)</label>
      <input
        min="0"
        type="number"
        class="custom__input"
        name="grade"
        :value="${x =>x.genericConfig.config?.max_score || 0}"
        @change="${(x, c) => x.updateColumn(c.event, 'max_score')}">
        ${T.when(x => x.hasError, T.html`
          <div class="text__error error__flex">
            <span class="material-symbols-outlined"> info </span>
            Custom Column max grade should be bigger than 0.
          </div>
        `)}
    </div>
    <div class="detail">
      <label for="due">Due</label>
      <jnct-datetime-input
        inType="datetime-local"
        :default="${x => x.genericConfig.config?.due_date ?? null}"
        :afterChange="${x => x.updateModificationHandler}"
        fieldName="due_date"
        name="due"
        :defaulttime="23:59">
      </jnct-datetime-input>
    </div>
    <div class="detail__last">
      <div>Show in Student Gradebook</div>
      <div class="switch">
        <div
          class="switch__item ${x => x.showStudent == true ? 'active' : ''}"
          role="button"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.toggleShow() : ''}"
          @click="${x => x.toggleShow()}">
          On
        </div>
        <div
          class="switch__item ${x => x.showStudent == true ? '' : 'active'}"
          role="button"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.toggleShow() : ''}"
          @click="${x => x.toggleShow()}">
          Off
        </div>
      </div>
    </div>
  </div>
</section>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  display: flex;
  width: 207px;
  min-width: 207px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: none;
}
fluent-select.big {
  width: 100%;
}

fluent-select::part(control), fluent-select::part(listbox) {
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

fluent-select::part(control) {
  height: 40px;
}
fluent-select::part(control):hover {
  border: 1px solid var(--neutral-30);
}
fluent-select::part(control):focus, fluent-select::part(control):active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

fluent-select::part(listbox) {
  padding: 2px 0px;
  background: white;
  border: none;
}

fluent-option {
  padding: 8px 12px;
  border-radius: unset;
  border: none;
}
fluent-option.selected {
  font-weight: 500;
  border-left: 2px solid #00BBB5;
  background: rgba(0, 193, 185, 0.08);
}
fluent-option.selected::before {
  display: none;
}
fluent-option:not(.selected):hover {
  font-weight: 400;
  background: var(--Color-Neutral-Transparent-neutral-a8, rgba(153, 160, 167, 0.08));
  background: var(--Color-Neutral-Transparent-neutral-a8, color(display-p3 0.6078 0.6275 0.6471/0.08));
}

fluent-tooltip::part(tooltip) {
  display: block;
  position: relative;
  padding: 8px 16px;
  left: 12px;
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--neutral-80);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
  font-family: inherit;
}

fluent-tooltip::part(tooltip)::after {
  left: -6px;
  background-color: var(--neutral-80);
  border: none;
}

fluent-tooltip.left::part(tooltip)::after {
  right: -6px;
  left: unset;
  background-color: var(--neutral-80);
  border: none;
}

* {
  box-sizing: border-box;
}

fluent-select::part(listbox) {
  --max-height: 200px ;
}

.modal-content {
  display: flex;
  width: 779px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  color: var(--neutral-90);
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
.header__title {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}
.header__title .material-symbols-outlined {
  font-size: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--neutral-70);
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
}
.description .margin {
  margin: 0px;
}

.main {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  color: var(--neutral-90);
  font-size: 18px;
  font-weight: 500;
  line-height: 160%;
  height: calc(100vh - 300px);
}
.main__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: scroll;
}
.main__details .detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.main__details .detail__label {
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
}
.main__details .detail__last {
  display: flex;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}

.switch {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--neutral-20);
}
.switch__item {
  display: flex;
  width: 160px;
  padding: 4px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: var(--neutral-90);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
}
.switch__item.active {
  background: white;
}

.width {
  width: 100%;
}

.error__flex {
  display: flex;
  gap: 4px;
  align-items: center;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView } from "@rws-framework/client";

interface ColumnType {
  show?: boolean,
  max_score?: number,
  title?: string,
  lesson?: {
    id: string,
    title: string
  },
  type?: string,
  due_date?: string,
  id?: string,
};

@RWSView('jnct-gradebook-custom-column', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradebookCustomColumn extends RWSViewComponent  {
  @observable genericConfig: any = {};
  @observable lessons: any[];
  @observable column: ColumnType = {};
  @observable showStudent: boolean;
  updateModificationHandler = this.updateModification.bind(this);

  connectedCallback() {
    super.connectedCallback();
    this.$emit('modal:shown');
    this.lessons = [...this.genericConfig.config.lessons];
    this.showStudent = this.genericConfig.config.show || false;
  };

  updateColumn(event: Event, type: string): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    if (type === 'lesson') {
      const lesson = this.lessons.find(el => el.id === value);
      this.column = {
        ...this.column,
        lesson: lesson,
      }
      return;
    }
    if (type === 'max_score') {
      this.column = {
        ...this.column,
        max_score: Number(value),
      }
      return;
    }
    this.column = {
      ...this.column,
      [type]: value,
    }
  }

  updateModification(fieldName: string, value: string, inputField: any) {
    if (!value) return;
    this.column.due_date = value;
    if (inputField) inputField.value = value
  }

  get hasError() {
    if (!this.genericConfig.config?.id) {
      return this.column.title === '' || !this.column.max_score;
    }
  };

  async create() {
    if (this.showStudent) {
      this.column.show = true;
    } else {
      this.column.show = false;
    };
    this.column.type = 'custom';
    if (this.genericConfig.config.id) {
      for (let key in this.genericConfig.config) {
        if (this.genericConfig.config.hasOwnProperty(key)) {
          if (!this.column.hasOwnProperty(key) && key !== 'lessons') {
            this.column[key] = this.genericConfig.config[key];
          }
        }
      }
    }
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    
    try {
      const response = await fetch(APIprefix + 'gradebook/' + this.genericConfig.courseId + '/add_column/', {
        method: "POST",
        body: JSON.stringify(this.column),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });

      const responseBody = await response.json();
      if (responseBody.status !== 'OK') {
        this.$emit('jnct:notifications:info', responseBody.status);
        this.genericConfig.switch();
        return;
      }
      
      const message = 'Column "' + this.column.title + '" ' + (this.column.id?"updated":"added") + '.';
      this.$emit('jnct:notifications:success', message);
      this.genericConfig.switch();
      this.$emit('gradebook:reload:data');
    } catch (error) {
      console.info("Error:", error);
    };
  }

  toggleShow() {
    this.showStudent = !this.showStudent;
  }
}

GradebookCustomColumn.defineComponent();

export { GradebookCustomColumn };
