import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="grading__header">
  <h1>
    Grading Center
  </h1>

  ${T.when(x=>x.loaded, T.html`
    <div class="flex-20">
      <button class="button__tertiary filter" @click="${x => x.openFilter()}">
        Filter ${x => x.filterBy || ''}
        <span class="material-symbols-outlined tune"> tune </span>
      </button>

      <jnct-searchbar :handleChange="${x => x.handleSearch}"></jnct-searchbar>
    </div>
  `)}
</header>


${T.when(x => !x.loaded, T.html`
  <section class="loader">
    <jnct-loading-spinner></jnct-loading-spinner>
  </section>
`)}

${T.when(x => x.loaded && x.table_data.length > 0, T.html`
  <section>
    <div class="grading__length">
      <div>
        Showing ${x => x.table_data?.length} items
      </div>

      ${T.when(x => x.gradeby.length, T.html`
        <div>
          Default: Grade by ${x => x.gradeby === 'bystudent' ? 'Student' : 'Question'} 
          (<span
            class="link"
            tabindex="0"
            @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.cancelGradeby() : ''}"
            @click="${x => x.cancelGradeby()}">Cancel</span>)
        </div>
      `)}
    </div>

    <jnct-table
      :data="${x => x.table_data}"
      :columnsConfig="${x => x.columns}"
      columnsLayout="auto 150px 150px 130px 130px 130px 116px">
    </jnct-table>
  </section>
`)}

${T.when(x => x.loaded && x.table_data.length === 0, T.html`
  <jnct-notfound
    headerText="No Assignments to Grade"
    description="Change your filters to see more Assignments.">
  </jnct-notfound>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.grading__header {
  display: flex;
  margin-bottom: 32px;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.grading__header h1 {
  padding: 0px;
  margin: 0px;
  color: var(-neutral-90);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.grading__length {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}

.filter {
  width: 104px;
  border: none;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.filter .tune {
  font-size: 22px;
}

.flex-20 {
  display: flex;
  gap: 20px;
}

.loader {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}`;

const shadowOptions = {"mode":"open"};
import {
  RWSView, RWSViewComponent, RWSInject, observable
} from '@rws-framework/client';

import ActivitiesService, {ActivitiesServiceInstance} from '../../services/ActivitiesService';
import EventsService, {EventsServiceInstance} from "../../services/EventsService";
import {Activity, ActivityStudentScore, ActivityWithGradeStats} from "../../types/activities";
import {RosterRecord} from "../../types/users";
import { Course } from 'src/types/course';
import { ColumnConfig, Data } from "../../types/table";
import { GradingCenterModal } from './gradingcentermodal/component';
import { GradingCenterFilterModal } from './gradingCenterFilterModal/component';

type AssessmentType = {
  title: string,
  type: string,
  chosen: boolean
}

type ChapterType = {
  title: string,
  id: string,
  chosen: boolean
}

@RWSView('grading-center', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class GradingCenter extends RWSViewComponent  {
  @observable loaded: boolean = false;
  @observable gradeby: string = '';
  @observable student_ids: string[];
  @observable activity_manager: ActivityWithGradeStats[];
  @observable activities_filtered: ActivityWithGradeStats[];
  @observable activities: Data[] = [];
  @observable table_data: Data[];
  @observable activity_student_score: ActivityStudentScore;
  @observable course: Course;
  columns: ColumnConfig[] = [
    {
      name: "link_title", 
      title: "Title", 
      allowSorting: true,
      link: true,
    },
    { 
      name: "submissions", 
      title: "Submissions", 
      allowSorting: true,
    },
    {
      name: "to grade",
      title: "To Grade",
      allowSorting: true 
    },
    {
      name: "got late",
      title: "Late",
      allowSorting: true,
      link: true,
    },
    {
      name: "due",
      title: "Due Date",
      allowSorting: true,
      date: true,
    },
    {
      name: "close",
      title: "Close Date",
      allowSorting: true,
      date: true,
    },
    {
      name: "goto",
      title: '',
      allowSorting: false,
      click: true,
    },
  ];
  @observable types: AssessmentType[] = [
    {title: 'Assessment', chosen: true, type: 'assessment'}, 
    {title: 'Discussion Board', chosen: true, type: 'board'},
    {title: 'Project', chosen: true, type: 'project'},
    {title: 'Project Comments', chosen: true, type: 'comments'},
    {title: 'Show Already Graded', chosen: false, type: 'graded'},
    {title: 'Show Auto-Graded', chosen: false, type: 'Auto'},
  ];
  @observable chapters: ChapterType[];

  constructor (
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
    @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
  ) {
    super()
  };

  async connectedCallback() {
    super.connectedCallback();
    this.loaded = false;
    this.$emit('jnct:dashboardService:flush');
    const roster = await this.eventsService.getRoster();
    const average = await this.eventsService.getAverage();
    this.course = await this.eventsService.getCourse();
    this.student_ids = roster.map(student => student._id);

    const activities: Activity[] = average.activity_manager;

    this.activity_student_score = average.activity_student_score;

    const gradingActivities = activities
      .filter(a =>this.activityService.isGradeableActivity(a))
      .map(a => this.activityService.calculateActivityGradeStats(a, this.activity_student_score, this.student_ids))
      .filter(a => a.submissions > 0);

    this.activity_manager = gradingActivities;

    const chapters = this.activity_manager?.map(item => {
      return {
        'title': item.lessonName || item.title,
        id: item.lesson?.id,
        chosen: false,
      };
    });

    // Remove duplicates based on the 'id' property
    this.chapters = chapters.filter((chapter, index, self) =>
      index === self.findIndex(c => c.id === chapter.id)
    );

    this.filterActivities(this.types, this.chapters, false)

    this.handleSearch = this.handleSearch.bind(this);

    if (localStorage.getItem('gradeAssessmentBy')) {
      this.gradeby= localStorage.getItem('gradeAssessmentBy');
    };

    this.loaded = true;
  };

  handleSearch(value: string) {
    if (value) {
      this.table_data = this.activities.filter(activity => {
        if (activity.link_title.value.toString().toLowerCase().includes(value.toLowerCase())) {
          return activity;
        }
        if (activity.due.value?.toString().toLowerCase().includes(value.toLowerCase())) {
          return activity;
        }
        if (activity.close.value?.toString().toLowerCase().includes(value.toLowerCase())) {
          return activity;
        }
      })
    } else {
      this.table_data = this.activities;
    }
  };

  gotoAssessmentBind = this.gotoAssessment.bind(this);

  gotoAssessment(obj: any) {
    const item = obj.onClickValue;
    if (item.submissions == 0 ) {
      return;
    }
    if (item.scoring_type === 'Auto' || item.sub_type === "branching") {
      this.$emit('jnct:gradingcenter:gradeby', {component_id: item.id, action: 'bystudent'});
      return;
    }
    if (['project', 'comments', 'board'].includes(item.type)) {
      this.$emit('jnct:gradingcenter:gofromgradingcenter', item);
    };
    if (item.type === 'assessment') {
      if (this.gradeby.length) {
        this.$emit('jnct:gradingcenter:gradeby', {component_id: item.id, action: this.gradeby});
        return;
      };

      this.openModal(item);
    }

    return;
  };

  cancelGradeby() {
    this.gradeby = '';
    localStorage.removeItem('gradeAssessmentBy');
    this.$emit('jnct:notifications:info', 'The default choice has been canceled');
  };

  openModal(item: any) {
    const modal = document.createElement('grading-center-modal') as GradingCenterModal;
    modal.text = item.lessonName + ':' + (item.title || item.instructor_title);
    modal.component_id = item.id;
    document.body.appendChild(modal);
  };

  openFilter() {
    const modal = document.createElement('filter-modal') as GradingCenterFilterModal;
    modal.chapters = this.chapters;
    modal.types = this.types;
    modal.action = this.filterActivities.bind(this);
    document.body.appendChild(modal);
  }

  filterActivities(types: AssessmentType[], chapters: ChapterType[], changedChapter: boolean) {
    this.types = types;
    this.chapters = changedChapter ? chapters : this.chapters;
    const autoType = this.types.find(type => type.type === 'Auto');
    const gradedType = this.types.find(type => type.type === 'graded')

    const allowedActivityTypes = this.types
      .filter(
        (t) =>
          ["assessment", "board", "project", "comments"].includes(t.type) &&
          t.chosen
      )
      .map((t) => t.type);

    const filteredData = this.activity_manager.filter((a) =>
      this.activityService.filterGradeableActivity(a, {
        type: allowedActivityTypes,
        scoring: autoType.chosen
          ? ["Auto", "Manual", "Mixed"]
          : ["Manual", "Mixed"],
        includeAlreadyGraded: !!gradedType.chosen,
        lessonIds: changedChapter
          ? chapters.filter((c) => c.chosen).map((c) => c.id)
          : null,
      })
    );

    const changeFormat = filteredData.map(item => {
      const activity = {
        "link_title": { 
            "value": `${item.lessonName}:${item.title || item.report_title}`,
            "link": item.link + '/' + item.id
        },
        "submissions": { "value": String(item.submissions) },
        "to grade": { "value": String(Math.max(0, item.submissions - item.count_graded - item.count_overdue)) },
        "got late": {
          "value": String(item.count_overdue),
          "link": `#/${this.course._id}/late_work_manager`
        },
        "due": { "value": item.due_date },
        "close": { "value": item.close_date },
        "type": { "value": item.type },
        "goto": { "value": 'Grade', 'onClick': this.gotoAssessmentBind, 'onClickValue': item },
        "lesson_id": { "value": item.lesson?.id }
      }

      return activity
    })

    this.activities = changeFormat;
    this.table_data = changeFormat;

    return;
  }
}

GradingCenter.defineComponent();

export { GradingCenter };
