import {AssessmentAttempt} from '../types/reports';

export function LearnositySessionDuration(score: AssessmentAttempt): string {
  let session_time = '';
  const total_time = score.items.reduce((total: number, item) => total + (item.time || 0), 0);
  const minutes = Math.floor(total_time / 60);
  const seconds = total_time % 60
  session_time = minutes + 'm ' + seconds + 's';
  return session_time;
};

// this is only for assessment
export function getLearnosityStatus(score, overrides, due_date: string = ''): string {
  if (score.status !== "Completed" ) return 'Not Submitted';

  const is_late = (new Date(overrides?.due_date || due_date) < new Date(score.dt_completed))

  if ((score.overdue || is_late) && ((overrides && overrides?.policy) || score.policy_applied)) {
    return 'Late (Auto-Deduction Applied)'
  };

  //we store manually_accepted for all scores (we dont know which exactly score is manually_accepted or manually_rejected)
  //so we need to add this status only for late scores
  //we are using actual score instead of override cause when instructor accept late attemp - it automatically get override with new due_date
  if (score.manually_rejected && is_late) return 'Late (Rejected)';
  if (score.overdue || is_late) return 'Late';
  if (score.manually_accepted && new Date(score.dt_completed) > new Date(due_date)) return 'Late (Accepted)';
  
  return 'On Time';
}
