import { IConvo } from '@backend-types';
import { WebChat } from './component';

export function handleExternalChanges(this: WebChat)
{
    this.custombookidChanged = function (this: WebChat, oldVal: string, newVal: string) {
        if (newVal) {
            this.custombookid = newVal;
            this.checkForBookId();
        } else {
            this.custombookid = null;
        }
    }.bind(this)

    this.customchapternrChanged = function (this: WebChat, oldVal: string, newVal: string) {
        if (newVal) {
            this.customchapternr = newVal;
            this.checkForBookChapter();
        } else {
            this.customchapternr = null;
        }
    }.bind(this)

    this.convoListChanged = function (this: WebChat, oldVal: IConvo[] | undefined, newVal: IConvo[]){
        if(newVal.length){
            this.checkLastConvo(newVal)
        }
    }.bind(this)

    this.devChanged = function (this: WebChat, oldVal: string, newVal: string) {
        if (oldVal !== newVal) {
            this.dev = newVal === 'true' ? 'true' : 'false';
        }
    }.bind(this)

    this.minifiedChanged = function(this: WebChat, oldVal: boolean, newVal: boolean)
    {        
        if(this.forceOpened === 'true' && !this.minified){
            this.opened = 'true';
            this.minified = false;            
            return;
        }

        if(newVal){    
            document.querySelector('body').classList.add('hide-chat');
        } else {
            document.querySelector('body').classList.remove('hide-chat');
        }       
    }.bind(this)
}
