import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section class="lti">
  <div class="lti__header">
    <button title="go to previous step" class="button__no-border" tabindex="0" @click="${x => x.goBack()}">
      <jnct-icon size="21px">arrow_back</jnct-icon>
      Back
    </button>
    <div class="lti__header--main">
      <h1 class="lti__header--name">
        LTI Setup
      </h1>
    </div>

    <ul class="lti__header--steps">
      ${T.repeat(x => x.steps, T.html`
        <li class="step">
          <div class="step__stroke ${(x,c) => c.parent.active >= x ? 'active' : ''}"></div>
        </li>
      `)}
    </ul>
  </div>

  ${T.when(x => x.pickAssignments  && !x.confirmAllOption, T.html`<div class="lti__search">
    <div class="lti__search--title">
      <jnct-icon size="21px">sync</jnct-icon>
      Select Assignments to Sync
    </div>
    <div class="lti__flex">
      ${T.when(x => x.selectedCount, T.html`<button class="button__primary" @click="${x => x.goNext()}">Next</button>`)}      
      ${T.when(x => x.syncAssignments.length, T.html`<jnct-searchbar></jnct-searchbar>`)}      
    </div>
  </div>`)}

  ${T.when(x => x.pickAssignments && !x.confirmAllOption, T.html`<main>    
    <lti-sync-assignments :selected="${ x => x.selectedSyncAssignments }" @change="${(x, c) => x.onSelectedSyncAssignments(c.event) }" :assignments="${x => x.syncAssignments}"></lti-sync-assignments>   
    ${T.when(x => !x.syncAssignments.length, T.html`<div><jnct-loading-spinner></jnct-loading-spinner></div>`)}
  </main>`)}
  
  ${T.when(x => !x.pickAssignments, T.html`
    ${T.when(x => x.active === 1, T.html`
      <article class="lti__text">
        This course has been connected to your LMS.
        You can now sync your gradebook with the gradebook in your LMS.  
        In addition to scores, we will sync assignment points and due dates. 
        Please click next to select the assignments you'd like to sync.
      </article>
    `)}

    ${T.when(x => x.active === 1, T.html`
      <h2 class="lti__title">
        What assignments would you like to sync to your LMS?
      </h2>
    `)}

    ${T.when(x => x.active === 2 && !x.confirmAllOption, T.html`
      <h2 class="lti__title">
        How would you like assignments to appear in your LMS grade book?
      </h2>
    `)}

    ${T.when(x => x.active !== 3  && !x.confirmAllOption, T.html`
      <ul class="lti__list">
        ${T.repeat(x => x.active === 1 ? x.options : x.gradebook_options, T.html`
          <li class="lti__item" style="display: flex; direction: row; justify-items: space-between;">
            
            <div class="main${(x, c) => c.parent.active === 2 ? ' soon' : '' }">
              <div class="main__bold" :innerHTML="${x => x.title}"></div>
              <div :innerHTML="${x => x.description}"></div>
            </div>                        

            ${T.when((x, c) => c.parent.active === 1 || c.parent.active === 2, T.html`
            <jnct-icon style="cursor: pointer;" @click="${(item, c)=> c.parent.chooseOption(item)}">
              arrow_right_alt
            </jnct-icon>
            `)}
          </li>
        `)}
      </ul>
    `)}    
  `)}
  
  ${T.when(x => x.active === 3, T.html`   
    <lti-sync-process :selectedAssignments="${x => x.selectedSyncAssignments}"
      courseId="${x => x.courseId}" 
      apiPrefix="${x => x.apiPrefix}" 
      page="setup" 
      type="${x => x.confirmAllOption}"
      :course_config="${ x => x.course_config}"
      :completedSyncAssignments="${ x=> x.completedSyncAssignments }"
      :completedSyncGrades="${ x=> x.completedSyncGrades }"
      :completedSyncSelections="${ x=> x.completedSyncSelections }"
    >
      <p>
        Ready to proceed?
        ${T.when(x => !["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.confirmAllOption), T.html`
        Assignments (${x => x.selectedSyncAssignments.length}) will be created as needed.
       `)}
        ${T.when(x => ["roll-up", "roll-up-percent", "total", "total-percent", "chapter", "chapter-percent"].includes(x.confirmAllOption), T.html`
        Assignments will be created as needed.
       `)}
      </p>
    </lti-sync-process>
  `)}

  ${T.when(x => x.confirmAllOption && x.active === 2, T.html`   
    <lti-sync-confirm 
      poor="true"
      title="Would you like to create your assignments in your LMS now?" 
      abortText="No" 
      proceedText="Yes" 
      @change="${ (x, c) => x.onAllConfirmation(c.event) }">      
    </lti-sync-confirm>
  `)}  
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.lti {
  box-sizing: border-box;
  padding: 14px 22px;
}
.lti .center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}
.lti .center__button {
  width: 380px;
  margin-bottom: 8px;
  justify-content: center;
}
.lti__header {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.lti__header--previous {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 24px 8px 0px;
  color: var(--neutral-90);
  font-size: 1.14286em;
  font-weight: 600;
  line-height: 160%;
}
.lti__header--previous .material-symbols-outlined {
  font-size: 18px;
}
.lti__header--main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lti__header--name {
  padding: 5px 0px;
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.lti__header--steps {
  width: 100%;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  list-style: none;
}
.lti__header--steps .step {
  flex: 1 0 0;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.lti__header--steps .step:first-child .step__stroke {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.lti__header--steps .step:last-child .step__stroke {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.lti__header--steps .step__stroke {
  height: 6px;
  width: 100%;
  color: black;
  background: var(--neutral-20);
}
.lti__header--steps .step__stroke.active {
  background: var(--primary_color);
}
.lti__header--steps .step.active {
  font-weight: 600;
}
.lti__flex {
  display: flex;
  gap: 20px;
  align-items: center;
}
.lti__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px;
}
.lti__search--title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.lti__search--title .material-symbols-outlined {
  font-size: 22px;
}
.lti__loader {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lti__text {
  margin-bottom: 32px;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.lti__title {
  margin-bottom: 32px;
  padding: 0px;
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 600;
  line-height: 140%;
}
.lti__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.lti__item {
  display: flex;
  padding: 16px 40px 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-10);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.lti__item .main {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.lti__item .main.soon {
  padding-right: 30px;
}
.lti__item .main__bold {
  display: inline;
  color: var(--neutral-90);
  font-size: 1.28571em;
  font-weight: 600;
  line-height: 160%;
}

:host(.checked) .control {
  background-color: pink;
}

.link {
  color: #0F75B8;
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
  cursor: pointer;
}

.coming-soon {
  border: 1px double var(--jnct-warning-color);
  border-radius: 15px;
  padding: 6px;
  color: var(--jnct-warning-color);
  width: 140px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}`;

const shadowOptions = {"mode":"open"};

import { observable, RWSViewComponent, RWSView, attr, RWSInject } from "@rws-framework/client";
import { LTIConfigType } from "../../../../types/lti/config";
import { LTISyncConfirmValue } from "../ltiSyncConfirm/component";
import { AssignmentsType } from "../ltiSyncAssignments/component";
import LTISyncService, { LTISyncServiceInstance } from "../../../../services/LTISyncService";
import _values from "./_values";

type OptionType = {
  type: string,
  title: string,
  description: string    
}

@RWSView('lti-sync-setup', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class LtiSetup extends RWSViewComponent  {
  @attr apiPrefix: string;
  @observable steps: number[] = [1, 2, 3];
  @observable active: number = 1;
  @observable pickAssignments: boolean = false;
  @observable syncAssignments: AssignmentsType[] = [];
  @observable selectedSyncAssignments: AssignmentsType[] = [];
  @observable buildTitle: string = (window as any).labelBundle.title;
  @observable course_config: LTIConfigType;
  @observable selectedCount: number = 0;
  @observable confirmAllOption: string | null = null;
  @attr courseId: string;
  @observable inProceed: boolean = false;

  @observable completedSyncAssignments: any = [];
  @observable completedSyncGrades: any = [];
  @observable completedSyncSelections: any = [];

  private forceBackToActive: number | null = null;

  options: OptionType[] = _values.bind(this)().options;
  gradebook_options: OptionType[] = _values.bind(this)().gradebook_options;

  constructor(@RWSInject(LTISyncService) private ltiSyncService: LTISyncServiceInstance){
    super();
  }
  
  connectedCallback () {
    super.connectedCallback();

    this.on<boolean>('jnct:ltisyncmanager:proceed-cancel', (e) => {
      if (e.detail) {   
        this.goPrevious()
      }
    });

    this.on<boolean>('jnct:ltisyncmanager:sync-done', (e) => {
      if (e.detail) {   
        this.goPrevious()
      }
    });
  };

  goPrevious() {
    if (this.forceBackToActive) {
      this.active = this.forceBackToActive
      this.forceBackToActive = null
    } else if (this.active > 1) {
      this.active--
    }
    if (this.active === 1) this.confirmAllOption = null
  }

  countSelected(): void {    
    this.selectedCount = this.selectedSyncAssignments ? this.selectedSyncAssignments.filter(x=>x.graded).length : 0;
  }  

  chooseOption(option: OptionType) {    
    this.pickAssignments = false

    if (option.type === 'total') {
      this.confirmAllOption = option.type
      this.pickAssignments = false
      this.forceBackToActive = 1
      this.active = 3
      return
    }  


    if (option.type === 'specific') {
      this.pickAssignments = true
      this.active = 2
      return
    }  

    if (option.type === 'none') {
      this.confirmAllOption = option.type
      this.selectedSyncAssignments = []
      this.forceBackToActive = 1
      this.active = 3
      return
    }

    if (option.type === 'all') {
      this.active = 2
      return
    }   

    this.confirmAllOption = option.type

    if (option.type == "assignment") {
      this.selectedSyncAssignments = this.syncAssignments.filter(x=>x.graded)
      this.countSelected()
    }
    
    this.$emit('jnct:ltisetup:choose', option)
  }
  
  onSelectedSyncAssignments(event: CustomEvent<AssignmentsType[]>) {
    if (event.detail) {      
      this.selectedSyncAssignments = event.detail
      this.countSelected()
    }
  }

  syncAssignmentsChanged(oldVal?: any[], newValue?: any[]) {    
    if (newValue) {
     this.startPick()
     this.countSelected()
    }    
  }
  
  course_configChanged(oldVal?: LTIConfigType, newValue?: LTIConfigType): void {    
    if (newValue) {             
      this.course_config = newValue;  
      this.startPick();       
    }
  }

  startPick() {
    if (!this.course_config && !this.syncAssignments.length) return;

    const cfg = this.course_config[0];
    const lineAssignments: AssignmentsType[] = [];

    if (cfg.selection) {
      for( const lineKey of Object.keys(cfg.line_items) ) {
        if (lineKey) {
          const foundAssignment = this.syncAssignments.find((el) => el.id === lineKey)

          if (foundAssignment?.graded && !lineAssignments.find(el => el.id === foundAssignment.id)) {
            lineAssignments.push(foundAssignment)
          }
        }
      }
      
      this.selectedSyncAssignments = lineAssignments
    }
    this.countSelected()
  }

  onFinalConfirm(event: CustomEvent<LTISyncConfirmValue>) { 
    if (event.detail === 'proceed') {      
      this.done()
      this.$emit('jnct:ltisetup:gotoltisyncmanager', true)
    } else {
      this.abort()
    }
  }

  onAllConfirmation(event: CustomEvent<LTISyncConfirmValue>) { 
    if (event.detail === 'proceed') {
      this.active = 3
      return
    }

    this.abort()
  }

  goNext() {
    this.active = 3
    this.pickAssignments = false
  }

  done() {
    this.active = 1
    this.confirmAllOption = null
    this.pickAssignments = false
    this.goBack()
    this.goProceed()
  }

  abort() {
    this.active = 1
    this.confirmAllOption = null
    this.pickAssignments = false
  }

  goBack() {
    this.countSelected()
    if (this.forceBackToActive !== null) {
      this.pickAssignments = this.forceBackToActive === 2
      this.active = this.forceBackToActive
      if (this.active === 1) this.confirmAllOption = null
      this.forceBackToActive = null
      return
    }

    if (this.active > 1) {
      if (this.pickAssignments) this.pickAssignments = this.active === 2;
      this.active--
      if (this.active === 1) this.confirmAllOption = null
    } else {
      this.$emit('jnct:ltisyncmanager:reloadLTI', true)
    }
  }

  async goProceed() {
    this.inProceed = true
  }

  courseIdChanged(oldVal?: string, newVal?: string) {
    this.courseId = newVal
  }

  completedSyncAssignmentsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncAssignments = newValue;
      //console.log('Completed assignments in RWS:', newValue);
    }
  };

  completedSyncGradesChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncGrades = newValue
      //console.log('Completed grades in RWS:', newValue)
    }
  };

  completedSyncSelectionsChanged(oldValue: any, newValue: any) {
    if (newValue) {
      this.completedSyncSelections = newValue
      //console.log('Completed selections in RWS:', newValue)
    }
  };
};

LtiSetup.defineComponent();

export { LtiSetup }
