import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div 
	class="webchat-container ${ x => x.minified ? '' : 'shown'}${x => x.navOpened ? ' nav-opened' : ''}${x => x.isInstructor ? ' is-instructor' : ''}"
	style="${ x => x.isInstructor ? '--chatH: ' + (x.chatH)  +'px' : ''}"
>
	<div class="webchat-op-area">		
		${T.when(x => x.navOpened && x.secondaryColor, T.html`<aside>
				${ T.when(x => !x.isInstructor, T.html`<chat-nav 
					:courseId="${x => x.courseId}"
					:list="${x => x.convoList}"
					style="--chat-nav-hover-bg: ${ x => x.secondaryColorDark}; --chat-nav-light-hover-bg: ${ x => x.secondaryColor};"
					:boxData="${ x => x.boxData }" 
					:convoId="${x => x.convoId}"
					:areaH="${ x => x.areaH }">
				</chat-nav>`)}	
				
				${ T.when(x => x.isInstructor, T.html`<instructor-chat-nav 
					:courseId="${x => x.courseId}"
					:list="${x => x.convoList}"
					style="--chat-nav-hover-bg: ${ x => x.secondaryColorDark}; --chat-nav-light-hover-bg: ${ x => x.secondaryColor}; border: 1px solid #EEEFF0;"
					:boxData="${ x => x.boxData }" 
					:convoId="${x => x.convoId}"
					:areaH="${ x => x.areaH }"
					>
				</instructor-chat-nav>`)}
		</aside>`)}
		
		<div id="web_chat_content_area">		
			${ T.when(x => x._init, T.html`
				<chat-header :currentConvo="${ x => x.currentConvo }" :profile="${x => x.chatProfile}" :chapterNr="${x => x.chapterNr}" :closeAllowed="${x => !x.forceOpened}" :instructor="${x => x.isInstructor}" :navOpened="${x => x.navOpened}" :boxData="${ x => x.boxData }" :opened="${x => x.navOpened}"></chat-header>	
				<main style="height: ${x => x.isInstructor ? '0' : (x.chatH)}px">
					<chat-convo-area 
						:query="${ x => x.query}" 
						:instructor="${ x => x.isInstructor}"
						:courseId="${ x => x.courseId }"
						:boxData="${ x => x.boxData }"
						:areaH="${ x => x.areaH }"
						:initials="${x => x.initials}"
						:userImage="${x => x.userImage}"
						:dev="${ x => x.dev }"
						:convoId="${x => x.convoId}"
						:wsId="${x => x.wsId}"
						:inspectedUser="${x => x.inspectedUser}"
						:noChoose="true"
						:msgOptions="${x => x.msgOptions}" :book="${x => x.bookModel}"
						:chapter="${x => x.chapterNr}"  :chosenModel="${x => x.chosenModel}" 
						:messages="${x => x.messages}" :chatContext="${x => x.chatContext}">
					</chat-convo-area>					
					${ T.when(x => !x.isInstructor, T.html`<chat-convo-footer 
						:typing="${x => x.typing}"
						:profile="${x => x.chatProfile}" 
						:boxData="${ x => x.boxData }" 
						dev="${ x => x.dev }" 
						convoId="${x => x.convoId}" 
						:chosenModel="${x => x.chosenModel}">
					</chat-convo-footer>`)}		
				</main>
			`)}			
		</div>		
	</div>
	${T.when(x => !x.minified && x.dev === 'true' && 1==2, T.html`<web-chat-dev-zone
		:temperatureHandler="${x => x.handlers(x).temperatureHandler}"
		:topKHandler="${x => x.handlers(x).topKHandler}"
		:topPHandler="${x => x.handlers(x).topPHandler}"
		:maxTokensToSampleHandler="${x => x.handlers(x).maxTokensToSampleHandler}"
		:temperature="${x => x.customTemperature }"
		:topK="${x => x.customTopK }"
		:topP="${x => x.customTopP }"
		:maxTokensToSample="${x => x.customMaxTokensToSampleHandler }"
	></web-chat-dev-zone>`)}
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: block;
}

a {
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
}

:host {
  transition: all 0s ease-in;
  min-width: 365px;
  min-height: 548px;
  max-height: 80vh;
  bottom: 65px;
  right: 30px;
  position: fixed;
  z-index: 100001;
  display: block;
}

body {
  background-color: var(--jchat-base-bg-color, #abd9e9);
  font-family: inherit;
}

.webchat-container {
  width: 100%;
  margin: 0px auto 0 auto;
  font-size: 15px;
  position: absolute;
  display: none;
  height: var(--chatH, 100%);
  margin-left: auto;
  margin-right: auto;
}
.webchat-container .webchat-op-area {
  display: none;
  background-color: var(--neutral-10);
  border: 8px solid var(--neutral-70);
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.webchat-container .webchat-op-area aside {
  display: none;
}
.webchat-container .webchat-op-area #web_chat_content_area {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .webchat-op-area #web_chat_content_area {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .webchat-op-area #web_chat_content_area {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .webchat-op-area #web_chat_content_area {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .webchat-op-area #web_chat_content_area main {
  height: 100%;
  position: relative;
  font-size: 15px;
  vertical-align: top;
}
.webchat-container.nav-opened .webchat-op-area aside {
  flex: 0 0 224px;
  max-width: 224px;
  max-height: 100%;
  border-right: none !important;
  border-top-left-radius: 15px;
  background-color: #FFF;
  display: flex;
  align-items: stretch;
}
.webchat-container.nav-opened .webchat-op-area #web_chat_content_area {
  flex: 0 0 calc(100% - 224px);
  max-width: calc(100% - 224px);
}
.webchat-container.is-instructor {
  position: static;
}
.webchat-container.is-instructor .webchat-op-area {
  display: none;
  border: none;
  background-color: #FFF;
}
.webchat-container.is-instructor.nav-opened .webchat-op-area aside {
  flex: 0 0 344px;
  max-width: 344px;
}
.webchat-container.is-instructor.nav-opened .webchat-op-area #web_chat_content_area {
  flex: 0 0 calc(100% - 344px);
  max-width: calc(100% - 344px);
}
.webchat-container.shown {
  display: flex;
}
.webchat-container.shown .webchat-op-area {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.webchat-container.shown .webchat-op-area main, .webchat-container.shown .webchat-op-area aside {
  border: none;
}
.webchat-container .controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.webchat-container .controls .control-col {
  flex: 0 0 25%;
  max-width: 25%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .control-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .control-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .control-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .control-col-small {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .control-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .control-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .control-col-small {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .control-half {
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .control-half {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .control-half {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .control-half {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .control-full {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .control-full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .control-full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .control-full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .h-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .h-controls {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .h-controls {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .h-controls {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .toggler-col {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: auto;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .webchat-container .controls .toggler-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .webchat-container .controls .toggler-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .webchat-container .controls .toggler-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.webchat-container .controls .param_switch {
  border-radius: 30px;
  background-color: var(--primary_color, #da1a5f);
  margin-left: auto;
  padding: 5px;
  margin-top: 30px;
  margin-right: 10px;
  cursor: pointer;
}
.webchat-container .controls .param_switch svg {
  max-width: 100%;
  max-height: 100%;
}
.webchat-container .controls .param_switch svg path {
  fill: #FFF !important;
}

.chat_toggler {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: var(--primary_color, #da1a5f);
  margin-left: auto;
  padding: 10px;
  margin-top: 0px;
  bottom: 0px;
  top: auto;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  position: absolute;
  display: block;
}
.chat_toggler svg {
  max-width: 100%;
  max-height: 100%;
}
.chat_toggler svg path {
  fill: #FFF !important;
}

.opened .chat_toggler {
  bottom: auto;
  top: 0;
  right: 0;
  display: none;
}

#webchat-model-config-wrap {
  position: fixed;
  right: 0;
  top: 0;
  width: 50px;
}
#webchat-model-config-wrap .config-toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 50px;
  height: 50px;
  background: var(--jchat-secondary-bg-color, #3b3e49);
  padding: 15px;
}
#webchat-model-config-wrap aside {
  display: none;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, ngAttr, RWSInject, externalAttr, externalObservable } from '@rws-framework/client';
import { observable } from '@microsoft/fast-element';
import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';
import { IConvoMsgOptions } from './children/chat-area/component';
import ResizeService, { ResizeServiceInstance } from '../../services/ResizeService';

import { IMessage } from './children/chat-message/component';
import { ClaudeModel, BedrockBaseModel, IBook, IConvo, DEFAULT_CLAUDE2_PARAMS, IChatProfile, IJWTUser } from '@backend-types';

import assignHandlers, { IWebChatHandlers, handleEvents } from './handlers';
import { callStreamApi, getCSSVar, getProfile, hexToRGBA } from './callers';
import { getDefaultParams, setHyperParam, ChangeHandlerType, getParamChangeHandlers, IHyperHandler } from './hyperparams';

import { v4 as uuid } from 'uuid';
import ConvoService, { ConvoServiceInstance } from '../../services/ConvoService';

import { IBoxHolder, IBoxData } from '../../types/box-data';

import { handleExternalChanges } from './changes';
import { handleWindowMechanics } from './window';


type PseudoBool = 'true' | 'false';

interface IContext {
    label?: string
    active?: boolean
    context_id?: string
}

const _CHAT_MIN_H = 548;

@RWSView('web-chat', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class WebChat extends RWSViewComponent  implements IBoxHolder {

    static fileList: string[] = [
        'svg/icon_talk_1.svg'
    ];

    @observable messages: IMessage[] = [];
    @observable hyperParameters: { key: string, value: any } | any = {};
    @observable bookId: string = null;
    @observable chapterNr: number = null;
    @observable chosenModel: BedrockBaseModel = null;
    @observable chatContext: IContext = { label: 'Book chat' };
    @observable bookModel: IBook = null;
    @observable minified: boolean = true;   
    @observable _init: boolean = false;   
    @observable customTemperature: number = DEFAULT_CLAUDE2_PARAMS.temperature;
    @observable customTopK: number = DEFAULT_CLAUDE2_PARAMS.top_k;
    @observable customMaxTokensToSample: number = DEFAULT_CLAUDE2_PARAMS.max_tokens_to_sample;
    @observable customTopP: number = DEFAULT_CLAUDE2_PARAMS.top_p;
    @externalObservable convoId: string;
    @observable wsId: string;
    @observable navOpened: boolean = false;
    @observable boxData: IBoxData;   
    
    @observable header: HTMLElement = null;
    @observable footer: HTMLElement = null;

    @ngAttr dev: PseudoBool = 'false';
    @ngAttr forceOpened: PseudoBool = 'false';
    @ngAttr instructor: PseudoBool = 'false';
    @ngAttr opened: PseudoBool = 'false';

    @ngAttr hTemperature?: string = DEFAULT_CLAUDE2_PARAMS.temperature.toString();
    @ngAttr hTopK?: string = DEFAULT_CLAUDE2_PARAMS.top_k.toString();
    @ngAttr hMaxTokensToSample?: string = DEFAULT_CLAUDE2_PARAMS.max_tokens_to_sample.toString();
    @ngAttr hTopP?: string = DEFAULT_CLAUDE2_PARAMS.top_p.toString();

    @ngAttr userImage: string | null = null;
    @ngAttr initials: string | null = 'U';

    @ngAttr courseId: string = null;
    @ngAttr lessonId: string = null;
    @externalAttr personaId: string = null;

    @ngAttr custombookid: string = null;
    @ngAttr customchapternr: string = null;

    @observable chatProfile: IChatProfile = null;
    @observable isInstructor: boolean = false;
    @observable inspectedUser: string;  

    handlers: (this: WebChat) => IWebChatHandlers = assignHandlers;
    streamCall: (msg: IMessage) => Promise<void> = callStreamApi;    
    profileCall: (this: WebChat) => Promise<void> = getProfile;
    getCSSVar: (cssVarName: string) => string = getCSSVar; 
    hexToRGBA: (hex: string, alpha?: number) => string = hexToRGBA   

    getDefaultHyperParams = getDefaultParams;
    setHyperParam = setHyperParam;

    @observable handler: HTMLElement;
    @observable secondaryColorDark: string = null;
    @observable secondaryColor: string = null;
    @observable query: string = null;
    @observable convoList: IConvo[] = [];  
    @observable typing: boolean = false;
    
    @observable areaH: number = 0;
    @observable chatH: number = 0;
    @observable chapterChangeAllowed: boolean = true;

    custombookidChanged: (oldVal: string, newVal: string) => void
    customchapternrChanged: (oldVal: string, newVal: string) => void
    convoListChanged: (oldVal: IConvo[], newVal: IConvo[]) => void
    devChanged: (oldVal: string, newVal: string) => void
    minifiedChanged: (oldVal: boolean, newVal: boolean) => void

    recalcArea: () => void
    setWindowMechanics: () => void


    hHandlers: IHyperHandler = getParamChangeHandlers.bind(this)();

    hTemperatureChanged: ChangeHandlerType<string> = this.hHandlers.hTemperature;
    hMaxTokensToSampleChanged: ChangeHandlerType<string> = this.hHandlers.hMaxTokensToSample;
    hTopKChanged: ChangeHandlerType<string> = this.hHandlers.hTopK;
    hTopPChanged: ChangeHandlerType<string> = this.hHandlers.hTopP;    

    public msgOptions: IConvoMsgOptions = {
        headerEnabled: false,
        dateEnabled: false
    };    

    @observable currentConvo: IConvo | null = null;
    @observable currentStudent: IJWTUser | null = null;

    constructor(
        @RWSInject(ConvoService) public convoService: ConvoServiceInstance,
        @RWSInject(ResizeService) public resizeService: ResizeServiceInstance,
        @RWSInject(WSService) public wsService: WSServiceInstance
    ){
        super();     
        
        handleExternalChanges.bind(this)();
        handleWindowMechanics.bind(this)();         
    }

    async connectedCallback() {
        super.connectedCallback()    

        this.isInstructor = this.instructor === 'true'

        if ((this as any)?.routeParams?.dev || this.dev === 'true') {
            this.dev = 'true'
        } else {
            this.dev = 'false'
        }           

        this.chosenModel = ClaudeModel

        this.chapterChangeAllowed = true

        this.checkForBookId()
        this.checkForBookChapter()

        this.getHyperParams()

        this.wsId = uuid()          

        handleEvents.bind(this)(this)

        if (this.routeParams?.opened || this.opened === 'true') {
            this.minified = false
        }        

        if (this.hTemperature) {
            this.hHandlers.hTemperature(null, this.hTemperature)
        }

        if (this.hMaxTokensToSample) {
            this.hHandlers.hMaxTokensToSample(null, this.hMaxTokensToSample)
        }

        if (this.hTopK) {
            this.hHandlers.hTopK(null, this.hTopK)
        }

        if (this.hTopP) {
            this.hHandlers.hTopP(null, this.hTopP)
        }

        this.setWindowMechanics()

        if (!this.isInstructor) {
            this.secondaryColorDark = this.hexToRGBA(this.getCSSVar('--secondary_color'), 0.2)
            this.secondaryColor = this.hexToRGBA(this.getCSSVar('--secondary_color'), 0.68)
        } else {
            this.secondaryColorDark = this.hexToRGBA(this.getCSSVar('--chat-nav-hover-bg-solid'), 0.4)
            this.secondaryColor = this.hexToRGBA(this.getCSSVar('--chat-nav-hover-bg-solid'), 0.2)
        }

        if (!this.wsService.isActive()) {
            this.wsService.init()
        }

        const savedVisibility:string | null = sessionStorage.getItem('_ai_last_chat_minified')

        if (savedVisibility !== null && this.forceOpened == 'false') {
            const lastVisibilitySetting: boolean = savedVisibility === 'true'
            this.minified = lastVisibilitySetting
        }    
        
        if (!this.isInstructor) {
            this.getConvos()                
        } else {
            console.log('instr')
            const boundaryElement = document.querySelector('.layout__border') as HTMLElement;

            this.removeAttribute('style')
            this.style.position = 'static'
            this.recalcArea()
            
            this.forceOpened = 'true'
            this.opened = 'true'
            this.minified = false
        }

        await this.profileCall()
        this._init = true

        this.boxData = this.resizeService.getBoxData(this)
    }

    private getHyperParams(){
        const provider = this.chosenModel?.providerName?.toLowerCase() || null;
        const defParams = this.getDefaultHyperParams(provider);

        const defaultParams: { [key: string]: any } = {};

        Object.keys(defParams).forEach(paramKey => {
            if (defParams[paramKey]) {
                defaultParams[paramKey] = this.setHyperParam(paramKey, defParams[paramKey]);
            }
        });

        this.hyperParameters = { ...defaultParams, ...this.hyperParameters };
    }

    async disconnectedCallback() {
        super.disconnectedCallback();   
        this.resizeService.destroyInteract();
    }

    checkForBookId() {
        this.bookId = this.routeParams.bookId || this.custombookid || null;

        if (this.bookId) {
            this.apiService.back.get<IBook>('train:get:book', { routeParams: { bookId: this.bookId } }).then((data: IBook) => {
                this.bookModel = data;          
            });
        }
    }

    checkForBookChapter() {
        this.chapterNr = this.customchapternr ? parseInt(this.customchapternr) : null;
    }

    checkLastConvo(convo_list: IConvo[]){
        if(convo_list.length){
            const lastConvoId = this.convoService.getLastConvoId(this.lessonId);
            if(lastConvoId){                                     
                this.$emit('convo.propagation', lastConvoId);
            }
        }    
    }    

    async getConvos()
    {
        this.convoList = await this.convoService.getConvoList(this.courseId);
    }
    
    clearConvoList()
    {
        this.convoId = null;
    }
}

WebChat.defineComponent();


export { WebChat, IContext };