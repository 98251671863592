import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="input ${x => x.disabled? 'disabled' : ''}">
  <input
    type="text"
    class="${x=>x.inType}"
    id="calendar"
    placeholder="${x=> x.inType=='time'? '': 'MM/DD/YYYY HH:MM'}"
    @change="${(x, c)=>x.onChange(c.event)}"
    :disabled="${x=>x.disabled}"
  />
  ${x => x.specialDateHidden ? T.html`
  
  <input
    type="text"
    class="${x=>x.inType} hidden-date-overlay input"
    placeholder="${x=> x.inType=='time'? '': 'MM/DD/YYYY HH:MM'}"
    @change="${(x, c)=>x.onChange(c.event)}"
    :disabled="${x=>x.disabled}"
  />
  ` : undefined}
  <span class="material-symbols-outlined icon" @click="${x=>x.open()}">
    ${x=> x.inType=='time'? 'schedule': 'calendar_today'}
  </span>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.input {
  border: none;
  display: flex;
  height: 24px;
  align-items: center;
  color: #3b3c3e;
  border-radius: 12px;
  background: #eeeff0;
  padding: 0px 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  width: fit-content;
  cursor: pointer;
  position: relative;
}
.input .icon {
  color: var(--link);
}
.input .date {
  width: 100px;
}
.input .time {
  width: 70px;
}
.input.disabled {
  cursor: not-allowed;
}

.hidden-date-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  right: 0;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
  RWSInject,
} from "@rws-framework/client";
import flatpickr from "flatpickr";
import { convertTime12to24 } from "../../../helpers/dateConversion";
import ActivityDateTimeService, {
  ActivityDateTimeServiceInstance,
} from "../../../services/ActivityDateTimeService";

declare const luxon: any;

@RWSView('jnct-datetime-input', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionDateTimeInput extends RWSViewComponent  {
  @attr default: string = "";
  @attr inType: string = "datetime-local";
  @attr afterChange: Function;
  @attr fieldName: string;
  @attr disabled: boolean = false;
  /**
   * This component should display the date/time in given timezone.
   * It the value it receives via `default` is expected to be UTC iso string.
   * The value it emits to `afterChange` is a UTC iso string as well.
   *
   * Unfortunately, the flatpickr does not support timezones, and assumes the given
   * date is local. Therefore, we need to manually add/subtract zone offsets before/after
   * passing dates to it. See `fromUtc` and `toUtc`, and their uses in `defaultChanged` and `afterChange`.
   */
  @attr timezone: string | undefined =
    window.localStorage.getItem("activity_settings_tz") || "local";
  @attr defaulttime: string = "23:59";
  /** Controls if dates between <2049, 2199> should be hidden. Dates like 1970 and 2199 are hidden regardless. */
  @attr hideSpecialFutureDates = true;
  @observable value: string;
  datePicker: any;
  pickerConfig: object = {};

  fromUtc(date: string | Date) {
    return ActivityDateTimeServiceInstance.fromUtc(date, this.timezone);
  }

  toUtc(date: string | Date) {
    return ActivityDateTimeServiceInstance.toUtc(date, this.timezone);
  }

  createConfig() {
    if (this.inType == "date") {
      this.pickerConfig = {
        altInput: true,
        altFormat: "m/d/Y",
        dateFormat: "m/d/Y",
        defaultHour: this.defaulttime.split(":")[0],
        defaultMinute: this.defaulttime.split(":")[1],
      };
    }
    if (this.inType == "time") {
      this.pickerConfig = {
        altInput: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
        defaultHour: this.defaulttime.split(":")[0],
        defaultMinute: this.defaulttime.split(":")[1],
      };
    }
    if (this.inType == "datetime-local") {
      this.pickerConfig = {
        enableTime: true,
        altInput: true,
        altFormat: "m/d/Y h:i K",
        dateFormat: "m/d/Y h:i K",
        defaultHour: this.defaulttime.split(":")[0],
        defaultMinute: this.defaulttime.split(":")[1],
      };
    }
  }

  open() {
    if (!this.disabled) {
      this.datePicker.open();
    }
  }

  /** Dates like 1970 and 2199 should not be displayed for the user, see comment near `ignoreSpecialDates` */
  get specialDateHidden() {
    return (
      JunctionDateTimeInput.ignoreSpecialDates(
        this.value,
        this.inType,
        this.hideSpecialFutureDates
      ) === ""
    );
  }

  // adjust focusability when displaying hidden date overlay
  valueChanged() {
    const isHidden = this.specialDateHidden;
    const input = this.$<HTMLInputElement>(
      "input[type=text]:not(.hidden-date-overlay)"
    ) as HTMLInputElement | null;

    if (!input) return;

    input.tabIndex = isHidden ? -1 : 0;
    input.setAttribute("aria-hidden", String(isHidden));
  }

  defaultChanged(old_val: string, new_val: string) {
    const datePickerEnabled = typeof this.datePicker === "object";
    const newDate =
      new_val && this.inType === "time"
        ? JunctionDateTimeInput.parseTimeToDate(new_val)
        : new Date(new_val);
    const validDate =
      new_val &&
      !["-", "", "-", undefined].includes(new_val) &&
      !new_val.includes("undefined") &&
      !Number.isNaN(newDate.getTime());

    // in case `default` was changed to empty/invalid value, reset the UI
    if (!validDate && datePickerEnabled) {
      this.value = "";
      return this.datePicker?.setDate();
    }

    if (!validDate) return;

    const newDateIso = newDate.toISOString();
    const zoneAdjustedDateIso = this.fromUtc(newDateIso);

    if (this.inType == "date") {
      this.value = zoneAdjustedDateIso;
      this.pickerConfig = {
        dateFormat: "M j, Y",
      };
    }
    if (this.inType == "time") {
      this.value = new_val;
      this.pickerConfig = {
        altInput: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
      };
    }
    if (this.inType == "datetime-local") {
      this.value = zoneAdjustedDateIso;
      this.pickerConfig = {
        enableTime: true,
        dateFormat: "M j, Y h:i K",
      };
    }

    if (datePickerEnabled) {
      this.datePicker.setDate(this.value);
    }
  }

  onChange(event) {
    if (typeof this.afterChange === "function") {
      if (event.target.value) {
        if (this.inType === "time")
          return this.afterChange(this.fieldName, event.target.value);

        const date = new Date(event.target.value);
        const iso = this.toUtc(date);

        this.afterChange(this.fieldName, iso, this);
      } else {
        this.afterChange(this.fieldName, event.target.value);
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.createConfig();

    this.value = this.value ?? this.default;

    this.datePicker = flatpickr(this.$("#calendar") as any, {
      ...this.pickerConfig,
      defaultDate: this.value,
      allowInput: true,
      clickOpens: false,
      parseDate: (date: string, format: string): Date => {
        switch (format) {
          case "h:i K":
            return JunctionDateTimeInput.parseTimeToDate(date);
          case "Y-m-d":
          case "m/d/Y h:i K": {
            const isSimpleUserInputWithoutTime =
              /^\s*\d{2}\/\d{2}\/\d{4}\s*$/.test(date);
            const output = new Date(date);
            const [defaultHour, defaultMinute] = this.defaulttime
              ? this.defaulttime.split(":").map((n) => Number.parseInt(n, 10))
              : [];

            // only apply the default time if parsing user input without time specified
            if (
              isSimpleUserInputWithoutTime &&
              defaultHour !== undefined &&
              !Number.isNaN(defaultHour) &&
              defaultMinute !== undefined &&
              !Number.isNaN(defaultMinute)
            ) {
              output.setHours(defaultHour);
              output.setMinutes(defaultMinute);
            }

            return output;
          }
          default:
            return new Date(date);
        }
      },
      onOpen: (dates, date, instance) => {
        if (this.specialDateHidden) instance.jumpToDate(new Date());
      },
    });
  }

  /**
   * Dates like 1970 and 2199 should never be displayed. They are used in the db to represent values
   * of distant past and distant future.
   * There is a special scenario of dates between <2049, 2199>, which depending on circumstance should be displayed or not.
   */
  static ignoreSpecialDates(
    date: string,
    dateType: string,
    ignoreFuture: boolean
  ): string {
    if (dateType !== "datetime-local" && dateType !== "date") return date;

    const d = new Date(date);

    if (d.getFullYear() <= 1970) return "";
    if (d.getFullYear() >= 2199) return "";

    if (ignoreFuture && d.getFullYear() >= 2049) return "";

    return date;
  }

  static parseTimeToDate(timeString: string): Date {
    // timeString is in the format HH:MM
    // sometimes with AM/PM
    if (timeString.length > 5) {
      timeString = convertTime12to24(timeString);
    }
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
    return date;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.value = undefined;

    if (this.datePicker) this.datePicker.destroy();
  }
}

JunctionDateTimeInput.defineComponent();

export { JunctionDateTimeInput };
