import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.courseReady, T.html`<book-binder-form @change="${ (x, c) => x.addBinding(c.event) }" course_id="${ x => x.course_id }"></book-binder-form>`)}

<fluent-data-grid :rowsData="${ x => x.data }" class="book-bind-grid"></fluent-data-grid>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: block;
}

a {
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
}

.book-bind-grid {
  width: 100%;
  --neutral-foreground-rest: var(--neutral-foreground-rest, #000);
}
.book-bind-grid fluent-button {
  --neutral-foreground-rest: #000;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr } from "@rws-framework/client";
import { IBook, IBookBind, ControllerActionResponse, ControllerActionErrorResponse, LessonListActionResponse, BookBindListActionResponse  } from '@backend-types'
import { html, ViewTemplate } from '@microsoft/fast-element';
import { BookBinderForm, BindingPayload } from './children/book-binder-form/component';

BookBinderForm;
type BinderGridData = {[header: string]: string | ViewTemplate}[]

@RWSView('book-binder', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class BookBinder extends RWSViewComponent  {
    
    @observable data: BinderGridData = [];

    @observable courseReady: boolean = false;
    @ngAttr course_id: string;   

    connectedCallback()
    {
        super.connectedCallback();          
    }

    private async getBindingData(): Promise<IBookBind[]>
    {
        try {           
            const result: BookBindListActionResponse = (await this.apiService.back.get<BookBindListActionResponse>('course:book-bindings:read-list', { routeParams: {
                courseId: this.course_id
            }}));

            this.courseReady = true;

            this.data = result.data.map((item: IBookBind) => ({                
                'Book id': item.book?.title,
                'Lesson': item.lesson ? item.lesson.title : '-',
                'Chapter nr': item.chapternr ? String(Number(item.chapternr) + 1) : 'All',
                'Persona': item.persona ? item.persona.title : '-',
                '-': html`<fluent-button @click=${() => this.onDelete.bind(this)(item)}><jnct-icon size="22px">delete</jnct-icon></fluent-button>`
            }));

            console.log(this.data)

            return result.data
        }catch(e: Error | unknown){
            this.notifyService.notify('Book bindings fetch error', 'error');
            console.error(e);
        }
    }

    async onDelete(item: IBookBind): Promise<void>
    {
        await this.apiService.back.get('course:book-bindings:delete', { routeParams: { id: item.id } });
        this.getBindingData();
    }

    async addBinding(event: CustomEvent<BindingPayload>): Promise<void>
    {        
        await this.apiService.back.post('course:book-bindings:set', {...event.detail, courseId: this.course_id}, { routeParams: { courseId: this.course_id } });
        this.getBindingData();
    }

    
    course_idChanged(oldVal: string, newVal?: string)
    {       
        if(newVal && newVal !== '' && newVal.indexOf('{{') == -1){
            this.course_id = newVal;
            this.getBindingData();
            
        }
    }
}

BookBinder.defineComponent();

export { BookBinder }