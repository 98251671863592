import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog
  id="adjust_grade_modal"
  trap-focus
  modal
  class="adjust_grade_modal">

  <header class="modal__header">
    <div class="modal__header--flex">
      <div class="modal__header--title">${x => x.student.name}</div>
      <h1 :innerHTML="${x => x.assessment.report_title}"></h1>
    </div>

    <div class="modal__header--buttons">
      <button
        class="button__secondary"
        tabindex="0"
        title="Cancel"
        @click="${x => x.close()}">
        Cancel
      </button>
      <button
        class="button__primary ${x => x.disabledSave? 'disabled' : ''}"
        tabindex="0"
        title="Save Changes"
        @click="${x => x.disabledSave ? '' : x.save()}">
        Save Changes
      </button>
    </div>
  </header>

  ${T.when(x => !x.loaded, T.html`
    <section class="loader">
      <jnct-loading-spinner></jnct-loading-spinner>
    </section>
  `)}
  ${T.when(x => x.loaded, T.html`

  <section class="modal__main main">
    <div class="main__header">
        <div class="item"></div>
        <div class="item item--190">Course Setting</div>
        <div class="item item--196">Student-Specific Setting</div>
        <div class="item item--rest">New Student-Specific Setting</div>
    </div>
    <ul class="main__list">
      ${T.repeat(x => x.list, T.html`
        <li class="main__list--item">
            <div class="item thin">${x => x.title}</div>
            <div class="item item--190 thin">
              ${x => x.date
                ? T.html`<jnct-date-tabular-display :date="${x => x.default}"></jnct-date-tabular-display>`
                : x.default}
            </div>
            <div class="item item--196 thin">
              ${x => x.date
                ? T.html`<jnct-date-tabular-display :date="${x => x.current}"></jnct-date-tabular-display>`
                : x.current}
            </div>
            <div class="item item--rest thin">
              ${T.when(x => x.date, T.html`
                <jnct-datetime-input
                  inType="datetime-local"
                  :default="${(x, c) => c.parent.modification[x.fieldName] ?? null}"
                  :afterChange="${(x, c) => c.parent.updateModificationHandler}"
                  fieldName="${x => x.fieldName}"
                  :defaulttime="23:59"
                ></jnct-datetime-input>
              `)}
              ${T.when(x => x.fieldName === 'submission_attempts', T.html`
                <grading-input
                  :point="${x => x.value ?? null}"
                  :maxPoints="${(x, c) => c.parent.maxPoints}"
                  :changePoint="${(x, c) => c.parent.changePoint}"
                  :studentid="${(x, c) => c.parent.student.id}"
                  class="margin">
                </grading-input>
                <div class="container">
                  <fluent-checkbox @change="${(x,c) => c.parent.updateOverall('submission_attempts')}" checked="${(x, c) => c.parent.global.submission_attempts}"></fluent-checkbox>
                  Override number of submission attempts for this student.
                </div>
              `)}
              ${T.when(x => x.fieldName === 'time_limit', T.html`
                <input class="custom__input margin" type="number" @input="${(x, c) => c.parent.change((c.event.target as HTMLInputElement).value, 'time_limit')}"></input>
                <div class="container">
                  <fluent-checkbox @change="${(x,c) => c.parent.updateOverall('time_limit')}" checked="${(x, c) => c.parent.global.time_limit}"></fluent-checkbox>
                  Override time limit for all activities for this student.
                </div>
              `)}
            <div>
        </li>
      `)}
    </ul>
  </section>
  `)}

  <div>
    <div id="learnosity-report" />
  </div>
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  display: flex;
  width: 207px;
  min-width: 207px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: none;
}
fluent-select.big {
  width: 100%;
}

fluent-select::part(control), fluent-select::part(listbox) {
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

fluent-select::part(control) {
  height: 40px;
}
fluent-select::part(control):hover {
  border: 1px solid var(--neutral-30);
}
fluent-select::part(control):focus, fluent-select::part(control):active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

fluent-select::part(listbox) {
  padding: 2px 0px;
  background: white;
  border: none;
}

fluent-option {
  padding: 8px 12px;
  border-radius: unset;
  border: none;
}
fluent-option.selected {
  font-weight: 500;
  border-left: 2px solid #00BBB5;
  background: rgba(0, 193, 185, 0.08);
}
fluent-option.selected::before {
  display: none;
}
fluent-option:not(.selected):hover {
  font-weight: 400;
  background: var(--Color-Neutral-Transparent-neutral-a8, rgba(153, 160, 167, 0.08));
  background: var(--Color-Neutral-Transparent-neutral-a8, color(display-p3 0.6078 0.6275 0.6471/0.08));
}

fluent-tooltip::part(tooltip) {
  display: block;
  position: relative;
  padding: 8px 16px;
  left: 12px;
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--neutral-80);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
  font-family: inherit;
}

fluent-tooltip::part(tooltip)::after {
  left: -6px;
  background-color: var(--neutral-80);
  border: none;
}

fluent-tooltip.left::part(tooltip)::after {
  right: -6px;
  left: unset;
  background-color: var(--neutral-80);
  border: none;
}

.adjust_grade_modal::part(positioning-region) {
  height: fit-content;
  max-height: calc(100vh - 60px);
  width: 1067px;
  margin: 40px auto 30px;
  overflow: auto;
  border-radius: 12px;
  z-index: 12000;
}
.adjust_grade_modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
}
.adjust_grade_modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}
.adjust_grade_modal .modal__header--flex {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.adjust_grade_modal .modal__header--title {
  color: var(--neutral-70);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.adjust_grade_modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 500;
  line-height: 140%;
}
.adjust_grade_modal .modal__header--buttons {
  display: flex;
  gap: 8px;
}
.adjust_grade_modal .modal__main {
  display: flex;
  width: 1019px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.adjust_grade_modal .modal__main .item {
  display: flex;
  width: 160px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
}
.adjust_grade_modal .modal__main .item--190 {
  width: 190px;
}
.adjust_grade_modal .modal__main .item--196 {
  width: 196px;
}
.adjust_grade_modal .modal__main .item--rest {
  flex: 1;
}
.adjust_grade_modal .modal__main .item.thin {
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
}
.adjust_grade_modal .modal__main .main__header {
  display: flex;
  height: 56px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.adjust_grade_modal .modal__main .main__list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.adjust_grade_modal .modal__main .main__list--item {
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-top: 2px solid var(--neutral-20);
}
.adjust_grade_modal .container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.adjust_grade_modal .margin {
  margin-bottom: 20px;
}`;

const shadowOptions = {"mode":"open"};
import { observable, RWSViewComponent, RWSView, RWSInject, attr } from "@rws-framework/client";
import { Activity } from "src/types/activities";
import { RosterRecord } from "src/types/users";
import TimeFormatService, { TimeFormatServiceInstance } from "../../../services/TimeFormatService";
import LearnosityService, { LearnosityServiceInstance } from "../../../services/LearnosityService";
import { Course } from "src/types/course";

interface List {
  id: string,
  title: string,
  default: string | number,
  current: string | number,
  fieldName: string,
  date?: boolean,
}

interface Global {
  submission_attempts?: string | number;
  time_limit?: number,
}
interface Modification {
  student_id: string,
  submission_attempts: string | number;
  time_limit: number,
  global: Global,
  close_date: string | null,
  open_date: string | null,
  due_date: string | null,
}

@RWSView('override-assessment-modal', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class OverrideAssessmentModal extends RWSViewComponent  {
  @observable loaded: boolean = false;
  @observable student: RosterRecord;
  @observable assessment: Activity;
  @attr course: Course;
  @observable maxPoints: number = 100;
  @observable list: List[];
  @observable modification: Modification;
  @observable disabledSave: boolean = true;
  @observable global: {};
  updateModificationHandler = this.updateModification.bind(this);

  constructor(
    @RWSInject(TimeFormatService) private timeFormatService: TimeFormatServiceInstance,
    @RWSInject(LearnosityService) private learnosityService: LearnosityServiceInstance,
  ){
    super();
  }


  async connectedCallback() {
    super.connectedCallback();
    this.changePoint = this.changePoint.bind(this);
    this.modification = {
      student_id: this.student._id,
      close_date: this.assessment.close_date ?? null,
      due_date: this.assessment.due_date ?? null,
      open_date: this.assessment.open_date ?? null,
      global: {},
      submission_attempts: this.assessment.submission_attempts ?? null,
      time_limit: this.assessment.time_limit ?? null,
    };
    const override = await this.learnosityService.getOverride(this.assessment.id, this.student._id, this.course._id);
    this.global = {...override.global};
    this.modification.global = {...override.global};
    const global_time = override.time_limit || override.global?.time_limit || null;
    const global_attempts = override.submission_attempts || override.global?.submission_attempts || null;
    this.list = [
      {
        id: '1',
        title: 'Open Date',
        default: this.dateInRange(this.assessment.open_date) ? this.assessment.open_date : '-',
        current: override.open_date && override.open_date !== this.assessment.open_date ? override.open_date : '-',
        fieldName: 'open_date',
        date: true,
      },
      {
        id: '2',
        title: 'Due Date',
        default: this.dateInRange(this.assessment.due_date) ? this.assessment.due_date : '-',
        current: override.due_date && override.due_date !== this.assessment.due_date ? override.due_date : '-',
        fieldName: 'due_date',
        date: true,
      },
      {
        id: '3',
        title: 'Close Date',
        default: this.dateInRange(this.assessment.close_date) ? this.assessment.close_date : '-',
        current: override.close_date && override.close_date !== this.assessment.close_date ? override.close_date : '-',
        fieldName: 'close_date',
        date: true,
      },
      {
        id: '4',
        title: 'Submission Attempts (0 = unlimited)',
        default: this.assessment.submission_attempts,
        current: global_attempts && global_attempts !== this.assessment.submission_attempts ? global_attempts : '-',
        fieldName: 'submission_attempts',
      },
      {
        id: '5',
        title: 'Time Limit (in minutes)',
        default: this.assessment.time_limit ? this.assessment.time_limit / 60 : '-',
        current: global_time && global_time !== this.assessment.time_limit ? global_time / 60 : '-',
        fieldName: 'time_limit',
      },
    ];
    this.loaded = true;
  };

  dateInRange(date: string): boolean {
    return date > '1970-02' && date < '2198';
  }

  change(value: number, type: string): void {
    this.disabledSave = false;
    this.modification.time_limit = value * 60;
  }

  updateModification(fieldName: string, value: string, inputField: any) {
    if (!value) return;
    this.disabledSave = false;
    this.modification[fieldName] = value;
    if (inputField) inputField.value = value
  }

  async save() {
    if (this.modification.global.time_limit) {
      this.modification.global.time_limit = this.modification.time_limit;
      delete this.modification.time_limit;
    }
    if (this.modification.global.submission_attempts) {
      this.modification.global.submission_attempts = this.modification.submission_attempts;
      delete this.modification.submission_attempts;
    }
    //@ts-ignore
    if (this.modification.global.submission_attempts == false) delete this.modification.global.submission_attempts;
    //@ts-ignore
    if (this.modification.global.time_limit == false) delete this.modification.global.time_limit;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 
    //@ts-ignore
    const APIprefix: string = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    try {
      const response = await fetch(APIprefix + "assessment_override/" + this.course._id + '/' + this.assessment.id, {
        method: "POST",
        body: JSON.stringify(this.modification),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });

      const message = 'Student override saved. Please click reload to get updated values';
      this.$emit('jnct:notifications:success', message);
    } catch (error) {
      console.error("Error:", error);
    };
    this.close()
  }

  changePoint(value: string | number, id: string): void {
    this.disabledSave = false;
    this.modification.submission_attempts = value;
  };

  updateOverall(type: string): void {
    this.modification.global[type] = !this.modification.global[type];
  }

  close() {
    this.remove();
  };
}

OverrideAssessmentModal.defineComponent();

export { OverrideAssessmentModal };
