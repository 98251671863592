import { RWSService, RWSViewComponent } from '@rws-framework/client';
import interact from 'interactjs';
import { IBoxHolder, IBoxData } from '../types/box-data';
import {bump, makeBoundary, markBoundaryData  } from './resize/_boundary';
import { onDrag, getRelative, moveTarget  } from './resize/_move';
import { applySize, onResize, onResizeEnd  } from './resize/_resize';
import { getBoxData } from './resize/_box';

interface _resize_base {
  target: HTMLElement,
  _interact?: Interact.Options
}

type ResizeEdge = boolean | HTMLElement | string;

export interface ResizeOptions extends _resize_base {
  limits?: {
    min?: {
      width: number,
      height: number
    },
    max?: {
      width: number,
      height: number
    }
  },
  boundary?: IBoxData | HTMLElement,  
  boundaryMod?: BoundaryModType
  edges?: { left: ResizeEdge, right: ResizeEdge, bottom: ResizeEdge, top: ResizeEdge },  
}

export type BoundaryModType = {
  w?: number,
  h?: number
}

export interface DragOptions extends _resize_base {
  boundary?: IBoxData | HTMLElement
  boundaryMod?: BoundaryModType
  handler?: HTMLElement
}

export type ResizeData = {
  initial?: {
    x: number,
    y: number,
    w: number,
    h: number
  } 
}

class ResizeService extends RWSService {  
  getBoxData = getBoxData;
  bump = bump;
  makeBoundary = makeBoundary;
  moveTarget = moveTarget;
  applySize = applySize;
  interactTool: Interact.Interactable = null;
  resizeData: ResizeData | null = null;

  private buildInteract(component: RWSViewComponent & IBoxHolder, options: ResizeOptions): void
  {
    if(!this.interactTool){
      this.interactTool = this.interact(component, options.target);
    }
  }

  resize(component: RWSViewComponent & IBoxHolder, options: ResizeOptions): ResizeService {
    this.buildInteract(component, options);
    const _self = this;
    this.interactTool
      .resizable({
        edges: options.edges ? options.edges : { left: true, right: true, bottom: true, top: true },
        listeners: {
          start: (event) => {
            this.resizeData = {
              initial: {
                w: event.rect.width,
                h: event.rect.height,
                x: event.rect.left,
                y: event.rect.top
              }
            };

            this.interactTool.draggable(false);
            document.body.style.userSelect = 'none';
          },
          end: (event) => {
            onResizeEnd.bind(_self)(event, component, options);
            this.resizeData = null;
          },
          move: function (event) {
            onResize.bind(_self)(event, component, options);            
          },
        }
      });

    return this;
  }

  drag(component: RWSViewComponent & IBoxHolder, options: DragOptions): ResizeService {    
    this.buildInteract(component, options);
    this.interact(component, options.target).draggable({
      allowFrom: options.handler ? options.handler : undefined,
      listeners: {
        start: event => {  
          markBoundaryData(event.target as HTMLElement, options);           
        },
        move: event => {
          onDrag.bind(this)(event, component, options);            
        },
        end: (event) => {
          // this.applyBoundary(event.target as HTMLElement, options);
          const boxData = getBoxData(component);
          
          const style = window.getComputedStyle(component);
          const matrix = new WebKitCSSMatrix(style.transform);
          let x = matrix.m41;
          let y = matrix.m42;

          boxData.left = x;
          boxData.top = y;

          this.saveBoxData(boxData);
        }
      }
    })

    return this;
  }   

  interact(component: RWSViewComponent & IBoxHolder, domEl: HTMLElement): Interact.Interactable {
    const componentInnards = (component as RWSViewComponent & { _interact: Interact.Interactable });

    if (!!componentInnards._interact) {
      return componentInnards._interact;
    }

    const tool = interact(domEl);
    componentInnards._interact = tool;

    return tool;
  }

  public destroyInteract()
  {
    this.interactTool = null;
  }
  
  getSavedBoxData(): IBoxData | null
  {
    const stringedBoxData = localStorage.getItem('webchat_box_data');

    if(stringedBoxData){
      return JSON.parse(stringedBoxData);
    }

    return null;
  }

  saveBoxData(boxData: IBoxData){
    localStorage.setItem('webchat_box_data', JSON.stringify(boxData));
  }
}

export default ResizeService.getSingleton();

export { ResizeService as ResizeServiceInstance }
