import IAnthropicHyperParams, { _defaults as _anthropic_defaults } from './IAnthropicHyperParams';
import IMetaHyperParams, { _defaults as _meta_defaults } from './IMetaHyperParams';
import { _defaults as _cohere_defaults , ICohereTextHyperParams, ICohereDocHyperParams} from './ICohereHyperParams';
import IAI21HyperParams, { _defaults as _ai21_defaults } from './IAI21HyperParams';
import { IAmazonLLMHyperParams, IAmazonHyperParams, _defaults as _amazon_defaults } from './IAmazonHyperParams';
import IHyperParameter from './_hyperparam';

const _hyperParamsDefaults: {
    [key: string]: any
} = {
    'anthropic': _anthropic_defaults,
    'meta': _meta_defaults,
    'ai21': _ai21_defaults,
    'amazon': _amazon_defaults,
    'cohere' : _cohere_defaults
};

export {
    IHyperParameter,
    IAnthropicHyperParams,
    IMetaHyperParams,
    ICohereTextHyperParams,
    ICohereDocHyperParams,
    IAmazonHyperParams,
    IAmazonLLMHyperParams,
    IAI21HyperParams,
    _hyperParamsDefaults
};