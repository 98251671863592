import IHyperParameter from './_hyperparam';

interface IAmazonHyperParams extends IHyperParameter {
    inputText?: string;
}

interface IAmazonLLMHyperParams extends IAmazonHyperParams {
    maxTokenCount: number;
    stopSequences: any[];   
    topP: number
}

export { IAmazonHyperParams, IAmazonLLMHyperParams };

const _defaults: { llm: IAmazonLLMHyperParams, embeddings: IAmazonHyperParams }  = {
    embeddings: {
        temperature: 1
    },
    llm: {
        maxTokenCount: 8192,
        stopSequences: [],
        temperature:0,
        topP:1
    }
};

export {
    _defaults
};