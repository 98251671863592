import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="persona-edit-modal">
  <div class="modal-header">
    <h2>Edit Persona</h2>
    <fluent-button appearance="stealth" @click="${x => x.genericConfig.hide()}">
      <fluent-icon name="close"></fluent-icon>
    </fluent-button>
  </div>
  <div class="modal-body">
    <form id="persona-form">
      <div class="form-group">
        <label for="persona-title">Name</label>
        <div class="form-field">
          <fluent-text-field id="persona-title" value="${x => x.persona?.title || ''}"></fluent-text-field>
        </div>
      </div>      
      <div class="form-group">
        <label for="persona-character">Character Traits</label>
        <div class="form-field">
          <fluent-text-area id="persona-character" value="${x => x.persona?.character || ''}"></fluent-text-field>
        </div>
      </div>
      <div class="form-group">
        <label for="persona-prompt">Persona prompt override</label>
        <div class="form-field">
          <fluent-text-area id="persona-prompt" value="${x => x.persona?.prompt || ''}"></fluent-text-area>
        </div>
      </div>
      <div class="form-group image-group">
        <label>Image</label>
        <div class="image-holder">         
          ${T.when(x => x.imageFile || x.persona?.image, T.html`<img class="preview" src="${x => x.persona?.image ? x.persona.image : x.imagePreview}"/>`)}
          ${T.when(x => !x.imageFile, T.html`<rws-uploader :uploadProgress="${x => x.progress}" :uploadParams="${ x => x.uploadParams}" :onFinish="${x => x.uploadCallback.bind(x)}"></rws-uploader>`)}
        </div>
      </div>
      <div class="form-actions">
        <fluent-button appearance="primary" @click="${x => x.formSave()}">${ x => x.persona?.id ? 'Save' : 'Add' }</fluent-button>
        <fluent-button class="right" appearance="stealth" @click="${x => x.hide()}">Cancel</fluent-button>
      </div>
    </form>
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.persona-edit-modal {
  max-width: 600px;
  padding: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 75px);
}
.persona-edit-modal::-webkit-scrollbar {
  width: 10px;
}
.persona-edit-modal::-webkit-scrollbar-track {
  background: #ccc;
}
.persona-edit-modal::-webkit-scrollbar-thumb {
  background: #333;
}
.persona-edit-modal::-moz-scrollbar {
  width: 10px;
}
.persona-edit-modal::-moz-scrollbar-track {
  background: #ccc;
}
.persona-edit-modal::-moz-scrollbar-thumb {
  background: #333;
}
.persona-edit-modal::-ms-scrollbar {
  width: 10px;
}
.persona-edit-modal::-ms-scrollbar-track {
  background: #ccc;
}
.persona-edit-modal::-ms-scrollbar-thumb {
  background: #333;
}
.persona-edit-modal * {
  box-sizing: border-box;
}
.persona-edit-modal #persona-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.persona-edit-modal #persona-form .form-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.persona-edit-modal #persona-form .form-group label {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .persona-edit-modal #persona-form .form-group label {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .persona-edit-modal #persona-form .form-group label {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .persona-edit-modal #persona-form .form-group label {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.persona-edit-modal #persona-form .form-group .form-field {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  .persona-edit-modal #persona-form .form-group .form-field {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  .persona-edit-modal #persona-form .form-group .form-field {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  .persona-edit-modal #persona-form .form-group .form-field {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.persona-edit-modal #persona-form .form-group .form-field fluent-text-area, .persona-edit-modal #persona-form .form-group .form-field fluent-text-field {
  display: block;
  width: 100%;
}
.persona-edit-modal #persona-form .form-group.image-group {
  display: block;
  width: 100%;
}
.persona-edit-modal #persona-form .form-group.image-group .form-field {
  display: block;
}
.persona-edit-modal #persona-form .form-group.image-group .image-holder img.preview {
  display: block;
  max-width: 100%;
}
.persona-edit-modal #persona-form .form-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.persona-edit-modal rws-uploader {
  --rws-uploader-text: rgb(51, 51, 51);
  --rws-uploader-primary: $main_color;
}`;

const shadowOptions = {"mode":"open"};
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
} from "@rws-framework/client";

import { IPersona } from "@backend-types";

type LTIModalConfig = {
  reseller?: string
  save: () => void,
  hide: () => void
}

@RWSView('junction-persona-edit-modal', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class JunctionLtiConfigModal extends RWSViewComponent  {
  @observable genericConfig: LTIModalConfig;  
  @observable persona: IPersona;
  @observable stopIt: boolean = false;
  @observable uploadParams: any;
  @observable progress: number = 0;
  @observable loading: boolean = false;
  @observable reseller: string = 'locally';
  
  @observable imageFile: File;
  @observable imagePreview: string;

  connectedCallback() {
    super.connectedCallback();
    this.reseller = (window as any).labelBundle.reseller || null;
    this.$emit("personas-modal:shown");
  }

  hide(){
    this.$emit("personas-modal:hidden");
  }

  done(): IPersona
  {    
    const $ = (selector: string): HTMLInputElement => this.$(selector) as HTMLInputElement;
    this.persona = {
        id: this.persona?.id || null,
        title: $('#persona-title').value,        
        character: $('#persona-character').value,
        prompt: $('#persona-prompt').value,
        reseller: this.reseller        
    };
    
    return this.persona;
  }

  genericConfigChanged(oldValue: any, newValue: any){
    if(newValue && newValue !== ''){      
      console.log('genericConfigChanged');
    }
  }

  onProgress(progress: number): void {
      progress = Math.ceil(progress);

      if (progress <= 100) {
          this.progress = progress;        

          if(progress === 100){
              this.loading = false;
          }
          return;
      }
  }

  async uploadCallback(uploadedFile: File): Promise<File> {
    try {
        this.stopIt = false;
        this.loading = true;
        this.imageFile = uploadedFile;

        await this.previewImage();

        return this.imageFile;
    } catch (e: Error | any){
        this.loading = false;
        this.notifyService.notify('File upload error', 'error');
        throw e;
    }                 
  }

  async previewImage(): Promise<string>
  {
    if(!this.imageFile){
      return '';
    }

    const self = this;

    return new Promise<string>((resolve, reject) => {
      try {
        if (self.imageFile && self.imageFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = function(e) {
              self.imagePreview = e.target.result as string;
              resolve(e.target.result as string);
            };
            reader.readAsDataURL(self.imageFile);
        } else {
            console.error('Please select an image file.');
        }
      }catch(e: Error | unknown){
        console.error(e);
        reject(e);
      }        
    });
  }

  async formSave(): Promise<IPersona>
  {
    let uploadResponse: any = null; 
    const createRoute: string = 'persona:resource:mod';

    try {
      if(this.imageFile){
        uploadResponse = await this.apiService.back.uploadFile(createRoute, this.imageFile, (progress: number) => {
          this.progress = progress * 100;                
          this.onProgress(this.progress);
        }, {}, this.done());            
      } else {
        uploadResponse = await this.apiService.back.post(createRoute, this.done());
      }
      
      this.loading = false;
      this.progress = 0;             
  
      if(uploadResponse.data.id){            
        this.$emit('personas-modal:reload-personas'); // Refresh the list
        this.hide();
        return uploadResponse.data;
      }else{
        this.hide();
        throw new Error('No data added');
      }   
    } catch(e: Error | unknown){
      alert('upload error!');
      this.hide();
      throw e;
    }       
  }
}

JunctionLtiConfigModal.defineComponent();

export { JunctionLtiConfigModal };
