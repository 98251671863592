import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="modal-content">
  ${T.when( x => !x.editAddMode, T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Presets"
  ></jnct-modal-header>

  <div class="content">
    ${T.when(x => x.presets, T.html` ${T.repeat(x=>x.presets, T.html`
    <div class="row cursor" @click="${(x, c)=>c.parent.modifyPreset(x)}">
      <jnct-preset-badge
        color="${x=>x.color}"
        text="${x=>x.name}"
        style="width: 150px"
      ></jnct-preset-badge>
      <span> Used for ${x=>x.appearances} Assessment(s) </span>
      <span class="material-symbols-outlined" style="font-size: 20px">
        chevron_right
      </span>
    </div>
    `)} `)} ${T.when(x => !x.presets?.length, T.html`
    <jnct-notfound
      noBorder="true"
      headerText="No Presets"
      description="Create your first Preset by clicking “Add New”."
    >
    </jnct-notfound>
    `)}
  </div>
  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.genericConfig.switch()}"
        style="gap: 8px"
      >
        Cancel
      </button></span
    >
    <span style="display: flex; gap: 20px"
      ><button class="button__secondary" @click="${x=>x.createPreset()}">
        <span class="material-symbols-outlined"> add </span> Add New</button
      ><button class="button__primary" @click="${x=>x.genericConfig.save()}">
        Save Changes
      </button></span
    >
  </div>
  `)} ${T.when( x => x.editAddMode, T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    :title="${x=>x.currentModal === 'CREATE_PRESET' ? 'Add Preset' : 'Edit Preset'}"
  ></jnct-modal-header>

  <jnct-activity-settings-form
    :bu_payload="${x => x.currentPresetPayload}"
    :current="${x => x.currentPresetPayload}"
    prefillWithDefault="true"
    :enableGradedOption="${() => false}"
    :onChange="${x => x.onPresetFormChange}"
  >
    <div class="preset_conf">
      <div class="name">
        <div class="header">Preset Name</div>
        <jnct-string-input
          class="${x => x.hasNameError ? 'error' : ''}"
          :default="${x=>x.currentPresetPayload.name}"
          :afterChange="${x=>x.changeVal}"
          fieldName="name"
        ></jnct-string-input>
        ${T.when(x => x.hasNameError, T.html`
        <div class="text__error error__flex">
          <span class="material-symbols-outlined size-14"> info </span>
          Preset Name cannot be empty.
        </div>
        `)}
      </div>

      <div class="color">
        <div class="header">Color</div>
        <jnct-select-color-input
          :default="${x=>x.currentPresetPayload.color}"
          :afterChange="${x=>x.changeVal}"
          fieldName="color"
        ></jnct-select-color-input>
      </div>
    </div>
  </jnct-activity-settings-form>

  ${T.when(x=>!x.showDelete, T.html`
  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.goBack()}"
        style="gap: 8px"
      >
        Go Back
      </button></span
    >
    <span style="display: flex; gap: 20px">
      ${T.when(x=>x.currentModal === 'MODIFY_PRESET', T.html`
      <button class="button__danger" @click="${x=>x.toggleDelete()}">
        Delete
      </button>
      `)}
      <button
        class="button__primary"
        @click="${x=>x.save()}"
        :disabled="${x => x.hasNameError}"
      >
        ${x => x.currentModal === 'MODIFY_PRESET' ? 'Save Changes' : 'Add'}
      </button></span
    >
  </div>
  `)} ${T.when(x=>x.showDelete, T.html`
  <div class="delete__container">
    <div class="text__warning">
      Are you sure you want to permanently delete this Preset?
    </div>
    <div class="delete__buttons">
      <button class="button__secondary" @click="${x=>x.toggleDelete()}">
        Cancel
      </button>
      <button class="button__danger" @click="${x=>x.delete()}">Delete</button>
    </div>
  </div>
  `)} `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.modal-content {
  display: flex;
  width: 640px;
  flex-direction: column;
  border: var(--account, 1px) solid var(--Color-palette-Neutral-20, #eeeff0);
  max-height: calc(100vh - 54px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.modal-content jnct-activity-settings-form {
  overflow-y: auto;
}
.modal-content .content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
}
.modal-content .content .row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 16px;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
}
.modal-content .preset_conf {
  display: flex;
  gap: 16px;
}
.modal-content .preset_conf .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}
.modal-content .preset_conf .name {
  width: 60%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .preset_conf .color {
  width: 40%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .options {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 48px);
  bottom: 0px;
}
.modal-content .tooltip {
  position: relative;
  display: inline-block;
}
.modal-content .tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.modal-content .tooltip:hover .tooltiptext {
  visibility: visible;
}
.modal-content .cursor {
  cursor: pointer;
}
.modal-content jnct-string-input.error {
  border-radius: 12px;
  border: 1px solid var(--action-error);
}
.modal-content .error__flex {
  display: flex;
  gap: 4px;
  align-items: center;
}
.modal-content .size-14 {
  font-size: 14px;
}
.modal-content .delete__container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 48px);
  bottom: 0px;
  align-items: center;
  gap: 16px;
}
.modal-content .delete__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
} from "@rws-framework/client";
import { pick } from "../../../helpers/utils";

import { JunctionActivitySettingsForm } from "../batchUpdate/activitySettingsForm/component";
import { Update, UpdateModel } from "../batchUpdate/common";
import { Activity, ActivityLockStatus } from "src/types/activities";

const presetFields = [
  "graded",
  "submission_attempts",
  "show_correct_answers",
  "show_responses",
  "show_score",
  "inline",
  "scramble",
  "lock_back_button",
  "time_limit",
  "requires_comment",
  "max_score",
  "confidence",
  "studycenter",
  "resume_max_attempts",
  "show",
] as const;

type PresetConfig = { _id: string; name: string; color: string } & Pick<
  Update,
  (typeof presetFields)[number]
>;

@RWSView('jnct-presets-list-modal', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionPresetsListModal extends RWSViewComponent  {
  @attr genericConfig: any = {};

  @observable currentModal: "LIST_PRESETS" | "MODIFY_PRESET" | "CREATE_PRESET" =
    "LIST_PRESETS";

  @observable mode: string;
  @observable showDelete: boolean = false;

  basePayload: PresetConfig = {
    ...pick(UpdateModel.default(), presetFields),
    _id: "",
    name: "",
    color: "Red",
  };
  @observable currentPresetPayload: PresetConfig = this.basePayload;
  @observable presets: PresetConfig[];

  get hasNameError() {
    return this.currentPresetPayload.name === "";
  }

  get editAddMode() {
    return (
      this.currentModal === "CREATE_PRESET" ||
      this.currentModal === "MODIFY_PRESET"
    );
  }

  displayModal(value: (typeof this)["currentModal"]) {
    this.currentModal = value;
  }

  modifyPreset(preset: PresetConfig) {
    this.currentPresetPayload = { ...this.basePayload, ...preset };
    this.displayModal("MODIFY_PRESET");
  }

  createPreset() {
    this.currentPresetPayload = { ...this.basePayload };
    this.displayModal("CREATE_PRESET");
  }

  goBack() {
    this.displayModal("LIST_PRESETS");
  }

  changeVal = (name: string, value: string) => {
    this.currentPresetPayload = {
      ...this.currentPresetPayload,
      [name]: value,
    };
  };

  onPresetFormChange = (preset: PresetConfig) => {
    this.currentPresetPayload = preset;
  };

  genericConfigChanged(olcVal: any, newVal: any) {
    if (typeof newVal.getPresets === "function") {
      this.presets = newVal.getPresets();
    }
  }

  save() {
    this.genericConfig.switch();

    if (this.currentModal == "CREATE_PRESET") {
      this.genericConfig.save(this.currentPresetPayload);
    }
    if (this.currentModal == "MODIFY_PRESET") {
      this.genericConfig.update(
        this.currentPresetPayload._id,
        this.currentPresetPayload
      );
    }
  }

  toggleDelete() {
    this.showDelete = !this.showDelete;
  }

  delete() {
    this.genericConfig.switch();
    this.showDelete = false;
    this.genericConfig.delete(this.currentPresetPayload._id);
  }

  connectedCallback() {
    super.connectedCallback();
    this.$emit("modal:shown");
  }
}

JunctionPresetsListModal.defineComponent();

export { JunctionPresetsListModal };
