import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="chat-nav" class="is-instructor"> 
    <header>
        <h1 class="header">
          <span class="material-symbols-outlined font-22" aria-hidden="true"> settings_applications </span>
          AI Chat History
        </h1>
    </header>
    <nav>        
        <div id="chat_search_field">
          <span class="material-symbols-outlined font-22" aria-hidden="true"> search </span>
          <input @keyup="${ (x,c) => x.search(c.event)}" placeholder="Search..."></input>
        </div> 
        <div id="chat-convo-list" :style="height: ${ x => x.areaH - 0 }px;">
                ${T.when(x => x.loading, T.html`<div class="chat-nav-loading"><jnct-loading textColor="#000"></jnct-loading></div>`)}                    
                ${T.repeat(x => Object.keys(x.list), T.html` 
                    ${T.when((x, c) => !c.parent.loading, T.html`<div class="optgroup ${(x, c) => c.parent.activeUser === x ? 'active' : ''}">       
                        <div class="optgroup-label" id="${ x => x.replace(' ', '_')}">
                          <span>${ x => x }</span>
                          <span class="material-symbols-outlined ex" @click="${ (x,c) => c.parent.toggle(x)}"> expand_more </span>
                        </div>
                        <div class="optgroup-content${ (x, c) => c.parent.activeUser === x ? ' opened' : ''}">
                            <fluent-listbox style="--stroke-width: 0;">
                                ${T.repeat((x, c) => c.parent.list[x], T.html`   
                                    <chat-nav-item 
                                        :item="${x => x}" 
                                        :itemContext="${(x, c) => c}" 
                                        :convoId="${ (x, c) => c.parent.convoId}" 
                                        :dotsActive="${(x, c) => c.parent.dotsActive}" 
                                        :active="${(x, c) => c.parentContext.parent.convoId === x.id || c.parentContext.parent.dotsActive === x.id}"
                                        extended="true">
                                    </chat-nav-item>
                                   `)} 
                            </fluent-listbox>        
                        </div>    
                    </div> `)}
                `)}                           
        </div>
    </nav>
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: block;
}

a {
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
}

:host {
  width: 100%;
}

#chat-nav {
  height: 100%;
  background-color: var(--neutral-70);
}
#chat-nav.is-instructor {
  background-color: #FFF;
}
#chat-nav header h2 {
  color: #FFF;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: left;
  padding: 12px 0 16px 8px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
#chat-nav nav {
  max-height: calc(100% - 40px);
  background-color: var(--neutral-70);
}
#chat-nav #chat-convo-list {
  height: var(--boxH, 100%);
  background-color: var(--neutral-70);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 8px;
}
#chat-nav #chat-convo-list::-webkit-scrollbar {
  width: 8px;
}
#chat-nav #chat-convo-list::-webkit-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
#chat-nav #chat-convo-list::-webkit-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
#chat-nav #chat-convo-list::-moz-scrollbar {
  width: 8px;
}
#chat-nav #chat-convo-list::-moz-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
#chat-nav #chat-convo-list::-moz-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
#chat-nav #chat-convo-list::-ms-scrollbar {
  width: 8px;
}
#chat-nav #chat-convo-list::-ms-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
#chat-nav #chat-convo-list::-ms-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
#chat-nav #chat-convo-list fluent-listbox {
  --neutral-fill-stealth-rest: var(--primary_color, --neutral-70);
  --neutral-fill-stealth-hover: var(--chat-nav-hover-bg);
  --neutral-fill-stealth-active: var(--chat-nav-hover-bg);
  --neutral-fill-stealth-focus: var(--chat-nav-hover-bg);
  --neutral-fill-secondary-rest: var(--chat-nav-hover-bg);
  --neutral-fill-secondary-hover: var(--chat-nav-hover-bg);
  --accent-fill-rest: transparent;
  --neutral-fill-secondary-active: var(--chat-nav-hover-bg);
  --neutral-fill-secondary-focus: var(--chat-nav-hover-bg);
}
#chat-nav #chat-convo-list fluent-listbox {
  width: 100%;
  border: none !important;
  outline: none !important;
  border-top-left-radius: 12px;
  padding: 0;
}
#chat-nav #chat-convo-list .chat-nav-loading {
  margin-top: 8px;
  margin-bottom: 8px;
  position: absolute;
  width: 100%;
}
#chat-nav #chat_search_field {
  background: var(--neutral-80);
  border-radius: 8px;
  width: auto;
  position: static;
  display: block;
  margin: 0 16px 0 8px;
}
#chat-nav #chat_search_field jnct-icon {
  margin-left: 8px;
  margin-top: 8px;
  float: left;
}
#chat-nav #chat_search_field input {
  width: calc(100% - 26px);
  float: left;
  height: 32px;
  padding: 8px;
  color: var(--neutral-30);
  border-radius: 8px;
  background: var(--neutral-80);
  box-shadow: none;
  outline: none;
  border: none;
}
#chat-nav #chat_search_field input::placeholder {
  color: var(--neutral-30);
}
#chat-nav #chat_search_field::after {
  display: table;
  clear: both;
  content: "";
}

fluent-text-field {
  margin-bottom: 8px;
}

#chat-convo-list {
  height: var(--boxH, 100%);
  overflow-y: scroll;
}
#chat-convo-list .optgroup {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}
#chat-convo-list .optgroup .ex {
  z-index: 2;
  width: 20px;
  line-height: 22px;
  font-size: 22px;
  font-weight: 300;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 8px;
}
#chat-convo-list .optgroup.active .ex {
  transform: rotate(180deg);
}
#chat-convo-list .optgroup-label {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  color: var(--neutral-60);
  position: relative;
  height: 25px;
}
#chat-convo-list .optgroup-label span {
  z-index: 2;
  position: relative;
  background-color: #FFF;
  padding-right: 15px;
}
#chat-convo-list .optgroup-label::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--neutral-20);
  left: 0;
  z-index: 1;
}
#chat-convo-list .optgroup-content {
  flex-grow: 1;
  border: 0;
  padding: 0;
  margin: 0;
  display: none;
}
#chat-convo-list .optgroup-content.opened {
  display: block;
}
#chat-convo-list fluent-listbox .optgroup-label {
  font-weight: bold;
  margin-bottom: 8px;
}

#chat-nav.is-instructor {
  background-color: #FFF;
  border: 0;
}
#chat-nav.is-instructor nav {
  background-color: #FFF;
}
#chat-nav.is-instructor #chat-convo-list {
  background-color: #FFF;
  margin-left: 16px;
  font-size: 14px;
}
#chat-nav.is-instructor #chat_search_field {
  background-color: var(--neutral-20);
  color: var(--neutral-60);
  border: 1px solid var(--neutral-20);
  width: auto;
  position: static;
  display: block;
  margin: 0 16px 16px 16px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
#chat-nav.is-instructor #chat_search_field:focus, #chat-nav.is-instructor #chat_search_field:hover, #chat-nav.is-instructor #chat_search_field:active {
  background-color: #FFF;
  border-color: var(--neutral-30);
}
#chat-nav.is-instructor #chat_search_field:focus input, #chat-nav.is-instructor #chat_search_field:hover input, #chat-nav.is-instructor #chat_search_field:active input {
  background-color: #FFF;
}
#chat-nav.is-instructor #chat_search_field .font-22 {
  font-weight: 300;
}
#chat-nav.is-instructor #chat_search_field input {
  height: 40px;
  background-color: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
#chat-nav.is-instructor #chat_search_field input::placeholder {
  color: var(--neutral-60);
}
#chat-nav.is-instructor .header {
  color: var(--neutral-90);
  overflow: hidden;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
  margin: 16px;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
#chat-nav.is-instructor .font-22 {
  font-size: 22px;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, RWSInject } from '@rws-framework/client';
import {  observable  } from '@microsoft/fast-element';
import { IConvo } from '@backend-types';
import ConvoService, { ConvoServiceInstance } from '../../../../../services/ConvoService';

import { IBoxHolder, IBoxData } from '../../../../../types/box-data';


@RWSView('instructor-chat-nav', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatNavInstructor extends RWSViewComponent  implements IBoxHolder {
  @observable list: {[userName: string]: IConvo[]} = {};
  @observable boxData: IBoxData;  
  @observable convoId: string;
  @observable courseId: string;
  @observable dotsActive: string = null;
  @observable activeUser: string = null;
  @observable areaH: number = 0;  
  @observable loading: boolean = false;

  private timeout: NodeJS.Timeout | null = null;

  constructor(@RWSInject(ConvoService) private convoService: ConvoServiceInstance){
    super();        
  }

  async getList()
  {
    this.loading = true;
    this.list = await this.convoService.getInstructorConvoList(this.courseId);
    this.loading = false;
  }

  async connectedCallback(): Promise<void>
  {
      super.connectedCallback();      
      this.getList();

      this.on('chat-nav:reload-list', () => {
        this.getList();
      });

      this.on('convo.propagation', (event: CustomEvent<string | null>) => {
        this.convoId = event.detail;
      });

      this.on('chat-nav:set-active-dots', (event: CustomEvent<string | null>) => {
        this.dotsActive = event.detail;
      });
  }

  async delete(item: IConvo): Promise<void>
  {
    this.dotsActive = null;
    await this.convoService.deleteConvo(item.id);
    this.list = await this.convoService.getInstructorConvoList(this.courseId);
  }

  async rename(item: IConvo): Promise<void>
  {
    this.dotsActive = null;
    const newName = prompt('Rename conversation', item.title ? item.title : ('Convo: ' + item.id.substring(-8)));

    await this.convoService.renameConvo(item.id, newName);
    this.list = await this.convoService.getInstructorConvoList(this.courseId);

  }

  async search(e: Event){
    const target = (e.target as HTMLInputElement);
    this.dotsActive = null;
    const q: string = target.value;
    
    const timeoutTime = 200;

    if(q.length > 2){
      this.loading = true;

      if(this.timeout){
        clearTimeout(this.timeout);
      }
      this.$emit('chat.search.query', q); 
      this.timeout = setTimeout(async () => {
        this.list = await this.convoService.getInstructorConvoList(this.courseId, q);
        this.loading = false;
      }, timeoutTime);     
    }else if(!q){      
      if(this.timeout){
        clearTimeout(this.timeout);      
      }
      this.$emit('chat.search.query', null);
        this.loading = true;
      this.timeout = setTimeout(async () => {
        this.list = await this.convoService.getInstructorConvoList(this.courseId);
        this.loading = false;
      }, timeoutTime);
    }
  }

  showDots(item: IConvo): void 
  {  
    if(this.dotsActive == item.id){
      this.dotsActive = null;
      return;
    }

    this.dotsActive = item.id;    
  }

  toggle(userName: string): void
  {         
    if(this.activeUser === userName){
      this.activeUser = null;
    }else{
      this.activeUser = userName;
    }

    this.$emit('webchat:instructor:set-inspected', this.activeUser);
  }
  
}

ChatNavInstructor.defineComponent();

export { ChatNavInstructor };