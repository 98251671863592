import {RWSService} from '@rws-framework/client';
import {Activity, ScoreRecord} from "../types/activities";
import {LateWorkStatus} from "../types/late-work";
import EventsService, {EventsServiceInstance} from "./EventsService";

class LateWorkService extends RWSService {
    constructor(
      @EventsService private eventsService: EventsServiceInstance
    ) {
      super();
    }

    isLateActivityType(activity: Activity): boolean {
      return activity.type === 'assessment' || activity.type === 'project'
    }

    isLateWork(score: ScoreRecord): boolean {
      if (!score) return false;
      if (score.project_reopened) return false;

      return !!score.contains_overdue || score.manually_accepted || score.manually_rejected || !!score.overdue_accepted;
    }

    getStatus(score: ScoreRecord): LateWorkStatus {
      if (score.manually_rejected) {
        return LateWorkStatus.rejected;
      }
      else if (score.overdue_accepted || score.manually_accepted) {
        return LateWorkStatus.accepted;
      }
      else {
        return LateWorkStatus.submitted;
      }
    }

    isPolicyApplied(score: ScoreRecord): boolean {
      return score.overdue_accepted === 1;
    }

    async isChangingPolicyAvailable(): Promise<boolean> {
      const course = await this.eventsService.getCourse();
      return !(course.section_type === 'section' && course.use_section_template);
    }

}

export default LateWorkService.getSingleton();

export {LateWorkService as LateWorkServiceInstance}
