import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="convo-area-wrap${x => x.instructor ? ' instructor-view' : ''}">
    ${T.when(x => x.noChoose === 'false',  (item, index) => T.html`<div id="choose_bedrock_model">
        <chat-convo-models :chosenModel="${x => x.chosenModel}"></chat-convo-models>
    </div>`)}   
    <section>                
        <div class="scroll-area" style="--boxH: ${ x => ( x.instructor ? ((x.areaH) + 'px') : ((x.areaH) + 'px'))};">
            <div class="scroll-content">                                            
                ${T.repeat(x => x.messageList,  (item, c) => T.html`                      
                    <chat-convo-message    
                    :query="${(x,c) => c.parent.query}"
                    forceDone="${x => x.done ? 'true' : 'false'}"
                    initials="${(x,c) => c.parent.initials}" 
                    userImage="${(x,c) => c.parent.userImage}" 
                    :inspectedUser="${(x,c) => c.parent.inspectedUser}"  
                    dev="${(x,c) => c.parent.dev}" 
                    convoId="${ (x,c) => c.parent.convoId}" 
                    wsId="${ (x,c) => c.parent.wsId}" 
                    tooltip="${item => item._prompt ? ('<p><h3>SentPrompt</h3>\n' + item._prompt.sentInput + '</p>') : ''}" 
                    dateEnabled="${ (item, c) => c.parent.msgOptions ? c.parent.msgOptions.dateEnabled : null}" 
                    headerEnabled="${ (item, c) => c.parent.msgOptions ? c.parent.msgOptions.headerEnabled : null}" 
                    :contentReturn="${item => item}" :item="${item => item}"/>
                `)}      
                
                ${T.when(x => !x.messageList.length,  (item, index) => T.html`      
                    <p class="no-chat">
                      ${T.when(x => x.instructor, T.html`
                      <jnct-notfound
                        noBorder="true"
                        headerText="No Messages"
                        description="Choose user and conversation on the left panel"
                      >
                      </jnct-notfound>
                      `)}
                      ${T.when(x => !x.instructor, T.html`
                        <p class="no-chat">No messages</p>
                        `)} 
                    </p>
                    <example-prompts :book="${ x => x.book}" courseId="${ x => x.courseId }"></example-prompts>
                `)}   
            </div>
        </div>
    </section>  

</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  display: block;
}

a {
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
}

.instructor-view section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.convo-area-wrap section {
  height: 100%;
  background-color: var(--jchat-section-bg-color, #FFF);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
}
.convo-area-wrap section .scroll-area {
  overflow-y: scroll;
  height: calc(var(--boxH, auto) + 30px);
}
.convo-area-wrap section .scroll-area::-webkit-scrollbar {
  width: 8px;
}
.convo-area-wrap section .scroll-area::-webkit-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
.convo-area-wrap section .scroll-area::-webkit-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
.convo-area-wrap section .scroll-area::-moz-scrollbar {
  width: 8px;
}
.convo-area-wrap section .scroll-area::-moz-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
.convo-area-wrap section .scroll-area::-moz-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
.convo-area-wrap section .scroll-area::-ms-scrollbar {
  width: 8px;
}
.convo-area-wrap section .scroll-area::-ms-scrollbar-track {
  background: var(--jchat-scrollbar-bg-color, #ccc);
}
.convo-area-wrap section .scroll-area::-ms-scrollbar-thumb {
  background: var(--jchat-scrollbar-color, #333);
}
.convo-area-wrap section .scroll-area.disabled {
  height: auto;
}
.convo-area-wrap section h2 {
  margin: 0;
}
.convo-area-wrap section h3 {
  margin: 0;
}
.convo-area-wrap section .status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.convo-area-wrap section .green {
  background-color: var(--jchat-bot-msg-bg-color, #EEEFF0);
}
.convo-area-wrap section .orange {
  background-color: var(--jchat-error-color, #ff725d);
}
.convo-area-wrap section .blue {
  background-color: var(--color-palette-primary-20, rgba(85, 186, 183, 0.2));
  margin-right: 0;
  margin-left: 7px;
}
.convo-area-wrap section #chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  border-top: 2px solid var(--jchat-border-color, #fff);
  border-bottom: 2px solid var(--jchat-border-color, #fff);
  transition: all 0.3s ease-in-out;
}
.convo-area-wrap section .no-chat {
  text-align: center;
  color: var(--jchat-secondary-bg-color, #3b3e49);
  font-weight: bold;
}`;

const shadowOptions = {"mode":"open"};
import { RWSInject, RWSView, RWSViewComponent } from '@rws-framework/client';
import { observable, attr } from '@microsoft/fast-element';
import { IMessage } from '../chat-message/component';

import WebChatEvents from '../../events';
import { IContext } from '../../component';
import { BedrockBaseModel, IBook } from '@backend-types';
import theStrings from '../../../../strings';
import { IBoxHolder, IBoxData } from '../../../../types/box-data';
import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';

interface IConvoMsgOptions { headerEnabled?: boolean, dateEnabled?: boolean }

@RWSView('chat-convo-area', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class WebChatConvo extends RWSViewComponent  implements IBoxHolder {
    @observable chatContext: IContext;
    @observable boxData: IBoxData;
    @observable messages: IMessage[] = [];

    @observable chosenModel: BedrockBaseModel = null;

    @observable messageList: IMessage[] = [];
    @observable book: IBook = null;

    @attr noChoose: string = 'false';
    @attr chapter: number = null;
    @observable instructor: boolean = false;

    @observable msgOptions: IConvoMsgOptions = {};

    @observable query: string = '';
    @observable courseId: string;
    @observable convoId: string;
    @observable wsId: string;

    @observable dev: boolean = false;

    @observable userImage: string | null = null;
    @observable initials: string | null = null;

    @observable areaH: number = 0;


    @observable safetyInfo = theStrings.osha.preview_info;

    constructor(@RWSInject(WSService) private wsService: WSServiceInstance) {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.chatContext = {
            label: this.safetyInfo,
            active: true
        };

        this.on<{ item: IContext }>(WebChatEvents.context.change, (event: CustomEvent<{ item: IContext }>) => {
            this.chatContext = event.detail.item;
        });

        this.on<any>('scroll-down', (event: CustomEvent<any>) => {
            const chatContainer = this.$<HTMLDivElement>('.scroll-area') as HTMLDivElement;

            this.domService.scrollToBottom(chatContainer);
        });        

       
        // this.areaH = document.querySelector('chat-footer').clientHeight;

    }

    convoIdChanged(oldVal: string, newVal: string) {
        if (oldVal !== newVal) {
            this.convoId = newVal;
        }
    }

    chapterChanged(oldVal: number, newVal: number) {

        if (newVal && oldVal !== newVal) {
            this.chapter = newVal;
            this.setLabels();
        } else {
            this.chapter = null;
        }
    }

    bookChanged(oldValue: IBook, newValue: IBook) {
        if (newValue) {
            this.book = newValue;
            this.setLabels();
        }
    }

    messagesChanged(oldValue: IMessage[], newValue: IMessage[]) {
        if (newValue.length) {
            this.messageList = newValue;


            const chatContainer = this.$<HTMLDivElement>('.scroll-area') as HTMLDivElement;

            this.onDOMLoad().then(() => {
                this.domService.scrollToBottom(chatContainer);
            });
        } else {
            this.messageList = [];
        }
    }

    msgOptionsChanged(oldValue: IConvoMsgOptions, newValue: IConvoMsgOptions) {
        if (newValue && newValue !== oldValue) {
            this.msgOptions = newValue;
        }
    }

    private setLabels() {
        // this.chatContext = {
        //   context_id: this.book ? this.book.id : null,
        //   label: this.book ? 'Chosen book: ' + this.book.title + (this.chapter ? `. Chosen chapter ${this.chapter}: "${this.book.chapters[this.chapter - 1].title}"` : '') : null,
        //   active: true
        // }

        this.chatContext = {
            context_id: this.book ? this.book.id : null,
            label: this.safetyInfo,
            active: true
        };
    }
}

WebChatConvo.defineComponent();

export { WebChatConvo, IConvoMsgOptions };