type ResizeCallback = (width: number, height: number) => void;

export function observeResizeChanges(
    element: HTMLElement,
    resizeCallback?: ResizeCallback
): void {
    const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
            const width = entry.contentRect.width;
            const height = entry.contentRect.height;
            resizeCallback?.(width, height);
        });
    });

    resizeObserver.observe(element);
}