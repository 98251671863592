
import { WebChat } from './component';

export function handleWindowMechanics(this: WebChat)
{
    this.setWindowMechanics = function (this: WebChat, oldVal: string, newVal: string) {
        
        if(this.isInstructor){
            this.on<any>('chat-footer-ready', (event: CustomEvent<HTMLElement>) => {
                this.footer = event.detail;
                this.recalcArea();
            });               
            return;
        }
        const savedBoxData = this.resizeService.getSavedBoxData();      

        const chatWindow = this.getShadowRoot().host as HTMLElement & { recalcArea: () => void };             
        const boundaryElement = document.querySelector('.layout__border') as HTMLElement;

        if(savedBoxData){
            this.boxData = savedBoxData;
     
            this.resizeService.moveTarget(chatWindow, this.boxData.left, this.boxData.top);
            this.resizeService.applySize(chatWindow, this.boxData.w, this.boxData.h)
        }else{
            this.boxData = this.resizeService.getBoxData(this);    
        }               
        
        if(this.forceOpened === 'true'){      
            chatWindow.style.display = 'block';
           
        }       

        this.on<any>('chat-footer-ready', (event: CustomEvent<HTMLElement>) => {
            this.footer = event.detail;
            this.recalcArea();
        });    

        this.on<HTMLElement>('chat.header:loaded', (event: CustomEvent<HTMLElement>) => {                           
            const chatWindowHeader = event.detail;                 
   
            
            if(chatWindowHeader){
                this.header = chatWindowHeader;

                this.recalcArea();


                const bMod= {
                    h: -46
                };


                this.resizeService
                .resize(this, {
                    target: chatWindow,
                    boundary: boundaryElement,
                    boundaryMod: bMod,
                    _interact: {
                        ignoreFrom: '#web_chat_content_area main, chat-header, chat-nav, chat-footer',
                    }
                })
                .drag(this, {
                    target: chatWindow,
                    handler: chatWindowHeader,
                    boundary: boundaryElement,
                    boundaryMod: bMod,
                    _interact: {
                        ignoreFrom: '#web_chat_content_area main, chat-header, chat-nav, chat-footer',
                    }
                });

                if(this.minified){
                    chatWindow.style.width = '365px';
                }                

                
    
                if(this.forceOpened === 'true'){      
                    this.boxData = {...this.boxData, h: 548}                          
                    this.resizeService.bump(chatWindow, this.boxData, this.resizeService.makeBoundary(boundaryElement, bMod));                  
                }                                   
            }
        })      
    }.bind(this)

    this.recalcArea = function (this: WebChat): void 
    {   

     
        let footOffset = this.footer ? this.footer.offsetHeight : 0;        
        let headOffset = this.header ? this.header.offsetHeight : 0;        

        let ADD_CSS_CHAT = 45;
        let SUBTRACT_CSS_AREA = 85;    
        
        if(this.isInstructor){
            footOffset = 0;
            headOffset = 0;
            ADD_CSS_CHAT = -50;
            SUBTRACT_CSS_AREA = 200;
        }

        this.chatH = this.offsetHeight - footOffset + ADD_CSS_CHAT;
        this.areaH = this.offsetHeight - footOffset - headOffset - SUBTRACT_CSS_AREA;    
    }.bind(this)
}
