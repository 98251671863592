import { BedrockBaseModel } from '../types/Bedrock';

const MODEL: BedrockBaseModel = {
    customizationsSupported: [],
    inferenceTypesSupported: [
        'ON_DEMAND'
    ],
    inputModalities: [
        'TEXT'
    ],
    modelArn: 'arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-v2',
    modelId: 'anthropic.claude-v2',
    modelLifecycle: {
        'status': 'ACTIVE'
    },
    modelName: 'Claude',
    outputModalities: [
        'TEXT'
    ],
    providerName: 'Anthropic',
    responseStreamingSupported: true
};


export default MODEL;