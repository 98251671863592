import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="config-loader">
    <input id="file_input" type="file"/>
    <button @click="${ x => x.loadConfig() }"><jnct-icon size="20px">upload</jnct-icon>Load AI config</button>
    <button @click="${ x => x.saveConfig() }" type="button"><jnct-icon size="20px">save</jnct-icon>Save AI config</button>
</div>`;

import './styles/layout.scss';
const styles = T.css`.config-loader {
  display: inline-flex;
  gap: 8px;
  margin-left: 8px;
}
.config-loader button {
  background-color: var(--secondary_color);
  color: #FFF;
  border: none;
  border-radius: 4px;
  padding: 4px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.config-loader input[type=file] {
  display: none;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr } from "@rws-framework/client";
import { IChatProfile, ControllerActionResponse, IAnthropicHyperParams, DEFAULT_CONVO_SPLIT_PARAMS, IConfigSplitterParams } from '@backend-types';
import { v1 as shortUniqid } from 'uuid';


@RWSView('chat-profile-configloader', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatProfileConfigLoader extends RWSViewComponent  {
    @observable profile: IChatProfile = null;
    @observable file: File | null = null;


    connectedCallback(): void {
        super.connectedCallback();       
    }  

    fileChanged(oldVal: File | undefined, newVal: File | null)
    {
        if(newVal){
            const reader = new FileReader();
    
            reader.onload = (e) => {
                try {
                    const jsonData: IChatProfile = JSON.parse(e.target?.result as string);
                    
                    
                    this.dispatchEvent(new CustomEvent<IChatProfile>('change', {detail: jsonData}));
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };

            reader.readAsText(newVal);
        }
    }

    loadConfig(){
        const fileInput = (this.$('#file_input') as HTMLInputElement);
        
        fileInput.addEventListener('change', () => {
            this.file = fileInput.files[0];                    
        });

        fileInput.click();
    }

    saveConfig()
    {
        console.log('saving', this.profile);
        this.downloadWithType(JSON.stringify(this.profile), `ai_settings_${this.generateShortUUID()}`, 'application/json');
    }

    private downloadWithType(content: string, fileName: string, mimeType: string) {
        const blob = new Blob([content], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    private generateShortUUID(): string {
        return shortUniqid().split('-')[0];
    }
}

ChatProfileConfigLoader.defineComponent();

export { ChatProfileConfigLoader }