import {RWSService} from '@rws-framework/client';
import {
  GradeByChapter,
  LastPageVisited,
  LastSubmittedAssignment,
  StudentActivityTimes,
  StudentDeepDiveAssignmentRecord, StudentDeepDiveDetailView,
  StudentDeepDiveListItem
} from '../types/reports';
import ActivitiesService, {ActivitiesServiceInstance} from "./ActivitiesService";
import EventsService, {EventsServiceInstance} from "./EventsService";
import {RosterRecord} from "../types/users";
import {Activity, ActivityStudentScore, LastSubmittedActivityPerStudent, LessonAssessments} from "../types/activities";
import {CalendarStatus} from "../types/calendar";


class StudentDeepDiveService extends RWSService {
  constructor(
    @ActivitiesService private activitiesService: ActivitiesServiceInstance,
    @EventsService private eventsService: EventsServiceInstance
  ) {
    super();
  }

  async getListView(): Promise<StudentDeepDiveListItem[]> {
    const [average, course, roster, activity_list]: [any, any, RosterRecord[], Activity[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse(),
      this.eventsService.getRoster(),
      this.eventsService.getActivities()
    ]);

    const courseId = course._id;
    const activity_student_score: ActivityStudentScore = average.activity_student_score;
    const studentActivityTimes: StudentActivityTimes = await this.getStudentActivityTimes(courseId);

    const lastSubmittedActivities: LastSubmittedActivityPerStudent = this.activitiesService.getLastSubmittedActivities(activity_student_score);
    const activities: Record<string, Activity> = (activity_list || []).reduce((acc, activity: Activity) => {
      if (activity.type === 'custom') {
        acc["_" + activity.id] = activity;
      } else {
        acc[activity.id] = activity;
      }
      return acc;
    }, {} as Record<string, Activity>);

    const report: StudentDeepDiveListItem[] = roster.map((student: RosterRecord): StudentDeepDiveListItem => {
      return {
        student: {
          id: student._id,
          sortname: student.lastname + ", " + student.firstname,
          fullname: student.name
        },
        avg_score: average.user_scores[student._id]?.percent || 0,
        lastTimeActive: this.getLastTimeActive(studentActivityTimes, student._id),
        lastSubmittedAssignment: this.getLastSubmittedAssignment(student._id, lastSubmittedActivities, activities),
        lastPageVisited: this.getLastPageVisited(studentActivityTimes, student._id, activities),
        courseId: courseId
      }
    });

    return report;
  }

  async getDetailView(student_id: string): Promise<StudentDeepDiveDetailView> {
    const [average, course, roster, activity_list]: [any, any, RosterRecord[], Activity[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse(),
      this.eventsService.getRoster(),
      this.eventsService.getActivities()
    ]);

    const activity_student_score: ActivityStudentScore = average.activity_student_score;
    const activities: Record<string, Activity> = (activity_list || []).reduce((acc, activity: Activity) => {
      if (activity.type === 'custom') {
        acc["_" + activity.id] = activity;
      } else {
        acc[activity.id] = activity;
      }
      return acc;
    }, {} as Record<string, Activity>);

    const courseId = course._id;
    const filteredScores = this.filterActivityStudentScore(activity_student_score, student_id);
    const studentActivityTimes: StudentActivityTimes = await this.getStudentActivityTimes(courseId);

    const lastSubmittedActivities: LastSubmittedActivityPerStudent = this.activitiesService.getLastSubmittedActivities(activity_student_score);
    const report: StudentDeepDiveListItem[] = roster.map((student: RosterRecord): StudentDeepDiveListItem => {
      return {
        student: {
          id: student._id,
          sortname: student.lastname + ", " + student.firstname,
          fullname: student.name
        },
        avg_score: average.user_scores[student._id]?.percent || 0,
        lastTimeActive: this.getLastTimeActive(studentActivityTimes, student._id),
        lastSubmittedAssignment: this.getLastSubmittedAssignment(student._id, lastSubmittedActivities, activities),
        lastPageVisited: this.getLastPageVisited(studentActivityTimes, student._id, activities),
        courseId: courseId
      }
    });

    const assignments = this.getAssignmentsTable(average.activity_manager, activity_student_score, student_id);
    return {
      student: roster.find(student => student._id === student_id),
      header: {
        avg_score: average.user_scores[student_id].percent,
        lastTimeActive: this.getLastTimeActive(studentActivityTimes, student_id),
        lastSubmittedAssignment: this.getLastSubmittedAssignment(student_id, this.activitiesService.getLastSubmittedActivities(filteredScores), activities),
        lastPageVisited: this.getLastPageVisited(studentActivityTimes, student_id, activities),
      },
      grade_by_chapter: this.getGradeByChapter(average, student_id, activities),
      submissions_chart: this.countSubmissionsChart(assignments),
      assignments: assignments,
      student_report: report.filter(s => s.student.id == student_id)?.[0]
    }
  }

  private async getStudentActivityTimes(courseId: string): Promise<StudentActivityTimes> {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    const response = await fetch(`${APIprefix}stats/studentactivitytimes/${courseId}`);
    return await response.json()
  }

  private getLastTimeActive(studentActivityTimes: StudentActivityTimes, student_id: string): Date | null {
    const studentData = studentActivityTimes[student_id];
    if (!studentData) {
      return null;
    }

    return new Date(studentData.last_time_active);
  }

  private getLastSubmittedAssignment(student_id: string, lastSubmittedActivities: LastSubmittedActivityPerStudent, activities: Record<string, Activity>): LastSubmittedAssignment {
    const lastSubmitted = lastSubmittedActivities[student_id];
    if (!lastSubmitted) {
      return null;
    }

    const activity_id = lastSubmitted.activity_id;
    let activity: Activity = activities[activity_id];

    // Here is where the retained id comes into play: What student takes is not the activity_id
    if (!activity) {
      Object.entries(activities).forEach(x => {
        if (x[1].activity_id == activity_id) {
          activity = x[1]
        }
      })
    }

    return activity? {
      id: activity_id,
      title: activity.report_title,
      link: activity.link
    } : null;
  }

  private getLastPageVisited(studentActivityTimes: StudentActivityTimes, student_id: string, activities: Record<string, Activity>): LastPageVisited {
    const studentData = studentActivityTimes[student_id];
    if (!studentData || !studentData.last_page_visited) {
      return null;
    }

    const pageActivity: Activity = activities[studentData.last_page_visited];

    if (!pageActivity) return null;

    return {
      id: studentData.last_page_visited,
      title: activities[studentData.last_page_visited].report_title,
      link: activities[studentData.last_page_visited].link
    }
  }

  private filterActivityStudentScore(activityStudentScore: ActivityStudentScore, studentId: string): ActivityStudentScore {
    /*
      Filter activityStudentScore to only include the student
     */
    const filteredScores: ActivityStudentScore = {};

    for (const [activityId, studentScores] of Object.entries(activityStudentScore)) {
      if (studentScores[studentId]) {
        filteredScores[activityId] = {[studentId]: studentScores[studentId]};
      }
    }

    return filteredScores;
  };

  private getAssignmentsTable(activities: Activity[], activity_student_score: ActivityStudentScore, student_id: string): StudentDeepDiveAssignmentRecord[] {
    return activities
      .filter(activity => activity.graded === true)
      .map(activity => {
        return {
          id: activity.id,
          activity_id: activity.activity_id,
          title: activity.report_title,
          link: activity.link.substr(2).split('/').length < 4? activity.link + '/' + activity.id : activity.link,
          type: activity.type,
          status: this.activitiesService.getActivityStatus(activity, activity_student_score, student_id),
          score_in_percentage: this.activitiesService.getGradeInPercentage(activity, activity_student_score, student_id),
          submission_date: this.activitiesService.getSubmissionDate(activity, activity_student_score, student_id),
          due_date: activity.due_date,
          lesson_id: activity.lesson?.id || "Course Level",
          lesson_title: activity.lesson?.title || "Course Level",
          manually_accepted: activity_student_score[activity.activity_id || activity.id]?.[student_id]?.manually_accepted,
          manually_rejected: activity_student_score[activity.activity_id || activity.id]?.[student_id]?.manually_rejected,
        } as StudentDeepDiveAssignmentRecord
    });
  }

  private countSubmissionsChart(assignments: StudentDeepDiveAssignmentRecord[]): {on_time: number, not_submitted: number, late: number} {
    let on_time = 0;
    let not_submitted = 0;
    let late = 0;

    assignments.forEach(assignment => {
      if (
        [CalendarStatus.Graded, CalendarStatus.Submitted].includes(assignment.status) &&
        assignment.submission_date < (assignment.due_date ? assignment.due_date : '2199-01-01T00:00:00.000Z')
      ) {
        on_time += 1;
      } else if (
        [CalendarStatus.Graded, CalendarStatus.Submitted].includes(assignment.status) &&
        assignment.submission_date > (assignment.due_date ? assignment.due_date : '2199-01-01T00:00:00.000Z')
      ) {
        late += 1;
      } else {
        not_submitted += 1;
      }
    });

    return {
      on_time,
      not_submitted,
      late
    }
  }

  getGradeByChapter(average: any, student_id: string, activities: Record<string, Activity>): GradeByChapter {
    const lessonassessments: LessonAssessments = average.lessonassessments;

    const gradeByChapter: GradeByChapter = Object.keys(lessonassessments).reduce((acc, lesson_id) => {
      const lesson = activities[lesson_id];
      const now = new Date().toISOString();

      let isAvailableLesson = true;

      if (!lesson || lesson.suppress_until_available) {
        isAvailableLesson = false;
      } else if (lesson.open_date && lesson.open_date > now) {
        isAvailableLesson = false
      }

      if (isAvailableLesson) {
        acc[lesson_id] = {
          title: activities[lesson_id]?.report_title,
          score_in_percentage: average.activity_student_score["_" + lesson_id]?.[student_id]?.percent ?? 0,
        };
      }
      return acc;
    }, {});

    return gradeByChapter;
  }
}

export default StudentDeepDiveService.getSingleton();

export {StudentDeepDiveService as StudentDeepDiveServiceInstance}
