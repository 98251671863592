import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when( x => x.dev === 'true', T.html`
    ${T.repeat(x => x.item.system_messages, T.html`<li class="message-item you system-message">
        <div class="message${x => !x.userMessage ? (x.done ? ' done' : ' loading') : ''}">     
            <div class="message-header">System Message</div>     
            <div class="message-content">
                ${(x) => x.message}
                <div class="msg_header">
                    <h3>${(x, c) => c.parent.formatDate(x.date, 'D.M.Y H:mm:ss')}</h3>
                </div>
            </div>       
    </li>`)}
`)}
<li class="message-item ${x => x.item.userMessage && !x.item._is_system ? 'me' : 'you'}${x => x.item._error ? ' error' : ''}${x => x.done ? ' done' : ' loading'}${x => x.item._is_system ? ' system-message' : ''}">
    <div class="msg-all-wrap">
        ${T.when(x => x.showUserMeta, T.html`<div class="message-usr-area">            
            ${T.when(x => !!x.fileAssets['svg/icon_talk_1.svg'] && !x.item.userMessage, T.html`<div class="svg-holder">${x => x.showAsset('svg/icon_talk_1.svg')}</div>`)}
            ${T.when(x => !!x.userImage && x.item.userMessage, T.html`<div class="circle-holder img-holder" style="background-image: url('${x => x.userImage }');"></div>`)}
            ${T.when(x => !x.userImage && x.initials && x.item.userMessage, T.html`<div class="circle-holder  initials-holder"><span class="${ x=> x.initials.split('').length === 1 ? 'only-one' : ''}">${x => x.initials.split('')[0] }</span>${T.when(x => x.initials.split('').length > 1, T.html`<span>${x => x.initials.split('')[1] }</span>`)}</div>`)}
        </div>`)}            
        <div class="message${x => !x.item.userMessage ? (x.done ? ' done' : ' loading') : ''}">          
            <div class="message-content">
                <line-splitter :query="${ x => x.query}" addClass="${x => !x.item.userMessage ? (x.done ? 'done' : 'loading') : ''}" :text="${(x) => x.item.content}"></line-splitter>
            </div>           

            ${T.when(x => x.done && x.dbId && x.scored === null && !!x.fileAssets['svg/icon_thumbs_up.svg'] && !x.item.userMessage, T.html`<br/><div class="scoring_holder">
                <span class="info-text">Please rate this answer:</span>
                <div class="score-it negative"><div class="svg-holder" @click="${ x => x.doScoreMsg(0) }">${x => x.showAsset('svg/icon_thumbs_up.svg')}</div></div>
                <div class="score-it positive"><div class="svg-holder" @click="${ x => x.doScoreMsg(1) }">${x => x.showAsset('svg/icon_thumbs_up.svg')}</div></div>
            </div>`)}

            ${T.when(x => x.dev === 'true' && x.done, T.html`<div>                            
                ${T.when(x => x.item._prompt?.sentInput, T.html`<div class="tooltip_holder">
                    <span id="sentInputTooltip" class="msg-tooltip-holder" @click="${ x => x.showAlert(x.alertSentInput()) }">?</span>
                    <fast-tooltip anchor="sentInputTooltip">Final prompt for Claude</fast-tooltip>
                </div>`)}

                ${T.when(x => x.item.intruder_prompt, T.html`<div class="tooltip_holder">
                        <span id="intruderInputTooltip" class="msg-tooltip-holder intruder" @click="${ x => x.showAlert(x.alertIntruderInput()) }">!</span>
                        <fast-tooltip anchor="intruderInputTooltip">Intruder prompt</fast-tooltip>
                </div>`)}

                ${T.when(x => x.item?._debug?.time, T.html`<div class="tooltip_holder">
                    <span id="timeDebugTooltip" class="msg-tooltip-holder time">
                        <svg fill="#000000" height="800px" width="800px" version="1.1" id="clock" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 488 488" xml:space="preserve">
                            <g transform="translate(0 -540.36)">
                                <g>
                                    <g>
                                        <path d="M351.1,846.96l-97.1-67.9v-116.7c0-5.5-4.5-10-10-10s-10,4.5-10,10v122c0,3.3,1.6,6.3,4.3,8.2l101.4,70.9     c1.7,1.2,3.7,1.8,5.7,1.8v0c3.1,0,6.2-1.5,8.2-4.4C356.7,856.36,355.6,850.16,351.1,846.96z"/>
                                        <path d="M416.4,611.96L416.4,611.96c-46.2-46.2-107.4-71.6-172.4-71.6s-126.2,25.4-172.4,71.6C25.4,658.16,0,719.36,0,784.36     s25.4,126.2,71.6,172.4c46.2,46.2,107.4,71.6,172.4,71.6s126.2-25.4,172.4-71.6s71.6-107.4,71.6-172.4S462.6,658.16,416.4,611.96     z M254,1008.16L254,1008.16v-40.8c0-5.5-4.5-10-10-10s-10,4.5-10,10v40.8c-115.6-5.1-208.7-98.2-213.8-213.8H61     c5.5,0,10-4.5,10-10s-4.5-10-10-10H20.2c5.1-115.6,98.2-208.7,213.8-213.8v40.8c0,5.5,4.5,10,10,10s10-4.5,10-10v-40.8     c115.6,5.1,208.7,98.2,213.8,213.8H427c-5.5,0-10,4.5-10,10s4.5,10,10,10h40.8C462.7,909.96,369.6,1003.06,254,1008.16z"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <fast-tooltip anchor="timeDebugTooltip">${x => x.printTimeDebug() }</fast-tooltip>
                </div>`)}

            </div>`)}

            ${T.when(x => (((x.dev === 'true' || x.visibility.author === true)) || (x.dev === 'true' || x.visibility.date === true)), T.html`<div class="msg_header">                      
                ${T.when(x => (x.dev === 'true' || x.visibility.author === true), T.html`<h2>            
                    ${T.when(x => x.item.model, T.html`[<strong>${x => x.item.model?.modelId}</strong>]`)}
                    ${x => x.inspectedUser && x.item.author === '_user' ? x.inspectedUser : x.item.author}

                    ${T.when(x => (x.item.var_storage.hyperparameters || false) && x.item.author !== '_user', T.html`<div id="" class="hyper-meta">
                        <span id="hyper_meta_${ x => x.item.id}" style="cursor: pointer;" style="font-size: 21px;" class="material-symbols-outlined" aria-hidden="true"> info </span>
                        <fluent-tooltip
                            anchor="hyper_meta_${ x => x.item.id}"
                            position="top"
                            delay="200">
                            Temperature: ${ x => x.item.var_storage.hyperparameters?.temperature }, 
                            Top K: ${ x => x.item.var_storage.hyperparameters?.top_k }, 
                            Top P: ${ x => x.item.var_storage.hyperparameters?.top_p }, 
                            Max tokens: ${ x => x.item.var_storage.hyperparameters?.max_tokens_to_sample }
                        </fluent-tooltip>
                    </div>`)}

                    ${T.when(x => (x.item.var_storage.splitparameters || false) && x.item.author !== '_user', T.html`<div id="" class="split-meta">
                        <span id="split_meta_${ x => x.item.id}" style="cursor: pointer;" style="font-size: 21px;" class="material-symbols-outlined" aria-hidden="true"> info </span>
                        <fluent-tooltip
                            anchor="split_meta_${ x => x.item.id}"
                            position="top"
                            delay="200">
                            Split text chunk size: ${ x => x.item.var_storage.splitparameters?.chunkSize }, 
                            Overlap between chunks: ${ x => x.item.var_storage.splitparameters?.chunkOverlap }, 
                            Amount of chunks sent to AI: ${ x => x.item.var_storage.splitparameters?.partsCount },                             
                        </fluent-tooltip>
                    </div>`)}
                </h2>`)}

                ${T.when(x => (x.item.var_storage.full_input_prompt || false) && x.item.author !== '_user', T.html`<div class="full-prompt">
                    <div class="prompt-content ${x => x.promptShow ? ' show' : ''}">
                        <div @click="${x => x.setPromptShow(false)}" class="backdrop"></div>
                        <section>
                            <main>
                                <button @click="${x => x.setPromptShow(false)}" class="close-preview"><jnct-icon size="22px">close</jnct-icon></button>
                                <p>${(x) => x.splitLines(x.item.var_storage.full_input_prompt)}</p>
                            </main>                            
                        </section>
                    </div>

                    ${T.when(x => !x.promptShow, T.html`<button @click="${x => x.setPromptShow(true)}" class="open-preview"><jnct-icon size="22px">preview</jnct-icon> Full Prompt</button>`)}
                </div>`)}
        
                ${T.when(x => (x.dev === 'true' || x.visibility.date === true), T.html`<div class="meta-zone">
                    <h3>${(x, c) => x.time('D.M.Y H:mm:ss')}</h3>              
                </div>`)}

                ${T.when(x => x.item.score !== null, T.html`<br/><div class="scoring_holder" style="margin-left: 0; text-align: left; font-weight: bold;">
                    <span class="info-text">Answer rated:</span>
                    ${T.when(x => x.item.score === 0, T.html`<div class="score-it negative"><div class="svg-holder" @click="${ x => x.doScoreMsg(0) }">${x => x.showAsset('svg/icon_thumbs_up.svg')}</div></div>`)}
                    ${T.when(x => x.item.score === 1, T.html`<div class="score-it positive"><div class="svg-holder" @click="${ x => x.doScoreMsg(1) }">${x => x.showAsset('svg/icon_thumbs_up.svg')}</div></div>`)}
                </div>`)}                
            </div>`)}
        </div>    
    </div> 
</li>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
  --jchat-base-bg-color: #abd9e9;
  --jchat-secondary-bg-color: #3b3e49;
  --jchat-alternative-color: #5e616a;
  --jchat-bot-msg-bg-color: #EEEFF0;
  --jchat-error-color: #ff725d;
  --jchat-my-msg-bg-color: rgba(85, 186, 183, 0.2);
  --jchat-section-bg-color: #FFF;
  --jtrainer-footer-bg-color: #FFF;
  --jtrainer-score-pos-color: var(--neutral-30);
  --jtrainer-score-neg-color: var(--neutral-30);
  --chat-nav-hover-bg-solid: #52b8b4;
}
:root .hide-chat web-chat {
  display: none;
}
:root .hide-chat web-chat.instructor-chat {
  display: block;
}

#view {
  min-height: calc(100vh - 100px);
}

.convo-tooltip {
  z-index: 999999;
  display: block;
  padding: 5px;
  height: 28px;
  background-color: #5e616a;
  position: absolute;
  color: #FFF;
  overflow: visible;
  border-radius: 5px;
}
.convo-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5e616a;
  width: 0;
  height: 0;
}

.ds-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-300, rgb(188, 188, 189));
}

#ai-manager-holder {
  margin: -10px -10px;
  height: calc(100vh - 16px);
  width: calc(100% + 20px);
}
#ai-manager-holder web-chat {
  position: absolute;
  width: 100% !important;
  height: 100%;
  max-height: none;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  transform: none !important;
}

fast-button[appearance=accent] {
  background-color: var(--primary_color, #da1a5f);
}

rws-uploader {
  --rws-uploader-bg: #FFF;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

@keyframes blinkNscale {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0.4);
  }
}
h2 {
  color: #7e818a;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
  margin: 0;
}

h3 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  color: #bbb;
  margin: 0;
}

li.message-item {
  padding: 12px;
  list-style: none;
}
li.message-item .message-usr-area {
  width: calc(var(--header_height, 30px) - 9px);
  margin-right: 15px;
}
li.message-item .message-usr-area .svg-holder {
  display: block;
  position: relative;
  right: 35px;
  width: 30px;
  height: 0px;
  padding-right: 15px;
  margin-right: 15px;
}
li.message-item .message-usr-area .circle-holder {
  height: calc(var(--header_height, 30px) - 9px);
  width: calc(var(--header_height, 30px) - 9px);
  margin: 2px auto;
  position: relative;
  border-radius: 50%;
  display: block;
}
li.message-item .message-usr-area .circle-holder.img-holder {
  background-size: 30px;
  height: 30px;
  width: 30px;
  position: relative;
  top: 10px;
  right: 20px;
  background-position: center;
  background-repeat: no-repeat;
}
li.message-item .message-usr-area .circle-holder.initials-holder {
  background-color: var(--primary_color, #da1a5f);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
  right: 20px;
}
li.message-item .message-usr-area .circle-holder.initials-holder span {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--jchat-initials-txt-color, #FFF);
}
li.message-item .message-usr-area .circle-holder.initials-holder span:first-child {
  margin-right: 3px;
}
li.message-item .message-usr-area .circle-holder.initials-holder span.only-one {
  margin-right: 0;
}
li.message-item .msg_header {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}
li.message-item .message {
  border-radius: 10px;
}
li.message-item .message .done {
  margin-left: 15px;
  position: relative;
  top: -15px;
}
li.message-item .message .message-content {
  color: var(--jchat-txt-color, rgb(0, 0, 0));
  border-radius: 10px;
  margin: 10px 10px 10px 0px;
  padding: 8px;
  min-width: 260px;
  line-height: 25px;
  font-size: 14px;
  display: inline-block;
  text-align: left;
}
li.message-item .message .message-content h1,
li.message-item .message .message-content h2,
li.message-item .message .message-content h3 {
  margin: 0 0 5px 0;
}
li.message-item .message .spacer {
  margin-right: 10px;
}
li.message-item .message p {
  margin: 0;
  padding: 0;
}
li.message-item .message .dev_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: "left";
  align-items: "top";
  padding-right: 10%;
  margin-top: 15px;
  position: relative;
}
li.message-item .message .dev_holder::before {
  content: "";
  width: 70%;
  height: 1px;
  background-color: var(--jchat-alternative-color, #5e616a);
  display: block;
  margin: 0 auto 15px auto;
  flex: 1 0 100%;
}
li.message-item .message .dev_holder .msg-tooltip-holder {
  margin-right: 10px;
}
li.message-item.me .msg-all-wrap {
  display: flex;
  justify-content: right;
}
li.message-item.me .msg-all-wrap .message .message-content {
  background-color: var(--primary-20);
  text-align: right;
}
li.message-item.me .msg-all-wrap .message.intruder-message {
  clear: both;
  background-color: var(--jchat-intruder-msg-bg-color, rgb(176, 50, 235));
  color: #FFF;
  display: inline-block;
  font-style: italic;
}
li.message-item.me .msg-all-wrap .message.done {
  margin-left: 15px;
  position: relative;
  top: -15px;
}
li.message-item.me .msg-all-wrap .triangle {
  border-color: transparent transparent var(--color-palette-primary-20, rgba(85, 186, 183, 0.2)) transparent;
  margin-left: auto;
  margin-right: 15px;
  display: none;
}
li.message-item.you .message .message-content {
  border: 1px solid var(--neutral-20);
  border-radius: 12px;
}
li.message-item.you .triangle {
  border-color: transparent transparent var(--jchat-bot-msg-bg-color, #EEEFF0) transparent;
  margin-left: 15px;
}
li.message-item.you .scoring_holder {
  display: block;
  margin-left: auto;
  padding-right: 10%;
  text-align: right;
}
li.message-item.you .scoring_holder .info-text {
  color: var(--jchat-txt-color, rgb(0, 0, 0));
  margin-right: 10px;
}
li.message-item.you .scoring_holder .score-it {
  margin-left: 5px;
  max-width: 20px;
  cursor: pointer;
  display: inline-block;
}
li.message-item.you .scoring_holder .score-it .svg-holder svg {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
li.message-item.you .scoring_holder .score-it.negative {
  margin-left: auto;
  transform: rotate(180deg);
}
li.message-item.you .scoring_holder .score-it.negative .svg-holder svg path {
  fill: var(--jtrainer-score-neg-color, #900);
}
li.message-item.you .scoring_holder .score-it.positive .svg-holder svg path {
  fill: var(--jtrainer-score-pos-color, #0a6124);
}
li.message-item.error .message .message-content {
  background-color: var(--jchat-error-color, #ff725d);
  color: var(--jchat-error-txt-color, #FFF);
}
li.message-item.error .triangle {
  border-color: transparent transparent var(--jchat-error-color, #ff725d);
}
li.message-item.system-message .message .message-header {
  font-size: 12px;
}
li.message-item.system-message .message .message-content {
  background-color: #4b79b6;
  color: var(--jchat-error-txt-color, #FFF);
}
li.message-item.system-message .triangle {
  border-color: transparent transparent #4b79b6;
}
li.message-item .msg-tooltip-holder {
  border: 2px solid #FFF;
  font-weight: bold;
  font-size: 24px;
  padding: 2px 9px 0px;
  border-radius: 30px;
  display: inline-block;
  width: 33px;
  height: 33px;
  cursor: pointer;
  text-align: center;
  border-color: var(--jchat-alternative-color, #5e616a);
  color: var(--jchat-alternative-color, #5e616a);
}
li.message-item .msg-tooltip-holder.intruder {
  border-color: var(--jchat-intruder-msg-bg-color, rgb(176, 50, 235));
  color: var(--jchat-intruder-msg-bg-color, rgb(176, 50, 235));
}
li.message-item .msg-tooltip-holder.time {
  border-color: var(--jchat-alternative-color, #5e616a);
  color: var(--jchat-alternative-color, #5e616a);
}
li.message-item .msg-tooltip-holder svg#clock {
  max-width: 100%;
  max-height: 100%;
  transform: scale(1.7);
  margin-top: -1px;
  fill: var(--jchat-alternative-color, #5e616a) !important;
}

.hyper-meta {
  display: inline;
}

.split-meta {
  display: inline;
}

.full-prompt .prompt-content {
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90000;
}
.full-prompt .prompt-content .backdrop {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.full-prompt .prompt-content section {
  display: none;
  position: absolute;
  background-color: #FFF;
  width: 80%;
  height: 80%;
  top: 50%;
  color: #000;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}
.full-prompt .prompt-content section::-webkit-scrollbar {
  width: 10px;
}
.full-prompt .prompt-content section::-webkit-scrollbar-track {
  background: #ccc;
}
.full-prompt .prompt-content section::-webkit-scrollbar-thumb {
  background: #333;
}
.full-prompt .prompt-content section::-moz-scrollbar {
  width: 10px;
}
.full-prompt .prompt-content section::-moz-scrollbar-track {
  background: #ccc;
}
.full-prompt .prompt-content section::-moz-scrollbar-thumb {
  background: #333;
}
.full-prompt .prompt-content section::-ms-scrollbar {
  width: 10px;
}
.full-prompt .prompt-content section::-ms-scrollbar-track {
  background: #ccc;
}
.full-prompt .prompt-content section::-ms-scrollbar-thumb {
  background: #333;
}
.full-prompt .prompt-content section main {
  padding: 15px;
}
.full-prompt .prompt-content.show {
  display: block;
}
.full-prompt .prompt-content.show .backdrop {
  display: block;
}
.full-prompt .prompt-content.show section {
  display: block;
}
.full-prompt button {
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.full-prompt button.close-preview {
  background: #900;
  color: #FFF;
  position: sticky;
  top: 0;
  right: 0;
  margin: 15px 15px 15px auto;
}
.full-prompt button.open-preview {
  background: var(--primary_color);
  color: #FFF;
  margin: 10px 0;
}`;

const shadowOptions = {"mode":"open"};
import { 
    RWSView, RWSViewComponent, RWSInject
} from '@rws-framework/client';

import { ITalkApiResponse, ILLMChunk, WSChatEndResponseType, IMessage as IDBMessage, IRWSPromptJSON } from '@backend-types';
import { attr, observable } from '@microsoft/fast-element';
// import DateService, {DateServiceInstance} from '../../services/DateService';

import { WSService, WSServiceInstance } from '@rws-framework/nest-interconnectors';
import '../../../line-splitter/component';
import moment from 'moment';

interface IMessage extends IDBMessage {
    _prompt?: Partial<IRWSPromptJSON>;
    _promise?: Promise<ITalkApiResponse>,
    _stream?: ReadableStream
    _error?: boolean;
    _debug?: any,
    _is_system?: boolean
}

const _ERROR_INFO_MSG = `Apologies. An unexpected chatbot error occurred. Please contact the administrator.`;

@RWSView('chat-convo-message', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class WebChatMessage extends RWSViewComponent  {
    @observable item: IMessage;
    @attr dateEnabled: string;
    @attr headerEnabled: string;

    @observable error: boolean = false;

    @observable visibility: { author: boolean, date: boolean } = { author: true, date: true };
    @observable dbId: string;
    @observable contentReturn: IMessage;
    @observable promptShow: boolean = false;

    @attr wsId: string;
    @attr convoId: string;
    @attr dev: boolean = false;
    @attr showUserMeta: boolean = false;
    
    private errorShown: boolean = false;

    @observable readingBook: boolean = false;
    @observable streaming: boolean = false;
    @observable done: boolean = false;

    @observable inspectedUser: string;  

    @attr userImage: string | null = null;
    @attr initials: string | null = null;
    @attr forceDone: string = 'false';

    @observable query: string = '';
    @observable scored: boolean | null = null;
    @observable sysMessageList: IMessage[] = [];

    private firstRun: boolean = true;

    private listenersRunning = false;

    static fileList: string[] = [
        'svg/icon_talk_1.svg',
        'svg/icon_thumbs_up.svg'
    ];        

    connectedCallback(): void {
        super.connectedCallback();
        
        if(this.forceDone !== 'false'){
            this.done = true;
        }

        this.startWatch();
    }

    constructor(
            @RWSInject(WSService) private wsService: WSServiceInstance, 
            // @RWSInject(DateService) protected dateService: DateServiceInstance
        ){
        super();
    } 

    setOutput(content: string, addToExisting: boolean = false) {
        if (!content) {
            return;
        }

        if (addToExisting) {
            content = this.item.content + content;
        }

        this.item = {
            ...this.item,
            content,
        };
    }

    addContent(cnt: Partial<IRWSPromptJSON>, paste: boolean = false, error: boolean = false) {
        if(this.firstRun){
            this.firstRun = false;           
            return;
        }

        if (!cnt || !cnt.output) {
            return;
        }

        if (cnt.output === '') {
            return;
        }

        if (this.done) {
            return;
        }

        if (!this.item || !cnt || (typeof cnt.output !== 'string')) {
            return;
        }        

        this.item = {
            ...this.item,
            _prompt: cnt,
            created_at: new Date(),
            _error: error
        };

        this.typeIt(cnt.output, paste);
    }

    startWatch() {
        const _self = this;

        if (this.item.userMessage) {
            return;
        }       

        this.wsService.listenForMessage((wsData: {
            data: { message: string }
        }, isJson?: boolean) => {      
            if (this.done) {            
                return;
            }          
            
            console.warn(wsData.data.message);

            this.sysMessageList = [
                ...this.sysMessageList,
                {
                    userMessage: false,
                    author: 'system',
                    created_at: new Date(),
                    _is_system: true,
                    content: wsData.data.message,                
                },
            ];
        }, 'system_message_' + this.wsId);

        this.streamingWSChunks(this.wsId, this.addContent.bind(this));

        this.loadingString<IMessage, Partial<IRWSPromptJSON>>(this.item, this.addContent.bind(this),
            async (item: IMessage, addContentCallback: (cnt: Partial<IRWSPromptJSON>, paste?: boolean, error?: boolean) => void) => {
                if (item._promise) {
                    try {

                        const freshContent = await (item._promise) as any;
                        if (freshContent && freshContent.success) {                            
                            addContentCallback(freshContent.data._prompt);                    
                            return true;
                        } else if (!freshContent.success) {
                            if (!_self.errorShown) {
                                console.error('Message string request error:', freshContent.data.error);
                                addContentCallback({ output: `${freshContent.data.error.message}\nStack:\n${freshContent.data.error.stack}` }, true, true);

                                _self.errorShown = true;
                            }

                            return true;
                        }
                    } catch (e: Error | any) {
                        if (!_self.errorShown) {
                            console.error('Message string error:', e);
                            addContentCallback({ output: `${e.message}\nStack:\n${e.stack}` }, true, true);

                            _self.errorShown = true;
                        }
                        return true;
                    }
                }
                else if (item._stream) {
                    return _self.streaming;
                }
                else {
                    addContentCallback({ output: this.contentReturn.content });                    
                    return true;
                }


                return false;
            });
    }

    private typeIt(word: string, paste: boolean = false, delay: number = 250) {
        if (this.item.userMessage && this.item.content) {
            return;
        }

        setTimeout(() => {
            this.setOutput(word, !paste);
        }, delay);


    }

    encodeHTML(s: string) {
        return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    }

    alertSentInput = () => `<div class="input-prompt">${this.encodeHTML(this.item._prompt.sentInput).replace(/\n/g, '<br/>')}</div>`;
    alertIntruderInput = () => `<div class="input-prompt">${this.encodeHTML(this.item.intruder_prompt).replace(/\n/g, '<br/>')}</div>`;

    showAlert(txt: string) {
        if (this.item._prompt) {            
            this.notifyService.alert(txt);
        }
    }

    streamingWSChunks(wsId: string, addContentCallback: (cnt: Partial<IRWSPromptJSON>, paste?: boolean, error?: boolean) => void) {        
        if (this.listenersRunning) {
            return;
        }
        type ChunksType = { success: true, data: ILLMChunk, method: string };

        const startListenDisable = this.wsService.listenForMessage((data: ChunksType, isJson?: boolean) => {
            this.streaming = true;
            addContentCallback({ output: '' }, true);         
        }, 'prompt_response_start_' + wsId);

        this.wsService.listenForMessage((wsData: {
            data: {_prompt: IRWSPromptJSON }
          }, isJson?: boolean) => {                  
            if (this.done) {
                return;
            }    
            this.item = {
                ...this.item,
                _error: true,
                content: _ERROR_INFO_MSG,
            }

            console.error(wsData.data._prompt.output);

            this.streaming = true;
            this.done = true;            
                            
        }, 'prompt_response_error_' + wsId);    

        let i = 0;
        const chunkListenDisable = this.wsService.listenForMessage((data: ChunksType) => {            
            if (i === 0) {
                startListenDisable();
            }

            if (data?.data?.content) {
                addContentCallback({ output: data.data.content }, i === 0);
            }
            i++;
            this.$emit('scroll-down', {});
        }, 'prompt_response_chunk_' + wsId);               

        this.wsService.listenForMessage((data: {
            data: WSChatEndResponseType
        }, isJson?: boolean) => {            
            if (this.done) {
                return;
            }            

            const responseReturn: WSChatEndResponseType = data.data;

            chunkListenDisable();

            // addContentCallback(responseReturn._prompt);      
            this.item = { ...this.item, _prompt: responseReturn._prompt, _debug: { time: responseReturn._time_debug } };
            this.dbId = responseReturn._prompt.var_storage.msg_db_id;

            this.done = true;
            i = 0;
            // this.streaming = false;            
            setTimeout(() => this.$emit('scroll-down', {}), 500);
        }, 'prompt_response_end_' + wsId);

        this.listenersRunning = true;
    }


    time(format: string = 'd.m.Y H:mm:ss') {
        return moment(this.item.created_at).format(format);
        // return this.dateService.use(this.item.created_at).format(format);
    }

    timeDebugOutput(): string {
        let output = '';

        let i = 0;
        Object.keys(this.item._debug.time).forEach((key) => {
            output += (i > 0 ? ', |' : '|') + key + '| : ' + (this.item._debug.time[key] / 1000).toFixed(2) + 's';
            i++;
        });

        return output;
    }

    async doScoreMsg(score: number): Promise<void> {
        this.apiService.back.get<any>('talk:score:response', { routeParams: { messageId: this.dbId, score: score.toString() } });
        this.scored = true;
        return;
    }

    scoreMsg = this.doScoreMsg.bind(this);
    printTimeDebug = this.timeDebugOutput.bind(this);

    headerEnabledChanged(oldValue: string, newValue: string) {
        if (newValue === '' || typeof newValue === 'undefined' || newValue === null) {
            return;
        }

        if (newValue !== null) {
            this.visibility = { ...this.visibility, author: this.parseBool(newValue) };
        }
    }

    dateEnabledChanged(oldValue: string, newValue: string) {
        if (newValue === '' || typeof newValue === 'undefined' || newValue === null) {
            return;
        }

        if (newValue !== oldValue) {
            this.visibility = { ...this.visibility, date: this.parseBool(newValue) };
        }

    }

    formatDate(date: Date, format: string){
        return moment(date).format(format);
    }

    convoIdChanged(oldVal: string, newVal: string) {
        if (oldVal !== newVal) {
            this.convoId = newVal;
        }
    }

    itemChanged(oldVal: IMessage, newVal: IMessage) {
        if (newVal && (!oldVal || oldVal.author !== newVal.author) && this.wsService) {          
            this.item = newVal;      
        }
    }

    private parseBool(input: any): boolean {
        if (typeof input === 'boolean' && input === true) {
            return input;
        }

        return input === 'true';
    }

    userImageChanged(oldVal: string, newVal: string) {
        if (newVal && oldVal !== newVal) {
            this.userImage = newVal;
        }
    }

    initialsChanged(oldVal: string, newVal: string) {
        if (newVal && oldVal !== newVal) {
            this.initials = newVal;
        }
    }

    wsIdChanged(oldVal: string, newVal: string) {
        if (newVal && oldVal !== newVal) {
            this.wsId = newVal;
        }
    }

    setPromptShow(value: boolean){
        this.promptShow = value;
    }

    splitLines(text: string): string
    {
        return text;
    }
}

WebChatMessage.defineComponent();

export { WebChatMessage, IMessage, IRWSPromptJSON };