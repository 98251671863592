import { WebChat } from './component';
import { _hyperParamsDefaults } from '@backend-types';

type ChangeHandlerType<T> = (oldVal: T, newVal: T) => void;
const _nth = <T>(array: T[], index: number): T => {
    return array.slice(index)[0];
}

function setHyperParam(this: WebChat, key: string, value: any): any
{
    const provider: string = this.chosenModel.providerName.toLowerCase();

    let parsedValue = value;

    const defaultVals = this.getDefaultHyperParams(provider);

    if(typeof defaultVals[key] !== typeof value){
        switch(typeof defaultVals[key]){
        case 'number':
            parsedValue = parseFloat(value);
            break;

        case 'string':
            parsedValue = value.toString();
            break;
        default:  

            parsedValue = value;
        }  
    }

    return parsedValue;    
}

function getDefaultParams(this: WebChat, provider: string | null)
{
    let params: any = null;

    if(!provider){
        return {};
    }

    params = _hyperParamsDefaults[provider];

    if(!params){
        throw new Error('no default hyperparameters definded for provider: ' + provider);
    }
  
    switch(provider){
    case 'amazon': 
        // eslint-disable-next-line no-case-declarations
        const amazonVersion = _nth(this.chosenModel.modelId.split('.')[1].split('-'), -2); 

        if([
            'lite',
            'express'
        ].includes(amazonVersion)){
            params = {...params.llm}; 
        }else{
            params = {...params.embeddings}; 
        }        

        break;
    case 'cohere': 
        // eslint-disable-next-line no-case-declarations
        const cohereVersion = _nth(this.chosenModel.modelId.split('.')[1].split('-'), -2);
        if(cohereVersion === 'text'){
            params = {...params.text}; 
        }else{
            params = {...params.doc}; 
        }
    
        break;
    }

    return params;
}

function handleParamAttributes<K extends keyof WebChat>(key: K): ChangeHandlerType<string>
{
    const handler = function(this: WebChat, oldVal: string, newVal: string): void
    {
        if(newVal && oldVal !== newVal){
            const attrValue: string = parseFloat(this[key] as string).toFixed(1);

            const setPropertyName =  'custom' + key.slice(1) as keyof WebChat;

            (this as any)[setPropertyName] = parseFloat(attrValue);      
        }    
    };

    return handler;
}

interface IHyperHandler {
  hTemperature: ChangeHandlerType<string>
  hMaxTokensToSample: ChangeHandlerType<string>
  hTopK: ChangeHandlerType<string>
  hTopP: ChangeHandlerType<string>
}

function getParamChangeHandlers(this: WebChat): IHyperHandler
{
    return {
        hTemperature: handleParamAttributes('hTemperature').bind(this),
        hMaxTokensToSample: handleParamAttributes('hMaxTokensToSample').bind(this),
        hTopK: handleParamAttributes('hTopK').bind(this),
        hTopP: handleParamAttributes('hTopP').bind(this),
    };
}

export { getDefaultParams, setHyperParam, handleParamAttributes, getParamChangeHandlers, ChangeHandlerType, IHyperHandler };