import { IBoxData } from "../../types/box-data";

export function getBoxData(target: HTMLElement): IBoxData {
    const $el = target.getBoundingClientRect();
    return {
      w: $el.width,
      h: $el.height,
      top: $el.top,
      left: $el.left
    }
  }