const rwsTemplate: null = null;
const styles: null = null;
const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, ApiService, observable, ngAttr, externalObservable, RWSInject } from "@rws-framework/client";
import { attr } from '@microsoft/fast-element';

import { BookBindFindActionResponse, IBookBind } from "@backend-types";
import { WebChat } from "../webchat/component";
import { WSService, WSServiceInstance } from "@rws-framework/nest-interconnectors";

@RWSView('chat-loader', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ChatLoader extends RWSViewComponent  {
    @attr opened: string = 'true';
    @ngAttr course_id: string;
    @ngAttr lesson_id: string;

    @observable bookBinding: IBookBind | null = null;
    @observable instructorElligible: boolean = false;

    private called: boolean = false;

    constructor(@RWSInject(WSService) private wsService: WSServiceInstance) {
        super();

        const existingChatLoader: ChatLoader =  document.querySelector('chat-loader') as ChatLoader

        if (existingChatLoader) existingChatLoader.remove()
    }

    connectedCallback(): void {
        if (window.location.href.indexOf('ai_manager') > -1) {            
            this.doSuicide()
            return
        }
                
        this.callApi()
    }

    private async callApi(): Promise<void> {                        
        if (!this.course_id || this.called) return;

        this.called = true

        await this.getBookBind()

        if (!this.bookBinding) {
            this.sendBinding()
            this.doSuicide()
            return
        }

        const savedBindingString = sessionStorage.getItem('webchat_storage_ai_binding')
        
        let newChat = true

        const existingWebChat: WebChat = document.querySelector('web-chat') as WebChat
        if (existingWebChat && savedBindingString) {
            const savedBinding = JSON.parse(savedBindingString)
            newChat = savedBinding.id !== this.bookBinding.id
        }

        if (newChat) {
            this.createWebChat()
        } else {
            this.doSuicide()
            this.sendBinding()
        }
    }

    private doSuicide() {
        const componentHost = this.getShadowRoot().host as HTMLElement
        componentHost.remove()
    }

    private clearWebChats() {
        const webChat: NodeListOf<WebChat> =  document.querySelectorAll('web-chat') as NodeListOf<WebChat>
        webChat.forEach(element => element.remove())
    }

    private createWebChat(clear = true): void {
        this.clearWebChats()

        const webChat: WebChat =  document.createElement('web-chat') as WebChat

        webChat.courseId = this.course_id
        webChat.lessonId = this.lesson_id
        webChat.custombookid = this.bookBinding.bookid

        if (this.bookBinding.chapternr !== '') {
            webChat.customchapternr = this.bookBinding.chapternr
        }

        if (this.bookBinding.persona) {
            webChat.personaId = this.bookBinding.persona.id
        }       

        webChat.opened = 'true'

       this.sendBinding()

        document.querySelector('body').append(webChat)

        this.doSuicide()
    }

    private sendBinding() {
        if (this.bookBinding) {
            sessionStorage.setItem('webchat_storage_ai_binding', JSON.stringify(this.bookBinding))
        } else {
            sessionStorage.removeItem('webchat_storage_ai_binding')
        }

        const bindingEvent = new CustomEvent<IBookBind>('jnct:ai-binding:set', {
            detail: this.bookBinding
        })

        document.documentElement.dispatchEvent(bindingEvent);
    }

    private async getBookBind(): Promise<void> {
        try {
            // AI Review - note that we don't check ACL here and everyone gets the button
            const bindingReturn = (await this.apiService.back.get<BookBindFindActionResponse>('course:book-bindings:find', {
                routeParams: { courseId: this.course_id, lessonId: this.lesson_id ? this.lesson_id : 'none' }
            })).data
            this.bookBinding = bindingReturn as IBookBind
        } catch(e) {
            // AI Review - shouldn't this reconnect??? 
            this.wsService.disconnect()
        }
    }
}

ChatLoader.defineComponent();

export { ChatLoader }