import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="book-bind-form">
    <form>        

        <div class="form-group">
            <label for="bookSelect">Book</label>
            <fluent-select title="Choose book" id="bookSelect" @change="${ (x, c) => x.changeBook(c.event)}">                
                ${T.repeat(x => x.books, T.html`<fluent-option value="${(item, c) => item.id}">${item =>
                    item.title}</fluent-option>`)}
            </fluent-select>
        </div>
        <div class="form-group">
            <label for="lessonSelect">Lesson</label>
            <fluent-select title="Choose lesson" id="lessonSelect" @change="${ (x, c) => x.changeLesson(c.event)}">
                <fluent-option :value="">All</fluent-option>
                ${T.repeat(x => x.courseLessons, T.html`<fluent-option
                    value="${(item, c) => item._id}">${item => item.title}</fluent-option>`)}
            </fluent-select>
        </div>

        <div class="form-group">
            <label for="chapterSelect">Chapter</label>
            <fluent-select id="chapterSelect" :disabled="${ x => x.chapters === 0}" title="Choose chapter"
                @change="${ (x, c) => x.changeChapter(c.event)}">
                <fluent-option :value="">All chapters</fluent-option>
                ${T.repeat(x => x.chaptersList, T.html`<fluent-option value="${(item, c) => c.index}">${(item, c) =>
                    c.index + 1}: ${item => item.title}</fluent-option>`, { positioning: true })}
            </fluent-select>
        </div>

        <div class="form-group">
            <label for="chapterSelect">Persona</label>
            <fluent-select title="Choose persona" id="personaSelect" @change="${ (x, c) => x.changePersona(c.event)}">
                <fluent-option :value="">Default</fluent-option>
                ${T.repeat(x => x.personas, T.html`<fluent-option value="${(item, c) => item.id}">${item =>
                    item.title}</fluent-option>`)}
            </fluent-select>
        </div>

        <div class="form-group has-buttons">
            <fluent-button
                :disabled="${ x => x.selectedBookId === null }"
                @click="${ x => x.addBinding()}">Save</fluent-button>
        </div>
    </form>
    <hr />
</div>`;

import './styles/layout.scss';
const styles = T.css`.book-bind-form {
  width: 100%;
}

fluent-button {
  --neutral-foreground-rest: #000;
}

form {
  display: flex;
  flex-wrap: wrap;
}
form * {
  box-sizing: border-box;
}
form .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px;
}
form .form-group label {
  font-weight: bold;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  form .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
form .form-group.has-buttons {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
@media screen and (max-width: var(--rws-md-width, 1200px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-sm-width, 992px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: var(--rws-xs-width, 768px)) {
  form .form-group.has-buttons {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
form .form-group fluent-text-field, form .form-group fluent-select {
  min-width: 0px;
  width: 100%;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, ngAttr, attr, RWSInject, ApiService, ApiServiceInstance } from "@rws-framework/client";
import { ILesson, IBook, IBookBind, ICourse, LessonListActionResponse, IChapterDBEntry, IPersona, ControllerActionResponse } from '@backend-types'
import PyBackApiService, { PyBackApiServiceInstance } from "../../../../services/PyBackApiService";


export type BindingPayload = {
    lessonId: string,
    bookId: string,
    chapterNr: number,
    persona?: string
}

@RWSView('book-binder-form', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class BookBinderForm extends RWSViewComponent  {

    @attr course_id: string;

    @observable courseLessons: ILesson[];
    @observable books: IBook[] = [];
    @observable chapters: number = 0;
    @observable chaptersList: IChapterDBEntry[] = [];

    @observable selectedLessonId: string | null = null;
    @observable selectedBookId: string| null = null;
    @observable selectedChapterNr:number| null = null;
    @observable selectedPersona: string| null = null;

    @observable personas: IPersona[] = [];

    constructor(
        @RWSInject(PyBackApiService) private pyService: PyBackApiServiceInstance
    ){
        super();
    }

    connectedCallback()
    {
        super.connectedCallback();  
        this.getLessons();     
        this.getPersonas();   
    }

    private async getLessons(): Promise<void>
    {
        this.courseLessons = ((await this.pyService.get<ICourse>(`collection/courses/${this.course_id}/`)).lessonsData);        
        this.apiService.back.get<IBook[]>('train:get:books').then((books: IBook[]) => {
            this.books = books;      

            if( this.books.length){
                this.selectedBookId = this.books[0].id;      
            }            
        });
    }

    changeLesson(event: CustomEvent<HTMLSelectElement | null>)
    {
        const lessonId = event.detail.value;

        this.selectedLessonId = lessonId === '' || lessonId === 'All' ? null : lessonId;
    }
    

    changeBook(event: CustomEvent<HTMLSelectElement | null>)
    {
        if(event.detail.value !== ''){        
            this.selectedBookId = event.detail.value;
     
        }else{
            this.selectedBookId = null;                   
        }        
    }

    selectedBookIdChanged(oldVal: string | undefined, newVal: string | null)
    {
        if(newVal){
            this.scanChapters(newVal);
        }else{
            this.chapters = 0;
            this.chaptersList = [];
        }
    }

    scanChapters(bookId: string | null){
        if(bookId !== '' || bookId !== null){
           
            const chapters: IChapterDBEntry[] = this.books.find(item => item.id === bookId).chapters;         

            this.chapters = chapters.length;

            const chapterlist: IChapterDBEntry[] = [];

            for(let i = 0; i< this.chapters; i++){
                chapterlist.push(chapters[i]);
            }

            this.chaptersList = chapterlist;      
            
           
            this.selectedBookId = bookId;
     
        }else{            
            this.chapters = 0;
            this.chaptersList = []            
        }        
    }

    changeChapter(event: CustomEvent<HTMLSelectElement | null>)
    {
        if(event.detail.value && event.detail.value !== '' && event.detail.value !== 'All'){
            this.selectedChapterNr = parseInt(event.detail.value);
        }else{
            this.selectedChapterNr = null;
        }        
    }

    changePersona(event: CustomEvent<HTMLSelectElement | null>)
    {
        this.selectedPersona = event.detail.value !== '' ? event.detail.value : null;
    }


    addBinding()
    {
        const lessonId = this.selectedLessonId;
        const bookId = this.selectedBookId;
        const chapterNr = this.selectedChapterNr;
        const persona = this.selectedPersona;

        const payload: BindingPayload = {
            lessonId,
            bookId,
            chapterNr,
            persona
        }

        this.getShadowRoot().querySelector('form').reset();

        this.dispatchEvent(new CustomEvent<BindingPayload>('change', {
            detail: payload
        }));

        this.selectedLessonId = null;        
        this.selectedChapterNr = null;
        this.selectedPersona = null;
    }

    private async getPersonas() {
        this.personas = (await this.apiService.back.get<ControllerActionResponse<IPersona[]>>('persona:resource:listadmin', { routeParams: { id: (window as any).labelBundle.reseller } })).data;
        console.log(this.personas);
    }
}

BookBinderForm.defineComponent();

export { BookBinderForm }